import React, { useState, useEffect} from 'react';
import BootStrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import {Modal, Button} from 'react-bootstrap';
import TablaBen from './TabDeducible.js';
import axios from './axios-config.js';
import swal from 'sweetalert';
import Moment from 'moment';
import $ from 'jquery';
import {
Col,  Fade,  Form,  FormGroup,    Input,  Label,  Row,
} from 'reactstrap';


const Pagination = () => {

  const [miVariable, setMiVariable] = useState([]);
    const [miVariableB, setMiVariableB] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [showModal1, setShowModal1] = useState(false);
  const [show, setShow]= useState(false);
  const [show1, setShow1]= useState(false);
  const handleClose1 = () => setShow1(false);
    const handleShow1 = () => setShow1(true)


    const ToogleTrueFalse1 = () => {
  setShowModal1(handleShow1);
}
  const aMoneda = (numero, opciones) => {
         // Valores por defecto
         opciones = opciones || {};
         opciones.simbolo = opciones.simbolo || "$";
         opciones.separadorDecimal = opciones.separadorDecimal || ".";
         opciones.separadorMiles = opciones.separadorMiles || ",";
         opciones.numeroDeDecimales = opciones.numeroDeDecimales >= 0 ? opciones.numeroDeDecimales : 2;
         opciones.posicionSimbolo = opciones.posicionSimbolo || "i";
         const CIFRAS_MILES = 3;

         // Redondear y convertir a cadena
         var num =parseFloat(numero);
         ////console.log(num);
         let numeroComoCadena = num.toFixed(opciones.numeroDeDecimales);

         // Comenzar desde la izquierda del separador o desde el final de la cadena si no se proporciona
         let posicionDelSeparador = numeroComoCadena.indexOf(opciones.separadorDecimal);
         if (posicionDelSeparador === -1) posicionDelSeparador = numeroComoCadena.length;
         let formateadoSinDecimales = "", indice = posicionDelSeparador;
         // Ir cortando desde la derecha de 3 en 3, y concatenar en una nueva cadena
         while (indice >= 0) {
             let limiteInferior = indice - CIFRAS_MILES;
             // Agregar separador si cortamos más de 3
             formateadoSinDecimales = (limiteInferior > 0 ? opciones.separadorMiles : "") + numeroComoCadena.substring(limiteInferior, indice) + formateadoSinDecimales;
             indice -= CIFRAS_MILES;
         }
         let formateadoSinSimbolo = `${formateadoSinDecimales}${numeroComoCadena.substr(posicionDelSeparador, opciones.numeroDeDecimales + 1)}`;
         return opciones.posicionSimbolo === "i" ? opciones.simbolo + formateadoSinSimbolo : formateadoSinSimbolo + opciones.simbolo;
     };
     // Probar
     const opcionesQuetzales = {
         numeroDeDecimales: 2,
         separadorDecimal: ".",
         separadorMiles: ",",
         simbolo: "Q ", // Con un espacio, ya que la función no agrega espacios
         posicionSimbolo: "i", // i = izquierda, d = derecha
     };
     const opcionesDolares = {
             numeroDeDecimales: 2,
             separadorDecimal: ".",
             separadorMiles: ",",
             simbolo: " USD", // Con un espacio, ya que la función no agrega espacios
             posicionSimbolo: "d", // i = izquierda, d = derecha
         };




useEffect(async() => {
  let datos=[];
  let mivaribleDep="";
  let parentesco="";




  axios.get(`${process.env.REACT_APP_API_URL}/it-api-clientes2/rs/operaciones/dataVehiculos/`+$('#certificado').val(),{
    headers: {
      'Authorization': `Bearer ${sessionStorage.getItem('jwtToken')}`,
    },
  }).then(json => 
    {
       if (json.status === 200) 
       {

            if (json.data.vehiculos.length>0)
            { 
              for (var i = 0; i < json.data.vehiculos.length; i++) 
              {
                  var d = new Date(json.data.vehiculos[i].fechaIngreso);

                  datos.push({ID:json.data.vehiculos[i].inciso,MARCA:json.data.vehiculos[i].modelosDeAutomovil.marcasDeAutomovil.nombreMarca,
                  PLACA:json.data.vehiculos[i].numeroDePlaca,
                  MODELO:json.data.vehiculos[i].modelosDeAutomovil.nombreModelo,ANIO:json.data.vehiculos[i].anio,SUMAASEGURADA:aMoneda(json.data.vehiculos[i].sumaAsegurada, opcionesQuetzales), USO:json.data.vehiculos[i].uso,TIPO:json.data.vehiculos[i].tiposDeVehiculo.nombreTipoVehiculo,INGRESO:Moment(d).format('L')});
              }
            }
            else
            {
                  var d = new Date(json.data.vehiculos.fechaIngreso);
                  datos.push({ID:json.data.vehiculos.inciso,MARCA:json.data.vehiculos.modelosDeAutomovil.marcasDeAutomovil.nombreMarca,
                  PLACA:json.data.vehiculos.numeroDePlaca,
                  MODELO:json.data.vehiculos.modelosDeAutomovil.modelosDeAutomovilPK.nombreModelo,ANIO:json.data.vehiculos.anio,SUMAASEGURADA:aMoneda(json.data.vehiculos.sumaAsegurada, opcionesQuetzales), USO:json.data.vehiculos.uso,TIPO:json.data.vehiculos.tiposDeVehiculo.nombreTipoVehiculo,INGRESO:Moment(d).format('L')});
            }
            mivaribleDep = datos;  

            setTimeout(
            function()
            {
            }, 9000);
            setMiVariable(mivaribleDep);
       } else {
        // swal("Error", "No se encontro información de Dependiente ", "warning");
       }
     }).catch(function(error) {

     });


}, []);

let datos_ben=[];
let parentesco_ben="";
let miVariableX='';
const rowEvents = {

  onClick: (e, row) =>{
  //console.log(row.ID);
$('#dato_deducible').val(row.ID);

    toogleTrueFalse1();
    //console.log('ingrese aca');

},


};
const defaultSorted = [
  {
    dataField: "ID",
    order: "DESC"
  }
];

const columns =[
      {dataField: "ID", text:"INCISO"},
      {dataField: "MARCA", text:"MARCA"},
      {dataField: "MODELO", text:"MODELO"},
      {dataField: "PLACA", text:"PLACA"},
      {dataField: "ANIO", text:"AÑO"},
        {dataField: "SUMAASEGURADA", text:"SUMA ASEGURADA"},
      {dataField: "USO", text:"USO"},
      {dataField: "TIPO", text:"TIPO VEHICULO"},
      {dataField: "INGRESO", text:"INGRESO"},
];



  const ModalContent = () => {
return(

<Modal size='xl' style={{maxWidth: '1600px', width: '100%'}} show={show1} onHide={handleClose1} >
      <Modal.Header  style={{background:'#872434', color:'white'}} closeButton>
      <Modal.Title >

      </Modal.Title>
    </Modal.Header>
      <Modal.Body>

<div className="col-xs-12 col-md-12">
                <div className="row">
                  <strong style={{
                      color: '#003479'
                    }}>Deducibles</strong>


                  </div>
                  <hr style={{
                      background: '#872434',
                      size: 5,
                      color: '#872434'
                    }}/>

                    <TablaBen/>
                  </div>
      </Modal.Body>
    <Modal.Footer>

    </Modal.Footer>
</Modal>
);
}
;

//const [show1, setShow1]= useState(false);
//const handleClose1 = () => setShow1(false);
//  const handleShow1 = () => setShow1(true)

const toogleTrueFalse1 = () => {
setShowModal1(handleShow1);
};


  return (

<div>
  <FormGroup row>
        <Col md="12">
    <BootStrapTable style={{maxWidth: '2600px', width: '20%'}}
      keyField="ID"
      data={miVariable}
      columns = {columns}
      rowEvents={rowEvents}
         defaultSorted={defaultSorted}
      pagination={paginationFactory()}
      striped
      bordered = {true}


      />
          </Col>
            </FormGroup>
{show1 ? <ModalContent /> : null}
</div>

  );
};

export default Pagination;
