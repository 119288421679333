import React, { useState, useEffect} from 'react';
import BootStrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import axios from './axios-config';
import swal from 'sweetalert';
import Moment from 'moment';
import $ from 'jquery';


const Pagination = () => {

  const [miVariable, setMiVariable] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [show, setShow]= useState(false);




useEffect(async() => {
  let datos=[];
  let mivaribleDep="";
  let parentesco="";




  axios.get(`${process.env.REACT_APP_API_URL}/it-api-clientes2/rs/operaciones/dependientes/`+$('#certificado').val(),{
    headers: {
      'Authorization': `Bearer ${sessionStorage.getItem('jwtToken')}`,
    },
  }).then(json => {
       if (json.status === 200) {

//console.log(json.data.tPolDependiente.length);
    if (json.data.tPolDependiente.length===undefined){

      if (json.data.tPolDependiente.parentesco==='1'){
      parentesco='Conyugue';

      }else{
      parentesco = 'Hij(@)';
      }

      var anio = json.data.tPolDependiente.fechaNacimiento.substring(0,4);
      var mes = json.data.tPolDependiente.fechaNacimiento.substring(5,7);
      var dia = json.data.tPolDependiente.fechaNacimiento.substring(8,10);

      var F = dia + '/' + mes + '/' + anio;

      mivaribleDep =[{ID:1,NOMBRES:json.data.tPolDependiente.primerNombre+' '+json.data.tPolDependiente.primerApellido, PARENTESCO:parentesco,FECHA:F}];

    }
    else{
  for (var i = 0; i < json.data.tPolDependiente.length; i++) {

  if (json.data.tPolDependiente[i].parentesco==='1'){
  parentesco='Conyugue';
  }else{
  parentesco = 'Hij(@)';
  }

  var anio = json.data.tPolDependiente[i].fechaNacimiento.substring(0,4);
  var mes = json.data.tPolDependiente[i].fechaNacimiento.substring(5,7);
  var dia = json.data.tPolDependiente[i].fechaNacimiento.substring(8,10);

  var F = dia + '/' + mes + '/' + anio;
    //datos.push({ID:i,NOMBRES:json.data.tPolDependiente[i].primerNombre+' '+json.data.tPolDependiente[i].primerApellido, PARENTESCO:parentesco,FECHA:Moment(d).format('L')});
    datos.push({ID:i,NOMBRES:json.data.tPolDependiente[i].primerNombre+' '+json.data.tPolDependiente[i].primerApellido, PARENTESCO:parentesco,FECHA:F});
    }
  mivaribleDep = datos;
  //console.log('antes'+datos);


}
  setTimeout(
   function()
   {

  //console.log(mivaribleDep);
     //do something special
   }, 9000);
   setMiVariable(mivaribleDep);
       } else {
         swal("Aviso", "No se encontro información de Dependiente ", "info");
       }
     }).catch(function(error) {

     })


}, []);

const columns =[
      {dataField: "ID", text:"ID"},
      {dataField: "NOMBRES", text:"Nombres y Apellidos"},
      {dataField: "PARENTESCO", text:"Parentesco"},
      {dataField: "FECHA", text:"Fecha Nacimiento"},

];

  return (
    <BootStrapTable style={{maxWidth: '1600px', width: '95%'}}
      keyField="ID"
      data={miVariable}
      columns = {columns}
      pagination={paginationFactory()}
      striped
      bordered = {true}
          wrapperClasses="table-responsive"
      />

  );
};
export default Pagination;
