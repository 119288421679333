import React, { useState, useEffect} from 'react'
import styled from 'styled-components';
import logo2 from './logo2.jpg';
import pol from './logopoliza.png';
import rec from './reclamosgm.png';
import pag from './pagos.jpg';
import ase from './ase.png';
import all from './all.png';
import asi from './LOGOGYT.png';
import {MultiStepForm, Step} from 'react-multi-form'
import {Button} from 'reactstrap';
import Forms from './Forms.js';
import Parte1 from './parte1.js';
import Parte2 from './parte2.js';
import TablaPoliza from './TablaPolizaDanos.js';
import TablaDep from './TablaVeh.js';
import TablaCob from './TablaCob.js';
import TablaInt from './TablaInt.js';
import TablaDire from './TablaDire.js';
import TablaBen from './TabDeducible.js';
import axios from './axios-config';
import swal from 'sweetalert';
import $ from 'jquery';
import LoadingOverlay from 'react-loading-overlay';
import BounceLoader from 'react-spinners/RingLoader';
import Moment from 'moment';
import 'moment/locale/es';
import loader from './pageLoader.gif';
import 'bootstrap/dist/css/bootstrap.min.css';
import { FaUserAlt } from 'react-icons/fa';
import { FaMailBulk } from 'react-icons/fa';
import { FaPhoneAlt } from 'react-icons/fa';
import { FaAddressCard } from 'react-icons/fa';
import { FaMapMarker } from 'react-icons/fa';
import { FaCalculator } from 'react-icons/fa';
import Select from 'react-select';
import {
  Card,
  CardBody,
  CardImg,
  CardTitle,
  CardText,
  CardHeader,
  CardFooter,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Row
} from 'reactstrap';



const Container = styled.div `
  max-width: 1800px;
  margin: 40px auto;
  @media(max-width: 690px) {
    width: 300px;
  }
`
const Poliza = () => {
  let validacion = 0;  


  const agregar_spinner_regional = (id_objeto) => {
    $("#" + id_objeto).addClass("overlay");
    $("#" + id_objeto).show();
  }

  const aMoneda = (numero, opciones) => {
         // Valores por defecto
         opciones = opciones || {};
         opciones.simbolo = opciones.simbolo || "$";
         opciones.separadorDecimal = opciones.separadorDecimal || ".";
         opciones.separadorMiles = opciones.separadorMiles || ",";
         opciones.numeroDeDecimales = opciones.numeroDeDecimales >= 0 ? opciones.numeroDeDecimales : 2;
         opciones.posicionSimbolo = opciones.posicionSimbolo || "i";
         const CIFRAS_MILES = 3;

         // Redondear y convertir a cadena
         var num =parseFloat(numero);
         ////console.log(num);
         let numeroComoCadena = num.toFixed(opciones.numeroDeDecimales);

         // Comenzar desde la izquierda del separador o desde el final de la cadena si no se proporciona
         let posicionDelSeparador = numeroComoCadena.indexOf(opciones.separadorDecimal);
         if (posicionDelSeparador === -1) posicionDelSeparador = numeroComoCadena.length;
         let formateadoSinDecimales = "", indice = posicionDelSeparador;
         // Ir cortando desde la derecha de 3 en 3, y concatenar en una nueva cadena
         while (indice >= 0) {
             let limiteInferior = indice - CIFRAS_MILES;
             // Agregar separador si cortamos más de 3
             formateadoSinDecimales = (limiteInferior > 0 ? opciones.separadorMiles : "") + numeroComoCadena.substring(limiteInferior, indice) + formateadoSinDecimales;
             indice -= CIFRAS_MILES;
         }
         let formateadoSinSimbolo = `${formateadoSinDecimales}${numeroComoCadena.substr(posicionDelSeparador, opciones.numeroDeDecimales + 1)}`;
         return opciones.posicionSimbolo === "i" ? opciones.simbolo + formateadoSinSimbolo : formateadoSinSimbolo + opciones.simbolo;
     };
     // Probar
     const opcionesQuetzales = {
         numeroDeDecimales: 2,
         separadorDecimal: ".",
         separadorMiles: ",",
         simbolo: "Q ", // Con un espacio, ya que la función no agrega espacios
         posicionSimbolo: "i", // i = izquierda, d = derecha
     };
    const     opcionesDolares = {
             numeroDeDecimales: 2,
             separadorDecimal: ".",
             separadorMiles: ",",
             simbolo: " USD", // Con un espacio, ya que la función no agrega espacios
             posicionSimbolo: "d", // i = izquierda, d = derecha
         };



  const limpiar_spinner = (id_objeto) => {
    //console.log("ingrese a limpieza");
    $('#' + id_objeto).removeClass("overlay");
    $('#' + id_objeto).html("");
    $("#" + id_objeto).hide();
  }
  useEffect(async () => {
    $('#nnnn').hide();
    $('#nfa').hide();
  
    // console.log($('#tipoPol').val());
    if ($('#tipoPol').val() === 'VA') {
      $('#nacla').hide();
      $("#nnnn").removeAttr("style");
      $('#nnnn').css('display', '');
      $("#nnnn").attr("style", "display:block");
      $('#nnnn').show();
      $('#nfa').hide();
    } else {
      $('#divveh').hide();
      // console.log('else daños');
  
      if ($('#tipoPol').val() === 'CERT' || $('#tipoPol').val() === 'MM' || $('#tipoPol').val() === 'EE' || $('#tipoPol').val() === 'TR' || $('#tipoPol').val() === 'INCEN' || $('#tipoPol').val() === 'TDINCEN' || $('#tipoPol').val() === 'FA' || $('#tipoPol').val() === 'RC' || $('#tipoPol').val() === 'CRI') {
        if ($('#tipoPol').val() === 'TDINCEN' || $('#tipoPol').val() === 'INCEN') {
          // console.log('sin intereses');
          $('#inte').hide();
          // $('#cobe').hide();
          $('#nadir').show();
          $('#valPrin').val(1);
        } else if ($('#tipoPol').val() === 'CRI') {
          $('#nadir').show();
          // $('#inte').hide();
          $('#valPrin').val(2);
          $('#cobe').show();
        } else {
          // console.log('con intereses');
          $('#inte').show();
          $('#nadir').show();
          // $('#cobe').show();
          $('#valPrin').val(2);
        }
        axios.get(`${process.env.REACT_APP_API_URL}/it-api-clientes2/rs/operaciones/ramos/` + $('#idepol').val(), {
          headers: {
            'Authorization': `Bearer ${sessionStorage.getItem('jwtToken')}`,
          },
        }).then(json => {
          // console.log('registro');
          if (json.status === 200) {
            if ($('#tipoPol').val() === 'FA') {
              $('#datGen').hide();
              $('#nacla').hide();
              $('#oculta_mc').show();
              $('#nfa').show();
              $('#nacla').hide();
              $('#doc_fianza').text(json.data.ramosXPoliza[0].documentoFianza);
              $("#garantia_fianza").text(json.data.ramosXPoliza[0].garantia);
              $('#beneficiario_fianza').text(json.data.ramosXPoliza[0].beneficiario);
              $('#descripcion_fianza').text(json.data.ramosXPoliza[0].descripcion);
              $('#descripcion_fianzaN').text(json.data.ramosXPoliza[0].descripcionFianza);
            } else {
              $('#oculta_mc').hide();
              if ($('#idclienteF').val() == '2391') {
                // console.log("entre por aca");
                $('#div_mc_ac').show();
                $("#suma_mc").text('Q. ' + json.data.ramosXPoliza[0].sumaAsegurada);
                $('#comi_mc').text(json.data.ramosXPoliza[0].pctComision);
                $('#descripcion_mc').text(json.data.ramosXPoliza[0].aclaracionesXPolizaList[0].descripcion);
              } else {
                $('#div_mc_ac').hide();
              }
              $('#nfa').hide();
              $('#nacla').show();
              // console.log($('#tipoPol').val());
              $("#suma_mc").show();
              if ($('#monedaPol').val() == 'Q') {
                $("#monto").val(aMoneda(json.data.ramosXPoliza[0].sumaAsegurada, opcionesQuetzales));
              } else {
                $("#monto").val(aMoneda(json.data.ramosXPoliza[0].sumaAsegurada, opcionesDolares));
              }
              // $('#cobe').hide();
            }
          } else {
            // swal("Error", "No se encontro información de Poliza", "warning");
          }
        }).catch(function (error) {
          // swal("Error", "No se encontro información  de Poliza", "warning");
        });
      }
    }
  }, []);

  const [active, setActive] = React.useState(1)
  const [loading, setLoading] = React.useState(false)
  return (<Container>
    <br></br>


        <div className="row">
          <div className="col-xs-12 col-md-4" align="left" id="oculta_mc" name="oculta_mc" style={{display:'none'}} >
            <strong style={{
                color: '#003479'
              }}>Información Póliza</strong>

            <hr style={{
                background: '#872434',
                size: 5,
                color: '#872434'
              }}/>
              <TablaPoliza/>
          </div>

          <div  className="col-xs-12 col-md-12" id="nnnn" name="nnnn">
            <div className="col-xs-12 col-md-12">
              <div className="row" >
                <h1><strong style={{
                    color: '#003479'
                  }}>Detalle Vehiculos</strong></h1>
                <h4>Si deseas ver tus deducibles puedes verlos dando clic en cada uno de los vehiculos del listado.</h4>

                </div>
                <hr style={{
                    background: '#872434',
                    size: 5,
                    color: '#872434'
                  }}/>
                  <TablaDep/>
                </div>
                <br></br>
                <br></br>
                <br></br>

                  </div>
                  <div  className="col-xs-12 col-md-8" id="nfa" name="nfa">
                    <div className="col-xs-12 col-md-12">
                      <div className="row" >
                        <strong style={{
                            color: '#003479'
                          }}>Datos de Fianza</strong>


                        </div>
                        <hr style={{
                            background: '#872434',
                            size: 5,
                            color: '#872434'
                          }}/>
                          <Col xs="12" lg="12">
                            <Label htmlFor="fecha">
                              <strong>Documento Fianza:</strong>&nbsp;<label id="doc_fianza" name="doc_fianza" value="" />
                            </Label>
                          </Col>
                          <br></br>
                          <br></br>

                          <Col xs="12" lg="12">
                            <Label htmlFor="poliza">
                              <strong>Garantia:</strong>&nbsp;<label id="garantia_fianza" name="garantia_fianza" value="" />
                            </Label>
                          </Col>
                          <br></br>
                          <br></br>
                            <Col xs="12" lg="12">
                              <Label htmlFor="poliza">
                                <strong>Beneficiario:</strong>&nbsp;<label id="beneficiario_fianza" name="beneficiario_fianza" value="" />
                              </Label>
                            </Col>
                            <br></br>
                            <br></br>
                          <Col xs="12" lg="12">
                            <Label htmlFor="aseguradora">
                              <strong>Descripción:</strong>&nbsp;<label id="descripcion_fianza" name="descripcion_fianza" value="" />
                            </Label>
                          </Col>
                          <br></br>
                          <br></br>
                          <Col xs="12" lg="12">
                            <Label htmlFor="aseguradora">
                              <strong>Descripción Fianza:</strong>&nbsp;<label id="descripcion_fianzaN" name="descripcion_fianzaN" value="" />
                            </Label>
                          </Col>
                                              </div>
                        <br></br>
                        <br></br>
                        <br></br>

                          </div>
                  <div  className="col-xs-12 col-md-12" id="nacla" name="nacla">
                    <div  className="col-xs-12 col-md-12" id="nadir" name="nadir"><h4>Dirección de Riesgo</h4>
                    <hr style={{
                        background: '#872434',
                        size: 5,
                        color: '#872434'
                      }}/>
                    <TablaDire/>
</div>


         <br></br>
         <div className="col-xs-12 col-md-12" id="inte" name="inte">
         <h4>Intereses o Bienes</h4>
          <TablaInt/>
          </div>
            <br></br>
            <br></br>
               <div className="col-xs-12 col-md-12" id="cobe" name="cobe">
<h4>Coberturas</h4>
<TablaCob/>
<br></br>
<br></br>
</div>
                          </div>

</div>





  </Container>)
}

export default Poliza
