import React, { useState, useEffect} from 'react';
import BootStrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  Collapse,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Fade,
  Form,
  FormGroup,
  Input,
  Label,
  Modal, ModalBody, ModalFooter, ModalHeader,
  Row,
} from 'reactstrap';
import axios from './axios-config';
import swal from 'sweetalert';
import Moment from 'moment';
import {FaUserAlt} from 'react-icons/fa';
import {FaMailBulk} from 'react-icons/fa';
import {FaPhoneAlt} from 'react-icons/fa';
import {FaAddressCard} from 'react-icons/fa';
import {FaMapMarker} from 'react-icons/fa';
import {FaLinkedin} from 'react-icons/fa';
import {FaFacebookSquare} from 'react-icons/fa';
import {FaInstagramSquare} from 'react-icons/fa';
import { FaCalculator } from 'react-icons/fa';
import $ from 'jquery';


const Pagination = () => {
  const [modalInfo, setModalInfo] = useState([]);
  const [miVariable, setMiVariable] = useState([]);

  const [showModal, setShowModal] = useState(false);
  const [show, setShow]= useState(false);
  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)

  swal("Se estan consultando tus datos de póliza, por favor espera un momento", {
buttons: false,
timer: 2000,
});
$('#carnetshow').hide();
  axios.get(`${process.env.REACT_APP_API_URL}/it-api-clientes2/rs/operaciones/certificado/`+$('#certificado').val(),{
    headers: {
      'Authorization': `Bearer ${sessionStorage.getItem('jwtToken')}`,
    },
  }).then(json => {

    //console.log('registro');
       if (json.status === 200) {
         $('#nom').text(json.data.tPolCertificado[0].idPoliza.idCliente.nombreComercial);
         $('#numero').text(json.data.tPolCertificado[0].idPoliza.numeroPoliza);
         $('#ramo').text(json.data.tPolCertificado[0].idPoliza.idTipoPoliza.descripcion);
         $('#aseguradora').text(json.data.tPolCertificado[0].idPoliza.idAseguradora.nombre);
         $('#vigencia').text(Moment(json.data.tPolCertificado[0].idPoliza.vigenciaInicio).format('L')+'-'+Moment(json.data.tPolCertificado[0].idPoliza.vigenciaFin).format('L'));
         $('#emision').text(Moment(json.data.tPolCertificado[0].idPoliza.fechaEmision).format('L'));
         $('#certi').text(json.data.tPolCertificado[0].numeroCertificado);
         if (json.data.tPolCertificado[0].carnet!=undefined){
           $('#carnet').text(json.data.tPolCertificado[0].carnet);
           $('#carnetshow').show();
         }
         else{
          $('#carnet').text('No Asociado');
         }
  //console.log('antes');
       //console.log('despuess'+$('#nombre').val());


  setTimeout(
   function()
   {
if (json.data.tPolCertificado[0].idPoliza.idCliente.nombreComercial===undefined){
   $('#nom').text(json.data.tPolCertificado[0].idPoliza.idCliente.primerNombre +' '+json.data.tPolCertificado[0].idPoliza.idCliente.segundoNombre+' '+
 json.data.tPolCertificado[0].idPoliza.idCliente.primerApellido+' '+json.data.tPolCertificado[0].idPoliza.idCliente.segundoApellido);
}else{
   $('#nom').text(json.data.tPolCertificado[0].idPoliza.idCliente.nombreComercial);
}

     $('#numero').text(json.data.tPolCertificado[0].idPoliza.numeroPoliza);
     $('#ramo').text(json.data.tPolCertificado[0].idPoliza.idTipoPoliza.descripcion);
     $('#aseguradora').text(json.data.tPolCertificado[0].idPoliza.idAseguradora.nombre);
     $('#vigencia').text(Moment(json.data.tPolCertificado[0].idPoliza.vigenciaInicio).format('L')+'-'+Moment(json.data.tPolCertificado[0].idPoliza.vigenciaFin).format('L'));
     $('#emision').text(Moment(json.data.tPolCertificado[0].idPoliza.fechaEmision).format('L'));
     $('#certi').text(json.data.tPolCertificado[0].numeroCertificado ? json.data.tPolCertificado[0].numeroCertificado : 1 );
     if (json.data.tPolCertificado[0].carnet!=undefined){
       $('#carnet').text(json.data.tPolCertificado[0].carnet);
       $('#carnetshow').show();
     }
     else{
      $('#carnet').text('No Asociado');
     }
  window.datos = json.data;
     //do something special
   }, 9000);
       } else {
         swal("Aviso", "No se encontro información de Poliza", "info");
       }
     }).catch(function(error) {

       swal("Aviso", "No se encontro información  de Poliza", "info");
     })


const columns =[
{dataField: "FECHA_INGRESO", text:"Fecha de ingreso"},
      {dataField: "TIPO_LOG", text:"Pasos del Proceso"},
      {dataField: "LOG", text:"Comentarios"},

];

  return (
    <div>
        <br></br>
    <Col xs="12" lg="12">
      <Label htmlFor="fecha">
        <strong>Nombre Poliza:</strong>&nbsp;<label id="nom" name="nom" value="" />
      </Label>
    </Col>
    <br></br>
    <br></br>

    <Col xs="12" lg="12">
      <Label htmlFor="poliza">
        <strong>Número Póliza:</strong>&nbsp;<label id="numero" name="numero" value="" />
      </Label>
    </Col>
    <br></br>
    <br></br>
      <Col xs="12" lg="12">
        <Label htmlFor="poliza">
          <strong>Número Certificado:</strong>&nbsp;<label id="certi" name="certi" value="" />
        </Label>
      </Col>
      <br></br>
      <br></br>
        <Col xs="12" lg="12" id="carnetshow" name="carnetshow">
          <Label htmlFor="carnet">
            <strong>Número de Carnet:</strong>&nbsp;<label id="carnet" name="carnet" value="" />
          </Label>
        </Col>
        <br></br>
        <br></br>
    <Col xs="12" lg="12">
      <Label htmlFor="aseguradora">
        <strong>Aseguradora:</strong>&nbsp;<label id="aseguradora" name="aseguradora" value="" />
      </Label>
    </Col>
    <br></br>
    <br></br>
    <Col xs="12" lg="12">
      <Label htmlFor="ramo">
        <strong>Ramo:</strong>&nbsp;<label id="ramo" name="ramo" value="" />
      </Label>
    </Col>
    <br></br>
    <br></br>
    <Col xs="12" md="2" lg="12">
      <Label htmlFor="emision">
        <strong>Emisión Poliza:</strong>&nbsp;<label id="emision" name="emision" value="" />
      </Label>
    </Col>
    <br></br>
    <br></br>
    <Col xs="12" lg="12">
      <Label htmlFor="vigencia">
        <strong>Vigencia Póliza:</strong>&nbsp;<label id="vigencia" name="vigencia" value="" />
      </Label>
    </Col>

    <br></br>
    <br></br>
    <br></br>
    <FormGroup className="form-actions">


    </FormGroup>

  </div>

  );
};
export default Pagination;
