import React, { useState, useEffect} from 'react';
import BootStrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import axios from './axios-config';
import swal from 'sweetalert';
import Moment from 'moment';
import $ from 'jquery';
import TablaCob from './TablaCob.js';
import TablaInt from './TablaInt.js';
import {Modal, Button} from 'react-bootstrap';
import {
Col,  Fade,  Form,  FormGroup,    Input,  Label,  Row,
} from 'reactstrap';


const Pagination = () => {

  const [miVariableDire, setMiVariableDire] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [show, setShow]= useState(false);
const [show1, setShow1]= useState(false);
      const handleShow1 = () => setShow1(true)
      const handleClose1 = () => setShow1(false);
    const [showModal1, setShowModal1] = useState(false);



useEffect(async() => {
  let datos=[];
  let datosiniG=[];
  let mivaribleDep="";
  let NOMBRE_INTERES="";


    axios.get(`${process.env.REACT_APP_API_URL}/it-api-clientes2/rs/operaciones/direcciones/`+$('#numeroPol').val()+'/'+$('#tipoPol').val()+'/'+$('#asegu').val(),{
      headers: {
        'Authorization': `Bearer ${sessionStorage.getItem('jwtToken')}`,
      },
    }).then(json => 
      {
        if(json.status===200)
        {

            for (var i = 0; i < json.data.vDirRiesgoCliente.length; i++) 
            {
              datosiniG.push({NOME:json.data.vDirRiesgoCliente[i].codigoDireccion,DIR:json.data.vDirRiesgoCliente[i].nomenclatura ? json.data.vDirRiesgoCliente[i].nomenclatura : 'N/D', COLO:json.data.vDirRiesgoCliente[i].nomenclatura ? json.data.vDirRiesgoCliente[i].colonia : 'N/D',
              ZOO:json.data.vDirRiesgoCliente[i].zona ? json.data.vDirRiesgoCliente[i].zona: 'N/D'});

              setTimeout(
                  function()
                  {
                    
              }, 12000);
            }
            setMiVariableDire(datosiniG);
        }    

      }).catch(function(error) 
      {

      });

  //console.log(miVariableDire)

    }, []);

    const rowEvents = {

      onClick: (e, row) =>{
      //console.log(row.NOME);
    $('#dato_cob').val(row.NOME);

        toogleTrueFalse1();
        //console.log('ingrese aca');

    },


    };
    const toogleTrueFalse1 = () => {
    setShowModal1(handleShow1);
    };

const columns =[
        {dataField: "NOME", text:"CODIGO"},
        {dataField: "DIR", text:"DIRECCION"},
        {dataField: "COLO", text:"COLONIA"},
        {dataField: "ZOO", text:"ZONA"}

];


  const ModalContent = () => {
return(

<Modal size='xl' style={{maxWidth: '1600px', width: '100%'}} show={show1} onHide={handleClose1} >
      <Modal.Header  style={{background:'#872434', color:'white'}} closeButton>
      <Modal.Title >

      </Modal.Title>
    </Modal.Header>
      <Modal.Body>

<div className="col-xs-12 col-md-12">
                <div className="row">
                  <strong style={{
                      color: '#003479'
                    }}>Coberturas</strong>


                  </div>
                  <hr style={{
                      background: '#872434',
                      size: 5,
                      color: '#872434'
                    }}/>

                    <TablaCob/>

                      <div className="row">
                        <strong style={{
                            color: '#003479'
                          }}>Interes o Bienes</strong>


                        </div>
                        <hr style={{
                            background: '#872434',
                            size: 5,
                            color: '#872434'
                          }}/>

                        <TablaInt/>
                  </div>
      </Modal.Body>
    <Modal.Footer>

    </Modal.Footer>
</Modal>
);
}
;

  return (<div>
  <FormGroup row>
        <Col md="12">
    <BootStrapTable style={{maxWidth: '1600px', width: '95%'}}
      keyField="COD"
      data={miVariableDire}
      columns = {columns}
      pagination={paginationFactory()}
        rowEvents={rowEvents}
      striped
      bordered = {true}
          wrapperClasses="table-responsive"
      />
    </Col>
       </FormGroup>
{show1 ? <ModalContent /> : null}
</div>
  );
};
export default Pagination;
