import React, { useState, useEffect} from 'react';
import styled from 'styled-components';
import BootStrapTable from "react-bootstrap-table-next";
import phone from './aseguradoras/phone.png';
import {FaSearch} from 'react-icons/fa';
import "./styles.css";
import {Button} from 'reactstrap';
import axios from './axios-config';
import swal from 'sweetalert';
import $ from 'jquery';
import 'moment/locale/es';
import 'bootstrap/dist/css/bootstrap.min.css';
import {
  Card,
  CardBody,
  CardImg,
  CardTitle,
  CardText,
  CardHeader,
  CardFooter,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Row
} from 'reactstrap';
import paginationFactory from "react-bootstrap-table2-paginator";
import BitacoraReclamo from './bitacora_Reclamo';
import {Modal} from 'react-bootstrap';

const Container = styled.div `
  max-width: 1800px;
  margin: 40px auto;
  @media(max-width: 690px) {
    width: 300px;
  }
`
const Poliza = () => {
  const [miVariableF, setMiVariableF] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [show, setShow]= useState(false);
  const handleClose1 = () => setShow(false);
  const handleShow1 = () => setShow(true);

const showdata = (data) => {
  //console.log(data.idREc);
  $('#idReclamoDet').val(data.idREc);
  setShowModal(handleShow1);
};

useEffect(async() => {
  let datosRec = [];
  let miVariableF='';
  var esAsazgua = false;
  if ($('#hdEsAzasgua').val()=="S"){
    esAsazgua = true;
  }

  axios.get(`${process.env.REACT_APP_API_URL}/it-api-clientes2/rs/operaciones/infoConAseg/`+$('#asegu').val(),{
    headers: {
      'Authorization': `Bearer ${sessionStorage.getItem('jwtToken')}`,
    },
  }).then(json => {
    if (json.status === 200) {
      if ((!esAsazgua)||((esAsazgua) && 
      ($('#numeroPol').val() == $('#hdNumPolAzasgua').val()))){
        $('#div_rec_info_mc').hide();
        $('#div_rec_info_azg').show();
        $('#div_rec_info').hide();
        $('#telefono_aseguradora').text('(502) ' + json.data.telefonoCabDanos.replace("//", " ó "));
        $('#ph').attr('href', 'tel:502'+json.data.telefonoCabDanos.replace("-","").substring(0, 8));

    axios.get(`${process.env.REACT_APP_API_URL}/it-api-clientes2/rs/operaciones/listadoReclamos/`+$('#idepol').val()+'/'+$('#hdClienteActual').val(),{
      headers: {
        'Authorization': `Bearer ${sessionStorage.getItem('jwtToken')}`,
      },
    }).then(json => {
      //console.log('Reclamos ',json );
         if (json.status === 200) {
         
          //console.log('Ingrese al servicio'+json.data.vGetlistadoreclamos.length);
          if( json.data.vGetlistadoreclamos.length>0){
            for (var i = 0; i < json.data.vGetlistadoreclamos.length; i++) {
                
              const idREc =  json.data.vGetlistadoreclamos[i].idReclamo;

              datosRec.push({indicador:json.data.vGetlistadoreclamos[i].estadoReclamo,
                monto:json.data.vGetlistadoreclamos[i].codigoMoneda + " " + json.data.vGetlistadoreclamos[i].monto,
                ingreso:json.data.vGetlistadoreclamos[i].fechaReclamo,
                reclamo:  <Button onClick={() => showdata({idREc})}  style={{
                  float: 'center',
                  color: '#ffffff',
                  background: '#003479'
                }} > <FaSearch size={18} />-<strong>{json.data.vGetlistadoreclamos[i].idReclamo}</strong></Button>,
                vencimiento:json.data.vGetlistadoreclamos[i].fechaResolucion,
                tipo:"Certificado"});
            }
            //console.log(datosRec);
          }else{
            const idREc =  json.data.vGetlistadoreclamos.idReclamo;
            datosRec.push({indicador:json.data.vGetlistadoreclamos.estadoReclamo,
                monto:json.data.vGetlistadoreclamos.codigoMoneda + " " + json.data.vGetlistadoreclamos.monto,
                ingreso:json.data.vGetlistadoreclamos.fechaReclamo,
                reclamo:  <Button onClick={() => showdata({idREc})}  style={{
                  float: 'center',
                  color: '#ffffff',
                  background: '#003479'
                }} ><FaSearch size={18} />-<strong>{json.data.vGetlistadoreclamos.idReclamo}</strong></Button>,
                vencimiento:json.data.vGetlistadoreclamos.fechaResolucion,
              tipo:"Certificado"});
          }
          miVariableF = datosRec;
            setMiVariableF(miVariableF);
          } else {
            swal("Error", "No se encontro información de  Poliza", "warning");
          }
        }).catch(function(error) {
          console.error(error);
          swal("Atención", "La póliza no cuenta con reclamos pendientes", "warning");
        })

      } else if ($('#idclienteF').val()=='2391'){
        $('#div_rec_info_mc').show();
        $('#div_rec_info').hide();
        $('#div_rec_info_azg').hide();
        $('#telefono_aseguradora_mc').text('(502) - 23868310 ');
        $('#phmc').attr('href', 'tel:50223868310');
      }else{
        $('#div_rec_info_mc').hide();
        $('#div_rec_info_azg').hide();
        $('#div_rec_info').show();
        $('#telefono_aseguradora').text('(502) ' + json.data.telefonoCabDanos.replace("//", " ó "));
        $('#ph').attr('href', 'tel:502'+json.data.telefonoCabDanos.replace("-","").substring(0, 8));
      }
    } else {
      swal("Error", "No se encontro información de Aseguradora", "warning");
    }
  }).catch(function(error) {
    swal("Error", "No eee encontro  información de Aseguradora", "warning");
  })
  }, []);


  const secondExample = {
    size: 50,
    count: 10,
    color: "black",
    activeColor: "yellow",
    value: 7.5,
    a11y: true,
    isHalf: true,
    emptyIcon: <i className="far fa-star" />,
    halfIcon: <i className="fa fa-star-half-alt" />,
    filledIcon: <i className="fa fa-star" />,
    onChange: newValue => {
      //console.log(`Example 2: new value is ${newValue}`);
    }
  };


  const agregar_spinner_regional = (id_objeto) => {
    $("#" + id_objeto).addClass("overlay");
    $("#" + id_objeto).show();
  }

  const ratingChanged = (newRating) => {
    //console.log(newRating);
  };

  const limpiar_spinner = (id_objeto) => {
    //console.log("ingrese a limpieza");
    $('#' + id_objeto).removeClass("overlay");
    $('#' + id_objeto).html("");
    $("#" + id_objeto).hide();
  }
  const columns=[];
  const [active, setActive] = React.useState(1)
  const [loading, setLoading] = React.useState(false)
  columns.push(
    {dataField: "reclamo", text:"No. Reclamo"},
    {dataField: "tipo", text:"Tipo Reclamo"},
    {dataField: "indicador", text:"Estado"},
    {dataField: "ingreso", text:"Fecha Siniestro"},
    {dataField: "monto", text:"Valor Pagado"},
    {dataField: "vencimiento", text:"Fecha Cierre"}
  );
  return (<Container>
        <div className="row">


<div  className="col-xs-12 col-md-12" align="left" id="div_rec_info" name="div_rec_info" style={{display:"none"}}>

  <center>  <h2><strong style={{
        color: '#003479'
      }}>IMPORTANTE</strong>
  </h2></center>
      <hr style={{
          background: '#872434',
          size: 9,
          color: '#872434'
        }}/>

    <br></br>

        <h3>Para la apertura y/ó seguimiento de un reclamo debes contactar directamente a la aseguradora, al siguiente número:
        </h3>
        <br></br>
        <center>  <Label htmlFor="Nombre">

        </Label>&nbsp;  <h1><label id="telefono_aseguradora"  name="telefono_aseguradora" value="" /></h1></center>
        <br></br>
        <br></br>
        <center><a id="ph" name = "ph"><img height="125px" src={phone} width="125px" alt="logo"/></a></center>

  </div>
  <div  className="col-xs-12 col-md-12" align="left" id="div_rec_info_mc" name="div_rec_info_mc" style={{display:"none"}}>

    <center>  <h2><strong style={{
          color: '#003479'
        }}>IMPORTANTE</strong>
    </h2></center>
        <hr style={{
            background: '#872434',
            size: 9,
            color: '#872434'
          }}/>

      <br></br>

          <br></br>
          <center>  <Label htmlFor="Nombre">

          </Label>&nbsp;  <h1><label id="telefono_aseguradora_mc"  name="telefono_aseguradora_mc" value="" /></h1></center>
          <br></br>
          <br></br>
          <center><a id="phmc" name = "phmc"><img height="125px" src={phone} width="125px" alt="logo"/></a></center>
    </div>
  </div>
  <div  className="col-xs-12 col-md-12" align="left" id="div_rec_info_azg" name="div_rec_info_azg" style={{display:"none"}}>
    <Modal size="xl" show={show} onHide={handleClose1}>
      <Modal.Header  style={{background:'#575a5d', color:'white'}} closeButton>
        <Modal.Title >
          Bitacora De Reclamo
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <BitacoraReclamo />
      </Modal.Body>
    </Modal>
    <Input type='hidden' id='idReclamoDet' name='idReclamoDet' />
    <BootStrapTable style={{maxWidth: '1600px', width: '95%'}}
      keyField="name"
      data={miVariableF}
      columns = {columns}
      pagination={paginationFactory()}
    />
  </div>

  </Container>)
}

export default Poliza
