import logo from './logo.png';
import regionalx from './regionalX.jpeg';
import './App.css';
import Forms from './Forms.js';
import React, { useState, useEffect, Component} from 'react';
import {Col, Input, Button, Row,Navbar,NavbarBrand,
NavbarToggler,Nav, NavItem,
Collapse,
NavbarText,
NavLink,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
Container} from 'reactstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import {FaAddressBook} from 'react-icons/fa';
import {FaMailBulk} from 'react-icons/fa';
import { FaUserAlt } from 'react-icons/fa';
import {FaWhatsappSquare} from 'react-icons/fa';
import {FaPhoneSquareAlt} from 'react-icons/fa';
import {FaLinkedin} from 'react-icons/fa';
import {FaFacebookSquare} from 'react-icons/fa';
import {FaInstagramSquare} from 'react-icons/fa';
import {FaSignInAlt} from 'react-icons/fa';
import {FaCalculator} from 'react-icons/fa';
import $ from 'jquery';
//import axios from 'axios';
import swal from 'sweetalert';
import axios from './axios-config';
require('dotenv').config()



class App extends Component
 {
   constructor(props) {        
    sessionStorage.removeItem("ejecutivoEncargado");
     super(props);     
     this.state = {
       state :"",
        msie: window.navigator.userAgent.indexOf('MSIE ') > -1 ,
        trident : window.navigator.userAgent.indexOf('Trident/') > -1 ,
        edge : window.navigator.userAgent.indexOf('Edge/') > -1,
        rev:false,
         isOpen: false
      }

     this.toggle1 = this.toggle1.bind(this);
     this.toggle = this.toggle.bind(this);
      this.reload = this.reload.bind(this);
  //   this.newContact = this.newContact.bind(this);
    

   }

reload(){
  swal({
      title: 'Somit',
      text: 'Espera un momento, se esta cerrando tu sesión, esperamos que vuelvas pronto',
      icon: 'success',
      timer: 4000,
      buttons: false,
  })
  .then(() => {
  window.location.reload(true);
  })

}
   toggle() {
     this.setState({
       isOpen: !this.state.isOpen
     });
   }

   toggle1 = () => {    
    //console.log( $('#email_input').val());
    axios.post(`${process.env.REACT_APP_API_URL}/it-api-clientes2/rs/operaciones/contacto_nuevo/` + $('#email_input').val(),{
      headers: {
        'Authorization': `Bearer ${sessionStorage.getItem('jwtToken')}`,
      },
    }).then(json => {
      if (json.status === 200) {
        //console.log(json);
        swal("Ingreso Exitoso", "Se ha almacenado correctamente tu correo", "success");
      } else {
        swal("Error", "No se pudo guardar", "warning");

      }
    }).catch(function(error) {

      swal("Error", "No ha ingresado", "warning");

    })

  }

render(){

  setTimeout(function(){
    axios.get(`${process.env.REACT_APP_API_URL}/it-api-clientes2/rs/operaciones/validacion/1`,{
      headers: {
        'Authorization': `Bearer ${sessionStorage.getItem('jwtToken')}`,
      },
    }).then(azasgua => {
      if (azasgua.status === 200) {
        //console.log("encontre bd 200")
        $("#hidedpi").val(azasgua.data.azasguaDpi);
        $("#hidefecnac").val(azasgua.data.azasguaFechaNac);
        $("#hdPagadoresAzasgua").val(azasgua.data.azasguaPagadores);
        $('#hdNumPolAzasgua').val(azasgua.data.azasguaPoliza);
        $('#hdClienteAzasgua').val(azasgua.data.azasguaClientePoliza);
      }else{
        //console.log("Error",azasgua.status);
        $("#hidedpi").val("2325977");
        $("#hidefecnac").val("1999-05-01T00:00:00-06:00");
        $("#hdPagadoresAzasgua").val("1800|1801|1803|1804|1806|498956");
        $("#hdEsAzasgua").val("N");
        $('#hdNumPolAzasgua').val("537");
        $('#hdClienteAzasgua').val("72");
      }
    }).catch(function(error) {
      //console.log("Excepcion",error);
      $("#hidedpi").val("2325977");
      $("#hidefecnac").val("1999-05-01T00:00:00-06:00");
      $("#hdPagadoresAzasgua").val("1800|1801|1803|1804|1806|498956");
      $("#hdEsAzasgua").val("N");
      $('#hdNumPolAzasgua').val("537");
      $('#hdClienteAzasgua').val("72");
    });
  },1000)

  if (this.state.msie==true || this.state.trident==true || this.state.edge==true){
    //console.log('explorer')
        swal("Error", "Estas utilizando Internet Explorer, por favor intentar con otro navegador", "error");
            this.setState({ rev: false });
        }

  return (
    <div >

    <div id="principal" name="principal" style={{
        backgroundImage: `url(${regionalx})`,

        height: "auto",
        width: "auto"
      }}>
      <br></br>
        <Navbar light expand="md">
          <NavbarBrand href="/">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</NavbarBrand>

              <NavbarToggler onClick={this.toggle} />

            <Collapse isOpen={this.state.isOpen} navbar style={{flexDirection: 'row-reverse'}} id="logout" name="logout" >
              <img id="mainLogo" name="mainLogo" height="100%" src={logo} width="220px" alt="logo"/>
              <Nav className="ml-auto" navbar>
              <NavItem id="nvClienteExterno" name="nvClienteExterno" style={{display:'none'}}>
                  <NavLink style={{color:'#872434'}}><h4><strong><label id="nomClienteExterno" name="nomClienteExterno"></label></strong></h4></NavLink>
                </NavItem>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <NavItem id="loga" name="loga" style={{display:'none'}}>
                  <NavLink style={{color:'#872434'}}><h4><a onClick={() => this.reload()} ><strong><FaUserAlt />&nbsp;&nbsp;Desconectar</strong></a></h4></NavLink>
                </NavItem>

              </Nav>

            </Collapse>

          </Navbar>

            <div>
        <input type="hidden" id="hidedpi" name="hidedpi" val=""></input>
        <input type="hidden" id="hidefecnac" name="hidefecnac" val=""></input>
        <input type="hidden" id="hdClienteAzasgua" name="hdClienteAzasgua" val="0"></input>
        <input type="hidden" id="hdEsAzasgua" name="hdEsAzasgua" val="N"></input>
        <input type="hidden" id="hdPagadoresAzasgua" name="hdPagadoresAzasgua" val="0"></input>
        <input type="hidden" id="hdNumPolAzasgua" name="hdNumPolAzasgua" val="0"></input>
        <input type="hidden" id="hdClienteActual" name="hdClienteActual" val="0"></input>
      </div>
{(!this.state.msie || !this.state.trident || !this.state.edge) && <Forms/>}

      <br></br>
      <br></br>
      <br></br>

    </div>

    <footer style={{
        background: '#872434',
        height: "auto",
        color: '#FFFFFF'
      }}>
      <Row>
        <div className="row" >
          <Col xs="12" md="4" style={{
              padding: '30px'
            }}>
            <h2>SERVICIO AL CLIENTE</h2>
            <hr style={{
                background: '#FFFFFF',
                size: 5,
                color: '#FFFFFF'
              }}/>
            <br></br>
            <div align="left">
              <div>

                <FaAddressBook title="Pagina" size='40px'/>&nbsp;&nbsp;<a title="Pagina" style={{
      height: "auto",
      color: '#FFFFFF',
      textDecoration:'none'
    }} href="https://www.somit.com/" target="_blank" rel="noopener noreferrer">www.somit.com</a>

              </div>
              <div>

                <FaPhoneSquareAlt title="Telefono" size='40px'/>&nbsp;&nbsp;<a title="Telefono" style={{
      height: "auto",
      color: '#FFFFFF',
      textDecoration:'none'
    }} href="tel:+50223868310" target="_blank" rel="noopener noreferrer">Cabina 24/7 (502) 2386-8310</a>

              </div>
              <div>

                <FaPhoneSquareAlt title="Telefono" size='40px'/>&nbsp;&nbsp;<a title="Telefono" style={{
      height: "auto",
      color: '#FFFFFF',
      textDecoration:'none'
    }} href="tel:+50240075257" target="_blank" rel="noopener noreferrer">Cabina de emergencia Gastos Médicos (502) 4007-5257</a>

              </div>
              <div>
                <FaMailBulk title="Correo" size='40px'/>&nbsp;&nbsp;<a title="Correo" style={{
      height: "auto",
      color: '#FFFFFF',
      textDecoration:'none'
    }} href="mailto:contactenosgt@somit.com?subject=Consulta General" target="_blank" rel="noopener noreferrer">contactenosgt@somit.com</a>

              </div>
              <div>
                <br></br>
                <Button style={{background:'transparent'}} id="email" name="email" href="https://www.somit.com/cotizadores/" target="_blank" rel="noopener noreferrer" type="submit">COTIZAR</Button>


              </div>
            </div>
          </Col>

          <Col xs="12" md="4" style={{
              padding: '30px'
            }}>
            <h2>REDES SOCIALES</h2>
            <hr style={{
                background: '#FFFFFF',
                size: 5,
                color: '#FFFFFF'
              }}/>
            <br></br>
            <div align='left'>
              <div >
                <a style={{
                    color: '#FFFFFF',
                    textDecoration:'none'
                  }} href='https://instagram.com/somit_corredores_de_seguros' target="_blank" rel="noopener noreferrer">
                  <FaInstagramSquare style={{
                      height: "auto",
                      color: '#FFFFFF'
                    }} size="40px"/>@somit_corredores_de_seguros</a>
              </div>
              <div >
                <a style={{
                    color: '#FFFFFF',
                    textDecoration:'none'
                  }} href='https://www.facebook.com/somitseguros/' target="_blank" rel="noopener noreferrer">
                  <FaFacebookSquare style={{
                      height: "auto",
                      color: '#FFFFFF'
                    }} size="40px"/>somit_corredores_de_seguros</a>
              </div>
              <div>
                <a style={{
                    color: '#FFFFFF',
                    textDecoration:'none'
                  }} href='https://www.linkedin.com/company/somit-corredores-de-seguros/mycompany/' target="_blank" rel="noopener noreferrer">
                  <FaLinkedin style={{
                      height: "auto",
                      color: '#FFFFFF'
                    }} size="40px"/>somit_corredores_de_seguros</a>
              </div>
              <div>
                <a style={{
                    color: '#FFFFFF'
                    ,
                    textDecoration:'none'
                  }} href='https://wa.me/50222856501' target="_blank" rel="noopener noreferrer">
                  <FaWhatsappSquare style={{
                      height: "auto",
                      color: '#FFFFFF'
                    }} size="40px"/>(502) 2285-6501</a>
              </div>
              <div>
                <a style={{
                    color: '#FFFFFF',
                    textDecoration:'none'
                  }} href='https://wa.me/50240075257' target="_blank" rel="noopener noreferrer">
                  <FaWhatsappSquare style={{
                      height: "auto",
                      color: '#FFFFFF'
                    }} size="40px"/>Cabina de emergencia Gastos Médicos (502) 4007-5257</a>
              </div>
            </div>
          </Col>
          <Col xs="12" md="4" style={{
              padding: '30px'
            }}>
            <h2>NEWSLETTER</h2>
            <hr style={{
                background: '#FFFFFF',
                size: 5,
                color: '#FFFFFF'
              }}/>
            <br></br>
            <div>
              Recibe información de nuestras últimas novedades en productos y asesoria
            </div>
            <br></br>
            <div>
              <Input id="email_input" name="email_input" type="email" placeholder="Ingresa tu correo @" size="2" bsSize="2"></Input>
            </div>
            <br></br>
            <div>
              <Button style={{background:'transparent'}} id="email" name="email" onClick={() => this.toggle1()} type="submit">SUSCRIBETE</Button>
            </div>
          </Col>
        </div>
      </Row>
      <Row>
        <br></br>
        <div className="row" align="center">
          <Col xs="4" md="4"></Col>
          <Col xs="12" md="4">
            <h6>
              <strong>Somit Corredores de Seguros S.A. 1990 - 2022</strong>
            </h6>
          </Col>
          <Col xs="4" md="4"></Col>

        </div>
      </Row>
    </footer>
  </div>);
}
}
export default App;
