import React, { useState, useEffect} from 'react';
import styled from 'styled-components';
import BootStrapTable from "react-bootstrap-table-next";
import phone from './aseguradoras/phone.png';
import "./styles.css";
import axios from './axios-config';
import swal from 'sweetalert';
import $ from 'jquery';
import 'moment/locale/es';
import 'bootstrap/dist/css/bootstrap.min.css';
import {
  Card,
  CardBody,
  CardImg,
  CardTitle,
  CardText,
  CardHeader,
  CardFooter,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Row
} from 'reactstrap';
import paginationFactory from "react-bootstrap-table2-paginator";

const Container = styled.div `
  max-width: 1800px;
  margin: 40px auto;
  @media(max-width: 690px) {
    width: 300px;
  }
`

const BitacoraReclamo = () => {
  
  const [miVariableF, setMiVariableF] = useState([]);
  const idReclamo = $('#idReclamoDet').val();

useEffect(async() => {
  let datosRec = [];
  let miVariableF='';
  var esAsazgua = false;
  if ($('#hdEsAzasgua').val()=="S"){
    esAsazgua = true;
  }
      if ((!esAsazgua)||((esAsazgua) && 
      ($('#numeroPol').val() == "537"))){
        $('#div_rec_det_azg').show();
    axios.get(`${process.env.REACT_APP_API_URL}/it-api-clientes2/rs/operaciones/detalleReclamo/N/`+ idReclamo,{
      headers: {
        'Authorization': `Bearer ${sessionStorage.getItem('jwtToken')}`,
      },
    }).then(json => {
         if (json.status === 200) {
          if( json.data.vGetdetallereclamo.length>0){
            for (var i = 0; i < json.data.vGetdetallereclamo.length; i++) {
              datosRec.push({fecha:json.data.vGetdetallereclamo[i].fecha,
                actividad:json.data.vGetdetallereclamo[i].actividad,
                descripcion:json.data.vGetdetallereclamo[i].descripcion,
                nombre:json.data.vGetdetallereclamo[i].nombre});
            }
          }else{
            datosRec.push({fecha:json.data.vGetdetallereclamo.fecha,
              actividad:json.data.vGetdetallereclamo.actividad,
              descripcion:json.data.vGetdetallereclamo.descripcion,
              nombre:json.data.vGetdetallereclamo.nombre});
          }
          miVariableF = datosRec;
            setMiVariableF(miVariableF);
          } else {
            swal("Error", "No se encontro información de  Poliza", "warning");
          }
        }).catch(function(error) {
          swal("Atención", "No se encontró bitacora para el reclamo seleccionado", "warning");
        })
      }
  }, []);

  const columns=[];
  columns.push(
    {dataField: "fecha", text:"Fecha"},
    {dataField: "actividad", text:"Actividad"},
    {dataField: "nombre", text:"Asistente"},
    {dataField: "descripcion", text:"Descripcion"}
  );
  return (<Container>
        
  <div  className="col-xs-12 col-md-12" align="left" id="div_rec_det_azg" name="div_rec_det_azg" style={{display:"none"}}>
    <Input type='hidden' id='idReclamoDet' name='idReclamoDet' />
    <BootStrapTable style={{maxWidth: '1600px', width: '95%'}}
      keyField="name"
      data={miVariableF}
      columns = {columns}
      pagination={paginationFactory()}
    />
    <br/>
  </div>

  </Container>)
}

export default BitacoraReclamo
