import React, { useState } from "react";
import "./App.css";
import General from "./general.js";
import FormAct from "./FormsAct.js";
import mclogo from "./mclogo.jpg";
import mcasaz from "./azaslogo.png";
import "bootstrap/dist/css/bootstrap.min.css";
import { FaUserAlt } from "react-icons/fa";
import { FaBuilding } from "react-icons/fa";
import { FaMailBulk } from "react-icons/fa";
import { FaPhoneAlt } from "react-icons/fa";
import { FaAddressCard } from "react-icons/fa";
import { FaMapMarker } from "react-icons/fa";
import { Modal } from "react-bootstrap";
import $ from "jquery";
import Moment from "moment";
import axios from "./axios-config";
import swal from "sweetalert";
function AppCentral() {
  const [Log, setLog] = useState([]);
  let fecha_val = $("#hidefecnac").val();
  //console.log(fecha_val);
  //console.log($("#hidedpi").val());
  axios
    .get(
      `${process.env.REACT_APP_API_URL}/it-api-clientes2/rs/operaciones/informacion/` +
        $("#hidedpi").val() +
        "/" +
        Moment.utc(fecha_val).format("YYYY-MM-DD"),{            
          headers: {
            'Authorization': `Bearer ${sessionStorage.getItem('jwtToken')}`,
          },
        }
    )
    .then((json) => {
      if (json.status === 200) {
        //console.log("DONDE MUESTRA LA IMAGEN: ", json.data)
        //console.log(json.data.idCliente);
        let pri_nombre = json.data.primerNombre
          ? json.data.primerNombre
          : json.data.razonSocial;
        let seg_nombre = json.data.segundoNombre
          ? json.data.segundoNombre
          : " ";
        let telefono = "Sin Registro";
        if (json.data.tcontactoClienteList.length > 0) {
          if (
            json.data.tcontactoClienteList[0].telefono != null ||
            json.data.tcontactoClienteList[0].telefono != undefined
          ) {
            telefono = "(502) " + json.data.tcontactoClienteList[0].telefono;
          }
        }
        window.infoCliente = pri_nombre;                
        //console.log(json);
        if (json.data.idCliente == 2391) {
          $("#nombre").text(pri_nombre);

          //console.log("es cliente de macdonald");
          $("#todo").hide();
          $("#solo").show();
          $("#soloA").hide();
          $("#soloM").show();
        } else if (json.data.idCliente == 16344) {
          ////console.log("es cliente de azasgua");
          $("#todo").hide();
          $("#soloA").show();
          $("#soloM").hide();
        } else if (json.data.idCliente == $("#hdClienteAzasgua").val()) {
          $("#nombreA").text(pri_nombre);
          $("#disabled-input").val(pri_nombre);
          $("#dv_infoPoliza").hide();
          $("#todo").hide();
          $("#soloA").show();
          $("#soloM").hide();
        } else {
          //console.log("es cliente individual");
          $("#nombreT").text(
            json.data.primerNombre +
              " " +
              seg_nombre +
              " " +
              json.data.primerApellido +
              " " +
              json.data.segundoApellido
          );

          $("#todo").show();
          $("#soloM").hide();
          $("#soloA").hide();
          //console.log("no es cliente de macdonald");
        }

        function validaCliente(cadena, cliente) {
          //console.log("Recibido", cadena, cliente);
          var pagadores = cadena.split("|");
          var esCliente = false;
          for (const pagador of pagadores) {
            if (pagador == cliente) {
              esCliente = true;
            }
          }
          return esCliente;
        }

        $("#direccions").text(json.data.tDireccionClienteList.descripcion);
        $("#email").text(json.data.direccionFacturaElectronica);
        $("#telefonos_").text(telefono);

        setTimeout(function () {
          window.datos = json.data;
          //do something special
        }, 9000);
      } else {
        swal(
          "Error",
          "datos, si no cuentas con usuario puedes crearlo",
          "warning"
        );
      }
    })
    .catch(function (error) {});

  $("#principal").removeAttr("style");
  const [show2, setShow2] = useState(false);
  const handleClose2 = () => setShow2(false);

  const ModalActualiza = () => {
    return (
      <Modal size="xl" show={show2} onHide={handleClose2}>
        <Modal.Header
          style={{ background: "#003479", color: "white" }}
          closeButton
        >
          <Modal.Title>Actualización de Datos Cliente</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <FormAct />
        </Modal.Body>
      </Modal>
    );
  };
  return (
    <div>
      <div className="row">
        <div
          className="col-xs-12 col-md-3"
          align="left"
          style={{ padding: "60px" }}
        >
          <strong
            style={{
              color: "#003479",
            }}
          >
            Información General
          </strong>

          <hr
            style={{
              background: "#872434",
              size: 5,
              color: "#872434",
            }}
          />
          <br></br>
          <div id="soloM" name="soloM" style={{ display: "none" }}>
            <img height="145px" src={mclogo} width="auto" alt="mc" />
            <FaBuilding title="Titular2" size="5%" />
            &nbsp;&nbsp;
            <label
              style={{ fontSize: "90%" }}
              id="nombre"
              name="nombre"
              value=""
            />
            <br></br>
            <br></br>
          </div>
          <div id="soloA" name="soloA" style={{ display: "none" }}>
            <img src={mcasaz} width="285px" alt="mc" />
            <FaBuilding title="Titular2" size="5%" />
            &nbsp;&nbsp;
            <label
              style={{ fontSize: "90%" }}
              id="nombreA"
              name="nombreA"
              value=""
            />
            <br></br>
            <br></br>
          </div>
          <div id="todo" name="todo" style={{ display: "none" }}>
            <FaUserAlt title="Titular2" size="5%" />
            &nbsp;&nbsp;
            <label id="nombreT" name="nombreT" value="" />
            <br></br>
            <br></br>
            <FaMailBulk title="Email" size="10%" />
            &nbsp;&nbsp;
            <label id="email" name="email" value="" />
            <br></br>
            <br></br>
            <FaPhoneAlt title="Telefono" size="10%" />
            &nbsp;&nbsp;
            <label id="telefonos_" name="telefonos_" value="" />
            <br></br>
            <br></br>
            <FaAddressCard title="Dirección" size="10%" />
            &nbsp;&nbsp;
            <label id="direccions" name="direccions" value="" />
            <br></br>
            <br></br>
          </div>
          <br></br>
          <br></br>
        </div>
        <div className="col-xs-12 col-md-9" align="center">
          <General />
        </div>
      </div>
      <div className="row">
        <div className="col-xs-12 col-md-6" align="center">
          {show2 ? <ModalActualiza /> : null}
        </div>
      </div>
    </div>
  );
}

export default AppCentral;
