import React, { useState, useEffect} from 'react';
import BootStrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import axios from './axios-config';
import swal from 'sweetalert';
import $ from 'jquery';


const Pagination = () => {

    const [miVariableB, setMiVariableB] = useState([]);


  let datos_ben=[];
  let parentesco_ben="";
  let miVariableX='';
  useEffect(async() => {

    axios.get(`${process.env.REACT_APP_API_URL}/it-api-clientes2/rs/operaciones/beneficiarios/`+$('#certificado').val(),{
      headers: {
        'Authorization': `Bearer ${sessionStorage.getItem('jwtToken')}`,
      },
    }).then(json => {
         if (json.status === 200) {
                              if (json.data.tPolBeneficiario.length===undefined){
                                              if (json.data.tPolBeneficiario.parentesco==='1'){
                                              parentesco_ben='Padre';
                                            }else if (json.data.tPolBeneficiario.parentesco==='2'){
                                              parentesco_ben = 'Madre';
                                            }else if (json.data.tPolBeneficiario.parentesco==='3'){
                                              parentesco_ben = 'Hermano';
                                            }else if (json.data.tPolBeneficiario.parentesco==='4'){
                                                parentesco_ben = 'Tio';
                                              }else if (json.data.tPolBeneficiario.parentesco==='5'){
                                                parentesco_ben = 'Abuelo';
                                              }else if (json.data.tPolBeneficiario.parentesco==='6'){
                                                  parentesco_ben = 'Conyugue';
                                                }else if (json.data.tPolBeneficiario.parentesco==='7'){
                                                  parentesco_ben = 'Hij(@)';
                                                }else if (json.data.tPolBeneficiario.parentesco==='8'){
                                                      parentesco_ben = 'Cuñado';
                                                    }else if (json.data.tPolBeneficiario.parentesco==='9'){
                                                      parentesco_ben = 'Suegro';
                                                    }else if (json.data.tPolBeneficiario.parentesco==='10'){
                                                        parentesco_ben = 'Novio';
                                                      }else if (json.data.tPolBeneficiario.parentesco==='11'){
                                                        parentesco_ben = 'No especificado';
                                                        }
                                                        else if (json.data.tPolBeneficiario.parentesco==='12'){
                                                            parentesco_ben = 'Amigo';
                                                          }else if (json.data.tPolBeneficiario.parentesco==='13'){
                                                            parentesco_ben = 'Sobrino';
                                                            }
                                                  else{
                                              parentesco_ben = 'No Especificado';
                                              }
                                      miVariableX =[{ID_BEN:1,NOMB_BEN:json.data.tPolBeneficiario.primerNombre +' '+json.data.tPolBeneficiario.primerApellido, PARENT_BEN:parentesco_ben,POR_BEN:json.data.tPolBeneficiario.porcentaje}];

                                    }
                              else{
                                  for (var i = 0; i < json.data.tPolBeneficiario.length; i++) {

                                        if (json.data.tPolBeneficiario[i].parentesco==='1'){
                                        parentesco_ben='Padre';
                                      }else if (json.data.tPolBeneficiario[i].parentesco==='2'){
                                        parentesco_ben = 'Madre';
                                      }else if (json.data.tPolBeneficiario[i].parentesco==='3'){
                                        parentesco_ben = 'Hermano';
                                      }else if (json.data.tPolBeneficiario[i].parentesco==='4'){
                                          parentesco_ben = 'Tio';
                                        }else if (json.data.tPolBeneficiario[i].parentesco==='5'){
                                          parentesco_ben = 'Abuelo';
                                        }else if (json.data.tPolBeneficiario[i].parentesco==='6'){
                                            parentesco_ben = 'Conyugue';
                                          }else if (json.data.tPolBeneficiario[i].parentesco==='7'){
                                            parentesco_ben = 'Hij(@)';
                                          }else if (json.data.tPolBeneficiario[i].parentesco==='8'){
                                                parentesco_ben = 'Cuñado';
                                              }else if (json.data.tPolBeneficiario[i].parentesco==='9'){
                                                parentesco_ben = 'Suegro';
                                              }else if (json.data.tPolBeneficiario[i].parentesco==='10'){
                                                  parentesco_ben = 'Novio';
                                                }else if (json.data.tPolBeneficiario[i].parentesco==='11'){
                                                  parentesco_ben = 'No especificado';
                                                  }
                                                  else if (json.data.tPolBeneficiario[i].parentesco==='12'){
                                                      parentesco_ben = 'Amigo';
                                                    }else if (json.data.tPolBeneficiario[i].parentesco==='13'){
                                                      parentesco_ben = 'Sobrino';
                                                      }
                                            else{
                                        parentesco_ben = 'No Especificado';
                                        }



                                        //console.log(json.data.tPolBeneficiario[i].primerNombre+'  '+parentesco_ben+ '   '  +json.data.tPolBeneficiario[i].primerApellido);
                                          datos_ben.push({ID_BEN:i,NOMB_BEN:json.data.tPolBeneficiario[i].primerNombre +' '+json.data.tPolBeneficiario[i].primerApellido, PARENT_BEN:parentesco_ben,POR_BEN:json.data.tPolBeneficiario[i].porcentaje});
                                  }
                                  miVariableX=datos_ben;
                                  //console.log('varios'+miVariableX);
                              }
                          setTimeout(
                           function()
                           {
                    //console.log('completado');
                           }, 9000);

                                setMiVariableB(miVariableX);
         } else {
           swal("Aviso", "No se encontros información de Poliza", "info");
         }
       }).catch(function(error) {


       })
       //console.log("final"+miVariableB);
  }, []);


const columns =[
  {dataField: "ID_BEN", text:"ID"},
{dataField: "NOMB_BEN", text:"Nombres y Apellidos"},
{dataField: "PARENT_BEN", text:"Parentesco"},
{dataField: "POR_BEN", text:"Porcentaje"},


];

  return (
    <BootStrapTable style={{maxWidth: '1600px', width: '95%'}}
      keyField="ID"
      data={miVariableB}
      columns = {columns}
      pagination={paginationFactory()}
      striped
      bordered = {true}
          wrapperClasses="table-responsive"
      />

  );
};
export default Pagination;
