import React, {Component} from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';

import axios from './axios-config';
import {
  Button,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  InputGroup
} from 'reactstrap';

import swal from 'sweetalert';


class Forms extends Component {
  constructor(props) {
    super(props);

    this.state = {
      state: "",
      info_cliente: [],
      informacion: [],
      idCliente:window.idCliente,
      pnamACT: window.pnombre,
      snamACT: window.psegundonombre,
      papeACT: window.pprimerapellido,
      sapeACT: window.psegundoapellido,
      emACT: window.pemail,
      direACT: window.pdireccion,
      telACT: window.ptelefono,
      telACT2: window.ptelefono,
      regexp: 999999,
      contra: "",
      validacion: "",
      show: false,
      name: "React",
      showHideDemo1: false,
      showHideDemo2: false,
      showHideDemo3: false,
      handleShow1: false,
      showResults: false,
      modal: false,
      large: false,
      small: false,
      primary: false,
      success: false,
      warning: false,
      danger: false,
      info: false,
      type: 'password',
      typeModal: 'password',
      typeModal3: 'password',
      collapse: true,
      score: 'null',
      fadeIn: true,
      timeout: 300
    };
    this.toggle1 = this.toggle1.bind(this);
    this.toggleFade1 = this.toggleFade1.bind(this);
    this._reCaptchaRef = React.createRef();
    this.onChangePN = this.onChangePN.bind(this);
    this.onChangeSN = this.onChangeSN.bind(this);
    this.onChangePP = this.onChangePP.bind(this);
    this.onChangeSP = this.onChangeSP.bind(this);
    this.onChangeEm = this.onChangeEm.bind(this);
    this.onChangeDir = this.onChangeDir.bind(this);
    this.onChangeConR = this.onChangeConR.bind(this);
    this.onChangeConC = this.onChangeConC.bind(this);
    this.CreateInfo = this.CreateInfo.bind(this);

    this.showModal = this.showModal.bind(this);
    this.hideModal = this.hideModal.bind(this);

  }

  CreateInfo(){
    /*if (navigator.userAgent.search("MSIE") >= 0){
      //$("#btnRegistrar").attr("disabled","disabled");
    }*/
      let datos=[{
      'idCliente':this.state.idCliente,
      'primerNombre':this.state.pnamACT,
      'segundoNombre':this.state.snamACT,
      'primerApellido':this.state.papeACT,
      'segundoApellido':this.state.sapeACT,
      'direccion':this.state.direACT,
      'telefonoR':this.state.telACT,
      'telefonoC':this.state.telACT2,
      'email':this.state.emACT
    }]

  //console.log(datos);
      axios.post(`${process.env.REACT_APP_API_URL}/it-api-clientes2/rs/operaciones/new3`,datos,{
        headers: {
          'Authorization': `Bearer ${sessionStorage.getItem('jwtToken')}`,
        },
      }).then(json => {
            if (json.status === 200) {
              //console.log(json);
              swal("Actualizacion Exitosa", "Tu información ha sido actualizado correctamente.", "success");
            } else {
              swal("Error", "No se pudo procesar correctamente los datos", "error");

            }
          }).catch(function(error) {

              swal("Error", "No se pudo procesar correctamente los datos", "error");
          })
  }

  showModal = () => {
    //console.log("llegue");
    this.setState({show: true});
  };

  hideModal = () => {
    this.setState({show: false});
  };

  toggle1() {
    this.setState({
      collapse: !this.state.collapse
    });
  }

  toggleFade1() {
    this.setState((prevState) => {
      return {
        fadeIn: !prevState
      }
    });
  }

  onChangePN(e) {

    this.setState({pnamACT: e.target.value});
  }

  onChangeSN(e) {

    this.setState({snamACT: e.target.value});
  }

  onChangePP(e) {

    this.setState({papeACT: e.target.value});
  }

  onChangeSP(e) {

    this.setState({sapeACT: e.target.value});
  }

  onChangeEm(e) {

    this.setState({emACT: e.target.value});
  }

  onChangeDir(e) {

    this.setState({direACT: e.target.value});
  }

  onChangeConR(e) {

    this.setState({telACT: e.target.value});
  }

  onChangeConC(e) {

    this.setState({telACT2: e.target.value});
  }

  render() {

    return (<div id="logaas" name="logaas">


      <strong>Datos Principales</strong>
      <hr style={{
          background: '#872434',
          size: 5,
          color: '#872434'
        }}/>

      <Form className="was-validated">
        <FormGroup row="row">
          <Col xs="12" md="6">
            <Label>
              <strong>Primer Nombre:</strong>
            </Label>

            <Input id="usuarion" name="usuarion" value={this.state.pnamACT} onChange={this.onChangePN} required="required"/>
          </Col>
          <Col xs="12" md="6">
            <Label>
              <strong>Segundo Nombre:</strong>
            </Label>

            <Input type="text" id="fecha" value={this.state.snamACT} onChange={this.onChangeSN} name="date-input" required="required"/>
          </Col>
          <br></br>
          <Col xs="12" md="6">
            <Label>
              <strong>Primer Apellido:</strong>
            </Label>

            <Input type="text" className="form-control" value={this.state.papeACT} onChange={this.onChangePP} name="dpi" id="dpi" required="required"/>

          </Col>
          <Col xs="12" md="6">
            <Label>
              <strong>Segundo Apellido:</strong>
            </Label>

            <Input type="mail" className="form-control" value={this.state.sapeACT} onChange={this.onChangeSP} name="email" id="email" required="required"/>

          </Col>
          <Col xs="12" md="6">
            <Label>
              <strong>Email:</strong>
            </Label>

            <InputGroup>
              <Input type="text" id="con" name="con" className="form-control" value={this.state.emACT} onChange={this.onChangeEm} required="required"/>
            </InputGroup>

          </Col>
          <br></br>
          <Col xs="12" md="6">
            <Label>
              <strong>Dirección:</strong>
            </Label>
            <InputGroup>
              <Input type="text" id="conc" name="conc" className="form-control" value={this.state.direACT} onChange={this.onChangeDir} required="required"/>

            </InputGroup>
          </Col>
          <br></br>
          <Col xs="12" md="6">
            <Label>
              <strong>Telefono Residencial:</strong>
            </Label>

            <InputGroup>
              <Input type="number" id="con" name="con" className="form-control" value={this.state.telACT} onChange={this.onChangeConR} required="required"/>
            </InputGroup>

          </Col>
          <br></br>
          <Col xs="12" md="6">
            <Label>
              <strong>Telefono Celular:</strong>
            </Label>
            <InputGroup>
              <Input type="number" id="conc" name="conc" className="form-control" value={this.state.telACT2} onChange={this.onChangeConC} required="required"/>

            </InputGroup>
          </Col>
        </FormGroup>
      </Form>
      <FormGroup className="form-actions">
<br></br>
        <Button onClick={() => this.CreateInfo()} style={{
            float: 'right',
            background: '#003479',
            color: '#ffffff'
          }} id="btnRegistrar3" size="lg" color="success">Registrar</Button>
      </FormGroup>

    </div>);
  }
}

export default Forms;
