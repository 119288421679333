import React from 'react'
import styled from 'styled-components';
import logo2 from './logo2.jpg';
import pol from './logopoliza.png';
import rec from './reclamosgm.png';
import pag from './pagos.jpg';
import ase from './ase.png';
import all from './all.png';
import asi from './LOGOGYT.png';
import {MultiStepForm, Step} from 'react-multi-form'
import {Button} from 'reactstrap';
import Forms from './Forms.js';
import Parte1 from './parte1.js';
import Parte2 from './parte2.js';
import TablaPoliza from './TablaPoliza.js';
import TablaDep from './TablaDep.js';
import TablaBen from './TabBen.js';
import axios from 'axios';
import swal from 'sweetalert';
import $ from 'jquery';
import LoadingOverlay from 'react-loading-overlay';
import BounceLoader from 'react-spinners/RingLoader';
import Moment from 'moment';
import 'moment/locale/es';
import loader from './pageLoader.gif';
import 'bootstrap/dist/css/bootstrap.min.css';
import { FaUserAlt } from 'react-icons/fa';
import { FaMailBulk } from 'react-icons/fa';
import { FaPhoneAlt } from 'react-icons/fa';
import { FaAddressCard } from 'react-icons/fa';
import { FaMapMarker } from 'react-icons/fa';
import { FaCalculator } from 'react-icons/fa';
import Select from 'react-select';
import {
  Card,
  CardBody,
  CardImg,
  CardTitle,
  CardText,
  CardHeader,
  CardFooter,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Row
} from 'reactstrap';



const Container = styled.div `
  max-width: 1800px;
  margin: 40px auto;
  @media(max-width: 690px) {
    width: 300px;
  }
`
const Poliza = () => {
  let validacion = 0;

  const agregar_spinner_regional = (id_objeto) => {
    $("#" + id_objeto).addClass("overlay");
    $("#" + id_objeto).show();
  }



  const limpiar_spinner = (id_objeto) => {
    //console.log("ingrese a limpieza");
    $('#' + id_objeto).removeClass("overlay");
    $('#' + id_objeto).html("");
    $("#" + id_objeto).hide();
  }

  const [active, setActive] = React.useState(1)
  const [loading, setLoading] = React.useState(false)
  return (<Container>
    <br></br>


        <div className="row">
          <div className="col-xs-12 col-md-6" align="left">
            <strong style={{
                color: '#003479'
              }}>Información Póliza</strong>

            <hr style={{
                background: '#872434',
                size: 5,
                color: '#872434'
              }}/>
              <TablaPoliza/>
          </div>
          <div className="col-xs-12 col-md-6">
            <div className="col-xs-12 col-md-12">
            <div className="row" >
               <strong style={{
                   color: '#003479'
                 }}>Información Dependientes Gastos Médicos</strong>


              </div>
              <hr style={{
                  background: '#872434',
                  size: 5,
                  color: '#872434'
                }}/>
               <TablaDep/>
              </div>
                  <br></br>
                      <br></br>
                          <br></br>
              <div className="col-xs-12 col-md-12">
              <div className="row">
                 <strong style={{
                     color: '#003479'
                   }}>Información Beneficiarios Vida</strong>


              </div>
              <hr style={{
                  background: '#872434',
                  size: 5,
                  color: '#872434'
                }}/>

              <TablaBen/>
                </div>
          </div>
</div>


    <br></br>
    <br></br>

  </Container>)
}

export default Poliza
