import React, { useState, useEffect} from 'react';
import BootStrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  Collapse,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Fade,
  Form,
  FormGroup,
  Input,
  Label,
  Modal, ModalBody, ModalFooter, ModalHeader,
  Row,
} from 'reactstrap';
import axios from './axios-config';
import swal from 'sweetalert';
import Moment from 'moment';
import {FaUserAlt} from 'react-icons/fa';
import {FaMailBulk} from 'react-icons/fa';
import {FaPhoneAlt} from 'react-icons/fa';
import {FaAddressCard} from 'react-icons/fa';
import {FaMapMarker} from 'react-icons/fa';
import {FaLinkedin} from 'react-icons/fa';
import {FaFacebookSquare} from 'react-icons/fa';
import {FaInstagramSquare} from 'react-icons/fa';
import { FaCalculator } from 'react-icons/fa';
import $ from 'jquery';


const Pagination = () => {
  const [modalInfo, setModalInfo] = useState([]);
  const [miVariable, setMiVariable] = useState([]);

  const [showModal, setShowModal] = useState(false);
  const [show, setShow]= useState(false);
  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)

  const aMoneda = (numero, opciones) => {
         // Valores por defecto
         opciones = opciones || {};
         opciones.simbolo = opciones.simbolo || "$";
         opciones.separadorDecimal = opciones.separadorDecimal || ".";
         opciones.separadorMiles = opciones.separadorMiles || ",";
         opciones.numeroDeDecimales = opciones.numeroDeDecimales >= 0 ? opciones.numeroDeDecimales : 2;
         opciones.posicionSimbolo = opciones.posicionSimbolo || "i";
         const CIFRAS_MILES = 3;

         // Redondear y convertir a cadena
         var num =parseFloat(numero);
         ////console.log(num);
         let numeroComoCadena = num.toFixed(opciones.numeroDeDecimales);

         // Comenzar desde la izquierda del separador o desde el final de la cadena si no se proporciona
         let posicionDelSeparador = numeroComoCadena.indexOf(opciones.separadorDecimal);
         if (posicionDelSeparador === -1) posicionDelSeparador = numeroComoCadena.length;
         let formateadoSinDecimales = "", indice = posicionDelSeparador;
         // Ir cortando desde la derecha de 3 en 3, y concatenar en una nueva cadena
         while (indice >= 0) {
             let limiteInferior = indice - CIFRAS_MILES;
             // Agregar separador si cortamos más de 3
             formateadoSinDecimales = (limiteInferior > 0 ? opciones.separadorMiles : "") + numeroComoCadena.substring(limiteInferior, indice) + formateadoSinDecimales;
             indice -= CIFRAS_MILES;
         }
         let formateadoSinSimbolo = `${formateadoSinDecimales}${numeroComoCadena.substr(posicionDelSeparador, opciones.numeroDeDecimales + 1)}`;
         return opciones.posicionSimbolo === "i" ? opciones.simbolo + formateadoSinSimbolo : formateadoSinSimbolo + opciones.simbolo;
     };
     // Probar
     const opcionesQuetzales = {
         numeroDeDecimales: 2,
         separadorDecimal: ".",
         separadorMiles: ",",
         simbolo: "Q ", // Con un espacio, ya que la función no agrega espacios
         posicionSimbolo: "i", // i = izquierda, d = derecha
     },
         opcionesDolares = {
             numeroDeDecimales: 2,
             separadorDecimal: ".",
             separadorMiles: ",",
             simbolo: " USD", // Con un espacio, ya que la función no agrega espacios
             posicionSimbolo: "d", // i = izquierda, d = derecha
         };


     const formatterQuetzales = new Intl.NumberFormat('es-GT', {
           style: 'currency',
           currency: 'GTQ',
           minimumFractionDigits: 2
         })


  axios.get(`${process.env.REACT_APP_API_URL}/it-api-clientes2/rs/operaciones/infoPolDan/`+$('#certificado').val(),{
    headers: {
      'Authorization': `Bearer ${sessionStorage.getItem('jwtToken')}`,
    },
  }).then(json => {
    if (json.status === 200) {
         $('#nom').text($('#disabled-input').val());
         $('#numero').text($('#polizaCompleta').val());
         $('#vigencia').text(Moment(json.data.vigenciaInicio).format('L')+'-'+Moment(json.data.vigenciaFin).format('L'));
         $('#vigPol').val(Moment(json.data.vigenciaInicio).format('L')+'-'+Moment(json.data.vigenciaFin).format('L'));
         $('#emision').text(Moment(json.data.fechaEmision).format('L'));
         $('#certi').text($('#monedaPol').val()+'. '+json.data.sumaAsegurada);
  //console.log('antes');
       //console.log('despuess'+$('#nombre').val());
       $('#emision_div').hide();

       } else {
         swal("Aviso", "No se encontro información de Poliza", "info");
       }
     }).catch(function(error) {

       swal("Aviso", "No se encontro información  de Poliza", "info");
     })


const columns =[
{dataField: "FECHA_INGRESO", text:"Fecha de ingreso"},
      {dataField: "TIPO_LOG", text:"Pasos del Proceso"},
      {dataField: "LOG", text:"Comentarios"},

];

  return (
    <div >
        <br></br>
    <Col xs="12" lg="12">
      <Label htmlFor="fecha">
        <strong>Nombre Poliza:</strong>&nbsp;<label id="nom" name="nom" value="" />
      </Label>
    </Col>
    <br></br>
    <br></br>

    <Col xs="12" lg="12">
      <Label htmlFor="poliza">
        <strong>Póliza:</strong>&nbsp;<label id="numero" name="numero" value="" />
      </Label>
    </Col>
    <br></br>
    <br></br>
    <div id="emision_div" name="emision_div">
    <Col xs="12" md="2" lg="12">
      <Label htmlFor="emision">
        <strong>Emisión Poliza:</strong>&nbsp;<label id="emision" name="emision" value="" />
      </Label>
    </Col>
    </div>
    <br></br>
    <br></br>
    <Col xs="12" lg="12">
      <Label htmlFor="vigencia">
        <strong>Vigencia Póliza:</strong>&nbsp;<label id="vigencia" name="vigencia" value="" />
      </Label>
    </Col>
    <br></br>
    <br></br>
    <Col xs="12" lg="12">
      <Label htmlFor="vigencia">
        <strong>Suma Asegurada:</strong>&nbsp;<label id="certi" name="certi" value="" />
      </Label>
    </Col>
    <br></br>
    <br></br>
    <br></br>
    <FormGroup className="form-actions">


    </FormGroup>

  </div>

  );
};
export default Pagination;
