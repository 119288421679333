import React, { useState, useEffect } from "react";
import styled from "styled-components";
import logo2 from "./logo2.jpg";
import pol from "./logopoliza.png";
import rec from "./reclamosgm.png";
import pag from "./pagos.jpg";
import ase from "./ase.png";
import all from "./all.png";
import asistente_uno from "./colaboradores/1229.JPG";
import asistente_dos from "./colaboradores/1161.JPG";
import asistente_tres from "./colaboradores/1040.jpeg";
import gerente_uno from "./colaboradores/1220.jpg";
import reclamos_uno from "./colaboradores/1113.JPG";
import reclamos_dos from "./colaboradores/1255.jpeg";
import reclamos_tres from "./colaboradores/1257.JPG";
import reclamos_cuatro from "./colaboradores/1281.jpg";
import reclamos_cinco from "./colaboradores/1288.jpeg";
import reclamos_seis from "./colaboradores/1276.jpg";
import reclamos_siete from "./colaboradores/1271.jpeg";
import reclamos_ocho from "./colaboradores/1246.jpeg";
import reclamos_nueve from "./colaboradores/1267.jpg";
import reclamos_diez from "./colaboradores/973.jfif";
import reclamos_once from "./colaboradores/322.bmp";
import reclamos_doce from "./colaboradores/300.jpg";
import reclamos_trece from "./colaboradores/1207.jpg";
import reclamos_catorce from "./colaboradores/1170.JPG";
import gerente_dos from "./colaboradores/861.png";
import asistente_cuatro from "./colaboradores/929.JPG";
import asistente_cinco from "./colaboradores/559.JPG";
import asistente_seis from "./colaboradores/1308.jpg";
import asistente_siete from "./colaboradores/361.JPG";
import asistente_diecinueve from "./colaboradores/928.jfif";
import asistente_ocho from "./colaboradores/1184.jpeg";
import asistente_nueve from "./colaboradores/1112.JPG";
import asistente_diez from "./colaboradores/1181.JPG";
import asistente_once from "./colaboradores/1298.jpeg";
import asistente_doce from "./colaboradores/1300.jpeg";
import asistente_trece from "./colaboradores/1104.jfif";
import asistente_catorce from "./colaboradores/1268.jpeg";
import asistente_quince from "./colaboradores/1304.jpg";
import asistente_dieciseis from "./colaboradores/1306.jpg";
import asistente_diecisiete from "./colaboradores/1307.jpg";
import asistente_dieciocho from "./colaboradores/1311.jpg";
import asistente_veitiuno from "./colaboradores/202.JPG";
import asistente_veinte from "./colaboradores/369.jpg";
import asistente_veinticinco from "./colaboradores/726.JPG";
import asistente_veintiseis from "./colaboradores/1240.jpeg";
import gerente_tres from "./colaboradores/70.jpg";
import gerente_cuatro from "./colaboradores/96.JPEG";
import gerente_cinco from "./colaboradores/968.JPG";
import gerente_seis from "./colaboradores/1083.jpeg";
import asistente_veintidos from "./colaboradores/726.JPG";
import asistente_veintitres from "./colaboradores/876.jpeg";
import ejemplo from "./ejemplo.jpg";
import ReactStars from "react-rating-stars-component";
import "./styles.css";
import asi from "./LOGOGYT.png";
import { MultiStepForm, Step } from "react-multi-form";
import { Button } from "reactstrap";
import Forms from "./Forms.js";
import Parte1 from "./parte1.js";
import Parte2 from "./parte2.js";
import TablaPoliza from "./TablaPoliza.js";
import TablaDep from "./TablaDep.js";
import TablaBen from "./TabBen.js";
import axios, { AxiosRequestConfig } from "./axios-config";
import swal from "sweetalert";
import $ from "jquery";
import LoadingOverlay from "react-loading-overlay";
import BounceLoader from "react-spinners/RingLoader";
import Moment from "moment";
import "moment/locale/es";
import loader from "./pageLoader.gif";
import "bootstrap/dist/css/bootstrap.min.css";
import { FaUserAlt } from "react-icons/fa";
import { FaMailBulk } from "react-icons/fa";
import { FaPhoneAlt } from "react-icons/fa";
import { FaAddressCard } from "react-icons/fa";
import { FaMapMarker } from "react-icons/fa";
import { FaCalculator } from "react-icons/fa";
import Califica from "./calificacion.js";
import Select from "react-select";
import {
  Card,
  CardBody,
  CardImg,
  CardTitle,
  CardText,
  CardHeader,
  CardFooter,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";

const Container = styled.div`
  max-width: 1800px;
  margin: 40px auto;
  @media (max-width: 690px) {
    width: 300px;
  }
`;
//console.log(asistente_uno);

const Poliza = () => {
  let validacion = 0;
  const [Asistente, setAsistente] = useState([]);
  const [Gerente, setGerente] = useState([]);
  const [Reclamo, setReclamo] = useState([]);
  let asistente = "";
  let gerente = "";
  useEffect(async () => {
    let datos = [];
    let sex = "";
    let mivariblease = "";
    let parentesco = "";
    let usr_dan = "";

    $("#info").hide();
    $("#info2").hide();
    $("#info3").hide();
    $("#datGen").hide();
    //console.log("idepol: ",$("#idepol").val());
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/it-api-clientes2/rs/operaciones/asesor/` +
          $("#idepol").val(),{
            headers: {
              'Authorization': `Bearer ${sessionStorage.getItem('jwtToken')}`,
            },
          }
      )
      .then((json) => {
        if (json.status === 200) {
          //console.log("aqui estoy");
          $("#nom_asis").text(json.data.asistentesTable[0].nombre);
          $("#email_asis")
            .attr("href", "mailto:" + json.data.asistentesTable[0].email)
            .attr("target", "_blank");
          $("#mail_asis").text(json.data.asistentesTable[0].email);
          $("#puesto_asis").text(json.data.asistentesTable[0].codigoPuesto);
          $("#telefono_asis").text(
            "(502) " +
              json.data.asistentesTable[0].telefonoDirecto +
              " Ext. " +
              json.data.asistentesTable[0].extension
          );
          $("#whatsapp_asis").text("(502) " + 22856501);
          //console.log(json.data.asistentesTable[0].codigoAsistente);
          if (json.data.asistentesTable[0].codigoAsistente === 1229) {
            $("#col_what").hide();
            setAsistente(asistente_uno);
            $("#pu_asis").text("Asesor Negocios Personas");

            axios
              .get(
                `${process.env.REACT_APP_API_URL}/it-api-clientes2/rs/operaciones/asesorGerente/` +
                  1220,{
                    headers: {
                      'Authorization': `Bearer ${sessionStorage.getItem('jwtToken')}`,
                    },
                  }
              )
              .then((json) => {
                if (json.status === 200) {
                  setGerente(gerente_uno);
                  $("#nom_gers").text(json.data.asistentesTable[0].nombre);
                  $("#email_ger")
                    .attr("href", "mailto:" + json.data.asistentesTable[0].email)
                    .attr("target", "_blank");
                  $("#mail_ger").text(json.data.asistentesTable[0].email);
                  $("#puesto_ger").text(json.data.asistentesTable[0].codigoPuesto);
                  $("#telefono_ger").text(
                    "(502) " +
                      json.data.asistentesTable[0].telefonoDirecto +
                      " Ext. " +
                      json.data.asistentesTable[0].extension
                  );
                  $("#whatsapp_ger").text("(502) " + 22856501);
                  $("#pu_ger").text("Gerente de Negocios Personas");
                } else {
                  swal(
                    "Error",
                    "No se encontro información de  Gerente",
                    "warning"
                  );
                }
              })
              .catch(function (error) {
                swal(
                  "Error",
                  "No eee encontro  información de Gerente",
                  "warning"
                );
              });
          } else if (json.data.asistentesTable[0].codigoAsistente === 726) {
            $("#div_reclamos").hide();
            $("#col_what").hide();
            setAsistente(asistente_veinticinco);
            $("#pu_asis").text("Asesor Negocios Daños");

            axios
              .get(
                `${process.env.REACT_APP_API_URL}/it-api-clientes2/rs/operaciones/asesorGerente/` +
                  968,{
                    headers: {
                      'Authorization': `Bearer ${sessionStorage.getItem('jwtToken')}`,
                    },
                  }
              )
              .then((json) => {
                if (json.status === 200) {
                  setGerente(gerente_cinco);
                  $("#nom_gers").text(json.data.asistentesTable[0].nombre);
                  $("#email_ger")
                    .attr("href", "mailto:" + json.data.asistentesTable[0].email)
                    .attr("target", "_blank");
                  $("#mail_ger").text(json.data.asistentesTable[0].email);
                  $("#puesto_ger").text(json.data.asistentesTable[0].codigoPuesto);
                  $("#telefono_ger").text(
                    "(502) " +
                      json.data.asistentesTable[0].telefonoDirecto +
                      " Ext. " +
                      json.data.asistentesTable[0].extension
                  );
                  $("#whatsapp_ger").text("(502) " + 22856501);
                  $("#pu_ger").text("Gerente de Negocios Daños");
                } else {
                  swal(
                    "Error",
                    "No se encontro información de  Gerente",
                    "warning"
                  );
                }
              })
              .catch(function (error) {
                swal(
                  "Error",
                  "No eee encontro  información de Gerente",
                  "warning"
                );
              });
          } else if (
            $("#tipoPol").val() === "VA" ||
            $("#tipoPol").val() === "CERT" ||
            $("#tipoPol").val() === "MM" ||
            $("#tipoPol").val() === "EE" ||
            $("#tipoPol").val() === "INCEN" ||
            $("#tipoPol").val() === "TDINCEN" ||
            $("#tipoPol").val() === "FA" ||
            $("#tipoPol").val() === "RC"
          ) {
           // console.log("PRIMER IF");
            $("#info").show();
            $("#info2").show();
            $("#info3").show();
            $("#div_gerente").show();
            $("#div_reclamos").hide();
            $("#div_gerente").removeClass();
            $("#div_gerente").addClass("col-xs-12 col-md-6");
            $("#div_asesor").removeClass();
            $("#div_asesor").addClass("col-xs-12 col-md-6");            
            switch (String(json.data.asistentesTable[0].codigoAsistente)) {
              case "1308":
                setAsistente(asistente_seis);
                usr_dan = "3333332";
                break;
              case "559":
                setAsistente(asistente_cinco);
                usr_dan = "3333331";
                break;
              case "929":
                setAsistente(asistente_cuatro);
                usr_dan = "3333333";
                break;
              case "361":
                setAsistente(asistente_siete);
                usr_dan = "3333334";
                break;
              case "928":
                setAsistente(asistente_diecinueve);
                usr_dan = "3333335";
                break;
              case "1184":
                setAsistente(asistente_ocho);
                usr_dan = "3333336";
                break;
              case "1112":
                setAsistente(asistente_nueve);
                usr_dan = "3333337";
                break;
              case "1181":
                setAsistente(asistente_diez);
                usr_dan = "3333338";
                break;
              case "1298":
                setAsistente(asistente_once);
                usr_dan = "3333339";
                break;
              case "1300":
                setAsistente(asistente_doce);
                usr_dan = "3333340";
                break;
              case "1104":
                setAsistente(asistente_trece);
                usr_dan = "3333341";
                break;
              case "1268":
                setAsistente(asistente_catorce);
                usr_dan = "3333342";
                break;
              case "1304":
                setAsistente(asistente_quince);
                usr_dan = "3333342";
                break;
              case "1306":
                setAsistente(asistente_dieciseis);
                usr_dan = "3333342";
                break;
              case "1307":
                setAsistente(asistente_diecisiete);
                usr_dan = "3333342";
                break;
              case "1311":
                setAsistente(asistente_dieciocho);
                usr_dan = "3333342";
                break;
              case "202":
                setAsistente(asistente_veitiuno);
                usr_dan = "3333343";
                break;
              case "369":
                setAsistente(asistente_veinte);
                usr_dan = "3333344";
                break;
              case "726":
                setAsistente(asistente_veintidos);
                usr_dan = "3333345";
                break;
              case "876":
                setAsistente(asistente_veintitres);
                usr_dan = "3333346";
                break;
            }

            $("#pu_asis").text("Asesor Negocios Daños");
            $("#col_what").show();            
            axios
              .get(
                `${process.env.REACT_APP_API_URL}/it-api-clientes2/rs/operaciones/usuario_reclamo/` +
                  usr_dan,{
                    headers: {
                      'Authorization': `Bearer ${sessionStorage.getItem('jwtToken')}`,
                    },
                  }
              )
              .then((json) => {
                $("#whatsapp_asis").text("(502)  " + json.data.contacto);
                $("#what")
                  .attr("href", "https://wa.me/502" + json.data.contacto)
                  .attr("target", "_blank");
              });
            let dato_gerente;

            if ($("#idclienteF").val() == 2391) {
              dato_gerente = 968;
              setGerente(gerente_cinco);
            } else {
              dato_gerente = 96;
              setGerente(gerente_cuatro);
            }
            axios
              .get(
                `${process.env.REACT_APP_API_URL}/it-api-clientes2/rs/operaciones/asesorGerente/` +
                  dato_gerente,{
                    headers: {
                      'Authorization': `Bearer ${sessionStorage.getItem('jwtToken')}`,
                    },
                  }
              )
              .then((json) => {
                if (json.status === 200) {
                  let dato;
                  switch (String(json.data.asistentesTable[0].codigoPuesto)) {
                    case "2":
                      dato = "Gerente de Negocios Daños";
                      break;
                    case "7":
                      dato = "Gerente de Negociación Regional";
                  }
                  setGerente(gerente_cuatro);
                  $("#nom_gers").text(json.data.asistentesTable[0].nombre);
                  $("#email_ger")
                    .attr("href", "mailto:" + json.data.asistentesTable[0].email)
                    .attr("target", "_blank");
                  $("#mail_ger").text(json.data.asistentesTable[0].email);
                  $("#puesto_ger").text(json.data.asistentesTable[0].codigoPuesto);
                  $("#telefono_ger").text(
                    "(502) " +
                      json.data.asistentesTable[0].telefonoDirecto +
                      " Ext. " +
                      json.data.asistentesTable[0].extension
                  );
                  // $('#whatsapp_ger').text('(502) '+22856501);
                  $("#pu_ger").text(dato);
                } else {
                  swal(
                    "Error",
                    "No se encontro información de  Gerente",
                    "warning"
                  );
                }
              })
              .catch(function (error) {

                swal(
                  "Error",
                  "No eee encontro  información de Gerente",
                  "warning"
                );
              });
          } else if (json.data.asistentesTable[0].codigoAsistente === 1161) {
            setAsistente(asistente_dos);
            $("#col_what").hide();

            $("#pu_asis").text("Asesor Negocios Personas");
            axios
              .get(
                `${process.env.REACT_APP_API_URL}/it-api-clientes2/rs/operaciones/asesorGerente/` +
                  861,{
                    headers: {
                      'Authorization': `Bearer ${sessionStorage.getItem('jwtToken')}`,
                    },
                  }
              )
              .then((json) => {
                if (json.status === 200) {
                  setGerente(gerente_dos);
                  $("#nom_gers").text(json.data.asistentesTable[0].nombre);
                  $("#email_ger")
                    .attr(
                      "href",
                      "mailto:" +
                        json.data.asistentesTable[0].email +
                        "?subject=Consulta de póliza: " +
                        $("#polizaCompleta").val().replace("&", "y")
                    )
                    .attr("target", "_blank");
                  $("#mail_ger").text(json.data.asistentesTable[0].email);
                  $("#puesto_ger").text(json.data.asistentesTable[0].codigoPuesto);
                  $("#telefono_ger").text(
                    "(502) " +
                      json.data.asistentesTable[0].telefonoDirecto +
                      " Ext. " +
                      json.data.asistentesTable[0].extension
                  );
                  $("#whatsapp_ger").text("(502) " + 22856501);
                  $("#pu_ger").text("Gerente de Negocios Personas");
                } else {
                  swal(
                    "Error",
                    "No se encontro información de  Gerente",
                    "warning"
                  );
                }
              })
              .catch(function (error) {
                swal(
                  "Error",
                  "No eee encontro  información de Gerente",
                  "warning"
                );
              });
          } else if (json.data.asistentesTable[0].codigoAsistente === 1040) {
            $("#col_what").hide();
            $("#div_gerente").prop("class").replace("col-xs-12 col-md-4", "");
            $("#div_gerente").hide();
            $("#div_asesor").removeClass();
            $("#div_asesor").addClass("col-xs-12 col-md-6");
            $("#div_reclamos").removeClass();
            $("#div_reclamos").addClass("col-xs-12 col-md-6");
            $("#pu_asis").text("Asesor Negocios Personas");
            setAsistente(asistente_tres);
            axios
              .get(
                `${process.env.REACT_APP_API_URL}/it-api-clientes2/rs/operaciones/asesorGerente/` +
                  70,{
                    headers: {
                      'Authorization': `Bearer ${sessionStorage.getItem('jwtToken')}`,
                    },
                  }
              )
              .then((json) => {
                if (json.status === 200) {
                  setGerente(gerente_tres);
                  $("#nom_gers").text(json.data.asistentesTable[0].nombre);
                  $("#email_ger")
                    .attr(
                      "href",
                      "mailto:" +
                        json.data.asistentesTable[0].email +
                        "?subject=Consulta de póliza: " +
                        $("#polizaCompleta").val().replace("&", "y")
                    )
                    .attr("target", "_blank");
                  $("#mail_ger").text(json.data.asistentesTable[0].email);
                  $("#puesto_ger").text(json.data.asistentesTable[0].codigoPuesto);
                  $("#telefono_ger").text(
                    "(502) " +
                      json.data.asistentesTable[0].telefonoDirecto +
                      " Ext. " +
                      json.data.asistentesTable[0].extension
                  );
                  $("#whatsapp_ger").text("(502) " + 22856501);
                  $("#pu_ger").text("Gerente de Negocios Personas");
                } else {
                  swal(
                    "Error",
                    "No se encontro información de  Gerente",
                    "warning"
                  );
                }
              })
              .catch(function (error) {
                swal(
                  "Aviso",
                  "No se encontro  información de Gerente",
                  "info"
                );
              });
          }else if (json.data.asistentesTable[0].codigoAsistente === 1240) {
            console.log("SEGUNDO IF 1240");
            $("#col_what").hide();
            $("#div_gerente").prop("class").replace("col-xs-12 col-md-4", "");
            $("#div_gerente").show();
            $("#div_asesor").removeClass();
            $("#div_asesor").addClass("col-xs-12 col-md-6");
            $("#div_reclamos").removeClass();
            $("#div_reclamos").addClass("col-xs-12 col-md-6");
            $("#div_reclamos").hide();
            $("#pu_asis").text("Asesor Negocios Personas");
            setAsistente(asistente_veintiseis);
            axios
              .get(
                `${process.env.REACT_APP_API_URL}/it-api-clientes2/rs/operaciones/asesorGerente/` +
                  1083,{
                    headers: {
                      'Authorization': `Bearer ${sessionStorage.getItem('jwtToken')}`,
                    },
                  }
              )
              .then((json22) => {
                if (json22.status === 200) {
                  console.log("json22: ",json22);
                  setGerente(gerente_seis);
                  $("#nom_gers").text(json22.data.asistentesTable[0].nombre);
                  $("#email_ger")
                    .attr(
                      "href",
                      "mailto:" +
                      json22.data.asistentesTable[0].email +
                        "?subject=Consulta de póliza: " +
                        $("#polizaCompleta").val().replace("&", "y")
                    )
                    .attr("target", "_blank");
                  $("#mail_ger").text(json22.data.asistentesTable[0].email);
                  $("#puesto_ger").text(json22.data.asistentesTable[0].codigoPuesto);
                  $("#telefono_ger").text(
                    "(502) " +
                    json22.data.asistentesTable[0].telefonoDirecto +
                      " Ext. " +
                      json22.data.asistentesTable[0].extension
                  );
                  $("#whatsapp_ger").text("(502) " + 22856501);
                  $("#pu_ger").text("Gerente de Negocios Personas");
                  
                } else {
                  swal(
                    "Error",
                    "No se encontro información de  Gerente",
                    "warning"
                  );
                }
              })
              .catch(function (error) {
                swal(
                  "Aviso",
                  "No se encontro  información de Gerente",
                  "info"
                );
              });
          }
           else {
            swal(
              "Aviso",
              "No se encontró información de tu Gerente",
              "info"
            );
          }

          if (
            $("#tipoPol").val() === "VA" ||
            $("#tipoPol").val() === "CERT" ||
            $("#tipoPol").val() === "MM" ||
            $("#tipoPol").val() === "EE" ||
            $("#tipoPol").val() === "INCEN" ||
            $("#tipoPol").val() === "TDINCEN" ||
            $("#tipoPol").val() === "FA" ||
            $("#tipoPol").val() === "TR" ||
            $("#tipoPol").val() === "RC"
          ) {
            //console.log("productos daños");
          } else {
            axios
              .get(
                `${process.env.REACT_APP_API_URL}/it-api-clientes2/rs/operaciones/usuario_reclamo/` +
                  $("#idepol").val(),{
                    headers: {
                      'Authorization': `Bearer ${sessionStorage.getItem('jwtToken')}`,
                    },
                  }
              )
              .then((json) => {
                if (json.status === 200) {
                  if (json.data.idAsistente === 1255) {
                    setReclamo(reclamos_dos);
                  } else if (json.data.idAsistente === 1257) {
                    setReclamo(reclamos_tres);
                  } else if (json.data.idAsistente === 1281) {
                    setReclamo(reclamos_cuatro);
                  } else if (json.data.idAsistente === 1288) {
                    setReclamo(reclamos_cinco);
                  } else if (json.data.idAsistente === 1276) {
                    setReclamo(reclamos_seis);
                  } else if (json.data.idAsistente === 1271) {
                    setReclamo(reclamos_siete);
                  } else if (json.data.idAsistente === 1246) {
                    setReclamo(reclamos_ocho);
                  } else if (json.data.idAsistente === 1267) {
                    setReclamo(reclamos_nueve);
                  } else if (json.data.idAsistente === 973) {
                    setReclamo(reclamos_diez);
                  } else if (json.data.idAsistente === 322) {
                    setReclamo(reclamos_once);
                  } else if (json.data.idAsistente === 300) {
                    setReclamo(reclamos_doce);
                  } else if (json.data.idAsistente === 1207) {
                    setReclamo(reclamos_trece);
                  } else if (json.data.idAsistente === 1170) {
                    setReclamo(reclamos_catorce);
                  } else {
                    setReclamo(reclamos_uno);
                  }

                  axios
                    .get(
                      `${process.env.REACT_APP_API_URL}/it-api-clientes2/rs/operaciones/asesorGerente/` +
                        json.data.idAsistente,{
                          headers: {
                            'Authorization': `Bearer ${sessionStorage.getItem('jwtToken')}`,
                          },
                        }
                    )
                    .then((jsonr) => {
                      if (jsonr.status === 200) {
                        $("#nom_rec").text(jsonr.data.asistentesTable[0].nombre);
                        $("#email_rec")
                          .attr(
                            "href",
                            "mailto:" +
                              jsonr.data.asistentesTable[0].email +
                              "?subject=Consulta de póliza: " +
                              $("#polizaCompleta").val().replace("&", "y")
                          )
                          .attr("target", "_blank");
                        $("#mail_rec").text(jsonr.data.asistentesTable[0].email);
                        $("#puesto_rec").text(
                          jsonr.data.asistentesTable[0].codigoPuesto
                        );
                        $("#telefono_rec").text(
                          "(502) " +
                            jsonr.data.asistentesTable[0].telefonoDirecto +
                            " Ext. " +
                            jsonr.data.asistentesTable[0].extension
                        );
                        $("#whatsapp_rec").text("(502) " + json.data.contacto);
                        $("#wh_rec").attr(
                          "href",
                          "tel:502" + json.data.contacto
                        );
                      } else {
                        swal("Error", "No se encontró información", "warning");
                      }
                    });
                } else {
                  swal(
                    "Error",
                    "No se encontro información de  Asesor Reclamo",
                    "warning"
                  );
                }
              })
              .catch(function (error) {
                swal(
                  "Error",
                  "No eee encontro  información de Asesor Reclamo dos",
                  "warning"
                );
              });
          }
          //console.log(asistente);
          setTimeout(function () {
            //do something special
          }, 9000);
          //     setMiVariable(mivaribleDep);
        } else {
          swal("Error", "No se encontro información de  Asesor", "warning");
        }
      })
      .catch(function (error) {
        swal("Error", "No eee encontro  información de Asesor", "warning");
      });
  }, []);

  const secondExample = {
    size: 50,
    count: 10,
    color: "black",
    activeColor: "yellow",
    value: 7.5,
    a11y: true,
    isHalf: true,
    emptyIcon: <i className="far fa-star" />,
    halfIcon: <i className="fa fa-star-half-alt" />,
    filledIcon: <i className="fa fa-star" />,
    onChange: (newValue) => {
      //console.log(`Example 2: new value is ${newValue}`);
    },
  };

  const agregar_spinner_regional = (id_objeto) => {
    $("#" + id_objeto).addClass("overlay");
    $("#" + id_objeto).show();
  };

  const ratingChanged = (newRating) => {
    //console.log(newRating);
  };

  const limpiar_spinner = (id_objeto) => {
    //console.log("ingrese a limpieza");
    $("#" + id_objeto).removeClass("overlay");
    $("#" + id_objeto).html("");
    $("#" + id_objeto).hide();
  };

  const [active, setActive] = React.useState(1);
  const [loading, setLoading] = React.useState(false);
  return (
    <Container>
      <div className="row">
        <div
          className="col-xs-12 col-md-6"
          align="left"
          id="div_gerente"
          name="div_gerente"
        >
          <div className="col-xs-12 col-md-12" align="center">
            <h3>
              {" "}
              <strong
                style={{
                  color: "#003479",
                }}
              >
                GERENTE DE PÓLIZA
              </strong>
            </h3>
            <hr
              style={{
                background: "#872434",
                size: 5,
                color: "#872434",
              }}
            />
          </div>
          <div id="info3" name="info3">
            <br></br>
            <br></br>
            <br></br>
            <br></br>
          </div>
          <div className="col-xs-12 col-md-12" align="left">
            <center>
              <img height="250px" src={Gerente} width="225px" alt="logo" />
            </center>
          </div>
          <h5>
            <div className="col-xs-12 col-md-12" align="left">
              <br></br>
              <Col xs="12" md="12" lg="12">
                <Label htmlFor="Nombre">
                  <strong>Nombre:</strong>
                </Label>
                &nbsp;<label id="nom_gers" name="nom_gers" value=""></label>
              </Col>
              <br></br>

              <Col xs="12" md="12" lg="12">
                <Label htmlFor="Puesto">
                  <strong>Puesto:</strong>
                </Label>
                &nbsp;
                <label
                  id="pu_ger"
                  name="pu_ger"
                  value="Gerente de Cuenta"
                ></label>
              </Col>
              <br></br>

              <Col xs="12" md="12" lg="12">
                <Label htmlFor="Email">
                  <strong>Email:</strong>&nbsp;
                  <a id="email_ger" name="email_ger">
                    <label id="mail_ger" name="mail_ger" value=""></label>
                  </a>
                </Label>
              </Col>
              <br></br>

              <Col xs="12" md="12" lg="12">
                <Label htmlFor="Telefono">
                  <strong>Telefono:</strong>&nbsp;
                  <a href="tel:+50223868310" target="_blank">
                    <label
                      id="telefono_ger"
                      name="telefono_ger"
                      value=""
                    ></label>
                  </a>
                </Label>
              </Col>
              <br></br>

              <Col xs="12" md="12" lg="12">
                <Label htmlFor="Puesto">
                  <strong>Horario de Atención:</strong>
                </Label>
                &nbsp;<label>Lun - Vier de 8:00 AM - 5:00 PM</label>
              </Col>
              <br></br>
              <br></br>
            </div>
          </h5>
        </div>
        <div
          className="col-xs-12 col-md-6"
          align="left"
          id="div_asesor"
          name="div_asesor"
        >
          <div className="col-xs-12 col-md-12" align="center">
            <h3>
              {" "}
              <strong
                style={{
                  color: "#003479",
                }}
              >
                ASISTENTE DE PÓLIZA
              </strong>
            </h3>
            <hr
              style={{
                background: "#872434",
                size: 5,
                color: "#872434",
              }}
            />
          </div>
          <div id="info" name="info" display="none">
            <center>
              <h4>
                Con tu asesor puedes solicitar la renovación, inclusión,
                exclusión o modificación que se necesite en la póliza.
              </h4>
            </center>
          </div>
          <div className="col-xs-12 col-md-12" align="left">
            <center>
              <img height="250px" src={Asistente} width="225px" alt="logo" />
            </center>
          </div>
          <h5>
            <div className="col-xs-12 col-md-12" align="left">
              <br></br>
              <Col xs="12" md="12" lg="12">
                <Label htmlFor="Nombre">
                  <strong>Nombre:</strong>
                </Label>
                &nbsp;
                <label id="nom_asis" name="nom_asis" value="" />
              </Col>
              <br></br>

              <Col xs="12" md="12" lg="12">
                <Label htmlFor="Puesto">
                  <strong>Puesto:</strong>
                </Label>
                &nbsp;<label id="pu_asis" name="pu_asis" value=""></label>
              </Col>
              <br></br>

              <Col xs="12" md="12" lg="12">
                <Label htmlFor="Email">
                  <strong>Email:</strong>&nbsp;
                  <a id="email_asis" name="email_asis">
                    <label id="mail_asis" name="mail_asis" value="" />
                  </a>
                </Label>
              </Col>
              <br></br>

              <Col xs="12" md="12" lg="12">
                <Label htmlFor="Telefono">
                  <strong>Telefono:</strong>&nbsp;
                  <a href="tel:+50223868310" target="_blank">
                    <label id="telefono_asis" name="telefono_asis" value="" />
                  </a>
                </Label>
              </Col>
              <br></br>
              <Col
                xs="12"
                md="12"
                lg="12"
                id="col_what"
                name="col_what"
                display="none"
              >
                <Label htmlFor="Whatsapp">
                  <strong>Whatsapp:</strong>&nbsp;
                  <a id="what" name="what" target="_blank">
                    <label
                      id="whatsapp_asis"
                      name="whatsapp_asis"
                      value=""
                    ></label>
                  </a>
                </Label>
              </Col>
              <br></br>
              <Col xs="12" md="12" lg="12">
                <Label htmlFor="Puesto">
                  <strong>Horario de Atención:</strong>
                </Label>
                &nbsp;<label>Lun - Vier de 8:00 AM - 5:00 PM</label>
              </Col>
              <br></br>
            </div>
          </h5>
        </div>
        <div
          className="col-xs-12 col-md-4"
          align="left"
          id="div_reclamos"
          name="div_reclamos"
        >
          <div className="col-xs-12 col-md-12" align="center">
            <h3>
              <strong
                style={{
                  color: "#003479",
                }}
              >
                ASESOR DE RECLAMOS
              </strong>
            </h3>
            <hr
              style={{
                background: "#872434",
                size: 5,
                color: "#872434",
              }}
            />
          </div>
          <div id="info2" name="infor2">
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
          </div>
          <div className="col-xs-12 col-md-12" align="left">
            <center>
              <img height="250px" src={Reclamo} width="225px  " alt="logo" />
            </center>
          </div>
          <h5>
            <div className="col-xs-12 col-md-12" align="left">
              <br></br>
              <Col xs="12" md="12" lg="12">
                <Label htmlFor="Nombre">
                  <strong>Nombre:</strong>
                </Label>
                &nbsp;
                <label id="nom_rec" name="nom_rec" value="" />
              </Col>
              <br></br>

              <Col xs="12" md="12" lg="12">
                <Label htmlFor="Puesto">
                  <strong>Puesto:</strong>
                </Label>
                &nbsp;<label>Jefe de Reclamos Personas</label>
              </Col>
              <br></br>

              <Col xs="12" md="12" lg="12">
                <Label htmlFor="Email">
                  <strong>Email:</strong>&nbsp;
                  <a id="email_rec" name="email_rec">
                    <label id="mail_rec" name="mail_rec" value="" />
                  </a>
                </Label>
              </Col>
              <br></br>

              <Col xs="12" md="12" lg="12">
                <Label htmlFor="Telefono">
                  <strong>Telefono:</strong>&nbsp;
                  <a href="tel:+50223868310" target="_blank">
                    <label id="telefono_rec" name="telefono_rec" value="" />
                  </a>
                </Label>
              </Col>
              <br></br>
              <Col xs="12" md="12" lg="12">
                <Label htmlFor="Puesto">
                  <strong>Horario de Atención:</strong>
                </Label>
                &nbsp;<label>Lun - Vier de 8:00 AM - 5:00 PM</label>
              </Col>
              <br></br>
            </div>
          </h5>
        </div>
      </div>
    </Container>
  );
};

export default Poliza;
