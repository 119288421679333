import React, { useState, useEffect} from "react";

import {
Col,  Fade,  Form,  FormGroup,    Input,  Label,  Row,
} from 'reactstrap';
import Moment from 'moment';
import 'moment/locale/es'
import axios from './axios-config';
import { FaSearch } from 'react-icons/fa';
import { FaFile } from 'react-icons/fa';
import { Pie } from 'react-chartjs-2';
import $ from 'jquery';
import swal from 'sweetalert';

import BootStrapTable from "react-bootstrap-table-next";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.css";
import {Modal, Button} from 'react-bootstrap';
import Tabs from './tab0.js';
import Bitacora from './tab.js';
import {Table, Thead, Tbody, Tr, Th, Td} from 'react-super-responsive-table'
import paginationFactory from "react-bootstrap-table2-paginator";
import Chart from 'chart.js';
import Drop from './dropzoneSingle.js';


const Pagination = () => {

  const [modalInfo2, setModalInfo2] = useState([]);
  const [files, setFiles] = useState([]);
  const [modalInfo1, setModalInfo1] = useState([]);
  const [modalInfo3, setModalInfo3] = useState([]);
  const [modalInfo4, setModalInfo4] = useState([]);
  const [modalInfo5, setModalInfo5] = useState([]);
  const [modalInfo6, setModalInfo6] = useState([]);
  const [showModal3, setShowModal3] = useState(false);
  const [showModal2, setShowModal2] = useState(false);
  const [showModal1, setShowModal1] = useState(false);
  const [show1, setShow1]= useState(false);
  const [show2, setShow2]= useState(false);
  const [show3, setShow3]= useState(false);
  const handleClose1 = () => setShow1(false);
  const handleClose2 = () => setShow2(false);
    const handleClose3 = () => setShow3(false);
  const handleShow1 = () => setShow1(true)
  const handleShow2 = () => setShow2(true)
    const handleShow3 = () => setShow3(true)
let tip = '';
let tipo = '';
let dip=0;
let datos  = [];
let validador=0;
let contador = 0;
let miVariables = window.variableP;
//console.log(miVariables);
let fechaInicio =new Date(window.variableP.idPoliza.vigenciaInicio);
let fechaFinal =new Date(window.variableP.idPoliza.vigenciaFin);
if (miVariables.TGmReclamoList!= undefined){
  contador = miVariables.TGmReclamoList.length ? miVariables.TGmReclamoList.length : 0 ;
}
else {

  swal("Información", "Esta póliza no poseé reclamos ingresados", "warning");
   contador = 999999;
}


let miVariable='';
let countPro=0;
let countFina =0;
let countOtr =0;
let countDat =0;
var state;
if (contador == 0){

  //console.log(miVariables.TGmReclamoList.TReclamo.idReclamo);
//console.log('hola');
///////////////////////////////////77

                    if (miVariables.TGmReclamoList.idDependiente===undefined){
                    //  //console.log("ingrse"+i);
                      window.infoCertificado='Elfrid';
                    }
                    else{
                      ////console.log("ingse servicio"+i);
                      axios.get(`${process.env.REACT_APP_API_URL}/it-api-clientes2/rs/operaciones/dependiente/`+miVariables.TGmReclamoList.idDependiente,{
                        headers: {
                          'Authorization': `Bearer ${sessionStorage.getItem('jwtToken')}`,
                        },
                      })
                        .then(json => {
                          window.infoDependiente=json.data.primerNombre+' '+json.data.primerApellido;
                          window.relacion=json.data.parentesco;
                        })
                    }

    let dip = miVariables.TGmReclamoList.idDependiente ? miVariables.TGmReclamoList.TReclamo.idReclamo :0;

    if (miVariables.idDependiente===undefined){
      tip = window.clienteTitular;
        tipo = 'Titular';
    }
    else{
      tip=window.infoDependiente;
      if (window.relacion==0){
        tipo = 'NO ESPECIFICADO';
      }else if(window.relacion==1){
        tipo = 'CONYUGE';
      }else{
        tipo = 'HIJO(A)';
      }
    }

//console.log(miVariables.TGmReclamoList.TReclamo.idEstado);
    if (miVariables.TGmReclamoList.TReclamo.idEstado==1) {

      state = 'PROCESO';
  }else if (miVariables.TGmReclamoList.TReclamo.idEstado==2) {

    state="FINALIZADO";
  }else{
    state="PROCESO";
  }
  //console.log(miVariables.TGmReclamoList.TReclamo.fechaIngreso);
  //console.log(fechaInicio);
  //console.log(fechaFinal);
  if(new Date(miVariables.TGmReclamoList.TReclamo.fechaIngreso) >= fechaInicio && new Date(miVariables.TGmReclamoList.TReclamo.fechaIngreso) <=fechaFinal){
    //console.log(miVariables.TGmReclamoList.TReclamo.fechaIngreso);
    //console.log(fechaInicio);
    //console.log(fechaFinal);
     countDat=countDat+1;
    if (miVariables.TGmReclamoList.TReclamo.idEstado==1) {
       countPro=countPro+1;

  }else if (miVariables.TGmReclamoList.TReclamo.idEstado==2) {
      countFina=countFina +1;

  }else{
  countOtr=countOtr +1;
  }

//console.log(miVariables.TGmReclamoList.TReclamo.idReclamo);
//console.log(tip);
//console.log(tipo);
miVariable = [{ID: miVariables.TGmReclamoList.TReclamo.idReclamo, RECLAMODE:tipo,TIPO:tip, FECHA:Moment(miVariables.TGmReclamoList.TReclamo.fechaIngreso).format('L'),
    ESTADO:state, ACCION:<h3> <FaSearch title="Ver Detalle Reclamo" /></h3> }];

}
}
else if (contador ==999999){
  //console.log('elseif'+contador);
  miVariable = [];
}
else {
  //console.log('ekse'+contador);
Moment.locale('es-mx');
for (var i = 0; i < miVariables.TGmReclamoList.length; i++) {



                  if (miVariables.TGmReclamoList[i].idDependiente===undefined){
                  //  //console.log("ingrse"+i);
                    window.infoCertificado='Elfrid';
                  }
                  else{
                    ////console.log("ingse servicio"+i);
                    axios.get(`${process.env.REACT_APP_API_URL}/it-api-clientes2/rs/operaciones/dependiente/`+miVariables.TGmReclamoList[i].idDependiente,{
                      headers: {
                        'Authorization': `Bearer ${sessionStorage.getItem('jwtToken')}`,
                      },
                    })
                      .then(json => {
                        window.infoDependiente=json.data.primerNombre+' '+json.data.primerApellido;
                        window.relacion=json.data.parentesco;
                      })
                  }

  let dip = miVariables.TGmReclamoList[i].idDependiente ? miVariables.TGmReclamoList[i].TReclamo.idReclamo :0;

  if (miVariables.TGmReclamoList[i].idDependiente===undefined){

    tip = window.clienteTitular;
      tipo = 'Titular';
  }
  else{
    tip=window.infoDependiente;
    if (window.relacion==0){
      tipo = 'NO ESPECIFICADO';
    }else if(window.relacion==1){
      tipo = 'CONYUGE';
    }else{
      tipo = 'HIJO(A)';
    }
  }

  if (miVariables.TGmReclamoList[i].TReclamo.idEstado==1) {

    state = 'PROCESO';
}else if (miVariables.TGmReclamoList[i].TReclamo.idEstado==2) {

  state="FINALIZADO";
}else{
  state="PROCESO";
}
if(new Date(miVariables.TGmReclamoList[i].TReclamo.fechaIngreso) >= fechaInicio && new Date(miVariables.TGmReclamoList[i].TReclamo.fechaIngreso) <=fechaFinal){
   countDat=countDat+1;
  if (miVariables.TGmReclamoList[i].TReclamo.idEstado==1) {
     countPro=countPro+1;

}else if (miVariables.TGmReclamoList[i].TReclamo.idEstado==2) {
    countFina=countFina +1;

}else{
countOtr=countOtr +1;
}
  datos.push({ID: miVariables.TGmReclamoList[i].TReclamo.idReclamo, RECLAMODE:tipo,TIPO:tip, FECHA:Moment(miVariables.TGmReclamoList[i].TReclamo.fechaIngreso).format('L'),
    ESTADO:state, ACCION:<h3> <FaSearch title="Ver Detalle Reclamo" /></h3> });

}
}

miVariable = datos;
}
const defaultSorted = [
  {
    dataField: "ID",
    order: "DESC"
  }
];

const columns =[
  {dataField: "ID", text:"Reclamo", sort:true},
  {dataField: "TIPO", text:"Nombre Reclamo"},
  {dataField: "RECLAMODE", text:"Tipo / Parentesco"},
  {dataField: "FECHA", text:"Fecha Ingreso"},
  {dataField: "ESTADO", text:"Estado",sort: true},
  {dataField: "ACCION", text:"Acción"}
];

let dat=[];
let dat2=[];
const rowEvents = {

  onClick: (e, row) =>{
  //console.log(row.ESTADO);
  if (row.ESTADO=='FINALIZADO'){
    //console.log('ingrese aca');
    axios.get(`${process.env.REACT_APP_API_URL}/it-api-clientes2/rs/operaciones/totalliq/`+row.ID,{
      headers: {
        'Authorization': `Bearer ${sessionStorage.getItem('jwtToken')}`,
      },
    })
  .then(json => {
     dat=json.data;
     setModalInfo2(row);
     setModalInfo3(json.data.dirigidoA);
     setModalInfo4(json.data.numeroCheque);
     window.rectab =row;
     toogleTrueFalse2();
  })
  axios.get(`${process.env.REACT_APP_API_URL}/it-api-clientes2/rs/operaciones/liq_bitacora/`+row.ID,{
    headers: {
      'Authorization': `Bearer ${sessionStorage.getItem('jwtToken')}`,
    },
  })
.then(json => {
   dat2=json.data;
   setModalInfo5(json.data.tRecLogUsuario.log);
   setModalInfo6(Moment(json.data.tRecLogUsuario.fechaLog).format('L'));

})
    setModalInfo2(row);
    setModalInfo3(dat.dirigidoA);
    setModalInfo4(dat.numeroCheque);
    //setModalInfo5(dat.tRecLogUsuario.log);
    window.rectab =row;
    toogleTrueFalse2();


  }else{
    //console.log("ingrese a apertura");
    setModalInfo1(row);
    window.rectab =row;
    toogleTrueFalse1();
  }

},
};


//console.log(countFina+'-'+countPro+'-'+countDat);
const data = {

  labels: ['PROCESO', 'FINALIZADO'],
  datasets: [
    {
      label: '# de reclamos',
      data: [countPro, countFina,countOtr],
      display:true,
      backgroundColor: [
'rgba(0, 52, 121)',
        'rgba(87, 88, 89)',

        'rgba(255, 206, 86, 0.2)',
        'rgba(75, 192, 192, 0.2)',
        'rgba(153, 102, 255, 0.2)',
        'rgba(255, 159, 64, 0.2)',
      ],
      borderColor: [

        'rgba(130, 130, 130)',
        'rgba(100, 101, 108)',
        'rgba(255, 206, 86, 1)',
        'rgba(75, 192, 192, 1)',
        'rgba(153, 102, 255, 1)',
        'rgba(255, 159, 64, 1)',
      ],
      borderWidth: 1,
    },
  ],
};


const toogleTrueFalse1 = () => {
setShowModal1(handleShow1);
};

const toogleTrueFalse2 = () => {
setShowModal2(handleShow2);
};

const Digitalizar = (valor) => {
var  element = document.getElementById('drop');
 var estado = element.style.display;
 if(estado == 'none'){
 element.style.display='block'
 }else{
 element.style.display = 'none';
 }
};

const Generar= () =>
{
//console.log(window.datoArchivoN);
//console.log(modalInfo1.ID);
setFiles(window.datoArchivoN)
////console.log(files);

  //custom-file-input
  const formData = new FormData();
//console.log(window.miVariable);
  // Update the formData object
  formData.append('codigo_aseguradora',window.miVariable.tPolCertificado.idPoliza.idAseguradora.idAseguradora);
  formData.append('codigo_tipo_poliza',window.miVariable.tPolCertificado.idPoliza.idTipoPoliza.codigoTipoPoliza);
  formData.append('numero_poliza',window.miVariable.tPolCertificado.idPoliza.numero_poliza);
  formData.append('id_reclamo',modalInfo1.ID);
  formData.append('rec_id_pais',1);
  formData.append('operacion',9);
        for (var i = 0; i < files[0].length; i++) {
          //console.log(i);
      //console.log(files[i]);
          //console.log(files[0][i].name);
          formData.append(
            "archivos[]",
            files[0][i],
            files[0][i].name
          );

        }

    var data = formData;
        //console.log(data);
        var url = 'http://regional.somit.com/Gestion_Reclamo';
        $.ajax({
            type: 'POST',
            url: url,
            enctype: 'multipart/form-data',
            processData: false,
            contentType: false,
            cache: false,
            data: data,
            success: function (digita)
            {

                var res = eval(digita);
                if (res[0] === 'false')
                {

                    swal({
                        title: "Somit Regional",
                        text: "No fue posible digitalizar, su sesión ha finalizado, vuelva a ingresar por favor.",
                        type: "error",
                        showCancelButton: false,
                        confirmButtonColor: '#3085d6',
                        cancelButtonColor: '#7c211e',
                        confirmButtonClass: "btn-danger",
                        confirmButtonText: 'aceptar',
                        closeOnConfirm: true,
                        closeOnCancel: false,
                    },
                            function (isConfirm)
                            {

                            });
                } else
                {
                    var result = "";
                    for (var i = 0; i < res.length; i++)
                    {
                        result = result + res[i] + "\n";
                    }
  //                  swal('Somit Regional', result, 'warning');
                }
            },
            complete: function ()
            {
//console.log(modalInfo1.ID);

swal("Exitoso", "Se ha cargado tu información correctamente y notificado al asesor de tu reclamo", "success");

            },
            error: function (digita)
            {
            }
        });

    return false;
}



const ModalContent = () => {
$('#drop').hide();
return(

  <Modal size='xl' style={{maxWidth: '1600px', width: '100%'}} show={show1} onHide={handleClose1} >
        <Modal.Header  style={{background:'#003479', color:'white'}} closeButton>
        <Modal.Title >
          Reclamo No: {modalInfo1.ID}
        </Modal.Title>
      </Modal.Header>
        <Modal.Body>
<div>
  <Button onClick={() => Digitalizar(modalInfo1.ID)} color='success'>Agregar Documentos Pendientes</Button></div>
<br></br>
<div id="drop" name = "drop"  style={{display:'none'}}>

<Drop />
<hr></hr>
  <div>
    <FormGroup className="form-actions">

                  <Button   style={{ float: 'right' }}  type="submit" size="sm"  onClick={() => Generar()}color="success">Generar</Button>
    </FormGroup></div>
<br></br>
</div>
<br></br>

          <Bitacora/>
        </Modal.Body>
      <Modal.Footer>

      </Modal.Footer>
</Modal>
);
}
;
const ModalContentLiq = () => {
return(
  <Modal size='xl' style={{maxWidth: '1600px', width: '100%'}} show={show2} onHide={handleClose2} >
        <Modal.Header  style={{background:'#003479', color:'white'}} closeButton>
        <Modal.Title >
          Liquidacion de Reclamo No: {modalInfo2.ID}
        </Modal.Title>
      </Modal.Header>
        <Modal.Body>
          <strong>Detalle Liquidacion</strong>
                               <hr style={{background:'#872434', size:5, color:'#872434'}} />
                                 <FormGroup row>
                                   <Col md="4">
                                    <Label> <strong>Numero de Cheque / Transferencia:</strong></Label>

                                    <Input value={modalInfo4} disabled />
                                   </Col>
                                   <Col md="4">
                                     <Label><strong>Dirigido a:</strong></Label>

                                    <Input value={modalInfo3} disabled />
                                   </Col>
                                   <Col md="4">
                                     <Label><strong>Fecha Liquidación:</strong></Label>

                                    <Input value={modalInfo6} disabled />
                                   </Col>
                                   <Col md="12">
                                     <Label><strong>Observaciones Liquidacion:</strong></Label>
                                   </Col>
                                   <Col md="12">
                                    <Input  rows="8" type="textarea" value={modalInfo5} disabled/>
                                   </Col>

                                 </FormGroup>

          <Tabs />

        </Modal.Body>
      <Modal.Footer>

      </Modal.Footer>
</Modal>
);
}
;

return (
  <div>
    <FormGroup row>
        <Col md="4">
          <h4>Reclamos Ingresados: &nbsp; {countDat}</h4>
        <Pie data={data} />
        <br></br>
      </Col>

  <Col md="8">
    <BootStrapTable
      bootstrap4
      keyField="ID"
      data={miVariable}
      columns = {columns}
      rowEvents={rowEvents}
   pagination={paginationFactory()}
   defaultSorted={defaultSorted}

      />
    </Col>
  </FormGroup>
    {show1 ? <ModalContent /> : null}
  {show2 ? <ModalContentLiq /> : null}
  </div>
);
};
export default Pagination;
