import React, { useState, useEffect} from 'react';
import BootStrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import axios from './axios-config';
import swal from 'sweetalert';
import $ from 'jquery';


const Pagination = () => {

    const [miVariableB, setMiVariableB] = useState([]);

    const aMoneda = (numero, opciones) => {
           // Valores por defecto
           opciones = opciones || {};
           opciones.simbolo = opciones.simbolo || "$";
           opciones.separadorDecimal = opciones.separadorDecimal || ".";
           opciones.separadorMiles = opciones.separadorMiles || ",";
           opciones.numeroDeDecimales = opciones.numeroDeDecimales >= 0 ? opciones.numeroDeDecimales : 2;
           opciones.posicionSimbolo = opciones.posicionSimbolo || "i";
           const CIFRAS_MILES = 3;

           // Redondear y convertir a cadena
           var num =parseFloat(numero);
           ////console.log(num);
           let numeroComoCadena = num.toFixed(opciones.numeroDeDecimales);

           // Comenzar desde la izquierda del separador o desde el final de la cadena si no se proporciona
           let posicionDelSeparador = numeroComoCadena.indexOf(opciones.separadorDecimal);
           if (posicionDelSeparador === -1) posicionDelSeparador = numeroComoCadena.length;
           let formateadoSinDecimales = "", indice = posicionDelSeparador;
           // Ir cortando desde la derecha de 3 en 3, y concatenar en una nueva cadena
           while (indice >= 0) {
               let limiteInferior = indice - CIFRAS_MILES;
               // Agregar separador si cortamos más de 3
               formateadoSinDecimales = (limiteInferior > 0 ? opciones.separadorMiles : "") + numeroComoCadena.substring(limiteInferior, indice) + formateadoSinDecimales;
               indice -= CIFRAS_MILES;
           }
           let formateadoSinSimbolo = `${formateadoSinDecimales}${numeroComoCadena.substr(posicionDelSeparador, opciones.numeroDeDecimales + 1)}`;
           return opciones.posicionSimbolo === "i" ? opciones.simbolo + formateadoSinSimbolo : formateadoSinSimbolo + opciones.simbolo;
       };
       // Probar
       const opcionesQuetzales = {
           numeroDeDecimales: 2,
           separadorDecimal: ".",
           separadorMiles: ",",
           simbolo: "Q ", // Con un espacio, ya que la función no agrega espacios
           posicionSimbolo: "i", // i = izquierda, d = derecha
       },
           opcionesDolares = {
               numeroDeDecimales: 2,
               separadorDecimal: ".",
               separadorMiles: ",",
               simbolo: " USD", // Con un espacio, ya que la función no agrega espacios
               posicionSimbolo: "d", // i = izquierda, d = derecha
           };


       const formatterQuetzales = new Intl.NumberFormat('es-GT', {
             style: 'currency',
             currency: 'GTQ',
             minimumFractionDigits: 2
           })

  let datos_ben=[];
  let parentesco_ben="";
  let miVariableA=[];
  useEffect(async() => {

    axios.get(`${process.env.REACT_APP_API_URL}/it-api-clientes2/rs/operaciones/infoDeducible/`+$('#certificado').val()+'/'+$('#dato_deducible').val(),{
      headers: {
        'Authorization': `Bearer ${sessionStorage.getItem('jwtToken')}`,
      },
    }).then(json => {
         if (json.status === 200) {
           //console.log(json.data.vDeduciblesAuto.length);
                              if (json.data.vDeduciblesAuto.length===undefined){


                          if ($('#monedaPol').val()=='Q'){
                            miVariableA =[{ID:json.data.vDeduciblesAuto.inciso,DESCRIPCION:json.data.vDeduciblesAuto.descripcionDeducible, MONTO:aMoneda(json.data.vDeduciblesAuto.montoDeducible,opcionesQuetzales),
                              MINIMO:aMoneda(json.data.vDeduciblesAuto.deducibleMinimo, opcionesQuetzales), PORCENTAJE:json.data.vDeduciblesAuto.porcentajeDeducible}];

                          }else{
                            miVariableA =[{ID:json.data.vDeduciblesAuto.inciso,DESCRIPCION:json.data.vDeduciblesAuto.descripcionDeducible, MONTO:aMoneda(json.data.vDeduciblesAuto.montoDeducible,opcionesDolares),
                              MINIMO:aMoneda(json.data.vDeduciblesAuto.deducibleMinimo, opcionesDolares), PORCENTAJE:json.data.vDeduciblesAuto.porcentajeDeducible}];

                          }

        }
  else{
    //console.log('ingrse a else');
      for (var i = 0; i < json.data.vDeduciblesAuto.length; i++) {
        //console.log(i);
                    if ($('#monedaPol').val()=='Q'){
        miVariableA.push({ID:json.data.vDeduciblesAuto[i].inciso,DESCRIPCION:json.data.vDeduciblesAuto[i].descripcionDeducible, MONTO:aMoneda(json.data.vDeduciblesAuto[i].montoDeducible,opcionesQuetzales),
          MINIMO:aMoneda(json.data.vDeduciblesAuto[i].deducibleMinimo, opcionesQuetzales), PORCENTAJE:json.data.vDeduciblesAuto[i].porcentajeDeducible});

        }else{

          miVariableA.push({ID:json.data.vDeduciblesAuto[i].inciso,DESCRIPCION:json.data.vDeduciblesAuto[i].descripcionDeducible, MONTO:aMoneda(json.data.vDeduciblesAuto[i].montoDeducible,opcionesDolares),
            MINIMO:aMoneda(json.data.vDeduciblesAuto[i].deducibleMinimo, opcionesDolares), PORCENTAJE:json.data.vDeduciblesAuto[i].porcentajeDeducible});

        }              }
                                //  miVariableX=datos_ben;
                                  //console.log('varios'+miVariableA.length);

                              }
                          setTimeout(
                           function()
                           {
                    //console.log('completado');
                  }, 2000);

                                setMiVariableB(miVariableA);
         } else {
           swal("Aviso", "No se encontros información de Poliza", "info");
         }
       }).catch(function(error) {


       })
       //console.log("final"+miVariableB);
  }, []);


const columns =[
  {dataField: "ID", text:"INCISO"},
{dataField: "DESCRIPCION", text:"DESCRIPCION"},
{dataField: "MONTO", text:"MONTO DEDUCIBLE"},
{dataField: "MINIMO", text:"DEDUCIBLE MINIMO"},
{dataField: "PORCENTAJE", text:"PORCENTAJE"},


];

  return (
    <BootStrapTable style={{maxWidth: '1600px', width: '95%'}}
      keyField="ID"
      data={miVariableB}
      columns = {columns}
      pagination={paginationFactory()}
      striped
      bordered = {true}
          wrapperClasses="table-responsive"
      />

  );
};
export default Pagination;
