import React, { Component } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import axios, { AxiosRequestConfig } from "./axios-config";
import { FaEye } from "react-icons/fa";
import { FaEyeSlash } from "react-icons/fa";

import zxcvbn from "zxcvbn";
import AppCentral from "./AppCentral.js";
import Moment from "moment";
import Capcha from "./captcha.js";
import { sendHttpPostRequestLogin } from "./Servicios/httpRequest";
import {
  Button,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  InputGroup,
  Row,
} from "reactstrap";
import { Modal } from "react-bootstrap";
import swal from "sweetalert";

import $ from "jquery";
import { Encriptar } from "./Util/Encripcion.js";

class Forms extends Component {
  constructor(props) {
    super(props);

    this.state = {
      state: "",
      info_cliente: [],
      informacion: [],
      poliza: "",
      crea: "",
      consulta: "",
      cliente: "",
      producto: "",
      tipopoliza: "",
      nam: "",
      fecha: "",
      fechaR: "",
      usuarion: "",
      email: "",
      dpi: "",
      dpiR: "",
      passN: "",
      passR: "",
      conc: "",
      regexp: 999999,
      contra: "",
      validacion: "",
      show: false,
      show2: false,
      show3: false,
      name: "React",
      showHideDemo1: false,
      showHideDemo2: false,
      showHideDemo3: false,
      handleShow1: false,
      showResults: false,
      modal: false,
      large: false,
      small: false,
      primary: false,
      success: false,
      warning: false,
      danger: false,
      dato_dpi: "",
      crea_usuario: "",
      dato_fecnac: "",
      info: false,
      type: "password",
      typeModal: "password",
      typeModal3: "password",
      typeModal4: "password",
      collapse: true,
      score: "null",
      fadeIn: true,
      timeout: 300,
    };
    this.toggle1 = this.toggle1.bind(this);
    this.toggleFade1 = this.toggleFade1.bind(this);
    this._reCaptchaRef = React.createRef();
    this.onChangeName = this.onChangeName.bind(this);
    this.onChangeRec = this.onChangeRec.bind(this);
    this.onChangeUsuario = this.onChangeUsuario.bind(this);
    this.onChangeCon = this.onChangeCon.bind(this);
    this.onChangeConC = this.onChangeConC.bind(this);
    this.onChangeMail = this.onChangeMail.bind(this);
    this.onChangeDpi = this.onChangeDpi.bind(this);
    this.onChangeDpiR = this.onChangeDpiR.bind(this);
    this.onChangeFecha = this.onChangeFecha.bind(this);
    this.onChangeFechaR = this.onChangeFechaR.bind(this);
    this.onChangePassN = this.onChangePassN.bind(this);
    this.onChangePassR = this.onChangePassR.bind(this);
    this.GetInfo = this.GetInfo.bind(this);
    this.CreateInfo = this.CreateInfo.bind(this);
    this.SendInfo = this.SendInfo.bind(this);
    this.SendInfoNew = this.SendInfoNew.bind(this);
    this.SendInfoContra = this.SendInfoContra.bind(this);
    this.showModal = this.showModal.bind(this);
    this.showModal2 = this.showModal2.bind(this);
    this.hideModal = this.hideModal.bind(this);
    this.hideModal2 = this.hideModal2.bind(this);
    this.hideModal3 = this.hideModal3.bind(this);
    this.hideComponentn = this.hideComponentn.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.handleClick2 = this.handleClick2.bind(this);
    this.handleClick4 = this.handleClick4.bind(this);
    this.handleClick3 = this.handleClick3.bind(this);
    this.setCookie = this.setCookie.bind(this);
  }

  setCookie = (name, value, days) => {
    let expires = "";
    if (days) {
      let date = new Date();
      date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
      expires = "; expires=" + date.toUTCString();
    }
    document.cookie = name + "=" + (value || "") + expires + "; path=/";
  };

  handleClick = () =>
    this.setState(({ type }) => ({
      type: type === "text" ? "password" : "text",
    }));

  handleClick2 = () =>
    this.setState(({ typeModal }) => ({
      typeModal: typeModal === "text" ? "password" : "text",
    }));

  handleClick4 = () =>
    this.setState(({ typeModal4 }) => ({
      typeModal4: typeModal4 === "text" ? "password" : "text",
    }));

  handleClick3 = () =>
    this.setState(({ typeModal3 }) => ({
      typeModal3: typeModal3 === "text" ? "password" : "text",
    }));

  showModal = () => {
    //console.log("llegue");
    this.setState({ show: true });
  };

  showModal3 = () => {
    //console.log("llegue modal3");
    this.setState({ show3: true });
  };
  showModal2 = () => {
    //console.log("llegue modal2");
    this.setState({ show2: true });
  };
  hideModal = () => {
    this.setState({ show: false });
  };

  hideModal2 = () => {
    this.setState({ show2: false });
  };

  hideModal3 = () => {
    this.setState({ show3: false });
  };

  hideComponentn = (name) => {
    switch (name) {
      case "showHideDemo1":
        window.showHideDemo1 = true;
        window.showHideDemo2 = false;

        // this.setState({ showHideDemo1: !this.state.showHideDemo1 });
        break;
      case "showHideDemo2":
        //console.log("muestra el 2");
        this.setState({ showResults: true });
        window.showHideDemo1 = false;
        window.showHideDemo2 = true;
        this.setState({ state: "componente" });
        $("#login").hide();
        //this.setState({ showHideDemo2: !this.state.showHideDemo2 });
        break;
    }
  };

  SendInfo() {
    let dpi_ = this.state.dpi;
    let fecha_ = this.state.fecha;
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/it-api-clientes2/rs/operaciones/informacion2/` +
          dpi_ +
          "/" +
          Moment(fecha_).format("YYYY-MM-DD"),
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("jwtToken")}`,
          },
        }
      )
      .then(function (responsen) {
        swal(
          "Se estan consultando tu información, para el envío, por favor espera un momento",
          {
            buttons: false,
            timer: 5000,
          }
        );
        //console.log(responsen.data);
        if (responsen.data.idCliente) {
          axios
            .get(
              `${process.env.REACT_APP_API_URL}/it-api-clientes2/rs/operaciones/mailRecuperacion/` +
                responsen.data.idCliente,
              {
                headers: {
                  Authorization: `Bearer ${sessionStorage.getItem("jwtToken")}`,
                  'Content-type':'application/json'
                },

              }
            )
            .then(function (responsea) {
              swal(
                "Atención",
                "Su usuario y contraseña ya fueron enviados a su bandeja de correo",
                "success"
              );
            })
            .catch(function (errorn) {
              swal(
                "Error",
                "Ha ocurrido un error en el envio, debes comunicarte con tu asesor de póliza",
                "error"
              );
            });
        }else
        {
          swal(
            "Atención",
            "No se encontraron registros con los datos ingresados.",
            "info"
          );
        }
      })
      .catch(function (error) {
        swal(
          "Error",
          "Se valido su información y se pudo constatar que no tiene usuario, por lo cual te invitamos a que crees uno",
          "warning"
        );
      });
  }
  SendInfoContra() {
    let dpi_ = this.state.dpi;
    let fecha_ = this.state.fecha;
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/it-api-clientes2/rs/operaciones/informacion2/` +
          dpi_ +
          "/" +
          Moment(fecha_).format("YYYY-MM-DD"),
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("jwtToken")}`,
          },
        }
      )
      .then(function (responsen) {
        swal(
          "Se estan consultando tu información, para el envío, por favor espera un momento",
          {
            buttons: false,
            timer: 5000,
          }
        );
        //console.log(responsen.data);
        if (responsen.data.idCliente) {
          axios
            .get(
              `${process.env.REACT_APP_API_URL}/it-api-clientes2/rs/operaciones/mailRecuperacion/` +
                responsen.data.idCliente,
              {
                headers: {
                  Authorization: `Bearer ${sessionStorage.getItem("jwtToken")}`,
                },
              }
            )
            .then(function (responsea) {
              swal(
                "Atención",
                "Su usuario y contraseña ya fueron enviados a su bandeja de correo",
                "success"
              );
            })
            .catch(function (errorn) {
              swal(
                "Error",
                "Ha ocurrido un error en el envio, debes comunicarte con tu asesor de póliza",
                "error"
              );
            });
        }else
        {
          swal(
            "Atención",
            "No se encontraron registros con los datos ingresados.",
            "info"
          );
        }
      })
      .catch(function (error) {
        swal(
          "Error",
          "Se valido su información y se pudo constatar que no tiene usuario, por lo cual te invitamos a que crees uno",
          "warning"
        );
      });
  }

  SendInfoNew() {
    let newPass = this.state.passN;
    let fecha_ = this.state.fechaR;
    let dpi_ = this.state.dpiR;
    //console.log("Aqui:");
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/it-api-clientes2/rs/operaciones/informacion2/` +
          dpi_ +
          "/" +
          Moment(fecha_).format("YYYY-MM-DD"),
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("jwtToken")}`,
          },
        }
      )
      .then(function (responsen) {
        swal(
          "Se estan consultando tu información, para el envío, por favor espera un momento",
          {
            buttons: false,
            timer: 5000,
          }
        );
        if (responsen.data.idCliente) {
          let datos={
            codigoCliente:responsen.data.idCliente,
            password:newPass
          };
          let datosEncriptados=Encriptar(JSON.stringify(datos));
          axios
            .post(
              `${process.env.REACT_APP_API_URL}/it-api-clientes2/rs/operaciones/UserRecuperacion`,
                datosEncriptados,
              {
                headers: {
                  Authorization: `Bearer ${sessionStorage.getItem("jwtToken")}`,
                  'Content-type':'application/json'
                },                
              }
            )
            .then(function (responsea) {
              swal("Atención", "Su contraseña fue actualizada", "success");
            })
            .catch(function (errorn) {
              swal(
                "Error",
                "Ha ocurrido un error en el envio, debes comunicarte con tu asesor de póliza",
                "error"
              );
            });
        } else {
          swal(
            "Atención",
            "No se encontraron registros con los datos ingresados.",
            "info"
          );
        }
      })
      .catch(function (error) {
        swal(
          "Error",
          "Se valido su información y se pudo constatar que no tiene usuario, por lo cual te invitamos a que crees uno",
          "warning"
        );
      });
  }

  async GetInfo() {
    function validaCliente(cadena, cliente) {
      //console.log("Recibido", cadena, cliente);
      var pagadores = cadena.split("|");
      var esCliente = false;
      for (const pagador of pagadores) {
        if (pagador == cliente) {
          esCliente = true;
        }
      }
      return esCliente;
    }
    //axios.get(`${process.env.REACT_APP_API_URL}it-api-clientes/rs/operaciones/login/` +$("#nam").val() +"/" +$("#contra").val()).then((json) => {

    var datos = {
      username: $("#nam").val(),
      password: $("#contra").val(),
    };

    const json = await sendHttpPostRequestLogin(
      `${process.env.REACT_APP_API_URL}/it-api-clientes2/rs/operaciones/login`,
      datos,
      1
    );
    //console.log("LOGIN", json);
    if (json.status === 200) {
      sessionStorage.setItem("jwtToken", json.data.datos);
      $("#hdClienteActual").val(json.data.dpi);
      //console.log("LOGIN", json);
      swal(
        "Ingreso Exitoso",
        "Ahora puedes consultar y gestionar tu(s) poliza(s)",
        "success"
      );
      $("#loga").show();
      $("#mainLogo").hide();
      $("#loga").css("display", "block");
      $("#loga").css("display", "");
      ////console.log(validaCliente($("#hdPagadoresAzasgua").val(),json.data.dpi));
      if (validaCliente($("#hdPagadoresAzasgua").val(), json.data.dpi)) {
        //console.log("encontre a asazgua");
        $("#hdEsAzasgua").val("S");
        $("#nvClienteExterno").show();

        axios
          .get(
            `${process.env.REACT_APP_API_URL}/it-api-clientes2/rs/operaciones/clienteInfo/` +
              json.data.dpi,
            {
              headers: {
                Authorization: `Bearer ${sessionStorage.getItem("jwtToken")}`,
              },
            }
          )
          .then((json) => {
            if (json.status === 200) {
              $("#nomClienteExterno").text(json.data.razonSocial);
            }
          })
          .catch(function (error) {
            //console.log(error);
          });
      } else {
        //console.log("entre al general");
        $("#hidedpi").val(json.data.dpi);
        $("#hidefecnac").val(json.data.fechaNac);
      }
      this.hideComponentn("showHideDemo2");
      this.setState({ dato_dpi: json.data.dpi });
      this.setState({ dato_fecnac: json.data.fecnac });
      this.setCookie("dpi", json.data.dpi, 0);
      this.setCookie("fechaNac", json.data.fechaNac, 0);
    } else {
      swal("Ingreso", "Debes validar tu usuario y contraseña, si no cuentas con usuario puedes crearlo en la opción Crear Usuario", "warning");
    }
    /* })
      .catch(function (error) {
        swal(
          "Error",
          "Debes validar tu usuario y contraseña, si no cuentas con usuario puedes crearlo en la opción Crear Usuario",
          "warning"
        );
      });*/
  }

  CreateInfo() {
    $("#btnRegistrar6").attr("disabled", "disabled");
    
    let datos = {
      idCliente: 99999,
      usuario: this.state.usuarion,
      contrasena: this.state.con,
      email: this.state.email,
      fechaIng: this.state.fecha,
      fechaNac: this.state.fecha,
      dpi: this.state.dpi,
      estado: 1,
    };
    let datosEncriptados=Encriptar(JSON.stringify(datos));
    // validaciòn de campos llenos //
    if (
      this.state.usuarion !== "" &&
      this.state.con !== "" &&
      this.state.email !== "" &&
      this.state.fecha !== "" &&
      this.state.dpi !== ""
    ) {
      // validaciòn de coincidencia de contraseñas //
      if (this.state.con !== this.state.conc) {
        swal(
          "Error",
          "La confirmación de la contraseña no coincide",
          "warning"
        );
      } else {
        let dpi_ = this.state.dpi;
        let fecha_ = this.state.fecha;
        // si todo esta correcto con los datos se consulta si el dpi y fecha de nacimiento estan asociados a una póliza
        axios
          .get(
            `${process.env.REACT_APP_API_URL}/it-api-clientes2/rs/operaciones/informacion/` +
              this.state.dpi +
              "/" +
              Moment(this.state.fecha).format("YYYY-MM-DD"),
            {
              headers: {
                Authorization: `Bearer ${sessionStorage.getItem("jwtToken")}`,
              },
            }
          )
          .then(function (response) {
            swal(
              "Se estan consultando tu información general, por favor espera un momento",
              {
                buttons: false,
                timer: 2000,
              }
            );
            // valida si el cliente ya tiene un usuario creado
            axios
              .get(
                `${process.env.REACT_APP_API_URL}/it-api-clientes2/rs/operaciones/informacion2/` +
                  dpi_ +
                  "/" +
                  Moment(fecha_).format("YYYY-MM-DD"),
                {
                  headers: {
                    Authorization: `Bearer ${sessionStorage.getItem(
                      "jwtToken"
                    )}`,
                  },
                }
              )
              .then(function (responsen) {
                //console.log(responsen);
                if (responsen.status === 200) {
                  swal(
                    "Atención",
                    "Usted ya cuenta con un usuario y contraseña, al momento en que se creo el usuario, fue envíado un correo, o bien puede restablecer su contraseña",
                    "warning"
                  );
                  $("#btnRegistrar6").attr("enabled", "enabled");
                }else if(responsen.status===204) //no encontro datos
                {
                      
                    // handle error
                    swal(
                      "Se estan creando tu usuario, por favor espera un momento",
                      {
                        buttons: false,
                        timer: 5000,
                      }
                    );
                    //console.log(error);
                    axios
                      .post(
                        `${process.env.REACT_APP_API_URL}/it-api-clientes2/rs/operaciones/new2/`,
                        datosEncriptados,
                        {
                          headers: {
                            'Authorization': `Bearer ${sessionStorage.getItem(
                              "jwtToken"
                            )}`,
                            'Content-Type':'application/json',
                          },

                        }
                      )
                      .then((json) => {
                        //console.log("creacion");
                        if (json.status === 200) {
                          //console.log(json);
                          swal({
                            title: "Creación Exitosa",
                            text: "Tu usuario ha sido creado con exito, ahora puedes ingresar con tu usuario y contraseña",
                            type: "success",
                          }).then(function () {
                            window.location.reload();
                          });
                        } else {
                          swal(
                            "Error",
                            "Ha ocurrido un error en la creación, debes comunicarte con tu asesor de póliza",
                            "error"
                          );
                        }
                      })
                      .catch(function (errorn) {
                        swal(
                          "Error",
                          "Ha ocurrido un error en la creación, debes comunicarte con tu asesor de póliza",
                          "error"
                        );
                      });

                }

              })
              .catch(function (error) {
               /* // handle error
                swal(
                  "Se estan creando tu usuario, por favor espera un momento",
                  {
                    buttons: false,
                    timer: 5000,
                  }
                );
                //console.log(error);
                axios
                  .post(
                    `${process.env.REACT_APP_API_URL}/it-api-clientes2/rs/operaciones/new2/`,
                    datos,
                    {
                      headers: {
                        Authorization: `Bearer ${sessionStorage.getItem(
                          "jwtToken"
                        )}`,
                      },
                    }
                  )
                  .then((json) => {
                    //console.log("creacion");
                    if (json.status === 204) {
                      //console.log(json);
                      swal({
                        title: "Creación Exitosa",
                        text: "Tu usuario ha sido creado con exito, ahora puedes ingresar con tu usuario y contraseña",
                        type: "success",
                      }).then(function () {
                        window.location.reload();
                      });
                    } else {
                      swal(
                        "Error",
                        "Ha ocurrido un error en la creación, debes comunicarte con tu asesor de póliza",
                        "error"
                      );
                    }
                  })
                  .catch(function (errorn) {
                    swal(
                      "Error",
                      "Ha ocurrido un error en la creación, debes comunicarte con tu asesor de póliza",
                      "error"
                    );
                  });*/
              })
              .then(function () {});
          })
          .catch(function (errorn) {
            swal(
              "Atención",
              "Encontramos un error en la creación de tu usuario, debes comunicarte con el asesor de tu póliza",
              "warning"
            );
            $("#btnRegistrar6").attr("enabled", "enabled");
            //console.log(errorn);
          })
          .then(function () {});
      }
    } else {
      swal(
        "Error",
        "Debes llenar todos los campos para que puedas crear tu usuario con exito",
        "error"
      );
      $("#btnRegistrar6").attr("enabled", "enabled");
    }
  }

  toggle1() {
    this.setState({
      collapse: !this.state.collapse,
    });
  }

  toggleFade1() {
    this.setState((prevState) => {
      return {
        fadeIn: !prevState,
      };
    });
  }

  onChangeName(e) {
    //$("span").remove();
    $("#nam").css("border-color", "#ced4da");
    $("#nam").find("span").remove();
    this.setState({ nam: e.target.value });
  }

  onChangeFecha(e) {
    this.setState({ fecha: e.target.value });
  }
  onChangeFechaR(e) {
    this.setState({ fechaR: e.target.value });
  }

  onChangeDpi(e) {
    $("span").remove();
    $("#dpi").css("border-color", "#ced4da");
    $("#dpi").find("span").remove();
    this.setState({ dpi: e.target.value });
  }

  onChangeDpiR(e) {
    $("span").remove();
    $("#dpi_recuperacionR").css("border-color", "#ced4da");
    $("#dpi_recuperacionR").find("span").remove();
    this.setState({ dpiR: e.target.value });
  }

  onChangePassN(e) {
    $("span").remove();
    $("#new_contrase").css("border-color", "#ced4da");
    $("#new_contrase").find("span").remove();
    this.setState({ passN: e.target.value });
  }

  onChangePassR(e) {
    $("span").remove();
    $("#new_contrase_r").css("border-color", "#ced4da");
    $("#new_contrase_r").find("span").remove();
    this.setState({ passR: e.target.value });
  }
  onChangeMail(e) {
    $("span").remove();
    $("#email").css("border-color", "#ced4da");
    $("#email").find("span").remove();
    this.setState({ email: e.target.value });
  }

  onChangeCon(e) {
    $("#con").css("border-color", "#ced4da");
    $("#con").find("span").remove();
    this.setState({ con: e.target.value });
    if (e.target.value === "") {
      this.setState({
        score: "null",
      });
    } else {
      var pw = zxcvbn(e.target.value);
      this.setState({
        score: pw.score,
      });
      //console.log(pw.score);
    }
  }

  onChangeConC(e) {
    $("#conc").css("border-color", "#ced4da");
    $("#conc").find("span").remove();
    this.setState({ conc: e.target.value });
  }
  onChangeUsuario(e) {
    $("span").remove();
    $("#usuarion").css("border-color", "#ced4da");
    $("#usuarion").find("span").remove();
    this.setState({ usuarion: e.target.value });
  }

  onChangeRec(e) {
    this.setState({ contra: e.target.value });
  }

  render() {
    var width = $(".g-recaptcha-response-2").parent().width();
    var scale;
    if (width < 302) {
      scale = width / 302;
    } else {
      scale = 1.0;
    }

    $(".g-recaptcha-response-2").css("transform", "scale(" + scale + ")");
    $(".g-recaptcha-response-2").css(
      "-webkit-transform",
      "scale(" + scale + ")"
    );
    $(".g-recaptcha-response-2").css("transform-origin", "0 0");
    $(".g-recaptcha-response-2").css("-webkit-transform-origin", "0 0");

    $(".next").prop("disabled", true);
    window.nam = this.state.nam;
    window.contra = this.state.contra;

    return (
      <div id="log" name="log" align="left">
        {window.showHideDemo2 && <AppCentral />}

        <div className="row" align="left" id="login" name="login">
          <Col xs="1" md="1"></Col>
          <Col xs="10" md="3">
            <div className="row" align="left" id="login" name="login">
              <h1 style={{ color: "#FFFFFF" }}>
                <strong>Bienvenido</strong>
              </h1>
            </div>
            <Form className="was-validated">
              <Row>
                <FormGroup>
                  <Label
                    align="left"
                    style={{
                      align: "left",
                    }}
                    htmlFor="nam"
                  >
                    <br></br>
                    <strong style={{ color: "#FFFFFF" }}>USUARIO</strong>
                  </Label>
                  <Input
                    style={{
                      borderRadius: "25px",
                    }}
                    type="text"
                    className="form-control"
                    id="nam"
                    value={this.state.nam}
                    onChange={this.onChangeName}
                    name="nam"
                    placeholder="Ingresa tu usuario"
                    required="required"
                  />
                </FormGroup>
                <br></br>
                <FormGroup id="div_Rec" name="div_Rec">
                  <Label htmlFor="contra" style={{ cursor: "pointer" }}>
                    <br></br>
                    <strong style={{ color: "#FFFFFF" }}>CONTRASEÑA</strong>
                  </Label>
                  <InputGroup>
                    <input
                      style={{
                        borderRadius: "25px",
                      }}
                      className="form-control"
                      id="contra"
                      name="contra"
                      value={this.state.contra}
                      placeholder="Ingresa tu contraseña"
                      onChange={this.onChangeRec}
                      type={this.state.type}
                      required="required"
                    />
                    &nbsp;{" "}
                    <span className="password__show" onClick={this.handleClick}>
                      {this.state.type === "text" ? (
                        <FaEyeSlash color="white" size="25px" />
                      ) : (
                        <FaEye color="white" size="25px" />
                      )}
                    </span>
                  </InputGroup>
                </FormGroup>
              </Row>
              <br></br>
              <div class="container">
                <div class="row">
                  <div class="col-sm">
                    <Button
                      onClick={() => this.GetInfo()}
                      style={{
                        float: "center",
                        color: "#ffffff",
                      }}
                    >
                      <strong> INGRESAR</strong>
                    </Button>
                  </div>
                  <div class="col-sm">
                    <div>
                      <strong>
                        <a
                          onClick={() => this.showModal()}
                          style={{
                            fontSize: 16,
                            cursor: "pointer",
                            color: "#FFFFFF",
                          }}
                        >
                          Crear Usuario
                        </a>
                      </strong>
                    </div>
                    <div>
                      <strong>
                        <a
                          onClick={() => this.showModal2()}
                          style={{
                            fontSize: 16,
                            cursor: "pointer",
                            color: "#FFFFFF",
                          }}
                        >
                          Recuperar Contraseña
                        </a>
                      </strong>
                    </div>
                  </div>
                </div>
              </div>
            </Form>
          </Col>

          <Col
            xs="12"
            md="3"
            style={{
              align: "right",
              padding: "20px",
              borderRadius: "25px",
            }}
          >
            <Capcha />
          </Col>
        </div>

        <br></br>
        <br></br>
        <Modal size="xl" show={this.state.show} onHide={this.hideModal}>
          <Modal.Header
            style={{ background: "#003479", color: "white" }}
            closeButton
          >
            <Modal.Title>Creación de Usuario</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <strong>Datos Principales</strong>
            <br></br> Recomendación, no utilices el navegador internet explorer,
            para aprovechar tu experiencia de usuario
            <hr style={{ background: "#872434", size: 5, color: "#872434" }} />
            <br></br>
            <Form className="was-validated">
              <FormGroup row>
                <Col xs="12" md="6">
                  <Label>
                    {" "}
                    <strong>Usuario:</strong>
                  </Label>

                  <Input
                    id="usuarion"
                    name="usuarion"
                    value={this.state.usuarion}
                    onChange={this.onChangeUsuario}
                    placeholder="Ingresa tu usuario"
                    required="required"
                  />
                </Col>
                <Col xs="12" md="6">
                  <Label>
                    <strong>Fecha de Nacimiento:</strong>
                  </Label>

                  <Input
                    type="date"
                    id="fecha"
                    value={this.state.fecha}
                    onChange={this.onChangeFecha}
                    name="date-input"
                    placeholder="date"
                    required="required"
                  />
                </Col>
                <br></br>
                <Col xs="12" md="6">
                  <Label>
                    {" "}
                    <strong>DPI:</strong>
                  </Label>

                  <Input
                    type="number"
                    className="form-control"
                    value={this.state.dpi}
                    onChange={this.onChangeDpi}
                    placeholder="Ingresa tu dpi"
                    name="dpi"
                    id="dpi"
                    required="required"
                  />
                </Col>
                <Col xs="12" md="6">
                  <Label>
                    {" "}
                    <strong>Email:</strong>
                  </Label>

                  <Input
                    type="mail"
                    className="form-control"
                    value={this.state.email}
                    onChange={this.onChangeMail}
                    placeholder="Ingresa tu correo"
                    name="email"
                    id="email"
                    required="required"
                  />
                </Col>
                <Col xs="12" md="6">
                  <Label>
                    <strong>Contraseña:</strong>
                  </Label>

                  <InputGroup>
                    <Input
                      type={this.state.typeModal}
                      id="con"
                      name="con"
                      className="form-control"
                      value={this.state.con}
                      onChange={this.onChangeCon}
                      placeholder="Ingresa tu contraseña"
                      required="required"
                    />
                  </InputGroup>
                </Col>
                <br></br>
                <Col xs="12" md="6">
                  <Label>
                    {" "}
                    <strong>Confirma tu contraseña:</strong>
                  </Label>
                  <InputGroup>
                    <Input
                      type={this.state.typeModal}
                      id="conc"
                      name="conc"
                      className="form-control"
                      value={this.state.conc}
                      onChange={this.onChangeConC}
                      placeholder="Ingresa nuevamente tu contraseña"
                      required="required"
                    />
                    &nbsp;{" "}
                    <span onClick={this.handleClick2}>
                      {this.state.typeModal === "text" ? (
                        <FaEyeSlash size="25px" />
                      ) : (
                        <FaEye size="25px" />
                      )}
                    </span>
                  </InputGroup>
                </Col>
                <br></br>
              </FormGroup>
            </Form>{" "}
            <br></br> <br></br>
            <FormGroup className="form-actions">
              <Button
                onClick={() => this.CreateInfo()}
                style={{
                  float: "right",
                  background: "#003479",
                  color: "#ffffff",
                }}
                size="lg"
                color="success"
              >
                Registrar
              </Button>
            </FormGroup>
          </Modal.Body>
        </Modal>
        <Modal size="lg" show={this.state.show2} onHide={this.hideModal2}>
          <Modal.Header
            style={{ background: "#003479", color: "white" }}
            closeButton
          >
            <Modal.Title>Recuperación de Usuario y Contraseña</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <strong>Información General</strong>
            <br></br>En este apartado podras recuperar tu usuario y contraseña,
            el cual sera enviado a tu correo registrado
            <hr style={{ background: "#872434", size: 5, color: "#872434" }} />
            <br></br>
            <Form className="was-validated">
              <FormGroup row>
                <div>
                  <Col xs="12" md="12">
                    <Label>
                      <strong>Fecha de Nacimiento:</strong>
                    </Label>

                    <Input
                      type="date"
                      id="fecha_recuperacion"
                      value={this.state.fecha}
                      onChange={this.onChangeFecha}
                      name="fecha_recuperacion"
                      placeholder="date"
                      required="required"
                    />
                  </Col>
                </div>
                <br></br>
                <br></br> <br></br>
                <div>
                  <Col xs="12" md="12">
                    <Label>
                      {" "}
                      <strong>DPI:</strong>
                    </Label>

                    <Input
                      type="number"
                      className="form-control"
                      value={this.state.dpi}
                      onChange={this.onChangeDpi}
                      placeholder="Ingresa tu dpi"
                      name="dpi_recuperacion"
                      id="dpi_recuperacion"
                      required="required"
                    />
                  </Col>

                  <br></br>
                </div>
              </FormGroup>
            </Form>{" "}
            <br></br> <br></br>
            <FormGroup className="form-actions">
              <Button
                onClick={() => this.SendInfo()}
                style={{
                  float: "right",
                  background: "#003479",
                  color: "#ffffff",
                }}
                size="lg"
                color="success"
              >
                Recuperar
              </Button>
              &nbsp;&nbsp;&nbsp;
              <Button
                onClick={() => this.showModal3()}
                style={{
                  float: "right",
                  background: "#872434",
                  color: "#ffffff",
                }}
                size="lg"
                color="success"
              >
                Cambiar Contraseña
              </Button>
            </FormGroup>
          </Modal.Body>
        </Modal>
        <Modal size="lg" show={this.state.show3} onHide={this.hideModal3}>
          <Modal.Header
            style={{ background: "#003479", color: "white" }}
            closeButton
          >
            <Modal.Title>Cambio de Contraseña</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <strong>Información General</strong>
            <br></br>Debes llenar los datos para poder cambiar tu contraseña
            <hr style={{ background: "#872434", size: 5, color: "#872434" }} />
            <br></br>
            <Form className="was-validated">
              <FormGroup row>
                <div>
                  <Col xs="12" md="12">
                    <Label>
                      <strong>Fecha de Nacimiento:</strong>
                    </Label>

                    <Input
                      type="date"
                      id="fecha_recuperacionR"
                      value={this.state.fechaR}
                      onChange={this.onChangeFechaR}
                      name="fecha_recuperacionR"
                      placeholder="date"
                      required="required"
                    />
                  </Col>
                </div>
                <br></br>
                <br></br> <br></br>
                <div>
                  <Col xs="12" md="12">
                    <Label>
                      {" "}
                      <strong>DPI:</strong>
                    </Label>

                    <Input
                      type="number"
                      className="form-control"
                      value={this.state.dpiR}
                      onChange={this.onChangeDpiR}
                      placeholder="Ingresa tu dpi"
                      name="dpi_recuperacionR"
                      id="dpi_recuperacionR"
                      required="required"
                    />
                  </Col>

                  <br></br>
                </div>
                <div>
                  <Col xs="12" md="12">
                    <Label>
                      <strong>Nueva Contraseña:</strong>
                    </Label>

                    <Input
                      type="text"
                      className="form-control"
                      value={this.state.passN}
                      onChange={this.onChangePassN}
                      placeholder="Ingresa tu Contraseña"
                      name="new_contrase"
                      id="new_contrase"
                      required="required"
                    />
                  </Col>
                </div>
                <br></br>
                <br></br> <br></br>
                <div>
                  <Col xs="12" md="12">
                    <Label>
                      {" "}
                      <strong>Confirmar Contraseña:</strong>
                    </Label>

                    <Input
                      type="text"
                      className="form-control"
                      value={this.state.passR}
                      onChange={this.onChangePassR}
                      placeholder="Repite tu contraseña"
                      name="new_contrase_r"
                      id="new_contrase_r"
                      required="required"
                    />
                  </Col>

                  <br></br>
                </div>
              </FormGroup>
            </Form>{" "}
            <br></br> <br></br>
            <FormGroup className="form-actions">
              <Button
                onClick={() => this.SendInfoNew()}
                style={{
                  float: "right",
                  background: "#003479",
                  color: "#ffffff",
                }}
                size="lg"
                color="success"
              >
                Guardar
              </Button>
              &nbsp;&nbsp;&nbsp;
            </FormGroup>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}

export default Forms;
