import React, { useState, useEffect} from 'react';
import BootStrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import axios from './axios-config';
import swal from 'sweetalert';
import Moment from 'moment';
import $ from 'jquery';


const Pagination = () => {

  const [miVariableCoberturas, setMiVariableCoberturas] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [show, setShow]= useState(false);


  const aMoneda = (numero, opciones) => {
         // Valores por defecto
         opciones = opciones || {};
         opciones.simbolo = opciones.simbolo || "$";
         opciones.separadorDecimal = opciones.separadorDecimal || ".";
         opciones.separadorMiles = opciones.separadorMiles || ",";
         opciones.numeroDeDecimales = opciones.numeroDeDecimales >= 0 ? opciones.numeroDeDecimales : 2;
         opciones.posicionSimbolo = opciones.posicionSimbolo || "i";
         const CIFRAS_MILES = 3;

         // Redondear y convertir a cadena
         var num =parseFloat(numero);
         ////console.log(num);
         let numeroComoCadena = num.toFixed(opciones.numeroDeDecimales);

         // Comenzar desde la izquierda del separador o desde el final de la cadena si no se proporciona
         let posicionDelSeparador = numeroComoCadena.indexOf(opciones.separadorDecimal);
         if (posicionDelSeparador === -1) posicionDelSeparador = numeroComoCadena.length;
         let formateadoSinDecimales = "", indice = posicionDelSeparador;
         // Ir cortando desde la derecha de 3 en 3, y concatenar en una nueva cadena
         while (indice >= 0) {
             let limiteInferior = indice - CIFRAS_MILES;
             // Agregar separador si cortamos más de 3
             formateadoSinDecimales = (limiteInferior > 0 ? opciones.separadorMiles : "") + numeroComoCadena.substring(limiteInferior, indice) + formateadoSinDecimales;
             indice -= CIFRAS_MILES;
         }
         let formateadoSinSimbolo = `${formateadoSinDecimales}${numeroComoCadena.substr(posicionDelSeparador, opciones.numeroDeDecimales + 1)}`;
         return opciones.posicionSimbolo === "i" ? opciones.simbolo + formateadoSinSimbolo : formateadoSinSimbolo + opciones.simbolo;
     };
     // Probar
     const opcionesQuetzales = {
         numeroDeDecimales: 2,
         separadorDecimal: ".",
         separadorMiles: ",",
         simbolo: "Q ", // Con un espacio, ya que la función no agrega espacios
         posicionSimbolo: "i", // i = izquierda, d = derecha
     };
    const     opcionesDolares = {
             numeroDeDecimales: 2,
             separadorDecimal: ".",
             separadorMiles: ",",
             simbolo: " USD", // Con un espacio, ya que la función no agrega espacios
             posicionSimbolo: "d", // i = izquierda, d = derecha
         };


useEffect(async() => {
  let datos=[];
  let datosini=[];
  let mivaribleDep="";
  let NOMBRE_INTERES="";
  let servicio ="";

  if ($('#valPrin').val()==1 || $('#valPrin').val()=='1'){
    servicio = `${process.env.REACT_APP_API_URL}/it-api-clientes2/rs/operaciones/intereses/`+$('#numeroPol').val()+'/'+$('#tipoPol').val()+'/'+$('#dato_cob').val();
  }
  else {
      servicio = `${process.env.REACT_APP_API_URL}/it-api-clientes2/rs/operaciones/intereses/`+$('#numeroPol').val()+'/'+$('#tipoPol').val();
  }

  if ($('#valPrin').val()==1 || $('#valPrin').val()=='1'){
    axios.get(servicio,
      {
        headers: {
          'Authorization': `Bearer ${sessionStorage.getItem('jwtToken')}`,
        },
      }).then(json => {
         if (json.status === 200) {
           //console.log('Consulta intereses general');
           if (json.data.interesesXPoliza.length== undefined){
             switch (json.data.interesesXPoliza.interesesXPolizaPK.codigoRamo) {
    case 'INT':
    //console.log('ingrese a equipo');
       switch (json.data.interesesXPoliza[0].interesesXPolizaPK.codigoInteres) {
         case "5": NOMBRE_INTERES=" MAQUINARIA"; break;
         case "2": NOMBRE_INTERES="CONTENIDO"; break;
         case "3": NOMBRE_INTERES="MEJORAS LOCATIVAS"; break;
         case "4": NOMBRE_INTERES="MERCADERIAS Y/O EXISTENCIAS"; break;
         case "1": NOMBRE_INTERES="EDIFICIO"; break;
         case "6": NOMBRE_INTERES="PROPIEDAD MUEBLE E INMUEBLE"; break;
         case "7": NOMBRE_INTERES="PROPIEDAD MUEBLE E INMUEBLE EXCLUYENDO EXISTENCIAS"; break;
         case "9": NOMBRE_INTERES="MENAJE DE HOGAR"; break;
         case "10": NOMBRE_INTERES="INTERRUPCION DE NEGOCIOS POR RIESGOS COMERCIALES"; break;
         case "11": NOMBRE_INTERES="INTERRUPCION  DE NEGOCIOS  RIESGOS INDUSTRIALES"; break;
         case "12": NOMBRE_INTERES="INTERRUPCION RENTAS DE ALQUILER"; break;
         case "13": NOMBRE_INTERES="INTERRUPCION DE NEGOCIOS RIESGOS COMERCIALES CONTINGENTES"; break;
         case "14": NOMBRE_INTERES="INTERRUPCION INTERDEPENDIENTE"; break;
         case "15": NOMBRE_INTERES="GASTOS EXTRAS"; break;
         case "16": NOMBRE_INTERES="***cambiar a coberturas***  RESPONSABILIDAD LEGAL DE INCENDIO"; break;
         case "17": NOMBRE_INTERES="CALDERAS"; break;
         case "18": NOMBRE_INTERES="ROTURA DE CRISTALES"; break;
         case "19": NOMBRE_INTERES="CAMARAS FRIGORIFICAS"; break;
         case "21": NOMBRE_INTERES="***cambiar a coberturas****DINERO Y/O VALORES"; break;
         case "22": NOMBRE_INTERES="CRISTALES Y/O ROTULOS"; break;
         case "23": NOMBRE_INTERES="FALLA ELECTRICA"; break;
         case "24": NOMBRE_INTERES="***cambiar a coberturas*** DINERO Y/O VALORES DENTRO DEL LOCAL"; break;
         case "25": NOMBRE_INTERES="***cambiar a coberturas****DINERO Y/O VALORES FUERA DEL LOCAL"; break;
         case "26": NOMBRE_INTERES="MOBILIARIO Y EQUIPO"; break;
         case "27": NOMBRE_INTERES="****cambiar a coberturas*** ROTURA DE MAQUINARIA"; break;
         case "28": NOMBRE_INTERES="ROTULOS LUMINOSOS"; break;
         case "29": NOMBRE_INTERES="INTERRUPCION DE NEGOCIOS POR RIESGOS COMERCIALES E INDUSTRIALES"; break;
         case "33": NOMBRE_INTERES="CAJAS FUERTES"; break;
         case "31": NOMBRE_INTERES="ROTURA DE MAQUINARIA Y GASTOS EXTRAS"; break;
         case "32": NOMBRE_INTERES="INTERRUPCION DE NEGOCIOS POR ROTURA DE MAQUINARIA"; break;
         case "8": NOMBRE_INTERES="MUEBLE E INMUEBLES RESIDENCIAS"; break;
         case "34": NOMBRE_INTERES="***cambiar a coberturas***  EXPLOSION DE CALDERAS"; break;
         case "35": NOMBRE_INTERES="DAÑO ELECTRICO E INTERRUPCION DE NEGOCIOS"; break;
         case "38": NOMBRE_INTERES="GASTOS DE APRESURAMIENTO"; break;
         case "39": NOMBRE_INTERES="GASTOS DE EXTINCION DE INCENDIO"; break;
         case "40": NOMBRE_INTERES="***cambiar a coberturas*** ROBO POR FORZAMIENTO"; break;
         case "41": NOMBRE_INTERES="***cambiar a coberturas***  ATRACO DE CONTENIDO"; break;
         case "42": NOMBRE_INTERES="INTERRUPCION POR VENTAS"; break;
         case "43": NOMBRE_INTERES="EQUIPO ELECTRONICO"; break;
         case "44": NOMBRE_INTERES="***  cambiar a coberturas***  HURTO Y/O DESAPARICION MISTERIOSA"; break;
         case "45": NOMBRE_INTERES="CUALQUIER PARTE DE LA REPUBLICA"; break;
         case "46": NOMBRE_INTERES="VEHICULOS DE TODO TIPO"; break;
         case "47": NOMBRE_INTERES="BIENES EN OTRAS UBICACIONES NO DESCRITAS EN LA POLIZA"; break;
         case "48": NOMBRE_INTERES="MONTAJE Y POSTMONTAJE DE MAQUINARIA"; break;
         case "49": NOMBRE_INTERES="NUEVAS CONSTRUCCIONES Y/O ADQUISICIONES"; break;
         case "50": NOMBRE_INTERES="HONORARIOS PROFESIONALES"; break;
         case "51": NOMBRE_INTERES="PROPIEDAD MUEBLE"; break;
         case "52": NOMBRE_INTERES="PUENTES"; break;
         case "53": NOMBRE_INTERES="CUENTAS POR COBRAR"; break;
         case "54": NOMBRE_INTERES="CLIENTES Y PROVEEDORES"; break;
         case "55": NOMBRE_INTERES="PROPIEDAD EN CURSO DE CONSTRUCCION"; break;
         case "56": NOMBRE_INTERES="GASTOS FIJOS SUELDOS Y SALARIOS"; break;
         case "57": NOMBRE_INTERES="INSTALACIONES"; break;
         case "58": NOMBRE_INTERES="ARMAS EN MOVIMIENTO Y/O ESTACIONARIAS"; break;
         case "59": NOMBRE_INTERES="PISCINAS"; break;
         case "20": NOMBRE_INTERES="***CAMBIAR ESTE INTERES A COBERTURAS***"; break;
         case "37": NOMBRE_INTERES="CAMBIAR A COBERTURA"; break;
         case "60": NOMBRE_INTERES="VALORES, DOCUMENTOS"; break;
         case "61": NOMBRE_INTERES="LINEAS DE TRASMISION Y DITRIBUCION"; break;
         case "62": NOMBRE_INTERES="OTROS BIENES"; break;
         case "63": NOMBRE_INTERES="PLANOS, REGISTROS Y LIBROS"; break;
         case "91": NOMBRE_INTERES="HIDROELECTRICA"; break;
         case "65": NOMBRE_INTERES="CAJEROS AUTOMATICOS"; break;
         case "66": NOMBRE_INTERES="OBRAS DE ARTE"; break;
         case "67": NOMBRE_INTERES="DISEÑOS Y PLANOS"; break;
         case "68": NOMBRE_INTERES="EQUIPO ELECTRONICO MÓVIL"; break;
         case "69": NOMBRE_INTERES="UBICACIONES NO NOMBRADAS"; break;
         case "70": NOMBRE_INTERES="PROPIEDAD DE EMPLEADOS"; break;
         case "71": NOMBRE_INTERES="TRASLADOS TEMPORALES"; break;
         case "73": NOMBRE_INTERES="EXISTENCIAS SECAS"; break;
         case "74": NOMBRE_INTERES="EXISTENCIAS EN CAMARAS FRIGORÍFICAS"; break;
         case "75": NOMBRE_INTERES="EQUIPO DE REFRIGERACION"; break;
         case "72": NOMBRE_INTERES="MEZANINE"; break;
         case "81": NOMBRE_INTERES="EQUIPO ELECTRONICO FIJO"; break;
         case "82": NOMBRE_INTERES="MAQUINARIA FIJA"; break;
         case "83": NOMBRE_INTERES="MAQUINARIA MÓVIL"; break;
         case "84": NOMBRE_INTERES="BIENES A LA INTEMPERIE"; break;
         case "85": NOMBRE_INTERES="NUEVOS BIENES MUEBLES"; break;
         case "93": NOMBRE_INTERES="CAJEROS AUTOMATICOS"; break;
         case "87": NOMBRE_INTERES="Nuevos Bienes Muebles e Inmuebles"; break;
         case "76": NOMBRE_INTERES="EXISTENCIAS DE CAFE"; break;
         case "77": NOMBRE_INTERES="PROPIEDAD INMUEBLE"; break;
         case "78": NOMBRE_INTERES="MATERIA PRIMA"; break;
         case "79": NOMBRE_INTERES="PRODUCTO EN PROCESO"; break;
         case "80": NOMBRE_INTERES="PRODUCTO TERMINADO"; break;
         case "86": NOMBRE_INTERES="IIMPUESTOS"; break;
         case "88": NOMBRE_INTERES="DAÑOS MATERIALES"; break;
         case "89": NOMBRE_INTERES="PORTADORES EXTERNOS DE DATOS"; break;
         case "90": NOMBRE_INTERES="GASTOS EXTRAS PARA EQUIPO ELECTRONICO"; break;
         case "92": NOMBRE_INTERES="CARPA"; break;
         case "94": NOMBRE_INTERES="NUEVAS UBICACIONES"; break;
         case "104": NOMBRE_INTERES="EQUIPO PORTATIL PROMOCIONES"; break;
         case "105": NOMBRE_INTERES="HERRAMIENTAS DE TRABAJO"; break;
         case "95": NOMBRE_INTERES="Transformadores, Equipo de Aire Acondicionado"; break;
         case "96": NOMBRE_INTERES="Tanque de Combustible"; break;
         case "97": NOMBRE_INTERES="Maquinaria en Negociación"; break;
         case "98": NOMBRE_INTERES="Achicadora"; break;
         case "99": NOMBRE_INTERES="Equipo de Levante"; break;
         case "100": NOMBRE_INTERES="Puentes de Concreto"; break;
         case "101": NOMBRE_INTERES="Riego"; break;
         case "102": NOMBRE_INTERES="Mobiliario, Equipo y Contenido"; break;
         case "103": NOMBRE_INTERES="Herramienta y Equipo"; break;
         case "171": NOMBRE_INTERES="CAMARAS FRIGORIFICAS"; break;
         case "172": NOMBRE_INTERES="***cambiar a coberturas****DINERO Y/O VALORES"; break;
         case "173": NOMBRE_INTERES="CRISTALES Y/O ROTULOS"; break;
         case "174": NOMBRE_INTERES="FALLA ELECTRICA"; break;
         case "175": NOMBRE_INTERES="***cambiar a coberturas*** DINERO Y/O VALORES DENTRO DEL LOCAL"; break;
         case "176": NOMBRE_INTERES="***cambiar a coberturas****DINERO Y/O VALORES FUERA DEL LOCAL"; break;
         case "177": NOMBRE_INTERES="MOBILIARIO Y EQUIPO"; break;
         case "178": NOMBRE_INTERES="****cambiar a coberturas*** ROTURA DE MAQUINARIA"; break;
         case "179": NOMBRE_INTERES="ROTULOS LUMINOSOS"; break;
         case "180": NOMBRE_INTERES="INTERRUPCION DE NEGOCIOS POR RIESGOS COMERCIALES E INDUSTRIALES"; break;
         case "181": NOMBRE_INTERES="CAJAS FUERTES"; break;
         case "182": NOMBRE_INTERES="ROTURA DE MAQUINARIA Y GASTOS EXTRAS"; break;
         case "183": NOMBRE_INTERES="INTERRUPCION DE NEGOCIOS POR ROTURA DE MAQUINARIA"; break;
         case "184": NOMBRE_INTERES="MUEBLE E INMUEBLES RESIDENCIAS"; break;
         case "185": NOMBRE_INTERES="***cambiar a coberturas***  EXPLOSION DE CALDERAS"; break;
         case "186": NOMBRE_INTERES="DAÑO ELECTRICO E INTERRUPCION DE NEGOCIOS"; break;
         case "187": NOMBRE_INTERES="GASTOS DE APRESURAMIENTO"; break;
         case "188": NOMBRE_INTERES="GASTOS DE EXTINCION DE INCENDIO"; break;
         case "154": NOMBRE_INTERES=" MAQUINARIA"; break;
         case "155": NOMBRE_INTERES="CONTENIDO"; break;
         case "156": NOMBRE_INTERES="MEJORAS LOCATIVAS"; break;
         case "157": NOMBRE_INTERES="MERCADERIAS Y/O EXISTENCIAS"; break;
         case "158": NOMBRE_INTERES="EDIFICIO"; break;
         case "159": NOMBRE_INTERES="PROPIEDAD MUEBLE E INMUEBLE"; break;
         case "160": NOMBRE_INTERES="PROPIEDAD MUEBLE E INMUEBLE EXCLUYENDO EXISTENCIAS"; break;
         case "161": NOMBRE_INTERES="MENAJE DE HOGAR"; break;
         case "162": NOMBRE_INTERES="INTERRUPCION DE NEGOCIOS POR RIESGOS COMERCIALES"; break;
         case "163": NOMBRE_INTERES="INTERRUPCION  DE NEGOCIOS  RIESGOS INDUSTRIALES"; break;
         case "164": NOMBRE_INTERES="INTERRUPCION RENTAS DE ALQUILER"; break;
         case "165": NOMBRE_INTERES="INTERRUPCION DE NEGOCIOS RIESGOS COMERCIALES CONTINGENTES"; break;
         case "166": NOMBRE_INTERES="INTERRUPCION INTERDEPENDIENTE"; break;
         case "167": NOMBRE_INTERES="GASTOS EXTRAS"; break;
         case "168": NOMBRE_INTERES="***cambiar a coberturas***  RESPONSABILIDAD LEGAL DE INCENDIO"; break;
         case "169": NOMBRE_INTERES="CALDERAS"; break;
         case "170": NOMBRE_INTERES="ROTURA DE CRISTALES"; break;
         case "256": NOMBRE_INTERES="OTRAS ESTRUCTURAS"; break;
         case "257": NOMBRE_INTERES="MEJORAS AL EDIFICIO"; break;
         case "112": NOMBRE_INTERES="MUROS PERIMETRALES"; break;
         case "106": NOMBRE_INTERES="MAQUINARIA Y EQUIPO"; break;
         case "107": NOMBRE_INTERES="EQUIPO DE RIEGO"; break;
         case "108": NOMBRE_INTERES="BANDA TRANSPORTADORA"; break;
         case "109": NOMBRE_INTERES="REPUESTOS"; break;
         case "110": NOMBRE_INTERES="MEDICINA EXISTENCIAS"; break;
         case "111": NOMBRE_INTERES="EQUIPO EMPACADORA"; break;
         case "189": NOMBRE_INTERES="***cambiar a coberturas*** ROBO POR FORZAMIENTO"; break;
         case "190": NOMBRE_INTERES="***cambiar a coberturas***  ATRACO DE CONTENIDO"; break;
         case "191": NOMBRE_INTERES="INTERRUPCION POR VENTAS"; break;
         case "192": NOMBRE_INTERES="EQUIPO ELECTRONICO"; break;
         case "193": NOMBRE_INTERES="***  cambiar a coberturas***  HURTO Y/O DESAPARICION MISTERIOSA"; break;
         case "194": NOMBRE_INTERES="CUALQUIER PARTE DE LA REPUBLICA"; break;
         case "195": NOMBRE_INTERES="VEHICULOS DE TODO TIPO"; break;
         case "196": NOMBRE_INTERES="BIENES EN OTRAS UBICACIONES NO DESCRITAS EN LA POLIZA"; break;
         case "197": NOMBRE_INTERES="MONTAJE Y POSTMONTAJE DE MAQUINARIA"; break;
         case "198": NOMBRE_INTERES="NUEVAS CONSTRUCCIONES Y/O ADQUISICIONES"; break;
         case "199": NOMBRE_INTERES="HONORARIOS PROFESIONALES"; break;
         case "200": NOMBRE_INTERES="PROPIEDAD MUEBLE"; break;
         case "201": NOMBRE_INTERES="PUENTES"; break;
         case "202": NOMBRE_INTERES="CUENTAS POR COBRAR"; break;
         case "203": NOMBRE_INTERES="CLIENTES Y PROVEEDORES"; break;
         case "204": NOMBRE_INTERES="PROPIEDAD EN CURSO DE CONSTRUCCION"; break;
         case "205": NOMBRE_INTERES="GASTOS FIJOS SUELDOS Y SALARIOS"; break;
         case "206": NOMBRE_INTERES="INSTALACIONES"; break;
         case "207": NOMBRE_INTERES="ARMAS EN MOVIMIENTO Y/O ESTACIONARIAS"; break;
         case "208": NOMBRE_INTERES="PISCINAS"; break;
         case "209": NOMBRE_INTERES="***CAMBIAR ESTE INTERES A COBERTURAS***"; break;
         case "210": NOMBRE_INTERES="CAMBIAR A COBERTURA"; break;
         case "211": NOMBRE_INTERES="VALORES, DOCUMENTOS"; break;
         case "212": NOMBRE_INTERES="LINEAS DE TRASMISION Y DITRIBUCION"; break;
         case "213": NOMBRE_INTERES="OTROS BIENES"; break;
         case "214": NOMBRE_INTERES="PLANOS, REGISTROS Y LIBROS"; break;
         case "215": NOMBRE_INTERES="HIDROELECTRICA"; break;
         case "216": NOMBRE_INTERES="CAJEROS AUTOMATICOS"; break;
         case "217": NOMBRE_INTERES="OBRAS DE ARTE"; break;
         case "218": NOMBRE_INTERES="DISEÑOS Y PLANOS"; break;
         case "219": NOMBRE_INTERES="EQUIPO ELECTRONICO MÓVIL"; break;
         case "220": NOMBRE_INTERES="UBICACIONES NO NOMBRADAS"; break;
         case "221": NOMBRE_INTERES="PROPIEDAD DE EMPLEADOS"; break;
         case "222": NOMBRE_INTERES="TRASLADOS TEMPORALES"; break;
         case "223": NOMBRE_INTERES="EXISTENCIAS SECAS"; break;
         case "224": NOMBRE_INTERES="EXISTENCIAS EN CAMARAS FRIGORÍFICAS"; break;
         case "225": NOMBRE_INTERES="EQUIPO DE REFRIGERACION"; break;
         case "226": NOMBRE_INTERES="MEZANINE"; break;
         case "227": NOMBRE_INTERES="EQUIPO ELECTRONICO FIJO"; break;
         case "228": NOMBRE_INTERES="MAQUINARIA FIJA"; break;
         case "229": NOMBRE_INTERES="MAQUINARIA MÓVIL"; break;
         case "230": NOMBRE_INTERES="BIENES A LA INTEMPERIE"; break;
         case "231": NOMBRE_INTERES="NUEVOS BIENES MUEBLES"; break;
         case "232": NOMBRE_INTERES="CAJEROS AUTOMATICOS"; break;
         case "233": NOMBRE_INTERES="Nuevos Bienes Muebles e Inmuebles"; break;
         case "234": NOMBRE_INTERES="EXISTENCIAS DE CAFE"; break;
         case "235": NOMBRE_INTERES="PROPIEDAD INMUEBLE"; break;
         case "236": NOMBRE_INTERES="MATERIA PRIMA"; break;
         case "237": NOMBRE_INTERES="PRODUCTO EN PROCESO"; break;
         case "238": NOMBRE_INTERES="PRODUCTO TERMINADO"; break;
         case "239": NOMBRE_INTERES="IIMPUESTOS"; break;
         case "240": NOMBRE_INTERES="DAÑOS MATERIALES"; break;
         case "241": NOMBRE_INTERES="PORTADORES EXTERNOS DE DATOS"; break;
         case "242": NOMBRE_INTERES="GASTOS EXTRAS PARA EQUIPO ELECTRONICO"; break;
       }
      break;
    case 'EE':
    //console.log('ingrese a certificado');
    switch (json.data.interesesXPoliza[0].interesesXPolizaPK.codigoInteres) {
      case "1": NOMBRE_INTERES="EQUIPO ELECTRONICO"; break;
  case "2": NOMBRE_INTERES="EQUIPO ELECTRONICO MOVIL"; break;
  case "3": NOMBRE_INTERES="RACK"; break;
  case "4": NOMBRE_INTERES="EQUIPO ELECTRONICO FIJO"; break;
  case "5": NOMBRE_INTERES="EQUIPO ELECTRONICO MOVIL"; break;
  }
    break;
    case "INCE" :
    //console.log('ingrese a INCEN');
    switch (json.data.interesesXPoliza[0].interesesXPolizaPK.codigoInteres) {
      case "1": NOMBRE_INTERES="EDIFICIO"; break;
      case "18": NOMBRE_INTERES=" ROTURA DE CRISTALES"; break;
      case "11": NOMBRE_INTERES="INTERRUPCION DE NEGOCIOS POR RIESGOS     INDUSTRIALES"; break;
      case "12": NOMBRE_INTERES=" INTERRUPCION RENTAS DE ALQUILER"; break;
      case "10": NOMBRE_INTERES="INTERRUPCION DE NEGOCIOS POR RIESGOS     COMERCIALES"; break;
      case "9": NOMBRE_INTERES="MENAJE DE HOGAR"; break;
      case "8": NOMBRE_INTERES=" MUEBLE E INMUEBLES RESIDENCIAS"; break;
      case "107": NOMBRE_INTERES="EDIFICIO"; break;
      case "108": NOMBRE_INTERES=" ROTURA DE CRISTALES"; break;
      case "7": NOMBRE_INTERES="PROPIEDAD MUEBLE E INMUEBLE EXCLUYENDO      EXISTENCIAS"; break;
      case "6": NOMBRE_INTERES=" PROPIEDAD MUEBLE E INMUEBLE"; break;
      case "20": NOMBRE_INTERES="CAMARAS FRIGORIFICAS  (CUARTOS FRIOS)"; break;
      case "22": NOMBRE_INTERES="CONTAMINACION POR CONTACTO CON OTRAS CARGAS"; break;
      case "21": NOMBRE_INTERES="HURTO Y PILLAJE"; break;
      case "23": NOMBRE_INTERES="MOBILIARIO Y EQUIPO"; break;
      case "14": NOMBRE_INTERES="INTERRUPCION INTERDEPENDIENTE"; break;
      case "13": NOMBRE_INTERES="INTERRUPCION DE NEGOCIOS RIESGOS     COMERCIALES CONTINGENTES"; break;
      case "15": NOMBRE_INTERES="GASTOS EXTRAS"; break;
      case "16": NOMBRE_INTERES="RESPONSABILIDAD LEGAL DE INCENDIO"; break;
      case "17": NOMBRE_INTERES="CALDERAS"; break;
      case "3": NOMBRE_INTERES="MEJORAS LOCATIVAS"; break;
      case "2": NOMBRE_INTERES="CONTENIDO"; break;
      case "4": NOMBRE_INTERES="MERCADERÍAS Y/O EXISTENCIAS"; break;
      case "5": NOMBRE_INTERES="MAQUINARIA"; break;
      case "24": NOMBRE_INTERES="ROBO POR FORZAMIENTO"; break;
      case "26": NOMBRE_INTERES="INTERRUPCION DE NEGOCIOS PARA RIESGOS COMERCIALES E INDUSTRIALES"; break;
      case "27": NOMBRE_INTERES="***CAMBIAR A COBERTURAS****DINERO Y/O VALORES DENTRO DEL LOCAL"; break;
      case "28": NOMBRE_INTERES="***CAMBIAR A COBERTURAS****DINERO Y/O VALORES FUERA DEL LOCAL"; break;
      case "29": NOMBRE_INTERES="CAJAS FUERTES"; break;
      case "30": NOMBRE_INTERES="******CAMBIAR A COBERTURAS****CRISTALES Y/O ROTULOS"; break;
      case "31": NOMBRE_INTERES="MONTAJE Y POSTMONTAJE DE MAQUINARIA"; break;
      case "32": NOMBRE_INTERES="BIENES EN OTRAS UBICACIONES NO DESCRITAS EN LA POLIZA"; break;
      case "33": NOMBRE_INTERES="NUEVAS CONSTRUCCIONES Y/O ADQUISICIONES"; break;
      case "34": NOMBRE_INTERES="BORDAS Y COMPUERTAS"; break;
      case "19": NOMBRE_INTERES="*****CAMBIAR A COBERTURA****"; break;
      case "25": NOMBRE_INTERES="******CAMBIAR A COBERTURA******"; break;
      case "35": NOMBRE_INTERES="OTROS BIENES"; break;
      case "36": NOMBRE_INTERES="EQUIPO ELECTRÓNICO"; break;
      case "37": NOMBRE_INTERES="TRASLADOS TEMPORALES"; break;
      case "46": NOMBRE_INTERES="BIENES PROPIEDAD DE TERCEROS"; break;
      case "38": NOMBRE_INTERES="INTERRUPCION DE NEGOCIOS CONTINGENTE"; break;
      case "40": NOMBRE_INTERES="PROPIEDAD INMUEBLE"; break;
      case "41": NOMBRE_INTERES="PROPIEDAD MUEBLE"; break;
      case "42": NOMBRE_INTERES="EQUIPO ELECTRÓNICO FIJO"; break;
      case "43": NOMBRE_INTERES="EQUIPO ELECTRÓNICO MÓVIL"; break;
      case "44": NOMBRE_INTERES="MAQUINARIA FIJA"; break;
      case "45": NOMBRE_INTERES="MAQUINARIA MÓVIL"; break;
      case "39": NOMBRE_INTERES="PLANTULAS"; break;
      case "109": NOMBRE_INTERES="INTERRUPCION DE NEGOCIOS POR RIESGOS     INDUSTRIALES"; break;
      case "110": NOMBRE_INTERES=" INTERRUPCION RENTAS DE ALQUILER"; break;
      case "111": NOMBRE_INTERES="INTERRUPCION DE NEGOCIOS POR RIESGOS     COMERCIALES"; break;
      case "112": NOMBRE_INTERES="MENAJE DE HOGAR"; break;
      case "113": NOMBRE_INTERES=" MUEBLE E INMUEBLES RESIDENCIAS"; break;
      case "114": NOMBRE_INTERES="PROPIEDAD MUEBLE E INMUEBLE EXCLUYENDO      EXISTENCIAS"; break;
      case "115": NOMBRE_INTERES=" PROPIEDAD MUEBLE E INMUEBLE"; break;
      case "116": NOMBRE_INTERES="CAMARAS FRIGORIFICAS  (CUARTOS FRIOS)"; break;
      case "117": NOMBRE_INTERES="CONTAMINACION POR CONTACTO CON OTRAS CARGAS"; break;
      case "118": NOMBRE_INTERES="HURTO Y PILLAJE"; break;
      case "119": NOMBRE_INTERES="MOBILIARIO Y EQUIPO"; break;
      case "120": NOMBRE_INTERES="INTERRUPCION INTERDEPENDIENTE"; break;
      case "121": NOMBRE_INTERES="INTERRUPCION DE NEGOCIOS RIESGOS     COMERCIALES CONTINGENTES"; break;
      case "122": NOMBRE_INTERES="GASTOS EXTRAS"; break;
      case "123": NOMBRE_INTERES="RESPONSABILIDAD LEGAL DE INCENDIO"; break;
      case "124": NOMBRE_INTERES="CALDERAS"; break;
      case "125": NOMBRE_INTERES="MEJORAS LOCATIVAS"; break;
      case "126": NOMBRE_INTERES="CONTENIDO"; break;
      case "127": NOMBRE_INTERES="MERCADERÍAS Y/O EXISTENCIAS"; break;
      case "128": NOMBRE_INTERES="MAQUINARIA"; break;
      case "129": NOMBRE_INTERES="ROBO POR FORZAMIENTO"; break;
      case "130": NOMBRE_INTERES="INTERRUPCION DE NEGOCIOS PARA RIESGOS COMERCIALES E INDUSTRIALES"; break;
      case "131": NOMBRE_INTERES="***CAMBIAR A COBERTURAS****DINERO Y/O VALORES DENTRO DEL LOCAL"; break;
      case "132": NOMBRE_INTERES="***CAMBIAR A COBERTURAS****DINERO Y/O VALORES FUERA DEL LOCAL"; break;
      case "133": NOMBRE_INTERES="CAJAS FUERTES"; break;
      case "134": NOMBRE_INTERES="******CAMBIAR A COBERTURAS****CRISTALES Y/O ROTULOS"; break;
      case "135": NOMBRE_INTERES="MONTAJE Y POSTMONTAJE DE MAQUINARIA"; break;
      case "136": NOMBRE_INTERES="BIENES EN OTRAS UBICACIONES NO DESCRITAS EN LA POLIZA"; break;
      case "137": NOMBRE_INTERES="NUEVAS CONSTRUCCIONES Y/O ADQUISICIONES"; break;
      case "138": NOMBRE_INTERES="BORDAS Y COMPUERTAS"; break;
      case "139": NOMBRE_INTERES="*****CAMBIAR A COBERTURA****"; break;
      case "140": NOMBRE_INTERES="******CAMBIAR A COBERTURA******"; break;
      case "141": NOMBRE_INTERES="OTROS BIENES"; break;
      case "142": NOMBRE_INTERES="EQUIPO ELECTRÓNICO"; break;
      case "143": NOMBRE_INTERES="TRASLADOS TEMPORALES"; break;
      case "144": NOMBRE_INTERES="BIENES PROPIEDAD DE TERCEROS"; break;
      case "145": NOMBRE_INTERES="INTERRUPCION DE NEGOCIOS CONTINGENTE"; break;
      case "146": NOMBRE_INTERES="PROPIEDAD INMUEBLE"; break;
      case "147": NOMBRE_INTERES="PROPIEDAD MUEBLE"; break;
      case "148": NOMBRE_INTERES="EQUIPO ELECTRÓNICO FIJO"; break;
      case "149": NOMBRE_INTERES="EQUIPO ELECTRÓNICO MÓVIL"; break;
      case "150": NOMBRE_INTERES="MAQUINARIA FIJA"; break;
      case "151": NOMBRE_INTERES="MAQUINARIA MÓVIL"; break;
      case "152": NOMBRE_INTERES="PLANTULAS"; break;
      case "153": NOMBRE_INTERES="OTRAS ESTRUCTURAS"; break;
  }
    break;
  case "CAR" :
  //console.log('ingrese a TDINCEN');
  switch (json.data.interesesXPoliza[0].interesesXPolizaPK.codigoInteres) {
    	case "2": NOMBRE_INTERES="RESPONSABILIDAD CIVIL EXTRACONTRACTUAL"; break;
    	case "3": NOMBRE_INTERES="REMOCION DE ESCOMBROS"; break;
    	case "4": NOMBRE_INTERES="RESPONSABILIDAD CIVIL CRUZADA"; break;
    	case "5": NOMBRE_INTERES="TERREMOTO"; break;
    	case "1": NOMBRE_INTERES="OBRAS EN CONSTRUCCIÓN"; break;
    	case "6": NOMBRE_INTERES="ROBO Y/O ATRACO"; break;
    	case "7": NOMBRE_INTERES="RESPONSABILIDAD CIVIL PATRONAL"; break;
    	case "8": NOMBRE_INTERES="MAQUINARIA Y EQUIPO"; break;
    	case "9": NOMBRE_INTERES="MAQUINARIA"; break;
    	case "10": NOMBRE_INTERES="EQUIPO"; break;
  }  break;
  case 'RM':
  //console.log('ingrese a RC');
     switch (json.data.interesesXPoliza[0].interesesXPolizaPK.codigoInteres) {
       case "1": NOMBRE_INTERES="MAQUINARIA Y EQUIPO"; break;
  case "2": NOMBRE_INTERES="INTERRUPCION DE NEGOCIOS POR RIESGOS COMERCIALES"; break;
  case "3": NOMBRE_INTERES="INTERRUPCION DE NEGOCIOS POR RIESGOS INDUSTRIALES"; break;
  case "4": NOMBRE_INTERES="RESPONSABILIDAD CIVIL  ****TRASLADAR A COBERTURAS****"; break;
  case "5": NOMBRE_INTERES="CALDERAS"; break;
  case "6": NOMBRE_INTERES="GASTOS  EXTRAS"; break;
     }
     break;
     case 'RC':
     //console.log('ingrese a TTR');
        switch (json.data.interesesXPoliza[0].interesesXPolizaPK.codigoInteres) {
          case "1": NOMBRE_INTERES="EDIFICIOS"; break;
          case "2": NOMBRE_INTERES="BIENES"; break;
        }
  break;
  }
    datosini =[{CODIGO:json.data.interesesXPoliza[0].interesesXPolizaPK.codigoInteres,NOMBRE:NOMBRE_INTERES, SUMA:aMoneda(json.data.interesesXPoliza[0].sumaAsegurada,opcionesDolares), LIMITE:0}];
  //console.log(datosini);
  }else{
            for (var i = 0; i < json.data.interesesXPoliza.length; i++) {
  //console.log(json.data.interesesXPoliza[0][i].interesesXPolizaPK.codigoRamo);
  switch (json.data.interesesXPoliza[i].interesesXPolizaPK.codigoRamo) {
    case 'INT':
    //console.log('ingrese a equipo');
       switch (String(json.data.interesesXPoliza[i].interesesXPolizaPK.codigoInteres)) {
         case "5": NOMBRE_INTERES=" MAQUINARIA"; break;
         case "2": NOMBRE_INTERES="CONTENIDO"; break;
         case "3": NOMBRE_INTERES="MEJORAS LOCATIVAS"; break;
         case "4": NOMBRE_INTERES="MERCADERIAS Y/O EXISTENCIAS"; break;
         case "1": NOMBRE_INTERES="EDIFICIO"; break;
         case "6": NOMBRE_INTERES="PROPIEDAD MUEBLE E INMUEBLE"; break;
         case "7": NOMBRE_INTERES="PROPIEDAD MUEBLE E INMUEBLE EXCLUYENDO EXISTENCIAS"; break;
         case "9": NOMBRE_INTERES="MENAJE DE HOGAR"; break;
         case "10": NOMBRE_INTERES="INTERRUPCION DE NEGOCIOS POR RIESGOS COMERCIALES"; break;
         case "11": NOMBRE_INTERES="INTERRUPCION  DE NEGOCIOS  RIESGOS INDUSTRIALES"; break;
         case "12": NOMBRE_INTERES="INTERRUPCION RENTAS DE ALQUILER"; break;
         case "13": NOMBRE_INTERES="INTERRUPCION DE NEGOCIOS RIESGOS COMERCIALES CONTINGENTES"; break;
         case "14": NOMBRE_INTERES="INTERRUPCION INTERDEPENDIENTE"; break;
         case "15": NOMBRE_INTERES="GASTOS EXTRAS"; break;
         case "16": NOMBRE_INTERES="***cambiar a coberturas***  RESPONSABILIDAD LEGAL DE INCENDIO"; break;
         case "17": NOMBRE_INTERES="CALDERAS"; break;
         case "18": NOMBRE_INTERES="ROTURA DE CRISTALES"; break;
         case "19": NOMBRE_INTERES="CAMARAS FRIGORIFICAS"; break;
         case "21": NOMBRE_INTERES="***cambiar a coberturas****DINERO Y/O VALORES"; break;
         case "22": NOMBRE_INTERES="CRISTALES Y/O ROTULOS"; break;
         case "23": NOMBRE_INTERES="FALLA ELECTRICA"; break;
         case "24": NOMBRE_INTERES="***cambiar a coberturas*** DINERO Y/O VALORES DENTRO DEL LOCAL"; break;
         case "25": NOMBRE_INTERES="***cambiar a coberturas****DINERO Y/O VALORES FUERA DEL LOCAL"; break;
         case "26": NOMBRE_INTERES="MOBILIARIO Y EQUIPO"; break;
         case "27": NOMBRE_INTERES="****cambiar a coberturas*** ROTURA DE MAQUINARIA"; break;
         case "28": NOMBRE_INTERES="ROTULOS LUMINOSOS"; break;
         case "29": NOMBRE_INTERES="INTERRUPCION DE NEGOCIOS POR RIESGOS COMERCIALES E INDUSTRIALES"; break;
         case "33": NOMBRE_INTERES="CAJAS FUERTES"; break;
         case "31": NOMBRE_INTERES="ROTURA DE MAQUINARIA Y GASTOS EXTRAS"; break;
         case "32": NOMBRE_INTERES="INTERRUPCION DE NEGOCIOS POR ROTURA DE MAQUINARIA"; break;
         case "8": NOMBRE_INTERES="MUEBLE E INMUEBLES RESIDENCIAS"; break;
         case "34": NOMBRE_INTERES="***cambiar a coberturas***  EXPLOSION DE CALDERAS"; break;
         case "35": NOMBRE_INTERES="DAÑO ELECTRICO E INTERRUPCION DE NEGOCIOS"; break;
         case "38": NOMBRE_INTERES="GASTOS DE APRESURAMIENTO"; break;
         case "39": NOMBRE_INTERES="GASTOS DE EXTINCION DE INCENDIO"; break;
         case "40": NOMBRE_INTERES="***cambiar a coberturas*** ROBO POR FORZAMIENTO"; break;
         case "41": NOMBRE_INTERES="***cambiar a coberturas***  ATRACO DE CONTENIDO"; break;
         case "42": NOMBRE_INTERES="INTERRUPCION POR VENTAS"; break;
         case "43": NOMBRE_INTERES="EQUIPO ELECTRONICO"; break;
         case "44": NOMBRE_INTERES="***  cambiar a coberturas***  HURTO Y/O DESAPARICION MISTERIOSA"; break;
         case "45": NOMBRE_INTERES="CUALQUIER PARTE DE LA REPUBLICA"; break;
         case "46": NOMBRE_INTERES="VEHICULOS DE TODO TIPO"; break;
         case "47": NOMBRE_INTERES="BIENES EN OTRAS UBICACIONES NO DESCRITAS EN LA POLIZA"; break;
         case "48": NOMBRE_INTERES="MONTAJE Y POSTMONTAJE DE MAQUINARIA"; break;
         case "49": NOMBRE_INTERES="NUEVAS CONSTRUCCIONES Y/O ADQUISICIONES"; break;
         case "50": NOMBRE_INTERES="HONORARIOS PROFESIONALES"; break;
         case "51": NOMBRE_INTERES="PROPIEDAD MUEBLE"; break;
         case "52": NOMBRE_INTERES="PUENTES"; break;
         case "53": NOMBRE_INTERES="CUENTAS POR COBRAR"; break;
         case "54": NOMBRE_INTERES="CLIENTES Y PROVEEDORES"; break;
         case "55": NOMBRE_INTERES="PROPIEDAD EN CURSO DE CONSTRUCCION"; break;
         case "56": NOMBRE_INTERES="GASTOS FIJOS SUELDOS Y SALARIOS"; break;
         case "57": NOMBRE_INTERES="INSTALACIONES"; break;
         case "58": NOMBRE_INTERES="ARMAS EN MOVIMIENTO Y/O ESTACIONARIAS"; break;
         case "59": NOMBRE_INTERES="PISCINAS"; break;
         case "20": NOMBRE_INTERES="***CAMBIAR ESTE INTERES A COBERTURAS***"; break;
         case "37": NOMBRE_INTERES="CAMBIAR A COBERTURA"; break;
         case "60": NOMBRE_INTERES="VALORES, DOCUMENTOS"; break;
         case "61": NOMBRE_INTERES="LINEAS DE TRASMISION Y DITRIBUCION"; break;
         case "62": NOMBRE_INTERES="OTROS BIENES"; break;
         case "63": NOMBRE_INTERES="PLANOS, REGISTROS Y LIBROS"; break;
         case "91": NOMBRE_INTERES="HIDROELECTRICA"; break;
         case "65": NOMBRE_INTERES="CAJEROS AUTOMATICOS"; break;
         case "66": NOMBRE_INTERES="OBRAS DE ARTE"; break;
         case "67": NOMBRE_INTERES="DISEÑOS Y PLANOS"; break;
         case "68": NOMBRE_INTERES="EQUIPO ELECTRONICO MÓVIL"; break;
         case "69": NOMBRE_INTERES="UBICACIONES NO NOMBRADAS"; break;
         case "70": NOMBRE_INTERES="PROPIEDAD DE EMPLEADOS"; break;
         case "71": NOMBRE_INTERES="TRASLADOS TEMPORALES"; break;
         case "73": NOMBRE_INTERES="EXISTENCIAS SECAS"; break;
         case "74": NOMBRE_INTERES="EXISTENCIAS EN CAMARAS FRIGORÍFICAS"; break;
         case "75": NOMBRE_INTERES="EQUIPO DE REFRIGERACION"; break;
         case "72": NOMBRE_INTERES="MEZANINE"; break;
         case "81": NOMBRE_INTERES="EQUIPO ELECTRONICO FIJO"; break;
         case "82": NOMBRE_INTERES="MAQUINARIA FIJA"; break;
         case "83": NOMBRE_INTERES="MAQUINARIA MÓVIL"; break;
         case "84": NOMBRE_INTERES="BIENES A LA INTEMPERIE"; break;
         case "85": NOMBRE_INTERES="NUEVOS BIENES MUEBLES"; break;
         case "93": NOMBRE_INTERES="CAJEROS AUTOMATICOS"; break;
         case "87": NOMBRE_INTERES="Nuevos Bienes Muebles e Inmuebles"; break;
         case "76": NOMBRE_INTERES="EXISTENCIAS DE CAFE"; break;
         case "77": NOMBRE_INTERES="PROPIEDAD INMUEBLE"; break;
         case "78": NOMBRE_INTERES="MATERIA PRIMA"; break;
         case "79": NOMBRE_INTERES="PRODUCTO EN PROCESO"; break;
         case "80": NOMBRE_INTERES="PRODUCTO TERMINADO"; break;
         case "86": NOMBRE_INTERES="IIMPUESTOS"; break;
         case "88": NOMBRE_INTERES="DAÑOS MATERIALES"; break;
         case "89": NOMBRE_INTERES="PORTADORES EXTERNOS DE DATOS"; break;
         case "90": NOMBRE_INTERES="GASTOS EXTRAS PARA EQUIPO ELECTRONICO"; break;
         case "92": NOMBRE_INTERES="CARPA"; break;
         case "94": NOMBRE_INTERES="NUEVAS UBICACIONES"; break;
         case "104": NOMBRE_INTERES="EQUIPO PORTATIL PROMOCIONES"; break;
         case "105": NOMBRE_INTERES="HERRAMIENTAS DE TRABAJO"; break;
         case "95": NOMBRE_INTERES="Transformadores, Equipo de Aire Acondicionado"; break;
         case "96": NOMBRE_INTERES="Tanque de Combustible"; break;
         case "97": NOMBRE_INTERES="Maquinaria en Negociación"; break;
         case "98": NOMBRE_INTERES="Achicadora"; break;
         case "99": NOMBRE_INTERES="Equipo de Levante"; break;
         case "100": NOMBRE_INTERES="Puentes de Concreto"; break;
         case "101": NOMBRE_INTERES="Riego"; break;
         case "102": NOMBRE_INTERES="Mobiliario, Equipo y Contenido"; break;
         case "103": NOMBRE_INTERES="Herramienta y Equipo"; break;
         case "171": NOMBRE_INTERES="CAMARAS FRIGORIFICAS"; break;
         case "172": NOMBRE_INTERES="***cambiar a coberturas****DINERO Y/O VALORES"; break;
         case "173": NOMBRE_INTERES="CRISTALES Y/O ROTULOS"; break;
         case "174": NOMBRE_INTERES="FALLA ELECTRICA"; break;
         case "175": NOMBRE_INTERES="***cambiar a coberturas*** DINERO Y/O VALORES DENTRO DEL LOCAL"; break;
         case "176": NOMBRE_INTERES="***cambiar a coberturas****DINERO Y/O VALORES FUERA DEL LOCAL"; break;
         case "177": NOMBRE_INTERES="MOBILIARIO Y EQUIPO"; break;
         case "178": NOMBRE_INTERES="****cambiar a coberturas*** ROTURA DE MAQUINARIA"; break;
         case "179": NOMBRE_INTERES="ROTULOS LUMINOSOS"; break;
         case "180": NOMBRE_INTERES="INTERRUPCION DE NEGOCIOS POR RIESGOS COMERCIALES E INDUSTRIALES"; break;
         case "181": NOMBRE_INTERES="CAJAS FUERTES"; break;
         case "182": NOMBRE_INTERES="ROTURA DE MAQUINARIA Y GASTOS EXTRAS"; break;
         case "183": NOMBRE_INTERES="INTERRUPCION DE NEGOCIOS POR ROTURA DE MAQUINARIA"; break;
         case "184": NOMBRE_INTERES="MUEBLE E INMUEBLES RESIDENCIAS"; break;
         case "185": NOMBRE_INTERES="***cambiar a coberturas***  EXPLOSION DE CALDERAS"; break;
         case "186": NOMBRE_INTERES="DAÑO ELECTRICO E INTERRUPCION DE NEGOCIOS"; break;
         case "187": NOMBRE_INTERES="GASTOS DE APRESURAMIENTO"; break;
         case "188": NOMBRE_INTERES="GASTOS DE EXTINCION DE INCENDIO"; break;
         case "154": NOMBRE_INTERES=" MAQUINARIA"; break;
         case "155": NOMBRE_INTERES="CONTENIDO"; break;
         case "156": NOMBRE_INTERES="MEJORAS LOCATIVAS"; break;
         case "157": NOMBRE_INTERES="MERCADERIAS Y/O EXISTENCIAS"; break;
         case "158": NOMBRE_INTERES="EDIFICIO"; break;
         case "159": NOMBRE_INTERES="PROPIEDAD MUEBLE E INMUEBLE"; break;
         case "160": NOMBRE_INTERES="PROPIEDAD MUEBLE E INMUEBLE EXCLUYENDO EXISTENCIAS"; break;
         case "161": NOMBRE_INTERES="MENAJE DE HOGAR"; break;
         case "162": NOMBRE_INTERES="INTERRUPCION DE NEGOCIOS POR RIESGOS COMERCIALES"; break;
         case "163": NOMBRE_INTERES="INTERRUPCION  DE NEGOCIOS  RIESGOS INDUSTRIALES"; break;
         case "164": NOMBRE_INTERES="INTERRUPCION RENTAS DE ALQUILER"; break;
         case "165": NOMBRE_INTERES="INTERRUPCION DE NEGOCIOS RIESGOS COMERCIALES CONTINGENTES"; break;
         case "166": NOMBRE_INTERES="INTERRUPCION INTERDEPENDIENTE"; break;
         case "167": NOMBRE_INTERES="GASTOS EXTRAS"; break;
         case "168": NOMBRE_INTERES="***cambiar a coberturas***  RESPONSABILIDAD LEGAL DE INCENDIO"; break;
         case "169": NOMBRE_INTERES="CALDERAS"; break;
         case "170": NOMBRE_INTERES="ROTURA DE CRISTALES"; break;
         case "256": NOMBRE_INTERES="OTRAS ESTRUCTURAS"; break;
         case "257": NOMBRE_INTERES="MEJORAS AL EDIFICIO"; break;
         case "112": NOMBRE_INTERES="MUROS PERIMETRALES"; break;
         case "106": NOMBRE_INTERES="MAQUINARIA Y EQUIPO"; break;
         case "107": NOMBRE_INTERES="EQUIPO DE RIEGO"; break;
         case "108": NOMBRE_INTERES="BANDA TRANSPORTADORA"; break;
         case "109": NOMBRE_INTERES="REPUESTOS"; break;
         case "110": NOMBRE_INTERES="MEDICINA EXISTENCIAS"; break;
         case "111": NOMBRE_INTERES="EQUIPO EMPACADORA"; break;
         case "189": NOMBRE_INTERES="***cambiar a coberturas*** ROBO POR FORZAMIENTO"; break;
         case "190": NOMBRE_INTERES="***cambiar a coberturas***  ATRACO DE CONTENIDO"; break;
         case "191": NOMBRE_INTERES="INTERRUPCION POR VENTAS"; break;
         case "192": NOMBRE_INTERES="EQUIPO ELECTRONICO"; break;
         case "193": NOMBRE_INTERES="***  cambiar a coberturas***  HURTO Y/O DESAPARICION MISTERIOSA"; break;
         case "194": NOMBRE_INTERES="CUALQUIER PARTE DE LA REPUBLICA"; break;
         case "195": NOMBRE_INTERES="VEHICULOS DE TODO TIPO"; break;
         case "196": NOMBRE_INTERES="BIENES EN OTRAS UBICACIONES NO DESCRITAS EN LA POLIZA"; break;
         case "197": NOMBRE_INTERES="MONTAJE Y POSTMONTAJE DE MAQUINARIA"; break;
         case "198": NOMBRE_INTERES="NUEVAS CONSTRUCCIONES Y/O ADQUISICIONES"; break;
         case "199": NOMBRE_INTERES="HONORARIOS PROFESIONALES"; break;
         case "200": NOMBRE_INTERES="PROPIEDAD MUEBLE"; break;
         case "201": NOMBRE_INTERES="PUENTES"; break;
         case "202": NOMBRE_INTERES="CUENTAS POR COBRAR"; break;
         case "203": NOMBRE_INTERES="CLIENTES Y PROVEEDORES"; break;
         case "204": NOMBRE_INTERES="PROPIEDAD EN CURSO DE CONSTRUCCION"; break;
         case "205": NOMBRE_INTERES="GASTOS FIJOS SUELDOS Y SALARIOS"; break;
         case "206": NOMBRE_INTERES="INSTALACIONES"; break;
         case "207": NOMBRE_INTERES="ARMAS EN MOVIMIENTO Y/O ESTACIONARIAS"; break;
         case "208": NOMBRE_INTERES="PISCINAS"; break;
         case "209": NOMBRE_INTERES="***CAMBIAR ESTE INTERES A COBERTURAS***"; break;
         case "210": NOMBRE_INTERES="CAMBIAR A COBERTURA"; break;
         case "211": NOMBRE_INTERES="VALORES, DOCUMENTOS"; break;
         case "212": NOMBRE_INTERES="LINEAS DE TRASMISION Y DITRIBUCION"; break;
         case "213": NOMBRE_INTERES="OTROS BIENES"; break;
         case "214": NOMBRE_INTERES="PLANOS, REGISTROS Y LIBROS"; break;
         case "215": NOMBRE_INTERES="HIDROELECTRICA"; break;
         case "216": NOMBRE_INTERES="CAJEROS AUTOMATICOS"; break;
         case "217": NOMBRE_INTERES="OBRAS DE ARTE"; break;
         case "218": NOMBRE_INTERES="DISEÑOS Y PLANOS"; break;
         case "219": NOMBRE_INTERES="EQUIPO ELECTRONICO MÓVIL"; break;
         case "220": NOMBRE_INTERES="UBICACIONES NO NOMBRADAS"; break;
         case "221": NOMBRE_INTERES="PROPIEDAD DE EMPLEADOS"; break;
         case "222": NOMBRE_INTERES="TRASLADOS TEMPORALES"; break;
         case "223": NOMBRE_INTERES="EXISTENCIAS SECAS"; break;
         case "224": NOMBRE_INTERES="EXISTENCIAS EN CAMARAS FRIGORÍFICAS"; break;
         case "225": NOMBRE_INTERES="EQUIPO DE REFRIGERACION"; break;
         case "226": NOMBRE_INTERES="MEZANINE"; break;
         case "227": NOMBRE_INTERES="EQUIPO ELECTRONICO FIJO"; break;
         case "228": NOMBRE_INTERES="MAQUINARIA FIJA"; break;
         case "229": NOMBRE_INTERES="MAQUINARIA MÓVIL"; break;
         case "230": NOMBRE_INTERES="BIENES A LA INTEMPERIE"; break;
         case "231": NOMBRE_INTERES="NUEVOS BIENES MUEBLES"; break;
         case "232": NOMBRE_INTERES="CAJEROS AUTOMATICOS"; break;
         case "233": NOMBRE_INTERES="Nuevos Bienes Muebles e Inmuebles"; break;
         case "234": NOMBRE_INTERES="EXISTENCIAS DE CAFE"; break;
         case "235": NOMBRE_INTERES="PROPIEDAD INMUEBLE"; break;
         case "236": NOMBRE_INTERES="MATERIA PRIMA"; break;
         case "237": NOMBRE_INTERES="PRODUCTO EN PROCESO"; break;
         case "238": NOMBRE_INTERES="PRODUCTO TERMINADO"; break;
         case "239": NOMBRE_INTERES="IIMPUESTOS"; break;
         case "240": NOMBRE_INTERES="DAÑOS MATERIALES"; break;
         case "241": NOMBRE_INTERES="PORTADORES EXTERNOS DE DATOS"; break;
         case "242": NOMBRE_INTERES="GASTOS EXTRAS PARA EQUIPO ELECTRONICO"; break;
       }
      break;
      case 'EE':
      //console.log('ingrese a certificado');
      switch (String(json.data.interesesXPoliza[i].interesesXPolizaPK.codigoInteres)) {
        case "1": NOMBRE_INTERES="EQUIPO ELECTRONICO"; break;
    case "2": NOMBRE_INTERES="EQUIPO ELECTRONICO MOVIL"; break;
    case "3": NOMBRE_INTERES="RACK"; break;
    case "4": NOMBRE_INTERES="EQUIPO ELECTRONICO FIJO"; break;
    case "5": NOMBRE_INTERES="EQUIPO ELECTRONICO MOVIL"; break;
    }
      break;
      case "INCE" :
      //console.log('ingrese a INCEN');
      switch (String(json.data.interesesXPoliza[i].interesesXPolizaPK.codigoInteres)) {
        case "1": NOMBRE_INTERES="EDIFICIO"; break;
        case "18": NOMBRE_INTERES=" ROTURA DE CRISTALES"; break;
        case "11": NOMBRE_INTERES="INTERRUPCION DE NEGOCIOS POR RIESGOS     INDUSTRIALES"; break;
        case "12": NOMBRE_INTERES=" INTERRUPCION RENTAS DE ALQUILER"; break;
        case "10": NOMBRE_INTERES="INTERRUPCION DE NEGOCIOS POR RIESGOS     COMERCIALES"; break;
        case "9": NOMBRE_INTERES="MENAJE DE HOGAR"; break;
        case "8": NOMBRE_INTERES=" MUEBLE E INMUEBLES RESIDENCIAS"; break;
        case "107": NOMBRE_INTERES="EDIFICIO"; break;
        case "108": NOMBRE_INTERES=" ROTURA DE CRISTALES"; break;
        case "7": NOMBRE_INTERES="PROPIEDAD MUEBLE E INMUEBLE EXCLUYENDO      EXISTENCIAS"; break;
        case "6": NOMBRE_INTERES=" PROPIEDAD MUEBLE E INMUEBLE"; break;
        case "20": NOMBRE_INTERES="CAMARAS FRIGORIFICAS  (CUARTOS FRIOS)"; break;
        case "22": NOMBRE_INTERES="CONTAMINACION POR CONTACTO CON OTRAS CARGAS"; break;
        case "21": NOMBRE_INTERES="HURTO Y PILLAJE"; break;
        case "23": NOMBRE_INTERES="MOBILIARIO Y EQUIPO"; break;
        case "14": NOMBRE_INTERES="INTERRUPCION INTERDEPENDIENTE"; break;
        case "13": NOMBRE_INTERES="INTERRUPCION DE NEGOCIOS RIESGOS     COMERCIALES CONTINGENTES"; break;
        case "15": NOMBRE_INTERES="GASTOS EXTRAS"; break;
        case "16": NOMBRE_INTERES="RESPONSABILIDAD LEGAL DE INCENDIO"; break;
        case "17": NOMBRE_INTERES="CALDERAS"; break;
        case "3": NOMBRE_INTERES="MEJORAS LOCATIVAS"; break;
        case "2": NOMBRE_INTERES="CONTENIDO"; break;
        case "4": NOMBRE_INTERES="MERCADERÍAS Y/O EXISTENCIAS"; break;
        case "5": NOMBRE_INTERES="MAQUINARIA"; break;
        case "24": NOMBRE_INTERES="ROBO POR FORZAMIENTO"; break;
        case "26": NOMBRE_INTERES="INTERRUPCION DE NEGOCIOS PARA RIESGOS COMERCIALES E INDUSTRIALES"; break;
        case "27": NOMBRE_INTERES="***CAMBIAR A COBERTURAS****DINERO Y/O VALORES DENTRO DEL LOCAL"; break;
        case "28": NOMBRE_INTERES="***CAMBIAR A COBERTURAS****DINERO Y/O VALORES FUERA DEL LOCAL"; break;
        case "29": NOMBRE_INTERES="CAJAS FUERTES"; break;
        case "30": NOMBRE_INTERES="******CAMBIAR A COBERTURAS****CRISTALES Y/O ROTULOS"; break;
        case "31": NOMBRE_INTERES="MONTAJE Y POSTMONTAJE DE MAQUINARIA"; break;
        case "32": NOMBRE_INTERES="BIENES EN OTRAS UBICACIONES NO DESCRITAS EN LA POLIZA"; break;
        case "33": NOMBRE_INTERES="NUEVAS CONSTRUCCIONES Y/O ADQUISICIONES"; break;
        case "34": NOMBRE_INTERES="BORDAS Y COMPUERTAS"; break;
        case "19": NOMBRE_INTERES="*****CAMBIAR A COBERTURA****"; break;
        case "25": NOMBRE_INTERES="******CAMBIAR A COBERTURA******"; break;
        case "35": NOMBRE_INTERES="OTROS BIENES"; break;
        case "36": NOMBRE_INTERES="EQUIPO ELECTRÓNICO"; break;
        case "37": NOMBRE_INTERES="TRASLADOS TEMPORALES"; break;
        case "46": NOMBRE_INTERES="BIENES PROPIEDAD DE TERCEROS"; break;
        case "38": NOMBRE_INTERES="INTERRUPCION DE NEGOCIOS CONTINGENTE"; break;
        case "40": NOMBRE_INTERES="PROPIEDAD INMUEBLE"; break;
        case "41": NOMBRE_INTERES="PROPIEDAD MUEBLE"; break;
        case "42": NOMBRE_INTERES="EQUIPO ELECTRÓNICO FIJO"; break;
        case "43": NOMBRE_INTERES="EQUIPO ELECTRÓNICO MÓVIL"; break;
        case "44": NOMBRE_INTERES="MAQUINARIA FIJA"; break;
        case "45": NOMBRE_INTERES="MAQUINARIA MÓVIL"; break;
        case "39": NOMBRE_INTERES="PLANTULAS"; break;
        case "109": NOMBRE_INTERES="INTERRUPCION DE NEGOCIOS POR RIESGOS     INDUSTRIALES"; break;
        case "110": NOMBRE_INTERES=" INTERRUPCION RENTAS DE ALQUILER"; break;
        case "111": NOMBRE_INTERES="INTERRUPCION DE NEGOCIOS POR RIESGOS     COMERCIALES"; break;
        case "112": NOMBRE_INTERES="MENAJE DE HOGAR"; break;
        case "113": NOMBRE_INTERES=" MUEBLE E INMUEBLES RESIDENCIAS"; break;
        case "114": NOMBRE_INTERES="PROPIEDAD MUEBLE E INMUEBLE EXCLUYENDO      EXISTENCIAS"; break;
        case "115": NOMBRE_INTERES=" PROPIEDAD MUEBLE E INMUEBLE"; break;
        case "116": NOMBRE_INTERES="CAMARAS FRIGORIFICAS  (CUARTOS FRIOS)"; break;
        case "117": NOMBRE_INTERES="CONTAMINACION POR CONTACTO CON OTRAS CARGAS"; break;
        case "118": NOMBRE_INTERES="HURTO Y PILLAJE"; break;
        case "119": NOMBRE_INTERES="MOBILIARIO Y EQUIPO"; break;
        case "120": NOMBRE_INTERES="INTERRUPCION INTERDEPENDIENTE"; break;
        case "121": NOMBRE_INTERES="INTERRUPCION DE NEGOCIOS RIESGOS     COMERCIALES CONTINGENTES"; break;
        case "122": NOMBRE_INTERES="GASTOS EXTRAS"; break;
        case "123": NOMBRE_INTERES="RESPONSABILIDAD LEGAL DE INCENDIO"; break;
        case "124": NOMBRE_INTERES="CALDERAS"; break;
        case "125": NOMBRE_INTERES="MEJORAS LOCATIVAS"; break;
        case "126": NOMBRE_INTERES="CONTENIDO"; break;
        case "127": NOMBRE_INTERES="MERCADERÍAS Y/O EXISTENCIAS"; break;
        case "128": NOMBRE_INTERES="MAQUINARIA"; break;
        case "129": NOMBRE_INTERES="ROBO POR FORZAMIENTO"; break;
        case "130": NOMBRE_INTERES="INTERRUPCION DE NEGOCIOS PARA RIESGOS COMERCIALES E INDUSTRIALES"; break;
        case "131": NOMBRE_INTERES="***CAMBIAR A COBERTURAS****DINERO Y/O VALORES DENTRO DEL LOCAL"; break;
        case "132": NOMBRE_INTERES="***CAMBIAR A COBERTURAS****DINERO Y/O VALORES FUERA DEL LOCAL"; break;
        case "133": NOMBRE_INTERES="CAJAS FUERTES"; break;
        case "134": NOMBRE_INTERES="******CAMBIAR A COBERTURAS****CRISTALES Y/O ROTULOS"; break;
        case "135": NOMBRE_INTERES="MONTAJE Y POSTMONTAJE DE MAQUINARIA"; break;
        case "136": NOMBRE_INTERES="BIENES EN OTRAS UBICACIONES NO DESCRITAS EN LA POLIZA"; break;
        case "137": NOMBRE_INTERES="NUEVAS CONSTRUCCIONES Y/O ADQUISICIONES"; break;
        case "138": NOMBRE_INTERES="BORDAS Y COMPUERTAS"; break;
        case "139": NOMBRE_INTERES="*****CAMBIAR A COBERTURA****"; break;
        case "140": NOMBRE_INTERES="******CAMBIAR A COBERTURA******"; break;
        case "141": NOMBRE_INTERES="OTROS BIENES"; break;
        case "142": NOMBRE_INTERES="EQUIPO ELECTRÓNICO"; break;
        case "143": NOMBRE_INTERES="TRASLADOS TEMPORALES"; break;
        case "144": NOMBRE_INTERES="BIENES PROPIEDAD DE TERCEROS"; break;
        case "145": NOMBRE_INTERES="INTERRUPCION DE NEGOCIOS CONTINGENTE"; break;
        case "146": NOMBRE_INTERES="PROPIEDAD INMUEBLE"; break;
        case "147": NOMBRE_INTERES="PROPIEDAD MUEBLE"; break;
        case "148": NOMBRE_INTERES="EQUIPO ELECTRÓNICO FIJO"; break;
        case "149": NOMBRE_INTERES="EQUIPO ELECTRÓNICO MÓVIL"; break;
        case "150": NOMBRE_INTERES="MAQUINARIA FIJA"; break;
        case "151": NOMBRE_INTERES="MAQUINARIA MÓVIL"; break;
        case "152": NOMBRE_INTERES="PLANTULAS"; break;
        case "153": NOMBRE_INTERES="OTRAS ESTRUCTURAS"; break;
    }
      break;
    case "CAR" :
    //console.log('ingrese a TDINCEN');
    switch (String(json.data.interesesXPoliza[i].interesesXPolizaPK.codigoInteres)) {
      	case "2": NOMBRE_INTERES="RESPONSABILIDAD CIVIL EXTRACONTRACTUAL"; break;
      	case "3": NOMBRE_INTERES="REMOCION DE ESCOMBROS"; break;
      	case "4": NOMBRE_INTERES="RESPONSABILIDAD CIVIL CRUZADA"; break;
      	case "5": NOMBRE_INTERES="TERREMOTO"; break;
      	case "1": NOMBRE_INTERES="OBRAS EN CONSTRUCCIÓN"; break;
      	case "6": NOMBRE_INTERES="ROBO Y/O ATRACO"; break;
      	case "7": NOMBRE_INTERES="RESPONSABILIDAD CIVIL PATRONAL"; break;
      	case "8": NOMBRE_INTERES="MAQUINARIA Y EQUIPO"; break;
      	case "9": NOMBRE_INTERES="MAQUINARIA"; break;
      	case "10": NOMBRE_INTERES="EQUIPO"; break;
    }  break;
    case 'RM':
    //console.log('ingrese a RC');
       switch (String(json.data.interesesXPoliza[i].interesesXPolizaPK.codigoInteres)) {
         case "1": NOMBRE_INTERES="MAQUINARIA Y EQUIPO"; break;
    case "2": NOMBRE_INTERES="INTERRUPCION DE NEGOCIOS POR RIESGOS COMERCIALES"; break;
    case "3": NOMBRE_INTERES="INTERRUPCION DE NEGOCIOS POR RIESGOS INDUSTRIALES"; break;
    case "4": NOMBRE_INTERES="RESPONSABILIDAD CIVIL  ****TRASLADAR A COBERTURAS****"; break;
    case "5": NOMBRE_INTERES="CALDERAS"; break;
    case "6": NOMBRE_INTERES="GASTOS  EXTRAS"; break;
       }
       break;
       case 'RC':
       //console.log('ingrese a TTR');
          switch (String(json.data.interesesXPoliza[i].interesesXPolizaPK.codigoInteres)) {
            case "1": NOMBRE_INTERES="EDIFICIOS"; break;
            case "2": NOMBRE_INTERES="BIENES"; break;
          }
    break;
    }
    if ($('#monedaPol').val()=='Q'){
              datosini.push({CODIGO:json.data.interesesXPoliza[i].interesesXPolizaPK.codigoInteres,NOMBRE:NOMBRE_INTERES, SUMA:aMoneda(json.data.interesesXPoliza[i].sumaAsegurada, opcionesQuetzales)});
      }else{
        datosini.push({CODIGO:json.data.interesesXPoliza[i].interesesXPolizaPK.codigoInteres,NOMBRE:NOMBRE_INTERES, SUMA:aMoneda(json.data.interesesXPoliza[i].sumaAsegurada, opcionesDolares)});

      }
}
                                }

              mivaribleDep = datosini;

                  //console.log(datosini);
            //console.log(mivaribleDep);

            setTimeout(
             function()
             {
             }, 9000);
             setMiVariableCoberturas(mivaribleDep);


         } else {
          // swal("Error", "No devolvio 200", "warning");
          //console.log("No devolvio 200");
         }

       }).catch(function(error) {


       })
  }
  else {




  axios.get(servicio,  {
    headers: {
      'Authorization': `Bearer ${sessionStorage.getItem('jwtToken')}`,
    },
  }  ).then(json => {
       if (json.status === 200) {
         //console.log(json.data.interesesXPolizaG.length);
         if (json.data.interesesXPolizaG.length== undefined){
           switch (json.data.interesesXPolizaG.interesesXPolizaGPK.codigoRamo) {
  case 'INT':
  //console.log('ingrese a equipo');
     switch (String(json.data.interesesXPolizaG.interesesXPolizaGPK.codigoInteres)) {
       case "5": NOMBRE_INTERES=" MAQUINARIA"; break;
       case "2": NOMBRE_INTERES="CONTENIDO"; break;
       case "3": NOMBRE_INTERES="MEJORAS LOCATIVAS"; break;
       case "4": NOMBRE_INTERES="MERCADERIAS Y/O EXISTENCIAS"; break;
       case "1": NOMBRE_INTERES="EDIFICIO"; break;
       case "6": NOMBRE_INTERES="PROPIEDAD MUEBLE E INMUEBLE"; break;
       case "7": NOMBRE_INTERES="PROPIEDAD MUEBLE E INMUEBLE EXCLUYENDO EXISTENCIAS"; break;
       case "9": NOMBRE_INTERES="MENAJE DE HOGAR"; break;
       case "10": NOMBRE_INTERES="INTERRUPCION DE NEGOCIOS POR RIESGOS COMERCIALES"; break;
       case "11": NOMBRE_INTERES="INTERRUPCION  DE NEGOCIOS  RIESGOS INDUSTRIALES"; break;
       case "12": NOMBRE_INTERES="INTERRUPCION RENTAS DE ALQUILER"; break;
       case "13": NOMBRE_INTERES="INTERRUPCION DE NEGOCIOS RIESGOS COMERCIALES CONTINGENTES"; break;
       case "14": NOMBRE_INTERES="INTERRUPCION INTERDEPENDIENTE"; break;
       case "15": NOMBRE_INTERES="GASTOS EXTRAS"; break;
       case "16": NOMBRE_INTERES="***cambiar a coberturas***  RESPONSABILIDAD LEGAL DE INCENDIO"; break;
       case "17": NOMBRE_INTERES="CALDERAS"; break;
       case "18": NOMBRE_INTERES="ROTURA DE CRISTALES"; break;
       case "19": NOMBRE_INTERES="CAMARAS FRIGORIFICAS"; break;
       case "21": NOMBRE_INTERES="***cambiar a coberturas****DINERO Y/O VALORES"; break;
       case "22": NOMBRE_INTERES="CRISTALES Y/O ROTULOS"; break;
       case "23": NOMBRE_INTERES="FALLA ELECTRICA"; break;
       case "24": NOMBRE_INTERES="***cambiar a coberturas*** DINERO Y/O VALORES DENTRO DEL LOCAL"; break;
       case "25": NOMBRE_INTERES="***cambiar a coberturas****DINERO Y/O VALORES FUERA DEL LOCAL"; break;
       case "26": NOMBRE_INTERES="MOBILIARIO Y EQUIPO"; break;
       case "27": NOMBRE_INTERES="****cambiar a coberturas*** ROTURA DE MAQUINARIA"; break;
       case "28": NOMBRE_INTERES="ROTULOS LUMINOSOS"; break;
       case "29": NOMBRE_INTERES="INTERRUPCION DE NEGOCIOS POR RIESGOS COMERCIALES E INDUSTRIALES"; break;
       case "33": NOMBRE_INTERES="CAJAS FUERTES"; break;
       case "31": NOMBRE_INTERES="ROTURA DE MAQUINARIA Y GASTOS EXTRAS"; break;
       case "32": NOMBRE_INTERES="INTERRUPCION DE NEGOCIOS POR ROTURA DE MAQUINARIA"; break;
       case "8": NOMBRE_INTERES="MUEBLE E INMUEBLES RESIDENCIAS"; break;
       case "34": NOMBRE_INTERES="***cambiar a coberturas***  EXPLOSION DE CALDERAS"; break;
       case "35": NOMBRE_INTERES="DAÑO ELECTRICO E INTERRUPCION DE NEGOCIOS"; break;
       case "38": NOMBRE_INTERES="GASTOS DE APRESURAMIENTO"; break;
       case "39": NOMBRE_INTERES="GASTOS DE EXTINCION DE INCENDIO"; break;
       case "40": NOMBRE_INTERES="***cambiar a coberturas*** ROBO POR FORZAMIENTO"; break;
       case "41": NOMBRE_INTERES="***cambiar a coberturas***  ATRACO DE CONTENIDO"; break;
       case "42": NOMBRE_INTERES="INTERRUPCION POR VENTAS"; break;
       case "43": NOMBRE_INTERES="EQUIPO ELECTRONICO"; break;
       case "44": NOMBRE_INTERES="***  cambiar a coberturas***  HURTO Y/O DESAPARICION MISTERIOSA"; break;
       case "45": NOMBRE_INTERES="CUALQUIER PARTE DE LA REPUBLICA"; break;
       case "46": NOMBRE_INTERES="VEHICULOS DE TODO TIPO"; break;
       case "47": NOMBRE_INTERES="BIENES EN OTRAS UBICACIONES NO DESCRITAS EN LA POLIZA"; break;
       case "48": NOMBRE_INTERES="MONTAJE Y POSTMONTAJE DE MAQUINARIA"; break;
       case "49": NOMBRE_INTERES="NUEVAS CONSTRUCCIONES Y/O ADQUISICIONES"; break;
       case "50": NOMBRE_INTERES="HONORARIOS PROFESIONALES"; break;
       case "51": NOMBRE_INTERES="PROPIEDAD MUEBLE"; break;
       case "52": NOMBRE_INTERES="PUENTES"; break;
       case "53": NOMBRE_INTERES="CUENTAS POR COBRAR"; break;
       case "54": NOMBRE_INTERES="CLIENTES Y PROVEEDORES"; break;
       case "55": NOMBRE_INTERES="PROPIEDAD EN CURSO DE CONSTRUCCION"; break;
       case "56": NOMBRE_INTERES="GASTOS FIJOS SUELDOS Y SALARIOS"; break;
       case "57": NOMBRE_INTERES="INSTALACIONES"; break;
       case "58": NOMBRE_INTERES="ARMAS EN MOVIMIENTO Y/O ESTACIONARIAS"; break;
       case "59": NOMBRE_INTERES="PISCINAS"; break;
       case "20": NOMBRE_INTERES="***CAMBIAR ESTE INTERES A COBERTURAS***"; break;
       case "37": NOMBRE_INTERES="CAMBIAR A COBERTURA"; break;
       case "60": NOMBRE_INTERES="VALORES, DOCUMENTOS"; break;
       case "61": NOMBRE_INTERES="LINEAS DE TRASMISION Y DITRIBUCION"; break;
       case "62": NOMBRE_INTERES="OTROS BIENES"; break;
       case "63": NOMBRE_INTERES="PLANOS, REGISTROS Y LIBROS"; break;
       case "91": NOMBRE_INTERES="HIDROELECTRICA"; break;
       case "65": NOMBRE_INTERES="CAJEROS AUTOMATICOS"; break;
       case "66": NOMBRE_INTERES="OBRAS DE ARTE"; break;
       case "67": NOMBRE_INTERES="DISEÑOS Y PLANOS"; break;
       case "68": NOMBRE_INTERES="EQUIPO ELECTRONICO MÓVIL"; break;
       case "69": NOMBRE_INTERES="UBICACIONES NO NOMBRADAS"; break;
       case "70": NOMBRE_INTERES="PROPIEDAD DE EMPLEADOS"; break;
       case "71": NOMBRE_INTERES="TRASLADOS TEMPORALES"; break;
       case "73": NOMBRE_INTERES="EXISTENCIAS SECAS"; break;
       case "74": NOMBRE_INTERES="EXISTENCIAS EN CAMARAS FRIGORÍFICAS"; break;
       case "75": NOMBRE_INTERES="EQUIPO DE REFRIGERACION"; break;
       case "72": NOMBRE_INTERES="MEZANINE"; break;
       case "81": NOMBRE_INTERES="EQUIPO ELECTRONICO FIJO"; break;
       case "82": NOMBRE_INTERES="MAQUINARIA FIJA"; break;
       case "83": NOMBRE_INTERES="MAQUINARIA MÓVIL"; break;
       case "84": NOMBRE_INTERES="BIENES A LA INTEMPERIE"; break;
       case "85": NOMBRE_INTERES="NUEVOS BIENES MUEBLES"; break;
       case "93": NOMBRE_INTERES="CAJEROS AUTOMATICOS"; break;
       case "87": NOMBRE_INTERES="Nuevos Bienes Muebles e Inmuebles"; break;
       case "76": NOMBRE_INTERES="EXISTENCIAS DE CAFE"; break;
       case "77": NOMBRE_INTERES="PROPIEDAD INMUEBLE"; break;
       case "78": NOMBRE_INTERES="MATERIA PRIMA"; break;
       case "79": NOMBRE_INTERES="PRODUCTO EN PROCESO"; break;
       case "80": NOMBRE_INTERES="PRODUCTO TERMINADO"; break;
       case "86": NOMBRE_INTERES="IIMPUESTOS"; break;
       case "88": NOMBRE_INTERES="DAÑOS MATERIALES"; break;
       case "89": NOMBRE_INTERES="PORTADORES EXTERNOS DE DATOS"; break;
       case "90": NOMBRE_INTERES="GASTOS EXTRAS PARA EQUIPO ELECTRONICO"; break;
       case "92": NOMBRE_INTERES="CARPA"; break;
       case "94": NOMBRE_INTERES="NUEVAS UBICACIONES"; break;
       case "104": NOMBRE_INTERES="EQUIPO PORTATIL PROMOCIONES"; break;
       case "105": NOMBRE_INTERES="HERRAMIENTAS DE TRABAJO"; break;
       case "95": NOMBRE_INTERES="Transformadores, Equipo de Aire Acondicionado"; break;
       case "96": NOMBRE_INTERES="Tanque de Combustible"; break;
       case "97": NOMBRE_INTERES="Maquinaria en Negociación"; break;
       case "98": NOMBRE_INTERES="Achicadora"; break;
       case "99": NOMBRE_INTERES="Equipo de Levante"; break;
       case "100": NOMBRE_INTERES="Puentes de Concreto"; break;
       case "101": NOMBRE_INTERES="Riego"; break;
       case "102": NOMBRE_INTERES="Mobiliario, Equipo y Contenido"; break;
       case "103": NOMBRE_INTERES="Herramienta y Equipo"; break;
       case "171": NOMBRE_INTERES="CAMARAS FRIGORIFICAS"; break;
       case "172": NOMBRE_INTERES="***cambiar a coberturas****DINERO Y/O VALORES"; break;
       case "173": NOMBRE_INTERES="CRISTALES Y/O ROTULOS"; break;
       case "174": NOMBRE_INTERES="FALLA ELECTRICA"; break;
       case "175": NOMBRE_INTERES="***cambiar a coberturas*** DINERO Y/O VALORES DENTRO DEL LOCAL"; break;
       case "176": NOMBRE_INTERES="***cambiar a coberturas****DINERO Y/O VALORES FUERA DEL LOCAL"; break;
       case "177": NOMBRE_INTERES="MOBILIARIO Y EQUIPO"; break;
       case "178": NOMBRE_INTERES="****cambiar a coberturas*** ROTURA DE MAQUINARIA"; break;
       case "179": NOMBRE_INTERES="ROTULOS LUMINOSOS"; break;
       case "180": NOMBRE_INTERES="INTERRUPCION DE NEGOCIOS POR RIESGOS COMERCIALES E INDUSTRIALES"; break;
       case "181": NOMBRE_INTERES="CAJAS FUERTES"; break;
       case "182": NOMBRE_INTERES="ROTURA DE MAQUINARIA Y GASTOS EXTRAS"; break;
       case "183": NOMBRE_INTERES="INTERRUPCION DE NEGOCIOS POR ROTURA DE MAQUINARIA"; break;
       case "184": NOMBRE_INTERES="MUEBLE E INMUEBLES RESIDENCIAS"; break;
       case "185": NOMBRE_INTERES="***cambiar a coberturas***  EXPLOSION DE CALDERAS"; break;
       case "186": NOMBRE_INTERES="DAÑO ELECTRICO E INTERRUPCION DE NEGOCIOS"; break;
       case "187": NOMBRE_INTERES="GASTOS DE APRESURAMIENTO"; break;
       case "188": NOMBRE_INTERES="GASTOS DE EXTINCION DE INCENDIO"; break;
       case "154": NOMBRE_INTERES=" MAQUINARIA"; break;
       case "155": NOMBRE_INTERES="CONTENIDO"; break;
       case "156": NOMBRE_INTERES="MEJORAS LOCATIVAS"; break;
       case "157": NOMBRE_INTERES="MERCADERIAS Y/O EXISTENCIAS"; break;
       case "158": NOMBRE_INTERES="EDIFICIO"; break;
       case "159": NOMBRE_INTERES="PROPIEDAD MUEBLE E INMUEBLE"; break;
       case "160": NOMBRE_INTERES="PROPIEDAD MUEBLE E INMUEBLE EXCLUYENDO EXISTENCIAS"; break;
       case "161": NOMBRE_INTERES="MENAJE DE HOGAR"; break;
       case "162": NOMBRE_INTERES="INTERRUPCION DE NEGOCIOS POR RIESGOS COMERCIALES"; break;
       case "163": NOMBRE_INTERES="INTERRUPCION  DE NEGOCIOS  RIESGOS INDUSTRIALES"; break;
       case "164": NOMBRE_INTERES="INTERRUPCION RENTAS DE ALQUILER"; break;
       case "165": NOMBRE_INTERES="INTERRUPCION DE NEGOCIOS RIESGOS COMERCIALES CONTINGENTES"; break;
       case "166": NOMBRE_INTERES="INTERRUPCION INTERDEPENDIENTE"; break;
       case "167": NOMBRE_INTERES="GASTOS EXTRAS"; break;
       case "168": NOMBRE_INTERES="***cambiar a coberturas***  RESPONSABILIDAD LEGAL DE INCENDIO"; break;
       case "169": NOMBRE_INTERES="CALDERAS"; break;
       case "170": NOMBRE_INTERES="ROTURA DE CRISTALES"; break;
       case "256": NOMBRE_INTERES="OTRAS ESTRUCTURAS"; break;
       case "257": NOMBRE_INTERES="MEJORAS AL EDIFICIO"; break;
       case "112": NOMBRE_INTERES="MUROS PERIMETRALES"; break;
       case "106": NOMBRE_INTERES="MAQUINARIA Y EQUIPO"; break;
       case "107": NOMBRE_INTERES="EQUIPO DE RIEGO"; break;
       case "108": NOMBRE_INTERES="BANDA TRANSPORTADORA"; break;
       case "109": NOMBRE_INTERES="REPUESTOS"; break;
       case "110": NOMBRE_INTERES="MEDICINA EXISTENCIAS"; break;
       case "111": NOMBRE_INTERES="EQUIPO EMPACADORA"; break;
       case "189": NOMBRE_INTERES="***cambiar a coberturas*** ROBO POR FORZAMIENTO"; break;
       case "190": NOMBRE_INTERES="***cambiar a coberturas***  ATRACO DE CONTENIDO"; break;
       case "191": NOMBRE_INTERES="INTERRUPCION POR VENTAS"; break;
       case "192": NOMBRE_INTERES="EQUIPO ELECTRONICO"; break;
       case "193": NOMBRE_INTERES="***  cambiar a coberturas***  HURTO Y/O DESAPARICION MISTERIOSA"; break;
       case "194": NOMBRE_INTERES="CUALQUIER PARTE DE LA REPUBLICA"; break;
       case "195": NOMBRE_INTERES="VEHICULOS DE TODO TIPO"; break;
       case "196": NOMBRE_INTERES="BIENES EN OTRAS UBICACIONES NO DESCRITAS EN LA POLIZA"; break;
       case "197": NOMBRE_INTERES="MONTAJE Y POSTMONTAJE DE MAQUINARIA"; break;
       case "198": NOMBRE_INTERES="NUEVAS CONSTRUCCIONES Y/O ADQUISICIONES"; break;
       case "199": NOMBRE_INTERES="HONORARIOS PROFESIONALES"; break;
       case "200": NOMBRE_INTERES="PROPIEDAD MUEBLE"; break;
       case "201": NOMBRE_INTERES="PUENTES"; break;
       case "202": NOMBRE_INTERES="CUENTAS POR COBRAR"; break;
       case "203": NOMBRE_INTERES="CLIENTES Y PROVEEDORES"; break;
       case "204": NOMBRE_INTERES="PROPIEDAD EN CURSO DE CONSTRUCCION"; break;
       case "205": NOMBRE_INTERES="GASTOS FIJOS SUELDOS Y SALARIOS"; break;
       case "206": NOMBRE_INTERES="INSTALACIONES"; break;
       case "207": NOMBRE_INTERES="ARMAS EN MOVIMIENTO Y/O ESTACIONARIAS"; break;
       case "208": NOMBRE_INTERES="PISCINAS"; break;
       case "209": NOMBRE_INTERES="***CAMBIAR ESTE INTERES A COBERTURAS***"; break;
       case "210": NOMBRE_INTERES="CAMBIAR A COBERTURA"; break;
       case "211": NOMBRE_INTERES="VALORES, DOCUMENTOS"; break;
       case "212": NOMBRE_INTERES="LINEAS DE TRASMISION Y DITRIBUCION"; break;
       case "213": NOMBRE_INTERES="OTROS BIENES"; break;
       case "214": NOMBRE_INTERES="PLANOS, REGISTROS Y LIBROS"; break;
       case "215": NOMBRE_INTERES="HIDROELECTRICA"; break;
       case "216": NOMBRE_INTERES="CAJEROS AUTOMATICOS"; break;
       case "217": NOMBRE_INTERES="OBRAS DE ARTE"; break;
       case "218": NOMBRE_INTERES="DISEÑOS Y PLANOS"; break;
       case "219": NOMBRE_INTERES="EQUIPO ELECTRONICO MÓVIL"; break;
       case "220": NOMBRE_INTERES="UBICACIONES NO NOMBRADAS"; break;
       case "221": NOMBRE_INTERES="PROPIEDAD DE EMPLEADOS"; break;
       case "222": NOMBRE_INTERES="TRASLADOS TEMPORALES"; break;
       case "223": NOMBRE_INTERES="EXISTENCIAS SECAS"; break;
       case "224": NOMBRE_INTERES="EXISTENCIAS EN CAMARAS FRIGORÍFICAS"; break;
       case "225": NOMBRE_INTERES="EQUIPO DE REFRIGERACION"; break;
       case "226": NOMBRE_INTERES="MEZANINE"; break;
       case "227": NOMBRE_INTERES="EQUIPO ELECTRONICO FIJO"; break;
       case "228": NOMBRE_INTERES="MAQUINARIA FIJA"; break;
       case "229": NOMBRE_INTERES="MAQUINARIA MÓVIL"; break;
       case "230": NOMBRE_INTERES="BIENES A LA INTEMPERIE"; break;
       case "231": NOMBRE_INTERES="NUEVOS BIENES MUEBLES"; break;
       case "232": NOMBRE_INTERES="CAJEROS AUTOMATICOS"; break;
       case "233": NOMBRE_INTERES="Nuevos Bienes Muebles e Inmuebles"; break;
       case "234": NOMBRE_INTERES="EXISTENCIAS DE CAFE"; break;
       case "235": NOMBRE_INTERES="PROPIEDAD INMUEBLE"; break;
       case "236": NOMBRE_INTERES="MATERIA PRIMA"; break;
       case "237": NOMBRE_INTERES="PRODUCTO EN PROCESO"; break;
       case "238": NOMBRE_INTERES="PRODUCTO TERMINADO"; break;
       case "239": NOMBRE_INTERES="IIMPUESTOS"; break;
       case "240": NOMBRE_INTERES="DAÑOS MATERIALES"; break;
       case "241": NOMBRE_INTERES="PORTADORES EXTERNOS DE DATOS"; break;
       case "242": NOMBRE_INTERES="GASTOS EXTRAS PARA EQUIPO ELECTRONICO"; break;
     }
    break;
  case 'EE':
  //console.log('ingrese a certificado');
  switch (json.data.interesesXPolizaG.interesesXPolizaGPK.codigoInteres) {
    case "1": NOMBRE_INTERES="EQUIPO ELECTRONICO"; break;
case "2": NOMBRE_INTERES="EQUIPO ELECTRONICO MOVIL"; break;
case "3": NOMBRE_INTERES="RACK"; break;
case "4": NOMBRE_INTERES="EQUIPO ELECTRONICO FIJO"; break;
case "5": NOMBRE_INTERES="EQUIPO ELECTRONICO MOVIL"; break;
}
  break;
  case "INCE" :
  //console.log('ingrese a INCEN');
  switch (json.data.interesesXPolizaG.interesesXPolizaGPK.codigoInteres) {
    case "1": NOMBRE_INTERES="EDIFICIO"; break;
    case "18": NOMBRE_INTERES=" ROTURA DE CRISTALES"; break;
    case "11": NOMBRE_INTERES="INTERRUPCION DE NEGOCIOS POR RIESGOS     INDUSTRIALES"; break;
    case "12": NOMBRE_INTERES=" INTERRUPCION RENTAS DE ALQUILER"; break;
    case "10": NOMBRE_INTERES="INTERRUPCION DE NEGOCIOS POR RIESGOS     COMERCIALES"; break;
    case "9": NOMBRE_INTERES="MENAJE DE HOGAR"; break;
    case "8": NOMBRE_INTERES=" MUEBLE E INMUEBLES RESIDENCIAS"; break;
    case "107": NOMBRE_INTERES="EDIFICIO"; break;
    case "108": NOMBRE_INTERES=" ROTURA DE CRISTALES"; break;
    case "7": NOMBRE_INTERES="PROPIEDAD MUEBLE E INMUEBLE EXCLUYENDO      EXISTENCIAS"; break;
    case "6": NOMBRE_INTERES=" PROPIEDAD MUEBLE E INMUEBLE"; break;
    case "20": NOMBRE_INTERES="CAMARAS FRIGORIFICAS  (CUARTOS FRIOS)"; break;
    case "22": NOMBRE_INTERES="CONTAMINACION POR CONTACTO CON OTRAS CARGAS"; break;
    case "21": NOMBRE_INTERES="HURTO Y PILLAJE"; break;
    case "23": NOMBRE_INTERES="MOBILIARIO Y EQUIPO"; break;
    case "14": NOMBRE_INTERES="INTERRUPCION INTERDEPENDIENTE"; break;
    case "13": NOMBRE_INTERES="INTERRUPCION DE NEGOCIOS RIESGOS     COMERCIALES CONTINGENTES"; break;
    case "15": NOMBRE_INTERES="GASTOS EXTRAS"; break;
    case "16": NOMBRE_INTERES="RESPONSABILIDAD LEGAL DE INCENDIO"; break;
    case "17": NOMBRE_INTERES="CALDERAS"; break;
    case "3": NOMBRE_INTERES="MEJORAS LOCATIVAS"; break;
    case "2": NOMBRE_INTERES="CONTENIDO"; break;
    case "4": NOMBRE_INTERES="MERCADERÍAS Y/O EXISTENCIAS"; break;
    case "5": NOMBRE_INTERES="MAQUINARIA"; break;
    case "24": NOMBRE_INTERES="ROBO POR FORZAMIENTO"; break;
    case "26": NOMBRE_INTERES="INTERRUPCION DE NEGOCIOS PARA RIESGOS COMERCIALES E INDUSTRIALES"; break;
    case "27": NOMBRE_INTERES="***CAMBIAR A COBERTURAS****DINERO Y/O VALORES DENTRO DEL LOCAL"; break;
    case "28": NOMBRE_INTERES="***CAMBIAR A COBERTURAS****DINERO Y/O VALORES FUERA DEL LOCAL"; break;
    case "29": NOMBRE_INTERES="CAJAS FUERTES"; break;
    case "30": NOMBRE_INTERES="******CAMBIAR A COBERTURAS****CRISTALES Y/O ROTULOS"; break;
    case "31": NOMBRE_INTERES="MONTAJE Y POSTMONTAJE DE MAQUINARIA"; break;
    case "32": NOMBRE_INTERES="BIENES EN OTRAS UBICACIONES NO DESCRITAS EN LA POLIZA"; break;
    case "33": NOMBRE_INTERES="NUEVAS CONSTRUCCIONES Y/O ADQUISICIONES"; break;
    case "34": NOMBRE_INTERES="BORDAS Y COMPUERTAS"; break;
    case "19": NOMBRE_INTERES="*****CAMBIAR A COBERTURA****"; break;
    case "25": NOMBRE_INTERES="******CAMBIAR A COBERTURA******"; break;
    case "35": NOMBRE_INTERES="OTROS BIENES"; break;
    case "36": NOMBRE_INTERES="EQUIPO ELECTRÓNICO"; break;
    case "37": NOMBRE_INTERES="TRASLADOS TEMPORALES"; break;
    case "46": NOMBRE_INTERES="BIENES PROPIEDAD DE TERCEROS"; break;
    case "38": NOMBRE_INTERES="INTERRUPCION DE NEGOCIOS CONTINGENTE"; break;
    case "40": NOMBRE_INTERES="PROPIEDAD INMUEBLE"; break;
    case "41": NOMBRE_INTERES="PROPIEDAD MUEBLE"; break;
    case "42": NOMBRE_INTERES="EQUIPO ELECTRÓNICO FIJO"; break;
    case "43": NOMBRE_INTERES="EQUIPO ELECTRÓNICO MÓVIL"; break;
    case "44": NOMBRE_INTERES="MAQUINARIA FIJA"; break;
    case "45": NOMBRE_INTERES="MAQUINARIA MÓVIL"; break;
    case "39": NOMBRE_INTERES="PLANTULAS"; break;
    case "109": NOMBRE_INTERES="INTERRUPCION DE NEGOCIOS POR RIESGOS     INDUSTRIALES"; break;
    case "110": NOMBRE_INTERES=" INTERRUPCION RENTAS DE ALQUILER"; break;
    case "111": NOMBRE_INTERES="INTERRUPCION DE NEGOCIOS POR RIESGOS     COMERCIALES"; break;
    case "112": NOMBRE_INTERES="MENAJE DE HOGAR"; break;
    case "113": NOMBRE_INTERES=" MUEBLE E INMUEBLES RESIDENCIAS"; break;
    case "114": NOMBRE_INTERES="PROPIEDAD MUEBLE E INMUEBLE EXCLUYENDO      EXISTENCIAS"; break;
    case "115": NOMBRE_INTERES=" PROPIEDAD MUEBLE E INMUEBLE"; break;
    case "116": NOMBRE_INTERES="CAMARAS FRIGORIFICAS  (CUARTOS FRIOS)"; break;
    case "117": NOMBRE_INTERES="CONTAMINACION POR CONTACTO CON OTRAS CARGAS"; break;
    case "118": NOMBRE_INTERES="HURTO Y PILLAJE"; break;
    case "119": NOMBRE_INTERES="MOBILIARIO Y EQUIPO"; break;
    case "120": NOMBRE_INTERES="INTERRUPCION INTERDEPENDIENTE"; break;
    case "121": NOMBRE_INTERES="INTERRUPCION DE NEGOCIOS RIESGOS     COMERCIALES CONTINGENTES"; break;
    case "122": NOMBRE_INTERES="GASTOS EXTRAS"; break;
    case "123": NOMBRE_INTERES="RESPONSABILIDAD LEGAL DE INCENDIO"; break;
    case "124": NOMBRE_INTERES="CALDERAS"; break;
    case "125": NOMBRE_INTERES="MEJORAS LOCATIVAS"; break;
    case "126": NOMBRE_INTERES="CONTENIDO"; break;
    case "127": NOMBRE_INTERES="MERCADERÍAS Y/O EXISTENCIAS"; break;
    case "128": NOMBRE_INTERES="MAQUINARIA"; break;
    case "129": NOMBRE_INTERES="ROBO POR FORZAMIENTO"; break;
    case "130": NOMBRE_INTERES="INTERRUPCION DE NEGOCIOS PARA RIESGOS COMERCIALES E INDUSTRIALES"; break;
    case "131": NOMBRE_INTERES="***CAMBIAR A COBERTURAS****DINERO Y/O VALORES DENTRO DEL LOCAL"; break;
    case "132": NOMBRE_INTERES="***CAMBIAR A COBERTURAS****DINERO Y/O VALORES FUERA DEL LOCAL"; break;
    case "133": NOMBRE_INTERES="CAJAS FUERTES"; break;
    case "134": NOMBRE_INTERES="******CAMBIAR A COBERTURAS****CRISTALES Y/O ROTULOS"; break;
    case "135": NOMBRE_INTERES="MONTAJE Y POSTMONTAJE DE MAQUINARIA"; break;
    case "136": NOMBRE_INTERES="BIENES EN OTRAS UBICACIONES NO DESCRITAS EN LA POLIZA"; break;
    case "137": NOMBRE_INTERES="NUEVAS CONSTRUCCIONES Y/O ADQUISICIONES"; break;
    case "138": NOMBRE_INTERES="BORDAS Y COMPUERTAS"; break;
    case "139": NOMBRE_INTERES="*****CAMBIAR A COBERTURA****"; break;
    case "140": NOMBRE_INTERES="******CAMBIAR A COBERTURA******"; break;
    case "141": NOMBRE_INTERES="OTROS BIENES"; break;
    case "142": NOMBRE_INTERES="EQUIPO ELECTRÓNICO"; break;
    case "143": NOMBRE_INTERES="TRASLADOS TEMPORALES"; break;
    case "144": NOMBRE_INTERES="BIENES PROPIEDAD DE TERCEROS"; break;
    case "145": NOMBRE_INTERES="INTERRUPCION DE NEGOCIOS CONTINGENTE"; break;
    case "146": NOMBRE_INTERES="PROPIEDAD INMUEBLE"; break;
    case "147": NOMBRE_INTERES="PROPIEDAD MUEBLE"; break;
    case "148": NOMBRE_INTERES="EQUIPO ELECTRÓNICO FIJO"; break;
    case "149": NOMBRE_INTERES="EQUIPO ELECTRÓNICO MÓVIL"; break;
    case "150": NOMBRE_INTERES="MAQUINARIA FIJA"; break;
    case "151": NOMBRE_INTERES="MAQUINARIA MÓVIL"; break;
    case "152": NOMBRE_INTERES="PLANTULAS"; break;
    case "153": NOMBRE_INTERES="OTRAS ESTRUCTURAS"; break;
}
  break;
case "CAR" :
//console.log('ingrese a TDINCEN');
switch (json.data.interesesXPolizaG.interesesXPolizaGPK.codigoInteres) {
  	case "2": NOMBRE_INTERES="RESPONSABILIDAD CIVIL EXTRACONTRACTUAL"; break;
  	case "3": NOMBRE_INTERES="REMOCION DE ESCOMBROS"; break;
  	case "4": NOMBRE_INTERES="RESPONSABILIDAD CIVIL CRUZADA"; break;
  	case "5": NOMBRE_INTERES="TERREMOTO"; break;
  	case "1": NOMBRE_INTERES="OBRAS EN CONSTRUCCIÓN"; break;
  	case "6": NOMBRE_INTERES="ROBO Y/O ATRACO"; break;
  	case "7": NOMBRE_INTERES="RESPONSABILIDAD CIVIL PATRONAL"; break;
  	case "8": NOMBRE_INTERES="MAQUINARIA Y EQUIPO"; break;
  	case "9": NOMBRE_INTERES="MAQUINARIA"; break;
  	case "10": NOMBRE_INTERES="EQUIPO"; break;
}  break;
case 'RM':
//console.log('ingrese a RC');
   switch (json.data.interesesXPolizaG.interesesXPolizaGPK.codigoInteres) {
     case "1": NOMBRE_INTERES="MAQUINARIA Y EQUIPO"; break;
case "2": NOMBRE_INTERES="INTERRUPCION DE NEGOCIOS POR RIESGOS COMERCIALES"; break;
case "3": NOMBRE_INTERES="INTERRUPCION DE NEGOCIOS POR RIESGOS INDUSTRIALES"; break;
case "4": NOMBRE_INTERES="RESPONSABILIDAD CIVIL  ****TRASLADAR A COBERTURAS****"; break;
case "5": NOMBRE_INTERES="CALDERAS"; break;
case "6": NOMBRE_INTERES="GASTOS  EXTRAS"; break;
   }
   break;
   case 'RC':
  // //console.log('ingrese a TTR');
      switch (json.data.interesesXPolizaG.interesesXPolizaGPK.codigoInteres) {
        case "1": NOMBRE_INTERES="EDIFICIOS"; break;
        case "2": NOMBRE_INTERES="BIENES"; break;
      }
break;
}
  datosini =[{CODIGO:json.data.interesesXPolizaG.interesesXPolizaGPK.codigoInteres,NOMBRE:NOMBRE_INTERES, SUMA:aMoneda(json.data.interesesXPolizaG.sumaAsegurada,opcionesDolares), LIMITE:0}];
////console.log(datosini);
}else{
          for (var i = 0; i < json.data.interesesXPolizaG.length; i++) {
////console.log(json.data.interesesXPolizaG[i].interesesXPolizaGPK.codigoRamo);
switch (json.data.interesesXPolizaG[i].interesesXPolizaGPK.codigoRamo) {
  case 'INT':
  //console.log('ingrese a equipo');
     switch (String(json.data.interesesXPolizaG[i].interesesXPolizaGPK.codigoInteres)) {
       case "5": NOMBRE_INTERES=" MAQUINARIA"; break;
       case "2": NOMBRE_INTERES="CONTENIDO"; break;
       case "3": NOMBRE_INTERES="MEJORAS LOCATIVAS"; break;
       case "4": NOMBRE_INTERES="MERCADERIAS Y/O EXISTENCIAS"; break;
       case "1": NOMBRE_INTERES="EDIFICIO"; break;
       case "6": NOMBRE_INTERES="PROPIEDAD MUEBLE E INMUEBLE"; break;
       case "7": NOMBRE_INTERES="PROPIEDAD MUEBLE E INMUEBLE EXCLUYENDO EXISTENCIAS"; break;
       case "9": NOMBRE_INTERES="MENAJE DE HOGAR"; break;
       case "10": NOMBRE_INTERES="INTERRUPCION DE NEGOCIOS POR RIESGOS COMERCIALES"; break;
       case "11": NOMBRE_INTERES="INTERRUPCION  DE NEGOCIOS  RIESGOS INDUSTRIALES"; break;
       case "12": NOMBRE_INTERES="INTERRUPCION RENTAS DE ALQUILER"; break;
       case "13": NOMBRE_INTERES="INTERRUPCION DE NEGOCIOS RIESGOS COMERCIALES CONTINGENTES"; break;
       case "14": NOMBRE_INTERES="INTERRUPCION INTERDEPENDIENTE"; break;
       case "15": NOMBRE_INTERES="GASTOS EXTRAS"; break;
       case "16": NOMBRE_INTERES="***cambiar a coberturas***  RESPONSABILIDAD LEGAL DE INCENDIO"; break;
       case "17": NOMBRE_INTERES="CALDERAS"; break;
       case "18": NOMBRE_INTERES="ROTURA DE CRISTALES"; break;
       case "19": NOMBRE_INTERES="CAMARAS FRIGORIFICAS"; break;
       case "21": NOMBRE_INTERES="***cambiar a coberturas****DINERO Y/O VALORES"; break;
       case "22": NOMBRE_INTERES="CRISTALES Y/O ROTULOS"; break;
       case "23": NOMBRE_INTERES="FALLA ELECTRICA"; break;
       case "24": NOMBRE_INTERES="***cambiar a coberturas*** DINERO Y/O VALORES DENTRO DEL LOCAL"; break;
       case "25": NOMBRE_INTERES="***cambiar a coberturas****DINERO Y/O VALORES FUERA DEL LOCAL"; break;
       case "26": NOMBRE_INTERES="MOBILIARIO Y EQUIPO"; break;
       case "27": NOMBRE_INTERES="****cambiar a coberturas*** ROTURA DE MAQUINARIA"; break;
       case "28": NOMBRE_INTERES="ROTULOS LUMINOSOS"; break;
       case "29": NOMBRE_INTERES="INTERRUPCION DE NEGOCIOS POR RIESGOS COMERCIALES E INDUSTRIALES"; break;
       case "33": NOMBRE_INTERES="CAJAS FUERTES"; break;
       case "31": NOMBRE_INTERES="ROTURA DE MAQUINARIA Y GASTOS EXTRAS"; break;
       case "32": NOMBRE_INTERES="INTERRUPCION DE NEGOCIOS POR ROTURA DE MAQUINARIA"; break;
       case "8": NOMBRE_INTERES="MUEBLE E INMUEBLES RESIDENCIAS"; break;
       case "34": NOMBRE_INTERES="***cambiar a coberturas***  EXPLOSION DE CALDERAS"; break;
       case "35": NOMBRE_INTERES="DAÑO ELECTRICO E INTERRUPCION DE NEGOCIOS"; break;
       case "38": NOMBRE_INTERES="GASTOS DE APRESURAMIENTO"; break;
       case "39": NOMBRE_INTERES="GASTOS DE EXTINCION DE INCENDIO"; break;
       case "40": NOMBRE_INTERES="***cambiar a coberturas*** ROBO POR FORZAMIENTO"; break;
       case "41": NOMBRE_INTERES="***cambiar a coberturas***  ATRACO DE CONTENIDO"; break;
       case "42": NOMBRE_INTERES="INTERRUPCION POR VENTAS"; break;
       case "43": NOMBRE_INTERES="EQUIPO ELECTRONICO"; break;
       case "44": NOMBRE_INTERES="***  cambiar a coberturas***  HURTO Y/O DESAPARICION MISTERIOSA"; break;
       case "45": NOMBRE_INTERES="CUALQUIER PARTE DE LA REPUBLICA"; break;
       case "46": NOMBRE_INTERES="VEHICULOS DE TODO TIPO"; break;
       case "47": NOMBRE_INTERES="BIENES EN OTRAS UBICACIONES NO DESCRITAS EN LA POLIZA"; break;
       case "48": NOMBRE_INTERES="MONTAJE Y POSTMONTAJE DE MAQUINARIA"; break;
       case "49": NOMBRE_INTERES="NUEVAS CONSTRUCCIONES Y/O ADQUISICIONES"; break;
       case "50": NOMBRE_INTERES="HONORARIOS PROFESIONALES"; break;
       case "51": NOMBRE_INTERES="PROPIEDAD MUEBLE"; break;
       case "52": NOMBRE_INTERES="PUENTES"; break;
       case "53": NOMBRE_INTERES="CUENTAS POR COBRAR"; break;
       case "54": NOMBRE_INTERES="CLIENTES Y PROVEEDORES"; break;
       case "55": NOMBRE_INTERES="PROPIEDAD EN CURSO DE CONSTRUCCION"; break;
       case "56": NOMBRE_INTERES="GASTOS FIJOS SUELDOS Y SALARIOS"; break;
       case "57": NOMBRE_INTERES="INSTALACIONES"; break;
       case "58": NOMBRE_INTERES="ARMAS EN MOVIMIENTO Y/O ESTACIONARIAS"; break;
       case "59": NOMBRE_INTERES="PISCINAS"; break;
       case "20": NOMBRE_INTERES="***CAMBIAR ESTE INTERES A COBERTURAS***"; break;
       case "37": NOMBRE_INTERES="CAMBIAR A COBERTURA"; break;
       case "60": NOMBRE_INTERES="VALORES, DOCUMENTOS"; break;
       case "61": NOMBRE_INTERES="LINEAS DE TRASMISION Y DITRIBUCION"; break;
       case "62": NOMBRE_INTERES="OTROS BIENES"; break;
       case "63": NOMBRE_INTERES="PLANOS, REGISTROS Y LIBROS"; break;
       case "91": NOMBRE_INTERES="HIDROELECTRICA"; break;
       case "65": NOMBRE_INTERES="CAJEROS AUTOMATICOS"; break;
       case "66": NOMBRE_INTERES="OBRAS DE ARTE"; break;
       case "67": NOMBRE_INTERES="DISEÑOS Y PLANOS"; break;
       case "68": NOMBRE_INTERES="EQUIPO ELECTRONICO MÓVIL"; break;
       case "69": NOMBRE_INTERES="UBICACIONES NO NOMBRADAS"; break;
       case "70": NOMBRE_INTERES="PROPIEDAD DE EMPLEADOS"; break;
       case "71": NOMBRE_INTERES="TRASLADOS TEMPORALES"; break;
       case "73": NOMBRE_INTERES="EXISTENCIAS SECAS"; break;
       case "74": NOMBRE_INTERES="EXISTENCIAS EN CAMARAS FRIGORÍFICAS"; break;
       case "75": NOMBRE_INTERES="EQUIPO DE REFRIGERACION"; break;
       case "72": NOMBRE_INTERES="MEZANINE"; break;
       case "81": NOMBRE_INTERES="EQUIPO ELECTRONICO FIJO"; break;
       case "82": NOMBRE_INTERES="MAQUINARIA FIJA"; break;
       case "83": NOMBRE_INTERES="MAQUINARIA MÓVIL"; break;
       case "84": NOMBRE_INTERES="BIENES A LA INTEMPERIE"; break;
       case "85": NOMBRE_INTERES="NUEVOS BIENES MUEBLES"; break;
       case "93": NOMBRE_INTERES="CAJEROS AUTOMATICOS"; break;
       case "87": NOMBRE_INTERES="Nuevos Bienes Muebles e Inmuebles"; break;
       case "76": NOMBRE_INTERES="EXISTENCIAS DE CAFE"; break;
       case "77": NOMBRE_INTERES="PROPIEDAD INMUEBLE"; break;
       case "78": NOMBRE_INTERES="MATERIA PRIMA"; break;
       case "79": NOMBRE_INTERES="PRODUCTO EN PROCESO"; break;
       case "80": NOMBRE_INTERES="PRODUCTO TERMINADO"; break;
       case "86": NOMBRE_INTERES="IIMPUESTOS"; break;
       case "88": NOMBRE_INTERES="DAÑOS MATERIALES"; break;
       case "89": NOMBRE_INTERES="PORTADORES EXTERNOS DE DATOS"; break;
       case "90": NOMBRE_INTERES="GASTOS EXTRAS PARA EQUIPO ELECTRONICO"; break;
       case "92": NOMBRE_INTERES="CARPA"; break;
       case "94": NOMBRE_INTERES="NUEVAS UBICACIONES"; break;
       case "104": NOMBRE_INTERES="EQUIPO PORTATIL PROMOCIONES"; break;
       case "105": NOMBRE_INTERES="HERRAMIENTAS DE TRABAJO"; break;
       case "95": NOMBRE_INTERES="Transformadores, Equipo de Aire Acondicionado"; break;
       case "96": NOMBRE_INTERES="Tanque de Combustible"; break;
       case "97": NOMBRE_INTERES="Maquinaria en Negociación"; break;
       case "98": NOMBRE_INTERES="Achicadora"; break;
       case "99": NOMBRE_INTERES="Equipo de Levante"; break;
       case "100": NOMBRE_INTERES="Puentes de Concreto"; break;
       case "101": NOMBRE_INTERES="Riego"; break;
       case "102": NOMBRE_INTERES="Mobiliario, Equipo y Contenido"; break;
       case "103": NOMBRE_INTERES="Herramienta y Equipo"; break;
       case "171": NOMBRE_INTERES="CAMARAS FRIGORIFICAS"; break;
       case "172": NOMBRE_INTERES="***cambiar a coberturas****DINERO Y/O VALORES"; break;
       case "173": NOMBRE_INTERES="CRISTALES Y/O ROTULOS"; break;
       case "174": NOMBRE_INTERES="FALLA ELECTRICA"; break;
       case "175": NOMBRE_INTERES="***cambiar a coberturas*** DINERO Y/O VALORES DENTRO DEL LOCAL"; break;
       case "176": NOMBRE_INTERES="***cambiar a coberturas****DINERO Y/O VALORES FUERA DEL LOCAL"; break;
       case "177": NOMBRE_INTERES="MOBILIARIO Y EQUIPO"; break;
       case "178": NOMBRE_INTERES="****cambiar a coberturas*** ROTURA DE MAQUINARIA"; break;
       case "179": NOMBRE_INTERES="ROTULOS LUMINOSOS"; break;
       case "180": NOMBRE_INTERES="INTERRUPCION DE NEGOCIOS POR RIESGOS COMERCIALES E INDUSTRIALES"; break;
       case "181": NOMBRE_INTERES="CAJAS FUERTES"; break;
       case "182": NOMBRE_INTERES="ROTURA DE MAQUINARIA Y GASTOS EXTRAS"; break;
       case "183": NOMBRE_INTERES="INTERRUPCION DE NEGOCIOS POR ROTURA DE MAQUINARIA"; break;
       case "184": NOMBRE_INTERES="MUEBLE E INMUEBLES RESIDENCIAS"; break;
       case "185": NOMBRE_INTERES="***cambiar a coberturas***  EXPLOSION DE CALDERAS"; break;
       case "186": NOMBRE_INTERES="DAÑO ELECTRICO E INTERRUPCION DE NEGOCIOS"; break;
       case "187": NOMBRE_INTERES="GASTOS DE APRESURAMIENTO"; break;
       case "188": NOMBRE_INTERES="GASTOS DE EXTINCION DE INCENDIO"; break;
       case "154": NOMBRE_INTERES=" MAQUINARIA"; break;
       case "155": NOMBRE_INTERES="CONTENIDO"; break;
       case "156": NOMBRE_INTERES="MEJORAS LOCATIVAS"; break;
       case "157": NOMBRE_INTERES="MERCADERIAS Y/O EXISTENCIAS"; break;
       case "158": NOMBRE_INTERES="EDIFICIO"; break;
       case "159": NOMBRE_INTERES="PROPIEDAD MUEBLE E INMUEBLE"; break;
       case "160": NOMBRE_INTERES="PROPIEDAD MUEBLE E INMUEBLE EXCLUYENDO EXISTENCIAS"; break;
       case "161": NOMBRE_INTERES="MENAJE DE HOGAR"; break;
       case "162": NOMBRE_INTERES="INTERRUPCION DE NEGOCIOS POR RIESGOS COMERCIALES"; break;
       case "163": NOMBRE_INTERES="INTERRUPCION  DE NEGOCIOS  RIESGOS INDUSTRIALES"; break;
       case "164": NOMBRE_INTERES="INTERRUPCION RENTAS DE ALQUILER"; break;
       case "165": NOMBRE_INTERES="INTERRUPCION DE NEGOCIOS RIESGOS COMERCIALES CONTINGENTES"; break;
       case "166": NOMBRE_INTERES="INTERRUPCION INTERDEPENDIENTE"; break;
       case "167": NOMBRE_INTERES="GASTOS EXTRAS"; break;
       case "168": NOMBRE_INTERES="***cambiar a coberturas***  RESPONSABILIDAD LEGAL DE INCENDIO"; break;
       case "169": NOMBRE_INTERES="CALDERAS"; break;
       case "170": NOMBRE_INTERES="ROTURA DE CRISTALES"; break;
       case "256": NOMBRE_INTERES="OTRAS ESTRUCTURAS"; break;
       case "257": NOMBRE_INTERES="MEJORAS AL EDIFICIO"; break;
       case "112": NOMBRE_INTERES="MUROS PERIMETRALES"; break;
       case "106": NOMBRE_INTERES="MAQUINARIA Y EQUIPO"; break;
       case "107": NOMBRE_INTERES="EQUIPO DE RIEGO"; break;
       case "108": NOMBRE_INTERES="BANDA TRANSPORTADORA"; break;
       case "109": NOMBRE_INTERES="REPUESTOS"; break;
       case "110": NOMBRE_INTERES="MEDICINA EXISTENCIAS"; break;
       case "111": NOMBRE_INTERES="EQUIPO EMPACADORA"; break;
       case "189": NOMBRE_INTERES="***cambiar a coberturas*** ROBO POR FORZAMIENTO"; break;
       case "190": NOMBRE_INTERES="***cambiar a coberturas***  ATRACO DE CONTENIDO"; break;
       case "191": NOMBRE_INTERES="INTERRUPCION POR VENTAS"; break;
       case "192": NOMBRE_INTERES="EQUIPO ELECTRONICO"; break;
       case "193": NOMBRE_INTERES="***  cambiar a coberturas***  HURTO Y/O DESAPARICION MISTERIOSA"; break;
       case "194": NOMBRE_INTERES="CUALQUIER PARTE DE LA REPUBLICA"; break;
       case "195": NOMBRE_INTERES="VEHICULOS DE TODO TIPO"; break;
       case "196": NOMBRE_INTERES="BIENES EN OTRAS UBICACIONES NO DESCRITAS EN LA POLIZA"; break;
       case "197": NOMBRE_INTERES="MONTAJE Y POSTMONTAJE DE MAQUINARIA"; break;
       case "198": NOMBRE_INTERES="NUEVAS CONSTRUCCIONES Y/O ADQUISICIONES"; break;
       case "199": NOMBRE_INTERES="HONORARIOS PROFESIONALES"; break;
       case "200": NOMBRE_INTERES="PROPIEDAD MUEBLE"; break;
       case "201": NOMBRE_INTERES="PUENTES"; break;
       case "202": NOMBRE_INTERES="CUENTAS POR COBRAR"; break;
       case "203": NOMBRE_INTERES="CLIENTES Y PROVEEDORES"; break;
       case "204": NOMBRE_INTERES="PROPIEDAD EN CURSO DE CONSTRUCCION"; break;
       case "205": NOMBRE_INTERES="GASTOS FIJOS SUELDOS Y SALARIOS"; break;
       case "206": NOMBRE_INTERES="INSTALACIONES"; break;
       case "207": NOMBRE_INTERES="ARMAS EN MOVIMIENTO Y/O ESTACIONARIAS"; break;
       case "208": NOMBRE_INTERES="PISCINAS"; break;
       case "209": NOMBRE_INTERES="***CAMBIAR ESTE INTERES A COBERTURAS***"; break;
       case "210": NOMBRE_INTERES="CAMBIAR A COBERTURA"; break;
       case "211": NOMBRE_INTERES="VALORES, DOCUMENTOS"; break;
       case "212": NOMBRE_INTERES="LINEAS DE TRASMISION Y DITRIBUCION"; break;
       case "213": NOMBRE_INTERES="OTROS BIENES"; break;
       case "214": NOMBRE_INTERES="PLANOS, REGISTROS Y LIBROS"; break;
       case "215": NOMBRE_INTERES="HIDROELECTRICA"; break;
       case "216": NOMBRE_INTERES="CAJEROS AUTOMATICOS"; break;
       case "217": NOMBRE_INTERES="OBRAS DE ARTE"; break;
       case "218": NOMBRE_INTERES="DISEÑOS Y PLANOS"; break;
       case "219": NOMBRE_INTERES="EQUIPO ELECTRONICO MÓVIL"; break;
       case "220": NOMBRE_INTERES="UBICACIONES NO NOMBRADAS"; break;
       case "221": NOMBRE_INTERES="PROPIEDAD DE EMPLEADOS"; break;
       case "222": NOMBRE_INTERES="TRASLADOS TEMPORALES"; break;
       case "223": NOMBRE_INTERES="EXISTENCIAS SECAS"; break;
       case "224": NOMBRE_INTERES="EXISTENCIAS EN CAMARAS FRIGORÍFICAS"; break;
       case "225": NOMBRE_INTERES="EQUIPO DE REFRIGERACION"; break;
       case "226": NOMBRE_INTERES="MEZANINE"; break;
       case "227": NOMBRE_INTERES="EQUIPO ELECTRONICO FIJO"; break;
       case "228": NOMBRE_INTERES="MAQUINARIA FIJA"; break;
       case "229": NOMBRE_INTERES="MAQUINARIA MÓVIL"; break;
       case "230": NOMBRE_INTERES="BIENES A LA INTEMPERIE"; break;
       case "231": NOMBRE_INTERES="NUEVOS BIENES MUEBLES"; break;
       case "232": NOMBRE_INTERES="CAJEROS AUTOMATICOS"; break;
       case "233": NOMBRE_INTERES="Nuevos Bienes Muebles e Inmuebles"; break;
       case "234": NOMBRE_INTERES="EXISTENCIAS DE CAFE"; break;
       case "235": NOMBRE_INTERES="PROPIEDAD INMUEBLE"; break;
       case "236": NOMBRE_INTERES="MATERIA PRIMA"; break;
       case "237": NOMBRE_INTERES="PRODUCTO EN PROCESO"; break;
       case "238": NOMBRE_INTERES="PRODUCTO TERMINADO"; break;
       case "239": NOMBRE_INTERES="IIMPUESTOS"; break;
       case "240": NOMBRE_INTERES="DAÑOS MATERIALES"; break;
       case "241": NOMBRE_INTERES="PORTADORES EXTERNOS DE DATOS"; break;
       case "242": NOMBRE_INTERES="GASTOS EXTRAS PARA EQUIPO ELECTRONICO"; break;
     }
    break;
    case 'EE':
    //console.log('ingrese a certificado');
    switch (String(json.data.interesesXPolizaG[i].interesesXPolizaGPK.codigoInteres)) {
      case "1": NOMBRE_INTERES="EQUIPO ELECTRONICO"; break;
  case "2": NOMBRE_INTERES="EQUIPO ELECTRONICO MOVIL"; break;
  case "3": NOMBRE_INTERES="RACK"; break;
  case "4": NOMBRE_INTERES="EQUIPO ELECTRONICO FIJO"; break;
  case "5": NOMBRE_INTERES="EQUIPO ELECTRONICO MOVIL"; break;
  }
    break;
    case "INCE" :
    //console.log('ingrese a INCEN');
    switch (String(json.data.interesesXPolizaG[i].interesesXPolizaGPK.codigoInteres)) {
      case "1": NOMBRE_INTERES="EDIFICIO"; break;
      case "18": NOMBRE_INTERES=" ROTURA DE CRISTALES"; break;
      case "11": NOMBRE_INTERES="INTERRUPCION DE NEGOCIOS POR RIESGOS     INDUSTRIALES"; break;
      case "12": NOMBRE_INTERES=" INTERRUPCION RENTAS DE ALQUILER"; break;
      case "10": NOMBRE_INTERES="INTERRUPCION DE NEGOCIOS POR RIESGOS     COMERCIALES"; break;
      case "9": NOMBRE_INTERES="MENAJE DE HOGAR"; break;
      case "8": NOMBRE_INTERES=" MUEBLE E INMUEBLES RESIDENCIAS"; break;
      case "107": NOMBRE_INTERES="EDIFICIO"; break;
      case "108": NOMBRE_INTERES=" ROTURA DE CRISTALES"; break;
      case "7": NOMBRE_INTERES="PROPIEDAD MUEBLE E INMUEBLE EXCLUYENDO      EXISTENCIAS"; break;
      case "6": NOMBRE_INTERES=" PROPIEDAD MUEBLE E INMUEBLE"; break;
      case "20": NOMBRE_INTERES="CAMARAS FRIGORIFICAS  (CUARTOS FRIOS)"; break;
      case "22": NOMBRE_INTERES="CONTAMINACION POR CONTACTO CON OTRAS CARGAS"; break;
      case "21": NOMBRE_INTERES="HURTO Y PILLAJE"; break;
      case "23": NOMBRE_INTERES="MOBILIARIO Y EQUIPO"; break;
      case "14": NOMBRE_INTERES="INTERRUPCION INTERDEPENDIENTE"; break;
      case "13": NOMBRE_INTERES="INTERRUPCION DE NEGOCIOS RIESGOS     COMERCIALES CONTINGENTES"; break;
      case "15": NOMBRE_INTERES="GASTOS EXTRAS"; break;
      case "16": NOMBRE_INTERES="RESPONSABILIDAD LEGAL DE INCENDIO"; break;
      case "17": NOMBRE_INTERES="CALDERAS"; break;
      case "3": NOMBRE_INTERES="MEJORAS LOCATIVAS"; break;
      case "2": NOMBRE_INTERES="CONTENIDO"; break;
      case "4": NOMBRE_INTERES="MERCADERÍAS Y/O EXISTENCIAS"; break;
      case "5": NOMBRE_INTERES="MAQUINARIA"; break;
      case "24": NOMBRE_INTERES="ROBO POR FORZAMIENTO"; break;
      case "26": NOMBRE_INTERES="INTERRUPCION DE NEGOCIOS PARA RIESGOS COMERCIALES E INDUSTRIALES"; break;
      case "27": NOMBRE_INTERES="***CAMBIAR A COBERTURAS****DINERO Y/O VALORES DENTRO DEL LOCAL"; break;
      case "28": NOMBRE_INTERES="***CAMBIAR A COBERTURAS****DINERO Y/O VALORES FUERA DEL LOCAL"; break;
      case "29": NOMBRE_INTERES="CAJAS FUERTES"; break;
      case "30": NOMBRE_INTERES="******CAMBIAR A COBERTURAS****CRISTALES Y/O ROTULOS"; break;
      case "31": NOMBRE_INTERES="MONTAJE Y POSTMONTAJE DE MAQUINARIA"; break;
      case "32": NOMBRE_INTERES="BIENES EN OTRAS UBICACIONES NO DESCRITAS EN LA POLIZA"; break;
      case "33": NOMBRE_INTERES="NUEVAS CONSTRUCCIONES Y/O ADQUISICIONES"; break;
      case "34": NOMBRE_INTERES="BORDAS Y COMPUERTAS"; break;
      case "19": NOMBRE_INTERES="*****CAMBIAR A COBERTURA****"; break;
      case "25": NOMBRE_INTERES="******CAMBIAR A COBERTURA******"; break;
      case "35": NOMBRE_INTERES="OTROS BIENES"; break;
      case "36": NOMBRE_INTERES="EQUIPO ELECTRÓNICO"; break;
      case "37": NOMBRE_INTERES="TRASLADOS TEMPORALES"; break;
      case "46": NOMBRE_INTERES="BIENES PROPIEDAD DE TERCEROS"; break;
      case "38": NOMBRE_INTERES="INTERRUPCION DE NEGOCIOS CONTINGENTE"; break;
      case "40": NOMBRE_INTERES="PROPIEDAD INMUEBLE"; break;
      case "41": NOMBRE_INTERES="PROPIEDAD MUEBLE"; break;
      case "42": NOMBRE_INTERES="EQUIPO ELECTRÓNICO FIJO"; break;
      case "43": NOMBRE_INTERES="EQUIPO ELECTRÓNICO MÓVIL"; break;
      case "44": NOMBRE_INTERES="MAQUINARIA FIJA"; break;
      case "45": NOMBRE_INTERES="MAQUINARIA MÓVIL"; break;
      case "39": NOMBRE_INTERES="PLANTULAS"; break;
      case "109": NOMBRE_INTERES="INTERRUPCION DE NEGOCIOS POR RIESGOS     INDUSTRIALES"; break;
      case "110": NOMBRE_INTERES=" INTERRUPCION RENTAS DE ALQUILER"; break;
      case "111": NOMBRE_INTERES="INTERRUPCION DE NEGOCIOS POR RIESGOS     COMERCIALES"; break;
      case "112": NOMBRE_INTERES="MENAJE DE HOGAR"; break;
      case "113": NOMBRE_INTERES=" MUEBLE E INMUEBLES RESIDENCIAS"; break;
      case "114": NOMBRE_INTERES="PROPIEDAD MUEBLE E INMUEBLE EXCLUYENDO      EXISTENCIAS"; break;
      case "115": NOMBRE_INTERES=" PROPIEDAD MUEBLE E INMUEBLE"; break;
      case "116": NOMBRE_INTERES="CAMARAS FRIGORIFICAS  (CUARTOS FRIOS)"; break;
      case "117": NOMBRE_INTERES="CONTAMINACION POR CONTACTO CON OTRAS CARGAS"; break;
      case "118": NOMBRE_INTERES="HURTO Y PILLAJE"; break;
      case "119": NOMBRE_INTERES="MOBILIARIO Y EQUIPO"; break;
      case "120": NOMBRE_INTERES="INTERRUPCION INTERDEPENDIENTE"; break;
      case "121": NOMBRE_INTERES="INTERRUPCION DE NEGOCIOS RIESGOS     COMERCIALES CONTINGENTES"; break;
      case "122": NOMBRE_INTERES="GASTOS EXTRAS"; break;
      case "123": NOMBRE_INTERES="RESPONSABILIDAD LEGAL DE INCENDIO"; break;
      case "124": NOMBRE_INTERES="CALDERAS"; break;
      case "125": NOMBRE_INTERES="MEJORAS LOCATIVAS"; break;
      case "126": NOMBRE_INTERES="CONTENIDO"; break;
      case "127": NOMBRE_INTERES="MERCADERÍAS Y/O EXISTENCIAS"; break;
      case "128": NOMBRE_INTERES="MAQUINARIA"; break;
      case "129": NOMBRE_INTERES="ROBO POR FORZAMIENTO"; break;
      case "130": NOMBRE_INTERES="INTERRUPCION DE NEGOCIOS PARA RIESGOS COMERCIALES E INDUSTRIALES"; break;
      case "131": NOMBRE_INTERES="***CAMBIAR A COBERTURAS****DINERO Y/O VALORES DENTRO DEL LOCAL"; break;
      case "132": NOMBRE_INTERES="***CAMBIAR A COBERTURAS****DINERO Y/O VALORES FUERA DEL LOCAL"; break;
      case "133": NOMBRE_INTERES="CAJAS FUERTES"; break;
      case "134": NOMBRE_INTERES="******CAMBIAR A COBERTURAS****CRISTALES Y/O ROTULOS"; break;
      case "135": NOMBRE_INTERES="MONTAJE Y POSTMONTAJE DE MAQUINARIA"; break;
      case "136": NOMBRE_INTERES="BIENES EN OTRAS UBICACIONES NO DESCRITAS EN LA POLIZA"; break;
      case "137": NOMBRE_INTERES="NUEVAS CONSTRUCCIONES Y/O ADQUISICIONES"; break;
      case "138": NOMBRE_INTERES="BORDAS Y COMPUERTAS"; break;
      case "139": NOMBRE_INTERES="*****CAMBIAR A COBERTURA****"; break;
      case "140": NOMBRE_INTERES="******CAMBIAR A COBERTURA******"; break;
      case "141": NOMBRE_INTERES="OTROS BIENES"; break;
      case "142": NOMBRE_INTERES="EQUIPO ELECTRÓNICO"; break;
      case "143": NOMBRE_INTERES="TRASLADOS TEMPORALES"; break;
      case "144": NOMBRE_INTERES="BIENES PROPIEDAD DE TERCEROS"; break;
      case "145": NOMBRE_INTERES="INTERRUPCION DE NEGOCIOS CONTINGENTE"; break;
      case "146": NOMBRE_INTERES="PROPIEDAD INMUEBLE"; break;
      case "147": NOMBRE_INTERES="PROPIEDAD MUEBLE"; break;
      case "148": NOMBRE_INTERES="EQUIPO ELECTRÓNICO FIJO"; break;
      case "149": NOMBRE_INTERES="EQUIPO ELECTRÓNICO MÓVIL"; break;
      case "150": NOMBRE_INTERES="MAQUINARIA FIJA"; break;
      case "151": NOMBRE_INTERES="MAQUINARIA MÓVIL"; break;
      case "152": NOMBRE_INTERES="PLANTULAS"; break;
      case "153": NOMBRE_INTERES="OTRAS ESTRUCTURAS"; break;
  }
    break;
  case "CAR" :
  //console.log('ingrese a TDINCEN');
  switch (String(json.data.interesesXPolizaG[i].interesesXPolizaGPK.codigoInteres)) {
    	case "2": NOMBRE_INTERES="RESPONSABILIDAD CIVIL EXTRACONTRACTUAL"; break;
    	case "3": NOMBRE_INTERES="REMOCION DE ESCOMBROS"; break;
    	case "4": NOMBRE_INTERES="RESPONSABILIDAD CIVIL CRUZADA"; break;
    	case "5": NOMBRE_INTERES="TERREMOTO"; break;
    	case "1": NOMBRE_INTERES="OBRAS EN CONSTRUCCIÓN"; break;
    	case "6": NOMBRE_INTERES="ROBO Y/O ATRACO"; break;
    	case "7": NOMBRE_INTERES="RESPONSABILIDAD CIVIL PATRONAL"; break;
    	case "8": NOMBRE_INTERES="MAQUINARIA Y EQUIPO"; break;
    	case "9": NOMBRE_INTERES="MAQUINARIA"; break;
    	case "10": NOMBRE_INTERES="EQUIPO"; break;
  }  break;
  case 'RM':
  //console.log('ingrese a RC');
     switch (String(json.data.interesesXPolizaG[i].interesesXPolizaGPK.codigoInteres)) {
       case "1": NOMBRE_INTERES="MAQUINARIA Y EQUIPO"; break;
  case "2": NOMBRE_INTERES="INTERRUPCION DE NEGOCIOS POR RIESGOS COMERCIALES"; break;
  case "3": NOMBRE_INTERES="INTERRUPCION DE NEGOCIOS POR RIESGOS INDUSTRIALES"; break;
  case "4": NOMBRE_INTERES="RESPONSABILIDAD CIVIL  ****TRASLADAR A COBERTURAS****"; break;
  case "5": NOMBRE_INTERES="CALDERAS"; break;
  case "6": NOMBRE_INTERES="GASTOS  EXTRAS"; break;
     }
     break;
     case 'RC':
     //console.log('ingrese a TTR');
        switch (String(json.data.interesesXPolizaG[i].interesesXPolizaGPK.codigoInteres)) {
          case "1": NOMBRE_INTERES="EDIFICIOS"; break;
          case "2": NOMBRE_INTERES="BIENES"; break;
        }
  break;
  }

  if ($('#monedaPol').val()=='Q'){
    datosini.push({CODIGO:json.data.interesesXPolizaG[i].interesesXPolizaGPK.codigoInteres,NOMBRE:NOMBRE_INTERES, SUMA:aMoneda(json.data.interesesXPolizaG[i].sumaAsegurada, opcionesQuetzales)});

  }else{
    datosini.push({CODIGO:json.data.interesesXPolizaG[i].interesesXPolizaGPK.codigoInteres,NOMBRE:NOMBRE_INTERES, SUMA:aMoneda(json.data.interesesXPolizaG[i].sumaAsegurada, opcionesDolares)});

  }
}
                              }

            mivaribleDep = datosini;

                //console.log(datosini);
          //console.log(mivaribleDep);

          setTimeout(
           function()
           {
           }, 9000);
           setMiVariableCoberturas(mivaribleDep);


       } else {
         //swal("Error", "No devolvio 200", "warning");
       }

     }).catch(function(error) {


     })
  }

}, []);

const columns =[
      {dataField: "CODIGO", text:"CODIGO"},
      {dataField: "NOMBRE", text:"NOMBRE"},
        {dataField: "SUMA", text:"SUMA ASEGURADA"}

];

  return (
    <BootStrapTable style={{maxWidth: '1600px', width: '95%'}}
      keyField="ID"
      data={miVariableCoberturas}
      columns = {columns}
      pagination={paginationFactory()}
      striped
      bordered = {true}
          wrapperClasses="table-responsive"
      />

  );
};
export default Pagination;
