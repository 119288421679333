import { Encriptar } from "../Util/Encripcion";
import axios from "../axios-config";

// Método genérico para realizar solicitudes HTTP
export async function sendHttpPostRequestLogin(url, data = null, encriptar) {
  try {    
        //'Authorization': authToken,
    const axiosConfig = {
      headers: {
        
        'Content-Type': 'application/json', // Si estás enviando datos en formato JSON
      },
    };
    if (encriptar == 1) {
      data = await Encriptar(JSON.stringify(data));
    }
    const response = await axios.post(url,data,axiosConfig);

    // Devuelve la respuesta
    return response;
  } catch (error) {
    throw error;
  }
}

export async function sendHttpGetRequest(method, url, data = null) {
  try {
    let datosEncriptados = Encriptar(data);
    const response = await axios({
      method: method,
      url: url,
      data: data,
    });

    // Devuelve la respuesta
    return response;
  } catch (error) {
    throw error;
  }
}
