import React, { useState, useEffect} from 'react';
import BootStrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import axios from './axios-config';
import swal from 'sweetalert';
import Moment from 'moment';
import $ from 'jquery';


const Pagination = () => {

  const [miVariableCoberturas, setMiVariableCoberturas] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [show, setShow]= useState(false);



  const aMoneda = (numero, opciones) => {
         // Valores por defecto
         opciones = opciones || {};
         opciones.simbolo = opciones.simbolo || "$";
         opciones.separadorDecimal = opciones.separadorDecimal || ".";
         opciones.separadorMiles = opciones.separadorMiles || ",";
         opciones.numeroDeDecimales = opciones.numeroDeDecimales >= 0 ? opciones.numeroDeDecimales : 2;
         opciones.posicionSimbolo = opciones.posicionSimbolo || "i";
         const CIFRAS_MILES = 3;

         // Redondear y convertir a cadena
         var num =parseFloat(numero);
         ////console.log(num);
         let numeroComoCadena = num.toFixed(opciones.numeroDeDecimales);

         // Comenzar desde la izquierda del separador o desde el final de la cadena si no se proporciona
         let posicionDelSeparador = numeroComoCadena.indexOf(opciones.separadorDecimal);
         if (posicionDelSeparador === -1) posicionDelSeparador = numeroComoCadena.length;
         let formateadoSinDecimales = "", indice = posicionDelSeparador;
         // Ir cortando desde la derecha de 3 en 3, y concatenar en una nueva cadena
         while (indice >= 0) {
             let limiteInferior = indice - CIFRAS_MILES;
             // Agregar separador si cortamos más de 3
             formateadoSinDecimales = (limiteInferior > 0 ? opciones.separadorMiles : "") + numeroComoCadena.substring(limiteInferior, indice) + formateadoSinDecimales;
             indice -= CIFRAS_MILES;
         }
         let formateadoSinSimbolo = `${formateadoSinDecimales}${numeroComoCadena.substr(posicionDelSeparador, opciones.numeroDeDecimales + 1)}`;
         return opciones.posicionSimbolo === "i" ? opciones.simbolo + formateadoSinSimbolo : formateadoSinSimbolo + opciones.simbolo;
     };
     // Probar
     const opcionesQuetzales = {
         numeroDeDecimales: 2,
         separadorDecimal: ".",
         separadorMiles: ",",
         simbolo: "Q ", // Con un espacio, ya que la función no agrega espacios
         posicionSimbolo: "i", // i = izquierda, d = derecha
     },
         opcionesDolares = {
             numeroDeDecimales: 2,
             separadorDecimal: ".",
             separadorMiles: ",",
             simbolo: " USD", // Con un espacio, ya que la función no agrega espacios
             posicionSimbolo: "d", // i = izquierda, d = derecha
         };


useEffect(async() => {
  let datos=[];
  let datosini=[];
  let mivaribleDep="";
  let nombre_cobertura="";
  let servicio ="";

  if ($('#valPrin').val()==1 || $('#valPrin').val()=="1")
  {
      //console.log('mostrar por direcciones');
      servicio = `${process.env.REACT_APP_API_URL}/it-api-clientes2/rs/operaciones/coberturaPoliza/`+$('#tipoPol').val()+'/'+$('#numeroPol').val()+'/'+$('#asegu').val()+'/'+$('#dato_cob').val();
    
      axios.get(servicio,{
        headers: {
          'Authorization': `Bearer ${sessionStorage.getItem('jwtToken')}`,
        },
      }).then(json => 
      {
        if (json.status === 200) 
        {
          if (json.data.coberturasXPoliza.length == undefined || json.data.coberturasXPoliza.length=='undefined')
          {

              switch (json.data.coberturasXPoliza[0].coberturasXPolizaPK.codigoTipoPoliza){
                case 'EE':                
                switch (String(json.data.coberturasXPoliza[0].coberturasXPolizaPK.codigoCobertura)) 
                {
                  case 9: nombre_cobertura="DAÑO MALICIOSO ESPECIAL  TERRORISMO "; break;
                  case 23: nombre_cobertura="HURACAN, CICLON, TIFON"; break;
                  case 17: nombre_cobertura="OTRAS COBERTURAS"; break;
                  case 14: nombre_cobertura="FALLA APROVISIONAMIENTO DE LA CORRIENTE ELECTRICA"; break;
                  case 18: nombre_cobertura="TRANSPORTE INCIDENTAL"; break;
                  case 19: nombre_cobertura="MOTIN, HUELGAS Y/O ALBOROTOS POPULARES, DAÑOS MALICIOSOS E INCENDIO CONSECUTIVO"; break;
                  case 20: nombre_cobertura="EXPLOSION, MOTIN, HUELGAS Y/O ALBOROTOS POPULARES, DAÑO MALICIOSO"; break;
                  case 24: nombre_cobertura="MOTIN, HUELGA Y/O CONMOCION CIVIL"; break;
                  case 16: nombre_cobertura="TODO RIESGO"; break;
                  case 15: nombre_cobertura="INTERRUPCION DE NEGOCIOS PARA RIESGOS COMERCIALES"; break;
                  case 22: nombre_cobertura="TERREMOTO"; break;
                  case 21: nombre_cobertura="COBERTURA DE EQUIPOS MOVILES Y PORTATILES FUERA DE LOS PREDIOS ASEGURADOS"; break;
                  case 7: nombre_cobertura="CORTO CIRCUITO, ALTERACIONES DE LA CORRIENTE ELECTRICA."; break;
                  case 8: nombre_cobertura="GASTOS DE REGRABACION O REPROCESAMIENTO DE DATOS"; break;
                  case 12: nombre_cobertura="INCREMENTO EN EL COSTO DE LA OPERACION"; break;
                  case 13: nombre_cobertura="ROBO Y/O ATRACO"; break;
                  case 1: nombre_cobertura="SECCION I : DAÑOS MATERIALES"; break;
                  case 2: nombre_cobertura="SECCION II :  PORTADORES EXTERNOS DE DATOS"; break;
                  case 3: nombre_cobertura="SECCION III :   GASTOS EXTRAS"; break;
                  case 4: nombre_cobertura="TERREMOTO, TEMBLOR Y/O ERUPCION VOLCANICA E INCENDIO CONSECUTIVO"; break;
                  case 5: nombre_cobertura="DAÑO MALICIOSO"; break;
                  case 6: nombre_cobertura="ROBO POR FORZAMIENTO DE LADRONES"; break;
                  case 25: nombre_cobertura="TRANSPORTE"; break;
                  case 58: nombre_cobertura="A.- INCENDIO CASUAL RAYO Y EXPLOSIÓN"; break;
                  case 59: nombre_cobertura="B.- ROBO"; break;
                  case 60: nombre_cobertura="C.- MOTIN, HUELGA, PARA LEGAL"; break;
                  case 61: nombre_cobertura="D.- HURACLÁN, CICLON, INUNDACIÓN, DESLIZAMIENTO"; break;
                  case 62: nombre_cobertura="F.-TEMBLOR TERREMOTO"; break;
                  case 63: nombre_cobertura="G. DANO MALICIOSO"; break;
                  case 64: nombre_cobertura="H.- DAÑOS POR COLISIÓN Y VUELCO"; break;
                  case 26: nombre_cobertura="A - Daño Directo Equipo Electronico"; break;
                  case 27: nombre_cobertura="B - Robo"; break;
                  case 28: nombre_cobertura="E - Equipo Móvil Y/O Portátil"; break;
                  case 29: nombre_cobertura="C - Eventos De Las Naturaleza"; break;
                  case 30: nombre_cobertura="D - Otros Riesgos"; break;
                  case 31: nombre_cobertura="S - Portadores Externos De Datos"; break;
                  case 32: nombre_cobertura="R - Daño Directo Equipo Electronico"; break;
                  case 33: nombre_cobertura="E - Equipo Móvil Y/O Portátil"; break;
                  case 34: nombre_cobertura="S - Portadores Externos De Datos"; break;
                  case 35: nombre_cobertura="A - Responsabilidad Civil Por Lesion O Muerte A Terceros Excluyendo Pasajeros"; break;
                  case 36: nombre_cobertura="C - Responsabilidad Civil Por Daños A Propiedades De Terceros"; break;
                  case 37: nombre_cobertura="E - Basica"; break;
                  case 38: nombre_cobertura="F - Robo Y Hurto"; break;
                  case 39: nombre_cobertura="G - Riesgos Adicionales"; break;
                  case 40: nombre_cobertura="H - Extraterritorialidad"; break;
                  case 41: nombre_cobertura="Incendio, impacto de rayo, implosión y explosión"; break;
                  case 42: nombre_cobertura="Humo, hollín, gases, líquidos y polvos corrosivos"; break;
                  case 43: nombre_cobertura="Inundación, acción del agua y humedad"; break;
                  case 44: nombre_cobertura="Cortocircuito"; break;
                  case 45: nombre_cobertura="Errores de construcción"; break;
                  case 46: nombre_cobertura="Errores de manejo"; break;
                  case 47: nombre_cobertura="Robo con violencia"; break;
                  case 48: nombre_cobertura="Granizo, helada, tempestad"; break;
                  case 49: nombre_cobertura="Dolo de terceros, impericia"; break;
                  case 50: nombre_cobertura="Deslizamiento de tierra, Hundimiento del terreno y caído de rocas"; break;
                  case 51: nombre_cobertura="Riesgo de terremoto"; break;
                  case 52: nombre_cobertura="Riesgo de tifón, ciclón y huracán"; break;
                  case 53: nombre_cobertura="Riesgo por daños causados por huelga, motín y conmoción civil"; break;
                  case 54: nombre_cobertura="Riesgo de hurto"; break;
                  case 55: nombre_cobertura="Cobertura de equipos móviles portátiles fuera de su local asegurado"; break;
                  case 56: nombre_cobertura="Incremento en el costo de operación"; break;
                  case 57: nombre_cobertura="Portadores externos de datos"; break;
                  case 65: nombre_cobertura="TODO RIESGO"; break;
                }
                break;
                case 'CERT':
                //console.log('ingrese a certificado');
                switch (String(json.data.coberturasXPoliza[0].coberturasXPolizaPK.codigoCobertura)) {
                case "23": nombre_cobertura="DAÑO MALICIOSO ESPECIAL (Terrorismo)"; break;
                case "24": nombre_cobertura="BARREDURA"; break;
                case "25": nombre_cobertura="MOTIN, HUELGA, CONMOCION CIVIL, DAÑOS MALICIOSOS Y ACTOS DE TERRORISMO"; break;
                case "16": nombre_cobertura="ATRACO"; break;
                case "26": nombre_cobertura="OTRAS COBERTURAS"; break;
                case "27": nombre_cobertura="MOTIN, HUELGA, CONMOCION CIVIL Y DAÑOS MALICIOSOS"; break;
                case "22": nombre_cobertura="AVERIA GRUESA"; break;
                case "17": nombre_cobertura="ROBO Y/O ATRACO"; break;
                case "18": nombre_cobertura="FALTA DE ENTREGA"; break;
                case "19": nombre_cobertura="CARGA Y DESCARGA"; break;
                case "34": nombre_cobertura="TODO RIESGO 1.1.63  (A)"; break;
                case "20": nombre_cobertura="TODO RIESGO"; break;
                case "21": nombre_cobertura="INTERRUPCION DE NEGOCIOS POR DAÑOS A MAQUINARIA"; break;
                case "31": nombre_cobertura="TODO RIESGO (INCISO C)"; break;
                case "28": nombre_cobertura="BODEGA A BODEGA"; break;
                case "32": nombre_cobertura="ESTADIA"; break;
                case "29": nombre_cobertura="RATERIA"; break;
                case "30": nombre_cobertura="HUELGAS, ALBOROTOS POPULARES Y CONMOCION CIVIL PARA EMBARQUES TERRESTRES"; break;
                case "13": nombre_cobertura="TODO RIESGO 1.1.82  (A)"; break;
                case "11": nombre_cobertura="MOTIN, HUELGAS Y ALBOROTOS POPULARES"; break;
                case "12": nombre_cobertura="GUERRA"; break;
                case "10": nombre_cobertura="FALTA DE PESO O DERRAME"; break;
                case "3": nombre_cobertura="ROBO DE BULTO POR ENTERO"; break;
                case "4": nombre_cobertura="ROBO PARCIAL"; break;
                case "14": nombre_cobertura="C.A.P.  1.1.82 (B)"; break;
                case "15": nombre_cobertura="L.A.P.  1.1.82 (C)"; break;
                case "1": nombre_cobertura="RIESGOS ORDINARIOS DE TRANSITO TRANSPORTE TERRESTRE Y/O AEREO"; break;
                case "2": nombre_cobertura="RIESGOS ORDINARIOS DE TRANSITO TRANSPORTE MARITIMO"; break;
                case "6": nombre_cobertura="OXIDACION"; break;
                case "5": nombre_cobertura="MOJADURA"; break;
                case "7": nombre_cobertura="CONTACTO CON OTRAS CARGAS"; break;
                case "8": nombre_cobertura="MANCHAS"; break;
                case "9": nombre_cobertura="ROTURAS"; break;
                case "33": nombre_cobertura="ALMACENAMIENTO"; break;
                case "48": nombre_cobertura="Cobertura de riesgos de cláusulas del instituto de londres, para cargamentos “a”"; break;
                case "49": nombre_cobertura="Cobertura de riesgos de cláusulas del instituto de londres, para cargamentos “b”"; break;
                case "50": nombre_cobertura="Huelga"; break;
                case "51": nombre_cobertura="Guerra"; break;
                case "39": nombre_cobertura="Vendaval, ciclón o tornado"; break;
                case "40": nombre_cobertura="Derrumbe de puentes o alcantarillas"; break;
                case "41": nombre_cobertura="Derrumbe de tierra, de piedras o de rocas"; break;
                case "42": nombre_cobertura="Inundación por el desbordamiento de ríos o quebradas"; break;
                case "43": nombre_cobertura="Saqueo"; break;
                case "44": nombre_cobertura="Robo o Asalto"; break;
                case "45": nombre_cobertura="Maniobras de Carga y Descarga; y Movimientos Bruscos"; break;
                case "46": nombre_cobertura="Refrigeración"; break;
                case "47": nombre_cobertura="Extraterritorialidad"; break;
                case "35": nombre_cobertura="Incendio, rayo, auto-ignición y explosión"; break;
                case "36": nombre_cobertura="Terremoto, temblor"; break;
                case "37": nombre_cobertura="Colisión del vehículo con otro vehículo u objeto"; break;
                case "38": nombre_cobertura="Vuelco del vehículo transportador de los bienes descritos"; break;
                case "53": nombre_cobertura="TODO RIESGO"; break;
                case "52": nombre_cobertura="COBERTURA BÁSICA H: Riesgos del Medio de Transporte"; break;
                case "54": nombre_cobertura="1.1.09 A"; break;
                case "55": nombre_cobertura="TRANSBORDO"; break;
                case "56": nombre_cobertura="RECUPERACION"; break;
                case "57": nombre_cobertura="DESTRUCCION"; break;
                case "58": nombre_cobertura="DAÑOS INTERNOS A CONTENEDORES"; break;
                }
                break;
                case "INCEN" :
                //console.log('ingrese a INCEN');
                switch (String(json.data.coberturasXPoliza[0].coberturasXPolizaPK.codigoCobertura)) {
                case "10": nombre_cobertura="REMOCION DE ESCOMBROS"; break;
                case "11": nombre_cobertura="GASTOS DE EXTINCIÓN DE INCENDIOS"; break;
                case "12": nombre_cobertura="VALORES,  DOCUMENTOS"; break;
                case "13": nombre_cobertura="HURACAN, TORMENTAS Y VIENTOS."; break;
                case "108": nombre_cobertura="ARRIENDO DE LOCALES DE LOCALES PROVISORIOS"; break;
                case "100": nombre_cobertura="I. TODO RIESGO CONDOMINIOS  PARA BIENES MUEBLES E INMUEBLES"; break;
                case "110": nombre_cobertura="TRASLADOS TEMPORALES"; break;
                case "111": nombre_cobertura="MONTAJE Y DESMONTAJE"; break;
                case "112": nombre_cobertura="TRANSITO INCIDENTAL"; break;
                case "113": nombre_cobertura="SERVICIO DE ASISTENCIA DOMICILARIA G&T"; break;
                case "28": nombre_cobertura="DINERO Y/O VALORES FUERA DEL LOCAL"; break;
                case "114": nombre_cobertura="EXPLOSION, MOTIN, HUELGAS Y/O ALBOROTOS POPULARES, DAÑO MALICIOSO"; break;
                case "109": nombre_cobertura="DAÑOS POR AGUA"; break;
                case "41": nombre_cobertura="RESPONSABILIDAD CIVIL LEGAL DE INCENDIO"; break;
                case "44": nombre_cobertura="R.C. DE PRODUCTOS O TRABAJOS TERMINADOS"; break;
                case "45": nombre_cobertura="INTERRUPCION DE NEGOCIOS PARA RIESGOS INDUSTRIALES"; break;
                case "546": nombre_cobertura="INCENDIO Y/O RAYO"; break;
                case "25": nombre_cobertura="ATRACO DE CONTENIDO"; break;
                case "26": nombre_cobertura="CRISTALES Y/O ROTULOS"; break;
                case "27": nombre_cobertura="DINERO Y/O VALORES DENTRO DEL LOCAL"; break;
                case "29": nombre_cobertura="IMPACTO DE NAVES ACUATICAS"; break;
                case "30": nombre_cobertura="OTRAS COBERTURAS"; break;
                case "91": nombre_cobertura="INTERRUPCION RENTAS DE ALQUILER"; break;
                case "48": nombre_cobertura="HONORARIOS PROFESIONALES"; break;
                case "14": nombre_cobertura="DAÑO MALICIOSO ESPECIAL  (TERRORISMO)"; break;
                case "15": nombre_cobertura="CORTO CIRCUITO"; break;
                case "32": nombre_cobertura="FALLA ELECTRICA"; break;
                case "34": nombre_cobertura="EXPLOSION DE CALDERAS"; break;
                case "35": nombre_cobertura="TRANSPORTE TERRESTRE"; break;
                case "36": nombre_cobertura="HURTO Y DESAPARICION MISTERIOSA"; break;
                case "37": nombre_cobertura="MONTAJE Y DESMONTAJE"; break;
                case "38": nombre_cobertura="ROTULOS LUMINOSOS"; break;
                case "39": nombre_cobertura="RESPONSABILIDAD CIVIL FAMILIAR"; break;
                case "17": nombre_cobertura="FRAUDE POR COMPUTADORAS DE TERCERAS PERSONAS Y EN TRANSFERENCIAS DE FONDOS"; break;
                case "18": nombre_cobertura="FALSIFICACION DE TARJETAS DE CREDITO"; break;
                case "19": nombre_cobertura="FALSIFICACION DE CHEQUES ENTRANTES"; break;
                case "20": nombre_cobertura="FALSIFICACION DE DEPOSITANTES"; break;
                case "21": nombre_cobertura="ACTOS DOLOSOS DE LOS TRABAJADORES"; break;
                case "22": nombre_cobertura="PARAGUAS PARA ALMACENADORA"; break;
                case "23": nombre_cobertura="DOLO O MALA FE PARA ALMACENADORA"; break;
                case "24": nombre_cobertura="ROBO POR FORZAMIENTO DE LADRONES"; break;
                case "7": nombre_cobertura="ROBO Y/O ATRACO"; break;
                case "159": nombre_cobertura="RESPONSABILIDAD CIVIL"; break;
                case "42": nombre_cobertura="AIG ASSIST"; break;
                case "43": nombre_cobertura="RESPONSABILIDAD CIVIL DE GARAGE KEEPER"; break;
                case "40": nombre_cobertura="RESPONSABILIDAD  CIVIL DE PREDIOS Y OPERACIONES"; break;
                case "92": nombre_cobertura="ROTURA DE MAQUINARIA"; break;
                case "127": nombre_cobertura="TERRORISMO (RJK)"; break;
                case "128": nombre_cobertura="MAQUINARIA EN MOVIMIENTO"; break;
                case "129": nombre_cobertura="TRANSPORTE INCIDENTAL DE BIENES"; break;
                case "130": nombre_cobertura="HURTO Y/O PILLAJE POST SINIESTRO"; break;
                case "131": nombre_cobertura="GASTOS DE LIMPIEZA Y DESCONTAMINACIÓN"; break;
                case "132": nombre_cobertura="TODO RIESGO DE CONSTRUCCIÓN Y MONTAJE"; break;
                case "148": nombre_cobertura="AMPARO AUTOMÁTICO DE NUEVAS PROPIEDADES"; break;
                case "149": nombre_cobertura="DAÑO ELÉCTRICO"; break;
                case "158": nombre_cobertura="RESPONSABILIDAD CIVIL DE CONDOMINIOS"; break;
                case "176": nombre_cobertura="RESPONSABILIDAD CIVIL INCENDIO Y/O EXPLOSION"; break;
                case "150": nombre_cobertura="Transporte"; break;
                case "152": nombre_cobertura="AMPARO PROPIEDAD PERSONAL EMPLEADOS"; break;
                case "153": nombre_cobertura="ALTERACIONES Y REPARACIONES"; break;
                case "154": nombre_cobertura="ASISTENCIA DOMICILIAR"; break;
                case "155": nombre_cobertura="EQUIPO ELECTRONICO"; break;
                case "156": nombre_cobertura="MATERIA PRIMA Y PRODUCTO TERMINADO"; break;
                case "117": nombre_cobertura="INUNDACION Y/O MAREMOTO"; break;
                case "119": nombre_cobertura="GASTOS DE CERRAJERIA"; break;
                case "123": nombre_cobertura="COBERTURA DE EQUIPO DE PROCESAMIENTO DE DATOS"; break;
                case "120": nombre_cobertura="COBERTURA DE ACCIDENTES PERSONALES"; break;
                case "121": nombre_cobertura="GASTOS EXTRAORDINARIOS"; break;
                case "133": nombre_cobertura="INTERRUPCIÓN DE FLUIDO ELECTRICO FUERA DE LAS INSTALACIONES"; break;
                case "101": nombre_cobertura="II. RESPONSABILIDAD CIVIL CONDOMINIOS"; break;
                case "102": nombre_cobertura=" R. C. PARA AREAS DE ESTACIONAMIENTOS Y PARQUEOS"; break;
                case "103": nombre_cobertura="IV.  CUOTAS DE ADMINISTRACION"; break;
                case "104": nombre_cobertura="1.  ROTURA DE MAQUINARIA"; break;
                case "157": nombre_cobertura="ROBO POR FORZAMIENTO DE LADRONES y/o ROBO AGRAVADO"; break;
                case "106": nombre_cobertura=" AMPARO AUTOMATICO DE GASTOS ADICIONALES"; break;
                case "107": nombre_cobertura="EXTENSIONES AUTOMATICAS"; break;
                case "16": nombre_cobertura="DEMOLICION"; break;
                case "115": nombre_cobertura="PILLAJE POST-SINIESTRO"; break;
                case "122": nombre_cobertura="COBERTURA DE TARJETA DE CREDITO"; break;
                case "118": nombre_cobertura="TODO RIESGO MULTICOBERTURAS"; break;
                case "124": nombre_cobertura="MOTIN Y/O HUELGAS Y/O CONMOCION CIVIL Y/O DAÑO MALICIOSO"; break;
                case "125": nombre_cobertura="MOTIN Y/O HUELGAS Y/O CONMOCION CIVIL Y/O DAÑO MALICIOSO Y/O EXPLOSION"; break;
                case "126": nombre_cobertura="TERREMOTO, TEMBLOR Y/O ERUPCION VOLCANICA, CAIDA DE CENIZA Y/O ARENA ..."; break;
                case "6": nombre_cobertura="DAÑO MALICIOSO ESPECIAL"; break;
                case "5": nombre_cobertura="TERREMOTO, TEMBLOR Y/O ERUPCION VOLCANICA"; break;
                case "2": nombre_cobertura="TODO RIESGO  (2)"; break;
                case "3": nombre_cobertura="TODO RIESGO  (3)"; break;
                case "1": nombre_cobertura="TODO RIESGO  DE INCENDIO"; break;
                case "4": nombre_cobertura="MOTIN  y HUELGAS  Y ALBOROTOS POPULARES"; break;
                case "9": nombre_cobertura="DINERO Y/O VALORES"; break;
                case "178": nombre_cobertura="TRANSPORTE INCIDENTAL"; break;
                case "179": nombre_cobertura="INT. DE NEGOCIOS COMERCIAL E INT. DE RENTAS DE ALQUILER"; break;
                case "180": nombre_cobertura="CUOTAS DE ADMINISTRACION"; break;
                case "181": nombre_cobertura="WALLET GUARD"; break;
                case "182": nombre_cobertura="ACCIDENTES PERSONALES"; break;
                case "177": nombre_cobertura="REPARACIONES PROVISORIAS"; break;
                case "135": nombre_cobertura="DAÑO ELECTRICO"; break;
                case "136": nombre_cobertura="CAR/EAR"; break;
                case "137": nombre_cobertura="ROTURA DE MAQUINARIA Y/O EXPLOSION DE CALDERAS"; break;
                case "138": nombre_cobertura="INT. DE NEGOCIOS RIESGOS INDUSTRIALES INTERDEPENDIENTE"; break;
                case "141": nombre_cobertura="RESPONSABILIDAD CIVIL PATRONAL"; break;
                case "142": nombre_cobertura="RESPONSABILIDAD CIVIL DE ELEVADORES Y/O GRADAS ELECTRICAS"; break;
                case "143": nombre_cobertura="GASTOS MÉDICOS VOLUNTARIOS"; break;
                case "144": nombre_cobertura="CUENTAS POR COBRAR"; break;
                case "145": nombre_cobertura="CAJAS FUERTES"; break;
                case "46": nombre_cobertura="INTERRUPCIÓN DE NEGOCIOS PARA RIESGOS COMERCIALES"; break;
                case "147": nombre_cobertura="GASTOS EXTRAS"; break;
                case "151": nombre_cobertura="INTERRUPCIÓN DE NEGOCIOS RIESGOS INDUSTRIALES Y COMERCIALES"; break;
                case "160": nombre_cobertura="RESPONSABILIDAD CIVIL DE TRANSPORTISTAS"; break;
                case "161": nombre_cobertura="RESPONSABILIDAD CIVIL DE POLUCION O CONTAMINACION"; break;
                case "162": nombre_cobertura="RESPONSABILIDAD CIVIL GENERAL"; break;
                case "134": nombre_cobertura="EXTENSION DE COBERTURA A PROVEEDORES Y CLIENTES"; break;
                case "139": nombre_cobertura="PÉRDIDAS CONSECUENCIALES"; break;
                case "140": nombre_cobertura="Gastos Adicionales y/o Extras"; break;
                case "163": nombre_cobertura="GASTOS DE APRESURAMIENTO"; break;
                case "164": nombre_cobertura="CONTAMINACION DE AMONIACO"; break;
                case "165": nombre_cobertura="DAÑOS POR AGUA"; break;
                case "166": nombre_cobertura="RECONSTRUCCION DE DOCUMENTOS"; break;
                case "167": nombre_cobertura="ROTURA DE CALDERAS Y MAQUINARIA"; break;
                case "168": nombre_cobertura="AMPARO AUTOMATICO DE GASTOS ADICIONALES DERIVADOS DE SINIESTRO"; break;
                case "169": nombre_cobertura="ALTERACIONES, ADICIONES Y MEJORAS"; break;
                case "170": nombre_cobertura="ALQUILER DE PLANTA DE GENERACION DE ENERGIA ELECTRICA"; break;
                case "171": nombre_cobertura="RESPONSABILIDAD CIVIL CONTRACTURAL"; break;
                case "172": nombre_cobertura="RESPONSABILIDAD CIVIL CRUZADA"; break;
                case "173": nombre_cobertura="RESPONSABILIDAD CIVIL CONTRATISTAS Y SUBCONTRATISTAS INDEPENDIENTES"; break;
                case "174": nombre_cobertura="RESPONSABILIDAD CIVIL PARA PARQUEOS Y ESTACIONAMIENTOS"; break;
                case "175": nombre_cobertura="DINERO Y CHEQUES EN LA OFICINA ADMINISTRATIVA"; break;
                case "547": nombre_cobertura="VEHICULOS CON MOTORES APAGADOS"; break;
                case "607": nombre_cobertura="REMOCION DE ESCOMBROS"; break;
                case "608": nombre_cobertura="GASTOS DE EXTINCIÓN DE INCENDIOS"; break;
                case "609": nombre_cobertura="VALORES,  DOCUMENTOS"; break;
                case "610": nombre_cobertura="HURACAN, TORMENTAS Y VIENTOS."; break;
                case "611": nombre_cobertura="ARRIENDO DE LOCALES DE LOCALES PROVISORIOS"; break;
                case "612": nombre_cobertura="I. TODO RIESGO CONDOMINIOS  PARA BIENES MUEBLES E INMUEBLES"; break;
                case "613": nombre_cobertura="TRASLADOS TEMPORALES"; break;
                case "614": nombre_cobertura="MONTAJE Y DESMONTAJE"; break;
                case "615": nombre_cobertura="TRANSITO INCIDENTAL"; break;
                case "616": nombre_cobertura="SERVICIO DE ASISTENCIA DOMICILARIA G&T"; break;
                case "617": nombre_cobertura="DINERO Y/O VALORES FUERA DEL LOCAL"; break;
                case "618": nombre_cobertura="EXPLOSION, MOTIN, HUELGAS Y/O ALBOROTOS POPULARES, DAÑO MALICIOSO"; break;
                case "286": nombre_cobertura="T Riesgos No Catastróficos"; break;
                case "287": nombre_cobertura="U Riesgos Catastróficos"; break;
                case "288": nombre_cobertura="A Incendio Casual y Rayo"; break;
                case "289": nombre_cobertura="B Riesgos Varios"; break;
                case "290": nombre_cobertura="C Inundación, Deslizamiento Vientos Inundación"; break;
                case "291": nombre_cobertura="D Convulsiones de la Naturaleza"; break;
                case "292": nombre_cobertura="E Daño Directo a la Mercancía"; break;
                case "293": nombre_cobertura="F Pérdida de Beneficios"; break;
                case "294": nombre_cobertura="G Lluvia y Derrame "; break;
                case "295": nombre_cobertura="H Pérdida de Rentas por contrato de arrendamiento"; break;
                case "296": nombre_cobertura="I Rotura de Cristales"; break;
                case "297": nombre_cobertura="Q Gastos Extra"; break;
                case "298": nombre_cobertura="A Incendio Casual y Rayo"; break;
                case "299": nombre_cobertura="B Riesgos Varios"; break;
                case "300": nombre_cobertura="C Inundación , Deslizamiento y Vientos"; break;
                case "301": nombre_cobertura="D Convulsiones de la Naturaleza"; break;
                case "302": nombre_cobertura="H Pérdida de Rentas por contrato de arrendamiento"; break;
                case "303": nombre_cobertura="I Rotura de Cristales"; break;
                case "304": nombre_cobertura="R Gastos por Alquiler"; break;
                case "305": nombre_cobertura="V Daño directo de bienes inmuebles"; break;
                case "306": nombre_cobertura="Y Daño directo de contenidos (opción 1,2 y 3)"; break;
                case "307": nombre_cobertura="D Convulsiones de la naturaleza"; break;
                case "308": nombre_cobertura="H Pérdida de rentas por contrato de arrendamiento"; break;
                case "309": nombre_cobertura="K Responsabilidad Civil"; break;
                case "310": nombre_cobertura="M Riesgos del Trabajo Servidores Domesticos"; break;
                case "311": nombre_cobertura="P Accidentes Personales Daños Materiales"; break;
                case "312": nombre_cobertura="Incendio"; break;
                case "313": nombre_cobertura="Agua al sofocar un incendio "; break;
                case "314": nombre_cobertura="Inundación , Deslizamiento y Vientos"; break;
                case "315": nombre_cobertura="Impacto de rayo."; break;
                case "316": nombre_cobertura="Humo u hollín proveniente de un incendio"; break;
                case "317": nombre_cobertura="Implosión y/o Explosión de cualquier tipo"; break;
                case "318": nombre_cobertura="Impacto de vehículos terrestres o aéreos u objetos caídos del cielo"; break;
                case "319": nombre_cobertura="Caída objetos contra la propiedad asegurada."; break;
                case "320": nombre_cobertura="Incendio y Daño Directo por Terremoto"; break;
                case "321": nombre_cobertura="Daño Directo por Vendaval, huracán, tornado, tromba o granizo"; break;
                case "322": nombre_cobertura="Daño Directo por Inundación"; break;
                case "323": nombre_cobertura="Extensión de Cobertura Catastrófica"; break;
                case "324": nombre_cobertura="Incendio y daño por desórdenes públicos."; break;
                case "325": nombre_cobertura="Daño Directo por Maldad"; break;
                case "326": nombre_cobertura="Saqueo"; break;
                case "327": nombre_cobertura="Pérdida consiguiente por falta de refrigeración"; break;
                case "328": nombre_cobertura="Pérdida de Renta"; break;
                case "329": nombre_cobertura="Lucro Cesante (Pérdida de Beneficios)"; break;
                case "330": nombre_cobertura="A Incendio Casual y Rayo"; break;
                case "331": nombre_cobertura="B Riesgos de Naturaleza Catastrófica Inundación"; break;
                case "332": nombre_cobertura="C Daños por Agua y Riesgos Atmosféricos"; break;
                case "333": nombre_cobertura="D Rotura de Cristales, Domos, Mármoles y Granitos."; break;
                case "334": nombre_cobertura="E Robo"; break;
                case "335": nombre_cobertura="F Gastos por Alquiler"; break;
                case "336": nombre_cobertura="G Pérdida de Rentas  "; break;
                case "337": nombre_cobertura="I Bienes Refrigerados o Congelados"; break;
                case "338": nombre_cobertura="J Mercancía en Tránsito"; break;
                case "339": nombre_cobertura="L Rotura de Maquinaria"; break;
                case "340": nombre_cobertura="M Equipo Electrónico"; break;
                case "341": nombre_cobertura="P Interrupción de Negocios"; break;
                case "342": nombre_cobertura="Q Multiasistencia"; break;
                case "343": nombre_cobertura="H Responsabilidad Civil Extracontractual y Subjetiva"; break;
                case "344": nombre_cobertura="Q Asistencia en la Vivienda"; break;
                case "345": nombre_cobertura="Daños Materiales por Riesgos Catastróficos y No Catastróficos"; break;
                }
                break;
                case "TDINCEN" :
                //console.log('ingrese a TDINCEN');
                switch (String(json.data.coberturasXPoliza[0].coberturasXPolizaPK.codigoCobertura)) {
                case "10": nombre_cobertura="REMOCION DE ESCOMBROS"; break;
                case "11": nombre_cobertura="GASTOS DE EXTINCIÓN DE INCENDIOS"; break;
                case "12": nombre_cobertura="VALORES,  DOCUMENTOS"; break;
                case "13": nombre_cobertura="HURACAN, TORMENTAS Y VIENTOS."; break;
                case "108": nombre_cobertura="ARRIENDO DE LOCALES DE LOCALES PROVISORIOS"; break;
                case "100": nombre_cobertura="I. TODO RIESGO CONDOMINIOS  PARA BIENES MUEBLES E INMUEBLES"; break;
                case "110": nombre_cobertura="TRASLADOS TEMPORALES"; break;
                case "111": nombre_cobertura="MONTAJE Y DESMONTAJE"; break;
                case "112": nombre_cobertura="TRANSITO INCIDENTAL"; break;
                case "113": nombre_cobertura="SERVICIO DE ASISTENCIA DOMICILARIA G&T"; break;
                case "28": nombre_cobertura="DINERO Y/O VALORES FUERA DEL LOCAL"; break;
                case "114": nombre_cobertura="EXPLOSION, MOTIN, HUELGAS Y/O ALBOROTOS POPULARES, DAÑO MALICIOSO"; break;
                case "109": nombre_cobertura="DAÑOS POR AGUA"; break;
                case "41": nombre_cobertura="RESPONSABILIDAD CIVIL LEGAL DE INCENDIO"; break;
                case "44": nombre_cobertura="R.C. DE PRODUCTOS O TRABAJOS TERMINADOS"; break;
                case "45": nombre_cobertura="INTERRUPCION DE NEGOCIOS PARA RIESGOS INDUSTRIALES"; break;
                case "546": nombre_cobertura="INCENDIO Y/O RAYO"; break;
                case "25": nombre_cobertura="ATRACO DE CONTENIDO"; break;
                case "26": nombre_cobertura="CRISTALES Y/O ROTULOS"; break;
                case "27": nombre_cobertura="DINERO Y/O VALORES DENTRO DEL LOCAL"; break;
                case "29": nombre_cobertura="IMPACTO DE NAVES ACUATICAS"; break;
                case "30": nombre_cobertura="OTRAS COBERTURAS"; break;
                case "91": nombre_cobertura="INTERRUPCION RENTAS DE ALQUILER"; break;
                case "48": nombre_cobertura="HONORARIOS PROFESIONALES"; break;
                case "14": nombre_cobertura="DAÑO MALICIOSO ESPECIAL  (TERRORISMO)"; break;
                case "15": nombre_cobertura="CORTO CIRCUITO"; break;
                case "32": nombre_cobertura="FALLA ELECTRICA"; break;
                case "34": nombre_cobertura="EXPLOSION DE CALDERAS"; break;
                case "35": nombre_cobertura="TRANSPORTE TERRESTRE"; break;
                case "36": nombre_cobertura="HURTO Y DESAPARICION MISTERIOSA"; break;
                case "37": nombre_cobertura="MONTAJE Y DESMONTAJE"; break;
                case "38": nombre_cobertura="ROTULOS LUMINOSOS"; break;
                case "39": nombre_cobertura="RESPONSABILIDAD CIVIL FAMILIAR"; break;
                case "17": nombre_cobertura="FRAUDE POR COMPUTADORAS DE TERCERAS PERSONAS Y EN TRANSFERENCIAS DE FONDOS"; break;
                case "18": nombre_cobertura="FALSIFICACION DE TARJETAS DE CREDITO"; break;
                case "19": nombre_cobertura="FALSIFICACION DE CHEQUES ENTRANTES"; break;
                case "20": nombre_cobertura="FALSIFICACION DE DEPOSITANTES"; break;
                case "21": nombre_cobertura="ACTOS DOLOSOS DE LOS TRABAJADORES"; break;
                case "22": nombre_cobertura="PARAGUAS PARA ALMACENADORA"; break;
                case "23": nombre_cobertura="DOLO O MALA FE PARA ALMACENADORA"; break;
                case "24": nombre_cobertura="ROBO POR FORZAMIENTO DE LADRONES"; break;
                case "7": nombre_cobertura="ROBO Y/O ATRACO"; break;
                case "159": nombre_cobertura="RESPONSABILIDAD CIVIL"; break;
                case "42": nombre_cobertura="AIG ASSIST"; break;
                case "43": nombre_cobertura="RESPONSABILIDAD CIVIL DE GARAGE KEEPER"; break;
                case "40": nombre_cobertura="RESPONSABILIDAD  CIVIL DE PREDIOS Y OPERACIONES"; break;
                case "92": nombre_cobertura="ROTURA DE MAQUINARIA"; break;
                case "127": nombre_cobertura="TERRORISMO (RJK)"; break;
                case "128": nombre_cobertura="MAQUINARIA EN MOVIMIENTO"; break;
                case "129": nombre_cobertura="TRANSPORTE INCIDENTAL DE BIENES"; break;
                case "130": nombre_cobertura="HURTO Y/O PILLAJE POST SINIESTRO"; break;
                case "131": nombre_cobertura="GASTOS DE LIMPIEZA Y DESCONTAMINACIÓN"; break;
                case "132": nombre_cobertura="TODO RIESGO DE CONSTRUCCIÓN Y MONTAJE"; break;
                case "148": nombre_cobertura="AMPARO AUTOMÁTICO DE NUEVAS PROPIEDADES"; break;
                case "149": nombre_cobertura="DAÑO ELÉCTRICO"; break;
                case "158": nombre_cobertura="RESPONSABILIDAD CIVIL DE CONDOMINIOS"; break;
                case "176": nombre_cobertura="RESPONSABILIDAD CIVIL INCENDIO Y/O EXPLOSION"; break;
                case "150": nombre_cobertura="Transporte"; break;
                case "152": nombre_cobertura="AMPARO PROPIEDAD PERSONAL EMPLEADOS"; break;
                case "153": nombre_cobertura="ALTERACIONES Y REPARACIONES"; break;
                case "154": nombre_cobertura="ASISTENCIA DOMICILIAR"; break;
                case "155": nombre_cobertura="EQUIPO ELECTRONICO"; break;
                case "156": nombre_cobertura="MATERIA PRIMA Y PRODUCTO TERMINADO"; break;
                case "117": nombre_cobertura="INUNDACION Y/O MAREMOTO"; break;
                case "119": nombre_cobertura="GASTOS DE CERRAJERIA"; break;
                case "123": nombre_cobertura="COBERTURA DE EQUIPO DE PROCESAMIENTO DE DATOS"; break;
                case "120": nombre_cobertura="COBERTURA DE ACCIDENTES PERSONALES"; break;
                case "121": nombre_cobertura="GASTOS EXTRAORDINARIOS"; break;
                case "133": nombre_cobertura="INTERRUPCIÓN DE FLUIDO ELECTRICO FUERA DE LAS INSTALACIONES"; break;
                case "101": nombre_cobertura="II. RESPONSABILIDAD CIVIL CONDOMINIOS"; break;
                case "102": nombre_cobertura=" R. C. PARA AREAS DE ESTACIONAMIENTOS Y PARQUEOS"; break;
                case "103": nombre_cobertura="IV.  CUOTAS DE ADMINISTRACION"; break;
                case "104": nombre_cobertura="1.  ROTURA DE MAQUINARIA"; break;
                case "157": nombre_cobertura="ROBO POR FORZAMIENTO DE LADRONES y/o ROBO AGRAVADO"; break;
                case "106": nombre_cobertura=" AMPARO AUTOMATICO DE GASTOS ADICIONALES"; break;
                case "107": nombre_cobertura="EXTENSIONES AUTOMATICAS"; break;
                case "16": nombre_cobertura="DEMOLICION"; break;
                case "115": nombre_cobertura="PILLAJE POST-SINIESTRO"; break;
                case "122": nombre_cobertura="COBERTURA DE TARJETA DE CREDITO"; break;
                case "118": nombre_cobertura="TODO RIESGO MULTICOBERTURAS"; break;
                case "124": nombre_cobertura="MOTIN Y/O HUELGAS Y/O CONMOCION CIVIL Y/O DAÑO MALICIOSO"; break;
                case "125": nombre_cobertura="MOTIN Y/O HUELGAS Y/O CONMOCION CIVIL Y/O DAÑO MALICIOSO Y/O EXPLOSION"; break;
                case "126": nombre_cobertura="TERREMOTO, TEMBLOR Y/O ERUPCION VOLCANICA, CAIDA DE CENIZA Y/O ARENA ..."; break;
                case "6": nombre_cobertura="DAÑO MALICIOSO ESPECIAL"; break;
                case "5": nombre_cobertura="TERREMOTO, TEMBLOR Y/O ERUPCION VOLCANICA"; break;
                case "2": nombre_cobertura="TODO RIESGO  (2)"; break;
                case "3": nombre_cobertura="TODO RIESGO  (3)"; break;
                case "1": nombre_cobertura="TODO RIESGO  DE INCENDIO"; break;
                case "4": nombre_cobertura="MOTIN  y HUELGAS  Y ALBOROTOS POPULARES"; break;
                case "9": nombre_cobertura="DINERO Y/O VALORES"; break;
                case "178": nombre_cobertura="TRANSPORTE INCIDENTAL"; break;
                case "179": nombre_cobertura="INT. DE NEGOCIOS COMERCIAL E INT. DE RENTAS DE ALQUILER"; break;
                case "180": nombre_cobertura="CUOTAS DE ADMINISTRACION"; break;
                case "181": nombre_cobertura="WALLET GUARD"; break;
                case "182": nombre_cobertura="ACCIDENTES PERSONALES"; break;
                case "177": nombre_cobertura="REPARACIONES PROVISORIAS"; break;
                case "135": nombre_cobertura="DAÑO ELECTRICO"; break;
                case "136": nombre_cobertura="CAR/EAR"; break;
                case "137": nombre_cobertura="ROTURA DE MAQUINARIA Y/O EXPLOSION DE CALDERAS"; break;
                case "138": nombre_cobertura="INT. DE NEGOCIOS RIESGOS INDUSTRIALES INTERDEPENDIENTE"; break;
                case "141": nombre_cobertura="RESPONSABILIDAD CIVIL PATRONAL"; break;
                case "142": nombre_cobertura="RESPONSABILIDAD CIVIL DE ELEVADORES Y/O GRADAS ELECTRICAS"; break;
                case "143": nombre_cobertura="GASTOS MÉDICOS VOLUNTARIOS"; break;
                case "144": nombre_cobertura="CUENTAS POR COBRAR"; break;
                case "145": nombre_cobertura="CAJAS FUERTES"; break;
                case "46": nombre_cobertura="INTERRUPCIÓN DE NEGOCIOS PARA RIESGOS COMERCIALES"; break;
                case "147": nombre_cobertura="GASTOS EXTRAS"; break;
                case "151": nombre_cobertura="INTERRUPCIÓN DE NEGOCIOS RIESGOS INDUSTRIALES Y COMERCIALES"; break;
                case "160": nombre_cobertura="RESPONSABILIDAD CIVIL DE TRANSPORTISTAS"; break;
                case "161": nombre_cobertura="RESPONSABILIDAD CIVIL DE POLUCION O CONTAMINACION"; break;
                case "162": nombre_cobertura="RESPONSABILIDAD CIVIL GENERAL"; break;
                case "134": nombre_cobertura="EXTENSION DE COBERTURA A PROVEEDORES Y CLIENTES"; break;
                case "139": nombre_cobertura="PÉRDIDAS CONSECUENCIALES"; break;
                case "140": nombre_cobertura="Gastos Adicionales y/o Extras"; break;
                case "163": nombre_cobertura="GASTOS DE APRESURAMIENTO"; break;
                case "164": nombre_cobertura="CONTAMINACION DE AMONIACO"; break;
                case "165": nombre_cobertura="DAÑOS POR AGUA"; break;
                case "166": nombre_cobertura="RECONSTRUCCION DE DOCUMENTOS"; break;
                case "167": nombre_cobertura="ROTURA DE CALDERAS Y MAQUINARIA"; break;
                case "168": nombre_cobertura="AMPARO AUTOMATICO DE GASTOS ADICIONALES DERIVADOS DE SINIESTRO"; break;
                case "169": nombre_cobertura="ALTERACIONES, ADICIONES Y MEJORAS"; break;
                case "170": nombre_cobertura="ALQUILER DE PLANTA DE GENERACION DE ENERGIA ELECTRICA"; break;
                case "171": nombre_cobertura="RESPONSABILIDAD CIVIL CONTRACTURAL"; break;
                case "172": nombre_cobertura="RESPONSABILIDAD CIVIL CRUZADA"; break;
                case "173": nombre_cobertura="RESPONSABILIDAD CIVIL CONTRATISTAS Y SUBCONTRATISTAS INDEPENDIENTES"; break;
                case "174": nombre_cobertura="RESPONSABILIDAD CIVIL PARA PARQUEOS Y ESTACIONAMIENTOS"; break;
                case "175": nombre_cobertura="DINERO Y CHEQUES EN LA OFICINA ADMINISTRATIVA"; break;
                case "547": nombre_cobertura="VEHICULOS CON MOTORES APAGADOS"; break;
                case "607": nombre_cobertura="REMOCION DE ESCOMBROS"; break;
                case "608": nombre_cobertura="GASTOS DE EXTINCIÓN DE INCENDIOS"; break;
                case "609": nombre_cobertura="VALORES,  DOCUMENTOS"; break;
                case "610": nombre_cobertura="HURACAN, TORMENTAS Y VIENTOS."; break;
                case "611": nombre_cobertura="ARRIENDO DE LOCALES DE LOCALES PROVISORIOS"; break;
                case "612": nombre_cobertura="I. TODO RIESGO CONDOMINIOS  PARA BIENES MUEBLES E INMUEBLES"; break;
                case "613": nombre_cobertura="TRASLADOS TEMPORALES"; break;
                case "614": nombre_cobertura="MONTAJE Y DESMONTAJE"; break;
                case "615": nombre_cobertura="TRANSITO INCIDENTAL"; break;
                case "616": nombre_cobertura="SERVICIO DE ASISTENCIA DOMICILARIA G&T"; break;
                case "617": nombre_cobertura="DINERO Y/O VALORES FUERA DEL LOCAL"; break;
                case "618": nombre_cobertura="EXPLOSION, MOTIN, HUELGAS Y/O ALBOROTOS POPULARES, DAÑO MALICIOSO"; break;
                case "286": nombre_cobertura="T Riesgos No Catastróficos"; break;
                case "287": nombre_cobertura="U Riesgos Catastróficos"; break;
                case "288": nombre_cobertura="A Incendio Casual y Rayo"; break;
                case "289": nombre_cobertura="B Riesgos Varios"; break;
                case "290": nombre_cobertura="C Inundación, Deslizamiento Vientos Inundación"; break;
                case "291": nombre_cobertura="D Convulsiones de la Naturaleza"; break;
                case "292": nombre_cobertura="E Daño Directo a la Mercancía"; break;
                case "293": nombre_cobertura="F Pérdida de Beneficios"; break;
                case "294": nombre_cobertura="G Lluvia y Derrame "; break;
                case "295": nombre_cobertura="H Pérdida de Rentas por contrato de arrendamiento"; break;
                case "296": nombre_cobertura="I Rotura de Cristales"; break;
                case "297": nombre_cobertura="Q Gastos Extra"; break;
                case "298": nombre_cobertura="A Incendio Casual y Rayo"; break;
                case "299": nombre_cobertura="B Riesgos Varios"; break;
                case "300": nombre_cobertura="C Inundación , Deslizamiento y Vientos"; break;
                case "301": nombre_cobertura="D Convulsiones de la Naturaleza"; break;
                case "302": nombre_cobertura="H Pérdida de Rentas por contrato de arrendamiento"; break;
                case "303": nombre_cobertura="I Rotura de Cristales"; break;
                case "304": nombre_cobertura="R Gastos por Alquiler"; break;
                case "305": nombre_cobertura="V Daño directo de bienes inmuebles"; break;
                case "306": nombre_cobertura="Y Daño directo de contenidos (opción 1,2 y 3)"; break;
                case "307": nombre_cobertura="D Convulsiones de la naturaleza"; break;
                case "308": nombre_cobertura="H Pérdida de rentas por contrato de arrendamiento"; break;
                case "309": nombre_cobertura="K Responsabilidad Civil"; break;
                case "310": nombre_cobertura="M Riesgos del Trabajo Servidores Domesticos"; break;
                case "311": nombre_cobertura="P Accidentes Personales Daños Materiales"; break;
                case "312": nombre_cobertura="Incendio"; break;
                case "313": nombre_cobertura="Agua al sofocar un incendio "; break;
                case "314": nombre_cobertura="Inundación , Deslizamiento y Vientos"; break;
                case "315": nombre_cobertura="Impacto de rayo."; break;
                case "316": nombre_cobertura="Humo u hollín proveniente de un incendio"; break;
                case "317": nombre_cobertura="Implosión y/o Explosión de cualquier tipo"; break;
                case "318": nombre_cobertura="Impacto de vehículos terrestres o aéreos u objetos caídos del cielo"; break;
                case "319": nombre_cobertura="Caída objetos contra la propiedad asegurada."; break;
                case "320": nombre_cobertura="Incendio y Daño Directo por Terremoto"; break;
                case "321": nombre_cobertura="Daño Directo por Vendaval, huracán, tornado, tromba o granizo"; break;
                case "322": nombre_cobertura="Daño Directo por Inundación"; break;
                case "323": nombre_cobertura="Extensión de Cobertura Catastrófica"; break;
                case "324": nombre_cobertura="Incendio y daño por desórdenes públicos."; break;
                case "325": nombre_cobertura="Daño Directo por Maldad"; break;
                case "326": nombre_cobertura="Saqueo"; break;
                case "327": nombre_cobertura="Pérdida consiguiente por falta de refrigeración"; break;
                case "328": nombre_cobertura="Pérdida de Renta"; break;
                case "329": nombre_cobertura="Lucro Cesante (Pérdida de Beneficios)"; break;
                case "330": nombre_cobertura="A Incendio Casual y Rayo"; break;
                case "331": nombre_cobertura="B Riesgos de Naturaleza Catastrófica Inundación"; break;
                case "332": nombre_cobertura="C Daños por Agua y Riesgos Atmosféricos"; break;
                case "333": nombre_cobertura="D Rotura de Cristales, Domos, Mármoles y Granitos."; break;
                case "334": nombre_cobertura="E Robo"; break;
                case "335": nombre_cobertura="F Gastos por Alquiler"; break;
                case "336": nombre_cobertura="G Pérdida de Rentas  "; break;
                case "337": nombre_cobertura="I Bienes Refrigerados o Congelados"; break;
                case "338": nombre_cobertura="J Mercancía en Tránsito"; break;
                case "339": nombre_cobertura="L Rotura de Maquinaria"; break;
                case "340": nombre_cobertura="M Equipo Electrónico"; break;
                case "341": nombre_cobertura="P Interrupción de Negocios"; break;
                case "342": nombre_cobertura="Q Multiasistencia"; break;
                case "343": nombre_cobertura="H Responsabilidad Civil Extracontractual y Subjetiva"; break;
                case "344": nombre_cobertura="Q Asistencia en la Vivienda"; break;
                case "345": nombre_cobertura="Daños Materiales por Riesgos Catastróficos y No Catastróficos"; break;
                }  break;
                case 'RC':
                //console.log('ingrese a RC');
                switch (String(json.data.coberturasXPoliza[0].coberturasXPolizaPK.codigoCobertura)) {
                case "28": nombre_cobertura="RESPONSABILIDAD RESERVA DE DOMINIO"; break;
                case "46": nombre_cobertura="R.C. PARA VUELOS CHARTER"; break;
                case "29": nombre_cobertura="RESPONSABILIDAD LEGAL POR DAÑOS POR INCENDIO"; break;
                case "47": nombre_cobertura="RESPONSABILIDAD LEGAL POR DAÑOS POR INCENDIO"; break;
                case "48": nombre_cobertura="OTRAS COBERTURAS"; break;
                case "49": nombre_cobertura="DEMOLICION"; break;
                case "44": nombre_cobertura="RESPONSABILIDAD CIVIL GASOLINERAS"; break;
                case "41": nombre_cobertura="RESPONSABILIDAD CIVIL BAJO LEYES DE BEBIDAS ALCOHOLICAS"; break;
                case "42": nombre_cobertura="RESPONSABILIDAD CIVIL POR EMBARCACIONES QUE NO SON PROPIEDAD DEL ASEGURADO"; break;
                case "29": nombre_cobertura="RESPONSABILIDAD CIVIL POR CONTAMINACION DEL MEDIO AMBIENTE"; break;
                case "30": nombre_cobertura="R.C. MAQUINAS AGRICOLAS"; break;
                case "31": nombre_cobertura="USO O TENENCIA DE COMBUSTIBLES"; break;
                case "32": nombre_cobertura="TRANSPORTE DE COMBUSTIBLES"; break;
                case "33": nombre_cobertura="RESPONSABILIDAD CIVIL CONTAMINACIÓN POR CONTACTO CON OTRAS CARGAS."; break;
                case "36": nombre_cobertura="RESPONSABILIDAD CIVIL LESIONES PERSONALES Y PUBLICITARIAS"; break;
                case "37": nombre_cobertura="REPATRIACION"; break;
                case "38": nombre_cobertura="RESPONSABILIDAD CIVIL DE AUTO CONTINGENTE"; break;
                case "39": nombre_cobertura="MALA PRACTICA HOSPITALARIA"; break;
                case "40": nombre_cobertura="RESPONSABILIDAD CIVIL DE MONTACARGAS"; break;
                case "35": nombre_cobertura="NEGLIGENCIA"; break;
                case "23": nombre_cobertura="RESPONSABILIDAD CIVIL GUARDIAS"; break;
                case "43": nombre_cobertura="R.C. MAL MANEJO"; break;
                case "50": nombre_cobertura="RESPONSABILIDAD DE DIRECTORES Y OFICIALES"; break;
                case "51": nombre_cobertura="RESPONSABILIDAD CIVIL DE MINERÍA"; break;
                case "52": nombre_cobertura="RESPONSABILIDAD CIVIL SUBCONTRATISTAS"; break;
                case "53": nombre_cobertura="RESPONSABILIDAD CIVIL DE ROTULOS"; break;
                case "54": nombre_cobertura="RESPONSABILIDAD CIVIL DE GASTOS MÉDICOS VOLUNTARIOS"; break;
                case "57": nombre_cobertura="Responsabilidad Civil de Restaurantes"; break;
                case "59": nombre_cobertura="GASTOS MÉDICOS VOLUNTARIOS"; break;
                case "62": nombre_cobertura="RESPONSABILIDAD CIVIL DE CONDOMINIO"; break;
                case "56": nombre_cobertura="HOLE IN ONE"; break;
                case "58": nombre_cobertura="EXCESOS DE RESPONSABILIDAD CIVIL"; break;
                case "63": nombre_cobertura="RESP. CIVIL CLUBS Y ASOCIACIONES SOCIALES Y DEPORTIVAS"; break;
                case "45": nombre_cobertura="CUOTAS DE ADMINISTRACION"; break;
                case "60": nombre_cobertura="R. C. Otros"; break;
                case "34": nombre_cobertura="RESPONSABILIDAD  CIVIL  DE ESTACIONAMIENTOS"; break;
                case "6": nombre_cobertura="RESPONSABILIDAD  CIVIL  DE TRANSPORTISTAS"; break;
                case "7": nombre_cobertura="RESPONSABILIDAD CIVIL FAMILIAR"; break;
                case "8": nombre_cobertura="RESPONSABILIDAD CIVIL GENERAL"; break;
                case "9": nombre_cobertura="RESPONSABILIDAD CIVIL PROFESIONAL"; break;
                case "10": nombre_cobertura="RESPONSABILIDAD CIVIL PENAL O CRIMINAL"; break;
                case "11": nombre_cobertura="RESPONSABILIDAD CIVIL DE ELEVADORES y/o GRADAS ELECTRICAS"; break;
                case "12": nombre_cobertura="RESPONSABILIDAD CIVIL CRUZADA"; break;
                case "13": nombre_cobertura="RESPONSABILIDAD CIVIL DE PARQUEOS Y/O ESTACIONAMIENTOS"; break;
                case "14": nombre_cobertura="RESPONSABILIDAD  CIVIL  DE CONTRATISTAS"; break;
                case "15": nombre_cobertura="RESPONSABILIDAD CIVIL DE VEHICULOS"; break;
                case "16": nombre_cobertura="RESPONSABILIDAD CIVIL ASUMIDA"; break;
                case "17": nombre_cobertura="R. C. POR DAÑOS A TERCEROS EN SUS PERSONAS"; break;
                case "18": nombre_cobertura="RESPONSABILIDAD CIVIL DE CONSTRUCCION"; break;
                case "19": nombre_cobertura="R. C. POR DAÑOS A TERCEROS EN SUS BIENES"; break;
                case "22": nombre_cobertura="EXCESOS DE R. C. DE VEHICULOS"; break;
                case "24": nombre_cobertura="RESPONSABILIDAD CIVIL POR EXPLOSION DE CALDERAS Y/O APARATOS QUE OPERAN A PRESIO"; break;
                case "25": nombre_cobertura="*********SUSTITUR POR LA COBERTURA 23*******"; break;
                case "26": nombre_cobertura="RESPONSABILIDAD CIVIL POR CAIDA PARCIAL O TOTAL DE ROTULOS, ARBOLES Y/O RAMAS"; break;
                case "27": nombre_cobertura="RESPONSABILIDAD DE GARAGE KEEPERS"; break;
                case "20": nombre_cobertura="RESPONSABILIDAD CIVIL DE DIRECTIVOS Y CONSEJEROS"; break;
                case "21": nombre_cobertura="RESPONSABILIDAD CIVIL DE ESTIBADORES"; break;
                case "1": nombre_cobertura="RESPONSABILIDAD  CIVIL  LEGAL DE INCENDIO Y/O EXPLOSION"; break;
                case "2": nombre_cobertura="RESPONSABILIDAD  CIVIL  DE PREDIOS Y OPERACIONES"; break;
                case "3": nombre_cobertura="RESPONSABILIDAD  CIVIL  DE PRODUCTOS O TRABAJOS TERMINADOS"; break;
                case "4": nombre_cobertura="RESPONSABILIDAD  CIVIL CONTRACTUAL O ASUMIDA"; break;
                case "5": nombre_cobertura="RESPONSABILIDAD  CIVIL PATRONAL"; break;
                case "68": nombre_cobertura="RESPONSABILIDAD CIVIL"; break;
                case "70": nombre_cobertura="RESPONSABILIDAD CIVIL DERIVADA DE LA VIOLACION DE PATENTES"; break;
                case "65": nombre_cobertura="RESPONSABILIDAD CIVIL DE NAVES AEREAS"; break;
                case "67": nombre_cobertura="REEMBOLSO A LA SOCIEDAD POR INDEMNIZACIÓN"; break;
                case "69": nombre_cobertura="RESPONSABILIDAD CIVIL DE DIRECTIVOS Y FUNCIONARIOS"; break;
                case "64": nombre_cobertura="RESPONSABILIDAD COLECTIVA DE PASAJEROS"; break;
                case "66": nombre_cobertura="RESPONSABILIDAD CIVIL DIRECTORES Y ADMINISTRADORES"; break;
                case "61": nombre_cobertura="RESPONSABILIDAD CIVIL MAQUINARIA Y EQUIPO MÓVIL"; break;
                case "71": nombre_cobertura="RESPONSABILIDAD CIVIL HOTELERA"; break;
                case "200": nombre_cobertura="A- RESPONSABILIDAD CIVIL POR LESION O MUERTE DE PERSONAS"; break;
                case "201": nombre_cobertura="B-	ATENCIÓN MEDICA INMEDIATA"; break;
                case "202": nombre_cobertura="C-RESPONSABILIDAD CIVIL POR DAÑOS A PROPIEDADES DE TERCEROS"; break;
                case "203": nombre_cobertura="D-SUBLÍMITE DE LAVANDERÍAS Y GUARDARROPAS"; break;
                case "204": nombre_cobertura="E-EQUIPAJE DE HUESPEDES"; break;
                case "205": nombre_cobertura="F-RESGUARDOS DE CAJAS DE SEGURIDAD"; break;
                case "206": nombre_cobertura="L-RESPONSABILIDAD CIVIL (COB A Y C)     "; break;
                case "207": nombre_cobertura="M-RESPONSABILIDAD CIVIL PRODUCTOS"; break;
                case "208": nombre_cobertura="N-RESPONSABILIDAD CIVIL PATRONAL"; break;
                case "209": nombre_cobertura="O-RESPONSABILIDAD CIVIL COLISION O VUELCO DE VEHÍCULOS"; break;
                case "210": nombre_cobertura="P-RESPONSABILIDAD CIVIL ROBO DE VEHÍCULOS"; break;
                case "211": nombre_cobertura="Q-RESPONSABILIDAD CIVIL PRUEBA DE VEHÍCULOS PARA TALLERES"; break;
                case "212": nombre_cobertura="R-RESPONSABILIDAD CIVIL ATENCIÓN MÉDICA INMEDIATA"; break;
                case "213": nombre_cobertura="RESPONSABILIDAD CIVIL CONSTRUCCION"; break;
                case "214": nombre_cobertura="RESPONSABILIDAD CIVIL SERVICIOS"; break;
                case "215": nombre_cobertura="RESPONSABILIDAD CIVIL INDUSTRIAS"; break;
                case "216": nombre_cobertura="RESPONSABILIDAD CIVIL LOCALES COMERCIALES"; break;
                case "217": nombre_cobertura="RESPONSABILIDAD CIVIL HOTELES Y SIMILARES"; break;
                case "218": nombre_cobertura="RESPONSABILIDAD CIVIL OPERADORES TURISTICOS"; break;
                case "219": nombre_cobertura="RESPONSABILIDAD CIVIL PARQUEOS"; break;
                case "220": nombre_cobertura="RESPONSABILIDAD CIVIL TALLERES"; break;
                case "221": nombre_cobertura="RESPONSABILIDAD CIVIL AUTOLAVADOS"; break;
                case "222": nombre_cobertura="RESPONSABILIDAD CIVIL ESTACIONES DE SERVICIO"; break;
                case "223": nombre_cobertura="RESPONSABILIDAD CIVIL ESPECTACULOS PUBLICOS"; break;
                case "224": nombre_cobertura="RESPONSABILIDAD CIVIL EVENTOS TAURINOS Y PIROTECNICOS"; break;
                case "225": nombre_cobertura="RESPONSABILIDAD CIVIL JUEGOS ELECTROMECANICOS"; break;
                case "226": nombre_cobertura="RESPONSABILIDAD CIVIL FAMILIAR Y PRIVADA"; break;
                case "227": nombre_cobertura="RESPONSABILIDAD CIVIL PREDIOS Y OPERACIONES"; break;
                case "228": nombre_cobertura="RESPONSABILIDAD CIVIL FABRICANTES Y CONTRATISTAS"; break;
                case "229": nombre_cobertura="RESPONSABILIDAD CIVIL DUEÑOS. PROPIETARIOS Y ARRENDATARIOS"; break;
                case "230": nombre_cobertura="RESPONSABILIDAD CIVIL ALMACENADORA"; break;
                case "231": nombre_cobertura="RESPONSABILIDAD CIVIL PRODUCTOS"; break;
                case "232": nombre_cobertura="COBERTURA GENERAL COMPRENSIVA"; break;
                case "233": nombre_cobertura="RESPONSABILIDDAD CIVIL CRUZADA"; break;
                case "234": nombre_cobertura="COBERTURA DE RESPONSABILIDAD CIVIL LEGAL DE DAÑOS POR INCENDIO"; break;
                case "235": nombre_cobertura="RESPONSABILIAD CIVIL LEGAL"; break;
                case "236": nombre_cobertura="RESPONSABILIAD CIVIL LEGAL CONTRACTUAL"; break;
                case "237": nombre_cobertura="RESPONSABILIAD CIVIL LEGAL EXTRA CONTRACTUAL"; break;
                case "238": nombre_cobertura="RESPONSABILIAD CIVIL CONTRACTUAL"; break;
                case "239": nombre_cobertura="RESPONSABILIAD CIVIL EXTRA CONTRACTUAL"; break;
                case "240": nombre_cobertura="U: Responsabilidad Civil Umbrella"; break;
                case "72": nombre_cobertura="RESPONSABILIDAD CIVIL PREDIO Y OPERACIONES INDUSTRIALES"; break;
                case "241": nombre_cobertura="RESPONSABILIDAD CIVIL CONTRATISTAS SUBCONTRATISTAS"; break;
                case "242": nombre_cobertura="RESPONSABILIDAD CIVIL DE ELEVADORES Y/O ESCALERAS ELECTRICAS"; break;
                case "243": nombre_cobertura="RESPONSABILIDAD CIVIL DE TRANSPORTISTAS"; break;
                case "244": nombre_cobertura="RESPONSABILIDAD CIVIL EN EXCESO DE AUTOMOVIL"; break;
                case "245": nombre_cobertura="RESPONSABILIDAD CIVIL POR CONTAMINACIÓN AL MEDIO AMBIENTE"; break;
                case "246": nombre_cobertura="RESPONSABILIDAD CIVIL POR INCENDIO Y/O EXPLOSION"; break;
                case "247": nombre_cobertura="RESPONSABILIDAD CIVIL POR DAÑOS PATRIMONIALES PUROS"; break;
                case "248": nombre_cobertura="RESPONSABILIDAD CIVIL POR ONDAS Y CAMPOS ELECTROMAGNETICOS"; break;
                case "249": nombre_cobertura="RESPONSABILIDAD CIVL POST-TRABAJOS"; break;
                case "250": nombre_cobertura="RESPONSABILIDAD CIVIL POR VIOLACION DE PATENTES"; break;
                case "251": nombre_cobertura="RESPONSABILIDAD CIVIL POR PRACTICAS LABORALES"; break;
                case "252": nombre_cobertura="RESPONSABILIDAD CIVIL FIDUCIARIA DE PLANES DE PRESTACIONES PARA EMPLEADOS"; break;
                case "253": nombre_cobertura="RESPONSABILIDAD CIVIL CUIDADO, CONTROL Y CUSTODIA"; break;
                }
                break;
                case 'TR':
              //console.log('ingrese a TTR');
              switch (String(json.data.coberturasXPoliza[0].coberturasXPolizaPK.codigoCobertura)) {
              case "27": nombre_cobertura="DAÑO MALICIOSO ESPECIAL (Terrorismo)"; break;
              case "23": nombre_cobertura="FALTA DE ENTREGA"; break;
              case "28": nombre_cobertura="CARGA Y DESCARGA"; break;
              case "29": nombre_cobertura="MOTIN, HUELGA, CONMOCION CIVIL, DAÑOS MALICIOSOS Y ACTOS DE TERRORISMO"; break;
              case "30": nombre_cobertura="MOTIN, HUELGA, CONMOCION CIVIL Y DAÑOS MALICIOSOS"; break;
              case "31": nombre_cobertura="AVERIA GRUESA"; break;
              case "21": nombre_cobertura="ATRACO"; break;
              case "32": nombre_cobertura="OTRAS COBERTURAS"; break;
              case "25": nombre_cobertura="CARGA SOBRE CUBIERTA"; break;
              case "26": nombre_cobertura="FLOTE"; break;
              case "22": nombre_cobertura="ROBO Y/O ATRACO"; break;
              case "24": nombre_cobertura="TODO RIESGO EXTERNO"; break;
              case "16": nombre_cobertura="TERREMOTO,TEMBLOR,ERUPCION VOLCANICA, CAIDA DE CENIZA Y/O ARENA VOLCANICA E INC"; break;
              case "17": nombre_cobertura="MAREMOTO"; break;
              case "18": nombre_cobertura="TEMBLOR"; break;
              case "19": nombre_cobertura="HURACAN"; break;
              case "20": nombre_cobertura="VIENTOS TEMPESTUOSOS"; break;
              case "34": nombre_cobertura="BODEGA A BODEGA"; break;
              case "35": nombre_cobertura="HUELGAS, ALBOROTOS POPULARES Y CONMOCION CIVIL"; break;
              case "36": nombre_cobertura="TODO RIESGO (INCISO C)"; break;
              case "37": nombre_cobertura="RATERIA"; break;
              case "38": nombre_cobertura="HUELGAS, ALBOROTOS POPULARES Y CONMOCION CIVIL ."; break;
              case "1": nombre_cobertura="RIESGOS ORDINARIOS DE TRANSITO TRANSPORTE TERRESTRE Y/O AEREO"; break;
              case "8": nombre_cobertura="TRANSBORDO Y RECUPERACION"; break;
              case "9": nombre_cobertura="ROTURAS"; break;
              case "2": nombre_cobertura="RIESGOS ORDINARIOS DE TRANSITO   TRANSPORTE MARITIMO"; break;
              case "7": nombre_cobertura="CONTACTO CON OTRAS CARGAS"; break;
              case "11": nombre_cobertura="MOTIN, HUELGAS Y ALBOROTOS POPULARES"; break;
              case "12": nombre_cobertura="GUERRA"; break;
              case "10": nombre_cobertura="FALTA DE PESO O DERRAME"; break;
              case "3": nombre_cobertura="ROBO DE BULTO POR ENTERO"; break;
              case "4": nombre_cobertura="ROBO PARCIAL"; break;
              case "5": nombre_cobertura="MOJADURA"; break;
              case "6": nombre_cobertura="OXIDACION"; break;
              case "13": nombre_cobertura="TODO RIESGO  1.1.82  (A)"; break;
              case "14": nombre_cobertura="C. A. P.   1.1.82   (B)"; break;
              case "15": nombre_cobertura=" L.A.P.  1.1.82  (C)"; break;
              case "39": nombre_cobertura="TODO RIESGO  1.1.63  (A)"; break;
              case "40": nombre_cobertura="PAGO DE IMPUESTOS ANTE SAT"; break;
              case "54": nombre_cobertura="Cobertura de riesgos de cláusulas del instituto de londres, para cargamentos “a”"; break;
              case "55": nombre_cobertura="Cobertura de riesgos de cláusulas del instituto de londres, para cargamentos “b”"; break;
              case "56": nombre_cobertura="Huelga"; break;
              case "57": nombre_cobertura="Guerra"; break;
              case "41": nombre_cobertura="Incendio, rayo, auto-ignición y explosión"; break;
              case "42": nombre_cobertura="Terremoto, temblor"; break;
              case "43": nombre_cobertura="Colisión del vehículo con otro vehículo u objeto"; break;
              case "44": nombre_cobertura="Vuelco del vehículo transportador de los bienes descritos"; break;
              case "45": nombre_cobertura="Vendaval, ciclón o tornado"; break;
              case "46": nombre_cobertura="Derrumbe de puentes o alcantarillas"; break;
              case "47": nombre_cobertura="Derrumbe de tierra, de piedras o de rocas"; break;
              case "48": nombre_cobertura="Inundación por el desbordamiento de ríos o quebradas"; break;
              case "49": nombre_cobertura="Saqueo"; break;
              case "50": nombre_cobertura="Robo o Asalto"; break;
              case "51": nombre_cobertura="Maniobras de Carga y Descarga"; break;
              case "52": nombre_cobertura="Refrigeración"; break;
              case "53": nombre_cobertura="Extraterritorialidad"; break;
              case "69": nombre_cobertura="Movimientos Bruscos"; break;
              case "58": nombre_cobertura="Riesgos del Medio de Transporte"; break;
              case "59": nombre_cobertura="Caída, Colisión o Vuelco de Mercancías"; break;
              case "60": nombre_cobertura="Caída de Mercancía en Predios"; break;
              case "61": nombre_cobertura="Fallas Mecánicas en el Sistema de Refrigeración"; break;
              case "62": nombre_cobertura="Todo Riesgo 1.1.82 A"; break;
              case "63": nombre_cobertura="Motin y Huelga"; break;
              case "64": nombre_cobertura="Robo y/o Atraco"; break;
              case "65": nombre_cobertura="Carga y Descarga"; break;
              case "66": nombre_cobertura="Bodega a Bodega"; break;
              case "67": nombre_cobertura="Estadía"; break;
              case "68": nombre_cobertura="Riesgos Ordinarios de Transito 1.1.82 C"; break;
              case "71": nombre_cobertura="TRANSBORDO"; break;
              case "72": nombre_cobertura="RECUPERACION"; break;
              case "73": nombre_cobertura="DESTRUCCION"; break;
              case "70": nombre_cobertura="Responsabilidad Civil Carga Vía Terrestre"; break;
              }
              break;
              }

              if (json.data.coberturasXPoliza[0].coberturasXPolizaPK.codigoDireccion!= undefined)
              { 
                if($('#monedaPol').val()=='Q') 
                {
                  datosini.push({CODIGO:json.data.coberturasXPoliza[0].coberturasXPolizaPK.codigoCobertura,DESCRIPCION:nombre_cobertura,SUMA:aMoneda(json.data.coberturasXPoliza[0].sumaAsegurada, opcionesQuetzales)});
                }else
                {
                  datosini.push({CODIGO:json.data.coberturasXPoliza[0].coberturasXPolizaPK.codigoCobertura,DESCRIPCION:nombre_cobertura,SUMA:aMoneda(json.data.coberturasXPoliza[0].sumaAsegurada, opcionesDolares)});
                }
              }
          }else
          {
            for (var i = 0; i < json.data.coberturasXPoliza.length; i++) 
            {          
                switch (json.data.coberturasXPoliza[i].coberturasXPolizaPK.codigoTipoPoliza) 
                {
                    case 'EE':
                    //console.log('ingrese a equipo');
                      switch (json.data.coberturasXPoliza[i].coberturasXPolizaPK.codigoCobertura) {
                        case 9: nombre_cobertura="DAÑO MALICIOSO ESPECIAL  TERRORISMO "; break;
                    case 23: nombre_cobertura="HURACAN, CICLON, TIFON"; break;
                    case 17: nombre_cobertura="OTRAS COBERTURAS"; break;
                    case 14: nombre_cobertura="FALLA APROVISIONAMIENTO DE LA CORRIENTE ELECTRICA"; break;
                    case 18: nombre_cobertura="TRANSPORTE INCIDENTAL"; break;
                    case 19: nombre_cobertura="MOTIN, HUELGAS Y/O ALBOROTOS POPULARES, DAÑOS MALICIOSOS E INCENDIO CONSECUTIVO"; break;
                    case 20: nombre_cobertura="EXPLOSION, MOTIN, HUELGAS Y/O ALBOROTOS POPULARES, DAÑO MALICIOSO"; break;
                    case 24: nombre_cobertura="MOTIN, HUELGA Y/O CONMOCION CIVIL"; break;
                    case 16: nombre_cobertura="TODO RIESGO"; break;
                    case 15: nombre_cobertura="INTERRUPCION DE NEGOCIOS PARA RIESGOS COMERCIALES"; break;
                    case 22: nombre_cobertura="TERREMOTO"; break;
                    case 21: nombre_cobertura="COBERTURA DE EQUIPOS MOVILES Y PORTATILES FUERA DE LOS PREDIOS ASEGURADOS"; break;
                    case 7: nombre_cobertura="CORTO CIRCUITO, ALTERACIONES DE LA CORRIENTE ELECTRICA."; break;
                    case 8: nombre_cobertura="GASTOS DE REGRABACION O REPROCESAMIENTO DE DATOS"; break;
                    case 12: nombre_cobertura="INCREMENTO EN EL COSTO DE LA OPERACION"; break;
                    case 13: nombre_cobertura="ROBO Y/O ATRACO"; break;
                    case 1: nombre_cobertura="SECCION I : DAÑOS MATERIALES"; break;
                    case 2: nombre_cobertura="SECCION II :  PORTADORES EXTERNOS DE DATOS"; break;
                    case 3: nombre_cobertura="SECCION III :   GASTOS EXTRAS"; break;
                    case 4: nombre_cobertura="TERREMOTO, TEMBLOR Y/O ERUPCION VOLCANICA E INCENDIO CONSECUTIVO"; break;
                    case 5: nombre_cobertura="DAÑO MALICIOSO"; break;
                    case 6: nombre_cobertura="ROBO POR FORZAMIENTO DE LADRONES"; break;
                    case 25: nombre_cobertura="TRANSPORTE"; break;
                    case 58: nombre_cobertura="A.- INCENDIO CASUAL RAYO Y EXPLOSIÓN"; break;
                    case 59: nombre_cobertura="B.- ROBO"; break;
                    case 60: nombre_cobertura="C.- MOTIN, HUELGA, PARA LEGAL"; break;
                    case 61: nombre_cobertura="D.- HURACLÁN, CICLON, INUNDACIÓN, DESLIZAMIENTO"; break;
                    case 62: nombre_cobertura="F.-TEMBLOR TERREMOTO"; break;
                    case 63: nombre_cobertura="G. DANO MALICIOSO"; break;
                    case 64: nombre_cobertura="H.- DAÑOS POR COLISIÓN Y VUELCO"; break;
                    case 26: nombre_cobertura="A - Daño Directo Equipo Electronico"; break;
                    case 27: nombre_cobertura="B - Robo"; break;
                    case 28: nombre_cobertura="E - Equipo Móvil Y/O Portátil"; break;
                    case 29: nombre_cobertura="C - Eventos De Las Naturaleza"; break;
                    case 30: nombre_cobertura="D - Otros Riesgos"; break;
                    case 31: nombre_cobertura="S - Portadores Externos De Datos"; break;
                    case 32: nombre_cobertura="R - Daño Directo Equipo Electronico"; break;
                    case 33: nombre_cobertura="E - Equipo Móvil Y/O Portátil"; break;
                    case 34: nombre_cobertura="S - Portadores Externos De Datos"; break;
                    case 35: nombre_cobertura="A - Responsabilidad Civil Por Lesion O Muerte A Terceros Excluyendo Pasajeros"; break;
                    case 36: nombre_cobertura="C - Responsabilidad Civil Por Daños A Propiedades De Terceros"; break;
                    case 37: nombre_cobertura="E - Basica"; break;
                    case 38: nombre_cobertura="F - Robo Y Hurto"; break;
                    case 39: nombre_cobertura="G - Riesgos Adicionales"; break;
                    case 40: nombre_cobertura="H - Extraterritorialidad"; break;
                    case 41: nombre_cobertura="Incendio, impacto de rayo, implosión y explosión"; break;
                    case 42: nombre_cobertura="Humo, hollín, gases, líquidos y polvos corrosivos"; break;
                    case 43: nombre_cobertura="Inundación, acción del agua y humedad"; break;
                    case 44: nombre_cobertura="Cortocircuito"; break;
                    case 45: nombre_cobertura="Errores de construcción"; break;
                    case 46: nombre_cobertura="Errores de manejo"; break;
                    case 47: nombre_cobertura="Robo con violencia"; break;
                    case 48: nombre_cobertura="Granizo, helada, tempestad"; break;
                    case 49: nombre_cobertura="Dolo de terceros, impericia"; break;
                    case 50: nombre_cobertura="Deslizamiento de tierra, Hundimiento del terreno y caído de rocas"; break;
                    case 51: nombre_cobertura="Riesgo de terremoto"; break;
                    case 52: nombre_cobertura="Riesgo de tifón, ciclón y huracán"; break;
                    case 53: nombre_cobertura="Riesgo por daños causados por huelga, motín y conmoción civil"; break;
                    case 54: nombre_cobertura="Riesgo de hurto"; break;
                    case 55: nombre_cobertura="Cobertura de equipos móviles portátiles fuera de su local asegurado"; break;
                    case 56: nombre_cobertura="Incremento en el costo de operación"; break;
                    case 57: nombre_cobertura="Portadores externos de datos"; break;
                    case 65: nombre_cobertura="TODO RIESGO"; break;
                      }
                      break;
                    case 'CERT':
                    //console.log('ingrese a certificado');
                    switch (String(json.data.coberturasXPoliza[i].coberturasXPolizaPK.codigoCobertura)) {
                      case "23": nombre_cobertura="DAÑO MALICIOSO ESPECIAL (Terrorismo)"; break;
                    case "24": nombre_cobertura="BARREDURA"; break;
                    case "25": nombre_cobertura="MOTIN, HUELGA, CONMOCION CIVIL, DAÑOS MALICIOSOS Y ACTOS DE TERRORISMO"; break;
                    case "16": nombre_cobertura="ATRACO"; break;
                    case "26": nombre_cobertura="OTRAS COBERTURAS"; break;
                    case "27": nombre_cobertura="MOTIN, HUELGA, CONMOCION CIVIL Y DAÑOS MALICIOSOS"; break;
                    case "22": nombre_cobertura="AVERIA GRUESA"; break;
                    case "17": nombre_cobertura="ROBO Y/O ATRACO"; break;
                    case "18": nombre_cobertura="FALTA DE ENTREGA"; break;
                    case "19": nombre_cobertura="CARGA Y DESCARGA"; break;
                    case "34": nombre_cobertura="TODO RIESGO 1.1.63  (A)"; break;
                    case "20": nombre_cobertura="TODO RIESGO"; break;
                    case "21": nombre_cobertura="INTERRUPCION DE NEGOCIOS POR DAÑOS A MAQUINARIA"; break;
                    case "31": nombre_cobertura="TODO RIESGO (INCISO C)"; break;
                    case "28": nombre_cobertura="BODEGA A BODEGA"; break;
                    case "32": nombre_cobertura="ESTADIA"; break;
                    case "29": nombre_cobertura="RATERIA"; break;
                    case "30": nombre_cobertura="HUELGAS, ALBOROTOS POPULARES Y CONMOCION CIVIL PARA EMBARQUES TERRESTRES"; break;
                    case "13": nombre_cobertura="TODO RIESGO 1.1.82  (A)"; break;
                    case "11": nombre_cobertura="MOTIN, HUELGAS Y ALBOROTOS POPULARES"; break;
                    case "12": nombre_cobertura="GUERRA"; break;
                    case "10": nombre_cobertura="FALTA DE PESO O DERRAME"; break;
                    case "3": nombre_cobertura="ROBO DE BULTO POR ENTERO"; break;
                    case "4": nombre_cobertura="ROBO PARCIAL"; break;
                    case "14": nombre_cobertura="C.A.P.  1.1.82 (B)"; break;
                    case "15": nombre_cobertura="L.A.P.  1.1.82 (C)"; break;
                    case "1": nombre_cobertura="RIESGOS ORDINARIOS DE TRANSITO TRANSPORTE TERRESTRE Y/O AEREO"; break;
                    case "2": nombre_cobertura="RIESGOS ORDINARIOS DE TRANSITO TRANSPORTE MARITIMO"; break;
                    case "6": nombre_cobertura="OXIDACION"; break;
                    case "5": nombre_cobertura="MOJADURA"; break;
                    case "7": nombre_cobertura="CONTACTO CON OTRAS CARGAS"; break;
                    case "8": nombre_cobertura="MANCHAS"; break;
                    case "9": nombre_cobertura="ROTURAS"; break;
                    case "33": nombre_cobertura="ALMACENAMIENTO"; break;
                    case "48": nombre_cobertura="Cobertura de riesgos de cláusulas del instituto de londres, para cargamentos “a”"; break;
                    case "49": nombre_cobertura="Cobertura de riesgos de cláusulas del instituto de londres, para cargamentos “b”"; break;
                    case "50": nombre_cobertura="Huelga"; break;
                    case "51": nombre_cobertura="Guerra"; break;
                    case "39": nombre_cobertura="Vendaval, ciclón o tornado"; break;
                    case "40": nombre_cobertura="Derrumbe de puentes o alcantarillas"; break;
                    case "41": nombre_cobertura="Derrumbe de tierra, de piedras o de rocas"; break;
                    case "42": nombre_cobertura="Inundación por el desbordamiento de ríos o quebradas"; break;
                    case "43": nombre_cobertura="Saqueo"; break;
                    case "44": nombre_cobertura="Robo o Asalto"; break;
                    case "45": nombre_cobertura="Maniobras de Carga y Descarga; y Movimientos Bruscos"; break;
                    case "46": nombre_cobertura="Refrigeración"; break;
                    case "47": nombre_cobertura="Extraterritorialidad"; break;
                    case "35": nombre_cobertura="Incendio, rayo, auto-ignición y explosión"; break;
                    case "36": nombre_cobertura="Terremoto, temblor"; break;
                    case "37": nombre_cobertura="Colisión del vehículo con otro vehículo u objeto"; break;
                    case "38": nombre_cobertura="Vuelco del vehículo transportador de los bienes descritos"; break;
                    case "53": nombre_cobertura="TODO RIESGO"; break;
                    case "52": nombre_cobertura="COBERTURA BÁSICA H: Riesgos del Medio de Transporte"; break;
                    case "54": nombre_cobertura="1.1.09 A"; break;
                    case "55": nombre_cobertura="TRANSBORDO"; break;
                    case "56": nombre_cobertura="RECUPERACION"; break;
                    case "57": nombre_cobertura="DESTRUCCION"; break;
                    case "58": nombre_cobertura="DAÑOS INTERNOS A CONTENEDORES"; break;
                    }
                    break;
                    case "INCEN" :
                    //console.log('ingrese a INCEN');
                    switch (String(json.data.coberturasXPoliza[i].coberturasXPolizaPK.codigoCobertura)) {
                    case "10": nombre_cobertura="REMOCION DE ESCOMBROS"; break;
                    case "11": nombre_cobertura="GASTOS DE EXTINCIÓN DE INCENDIOS"; break;
                    case "12": nombre_cobertura="VALORES,  DOCUMENTOS"; break;
                    case "13": nombre_cobertura="HURACAN, TORMENTAS Y VIENTOS."; break;
                    case "108": nombre_cobertura="ARRIENDO DE LOCALES DE LOCALES PROVISORIOS"; break;
                    case "100": nombre_cobertura="I. TODO RIESGO CONDOMINIOS  PARA BIENES MUEBLES E INMUEBLES"; break;
                    case "110": nombre_cobertura="TRASLADOS TEMPORALES"; break;
                    case "111": nombre_cobertura="MONTAJE Y DESMONTAJE"; break;
                    case "112": nombre_cobertura="TRANSITO INCIDENTAL"; break;
                    case "113": nombre_cobertura="SERVICIO DE ASISTENCIA DOMICILARIA G&T"; break;
                    case "28": nombre_cobertura="DINERO Y/O VALORES FUERA DEL LOCAL"; break;
                    case "114": nombre_cobertura="EXPLOSION, MOTIN, HUELGAS Y/O ALBOROTOS POPULARES, DAÑO MALICIOSO"; break;
                    case "109": nombre_cobertura="DAÑOS POR AGUA"; break;
                    case "41": nombre_cobertura="RESPONSABILIDAD CIVIL LEGAL DE INCENDIO"; break;
                    case "44": nombre_cobertura="R.C. DE PRODUCTOS O TRABAJOS TERMINADOS"; break;
                    case "45": nombre_cobertura="INTERRUPCION DE NEGOCIOS PARA RIESGOS INDUSTRIALES"; break;
                    case "546": nombre_cobertura="INCENDIO Y/O RAYO"; break;
                    case "25": nombre_cobertura="ATRACO DE CONTENIDO"; break;
                    case "26": nombre_cobertura="CRISTALES Y/O ROTULOS"; break;
                    case "27": nombre_cobertura="DINERO Y/O VALORES DENTRO DEL LOCAL"; break;
                    case "29": nombre_cobertura="IMPACTO DE NAVES ACUATICAS"; break;
                    case "30": nombre_cobertura="OTRAS COBERTURAS"; break;
                    case "91": nombre_cobertura="INTERRUPCION RENTAS DE ALQUILER"; break;
                    case "48": nombre_cobertura="HONORARIOS PROFESIONALES"; break;
                    case "14": nombre_cobertura="DAÑO MALICIOSO ESPECIAL  (TERRORISMO)"; break;
                    case "15": nombre_cobertura="CORTO CIRCUITO"; break;
                    case "32": nombre_cobertura="FALLA ELECTRICA"; break;
                    case "34": nombre_cobertura="EXPLOSION DE CALDERAS"; break;
                    case "35": nombre_cobertura="TRANSPORTE TERRESTRE"; break;
                    case "36": nombre_cobertura="HURTO Y DESAPARICION MISTERIOSA"; break;
                    case "37": nombre_cobertura="MONTAJE Y DESMONTAJE"; break;
                    case "38": nombre_cobertura="ROTULOS LUMINOSOS"; break;
                    case "39": nombre_cobertura="RESPONSABILIDAD CIVIL FAMILIAR"; break;
                    case "17": nombre_cobertura="FRAUDE POR COMPUTADORAS DE TERCERAS PERSONAS Y EN TRANSFERENCIAS DE FONDOS"; break;
                    case "18": nombre_cobertura="FALSIFICACION DE TARJETAS DE CREDITO"; break;
                    case "19": nombre_cobertura="FALSIFICACION DE CHEQUES ENTRANTES"; break;
                    case "20": nombre_cobertura="FALSIFICACION DE DEPOSITANTES"; break;
                    case "21": nombre_cobertura="ACTOS DOLOSOS DE LOS TRABAJADORES"; break;
                    case "22": nombre_cobertura="PARAGUAS PARA ALMACENADORA"; break;
                    case "23": nombre_cobertura="DOLO O MALA FE PARA ALMACENADORA"; break;
                    case "24": nombre_cobertura="ROBO POR FORZAMIENTO DE LADRONES"; break;
                    case "7": nombre_cobertura="ROBO Y/O ATRACO"; break;
                    case "159": nombre_cobertura="RESPONSABILIDAD CIVIL"; break;
                    case "42": nombre_cobertura="AIG ASSIST"; break;
                    case "43": nombre_cobertura="RESPONSABILIDAD CIVIL DE GARAGE KEEPER"; break;
                    case "40": nombre_cobertura="RESPONSABILIDAD  CIVIL DE PREDIOS Y OPERACIONES"; break;
                    case "92": nombre_cobertura="ROTURA DE MAQUINARIA"; break;
                    case "127": nombre_cobertura="TERRORISMO (RJK)"; break;
                    case "128": nombre_cobertura="MAQUINARIA EN MOVIMIENTO"; break;
                    case "129": nombre_cobertura="TRANSPORTE INCIDENTAL DE BIENES"; break;
                    case "130": nombre_cobertura="HURTO Y/O PILLAJE POST SINIESTRO"; break;
                    case "131": nombre_cobertura="GASTOS DE LIMPIEZA Y DESCONTAMINACIÓN"; break;
                    case "132": nombre_cobertura="TODO RIESGO DE CONSTRUCCIÓN Y MONTAJE"; break;
                    case "148": nombre_cobertura="AMPARO AUTOMÁTICO DE NUEVAS PROPIEDADES"; break;
                    case "149": nombre_cobertura="DAÑO ELÉCTRICO"; break;
                    case "158": nombre_cobertura="RESPONSABILIDAD CIVIL DE CONDOMINIOS"; break;
                    case "176": nombre_cobertura="RESPONSABILIDAD CIVIL INCENDIO Y/O EXPLOSION"; break;
                    case "150": nombre_cobertura="Transporte"; break;
                    case "152": nombre_cobertura="AMPARO PROPIEDAD PERSONAL EMPLEADOS"; break;
                    case "153": nombre_cobertura="ALTERACIONES Y REPARACIONES"; break;
                    case "154": nombre_cobertura="ASISTENCIA DOMICILIAR"; break;
                    case "155": nombre_cobertura="EQUIPO ELECTRONICO"; break;
                    case "156": nombre_cobertura="MATERIA PRIMA Y PRODUCTO TERMINADO"; break;
                    case "117": nombre_cobertura="INUNDACION Y/O MAREMOTO"; break;
                    case "119": nombre_cobertura="GASTOS DE CERRAJERIA"; break;
                    case "123": nombre_cobertura="COBERTURA DE EQUIPO DE PROCESAMIENTO DE DATOS"; break;
                    case "120": nombre_cobertura="COBERTURA DE ACCIDENTES PERSONALES"; break;
                    case "121": nombre_cobertura="GASTOS EXTRAORDINARIOS"; break;
                    case "133": nombre_cobertura="INTERRUPCIÓN DE FLUIDO ELECTRICO FUERA DE LAS INSTALACIONES"; break;
                    case "101": nombre_cobertura="II. RESPONSABILIDAD CIVIL CONDOMINIOS"; break;
                    case "102": nombre_cobertura=" R. C. PARA AREAS DE ESTACIONAMIENTOS Y PARQUEOS"; break;
                    case "103": nombre_cobertura="IV.  CUOTAS DE ADMINISTRACION"; break;
                    case "104": nombre_cobertura="1.  ROTURA DE MAQUINARIA"; break;
                    case "157": nombre_cobertura="ROBO POR FORZAMIENTO DE LADRONES y/o ROBO AGRAVADO"; break;
                    case "106": nombre_cobertura=" AMPARO AUTOMATICO DE GASTOS ADICIONALES"; break;
                    case "107": nombre_cobertura="EXTENSIONES AUTOMATICAS"; break;
                    case "16": nombre_cobertura="DEMOLICION"; break;
                    case "115": nombre_cobertura="PILLAJE POST-SINIESTRO"; break;
                    case "122": nombre_cobertura="COBERTURA DE TARJETA DE CREDITO"; break;
                    case "118": nombre_cobertura="TODO RIESGO MULTICOBERTURAS"; break;
                    case "124": nombre_cobertura="MOTIN Y/O HUELGAS Y/O CONMOCION CIVIL Y/O DAÑO MALICIOSO"; break;
                    case "125": nombre_cobertura="MOTIN Y/O HUELGAS Y/O CONMOCION CIVIL Y/O DAÑO MALICIOSO Y/O EXPLOSION"; break;
                    case "126": nombre_cobertura="TERREMOTO, TEMBLOR Y/O ERUPCION VOLCANICA, CAIDA DE CENIZA Y/O ARENA ..."; break;
                    case "6": nombre_cobertura="DAÑO MALICIOSO ESPECIAL"; break;
                    case "5": nombre_cobertura="TERREMOTO, TEMBLOR Y/O ERUPCION VOLCANICA"; break;
                    case "2": nombre_cobertura="TODO RIESGO  (2)"; break;
                    case "3": nombre_cobertura="TODO RIESGO  (3)"; break;
                    case "1": nombre_cobertura="TODO RIESGO  DE INCENDIO"; break;
                    case "4": nombre_cobertura="MOTIN  y HUELGAS  Y ALBOROTOS POPULARES"; break;
                    case "9": nombre_cobertura="DINERO Y/O VALORES"; break;
                    case "178": nombre_cobertura="TRANSPORTE INCIDENTAL"; break;
                    case "179": nombre_cobertura="INT. DE NEGOCIOS COMERCIAL E INT. DE RENTAS DE ALQUILER"; break;
                    case "180": nombre_cobertura="CUOTAS DE ADMINISTRACION"; break;
                    case "181": nombre_cobertura="WALLET GUARD"; break;
                    case "182": nombre_cobertura="ACCIDENTES PERSONALES"; break;
                    case "177": nombre_cobertura="REPARACIONES PROVISORIAS"; break;
                    case "135": nombre_cobertura="DAÑO ELECTRICO"; break;
                    case "136": nombre_cobertura="CAR/EAR"; break;
                    case "137": nombre_cobertura="ROTURA DE MAQUINARIA Y/O EXPLOSION DE CALDERAS"; break;
                    case "138": nombre_cobertura="INT. DE NEGOCIOS RIESGOS INDUSTRIALES INTERDEPENDIENTE"; break;
                    case "141": nombre_cobertura="RESPONSABILIDAD CIVIL PATRONAL"; break;
                    case "142": nombre_cobertura="RESPONSABILIDAD CIVIL DE ELEVADORES Y/O GRADAS ELECTRICAS"; break;
                    case "143": nombre_cobertura="GASTOS MÉDICOS VOLUNTARIOS"; break;
                    case "144": nombre_cobertura="CUENTAS POR COBRAR"; break;
                    case "145": nombre_cobertura="CAJAS FUERTES"; break;
                    case "46": nombre_cobertura="INTERRUPCIÓN DE NEGOCIOS PARA RIESGOS COMERCIALES"; break;
                    case "147": nombre_cobertura="GASTOS EXTRAS"; break;
                    case "151": nombre_cobertura="INTERRUPCIÓN DE NEGOCIOS RIESGOS INDUSTRIALES Y COMERCIALES"; break;
                    case "160": nombre_cobertura="RESPONSABILIDAD CIVIL DE TRANSPORTISTAS"; break;
                    case "161": nombre_cobertura="RESPONSABILIDAD CIVIL DE POLUCION O CONTAMINACION"; break;
                    case "162": nombre_cobertura="RESPONSABILIDAD CIVIL GENERAL"; break;
                    case "134": nombre_cobertura="EXTENSION DE COBERTURA A PROVEEDORES Y CLIENTES"; break;
                    case "139": nombre_cobertura="PÉRDIDAS CONSECUENCIALES"; break;
                    case "140": nombre_cobertura="Gastos Adicionales y/o Extras"; break;
                    case "163": nombre_cobertura="GASTOS DE APRESURAMIENTO"; break;
                    case "164": nombre_cobertura="CONTAMINACION DE AMONIACO"; break;
                    case "165": nombre_cobertura="DAÑOS POR AGUA"; break;
                    case "166": nombre_cobertura="RECONSTRUCCION DE DOCUMENTOS"; break;
                    case "167": nombre_cobertura="ROTURA DE CALDERAS Y MAQUINARIA"; break;
                    case "168": nombre_cobertura="AMPARO AUTOMATICO DE GASTOS ADICIONALES DERIVADOS DE SINIESTRO"; break;
                    case "169": nombre_cobertura="ALTERACIONES, ADICIONES Y MEJORAS"; break;
                    case "170": nombre_cobertura="ALQUILER DE PLANTA DE GENERACION DE ENERGIA ELECTRICA"; break;
                    case "171": nombre_cobertura="RESPONSABILIDAD CIVIL CONTRACTURAL"; break;
                    case "172": nombre_cobertura="RESPONSABILIDAD CIVIL CRUZADA"; break;
                    case "173": nombre_cobertura="RESPONSABILIDAD CIVIL CONTRATISTAS Y SUBCONTRATISTAS INDEPENDIENTES"; break;
                    case "174": nombre_cobertura="RESPONSABILIDAD CIVIL PARA PARQUEOS Y ESTACIONAMIENTOS"; break;
                    case "175": nombre_cobertura="DINERO Y CHEQUES EN LA OFICINA ADMINISTRATIVA"; break;
                    case "547": nombre_cobertura="VEHICULOS CON MOTORES APAGADOS"; break;
                    case "607": nombre_cobertura="REMOCION DE ESCOMBROS"; break;
                    case "608": nombre_cobertura="GASTOS DE EXTINCIÓN DE INCENDIOS"; break;
                    case "609": nombre_cobertura="VALORES,  DOCUMENTOS"; break;
                    case "610": nombre_cobertura="HURACAN, TORMENTAS Y VIENTOS."; break;
                    case "611": nombre_cobertura="ARRIENDO DE LOCALES DE LOCALES PROVISORIOS"; break;
                    case "612": nombre_cobertura="I. TODO RIESGO CONDOMINIOS  PARA BIENES MUEBLES E INMUEBLES"; break;
                    case "613": nombre_cobertura="TRASLADOS TEMPORALES"; break;
                    case "614": nombre_cobertura="MONTAJE Y DESMONTAJE"; break;
                    case "615": nombre_cobertura="TRANSITO INCIDENTAL"; break;
                    case "616": nombre_cobertura="SERVICIO DE ASISTENCIA DOMICILARIA G&T"; break;
                    case "617": nombre_cobertura="DINERO Y/O VALORES FUERA DEL LOCAL"; break;
                    case "618": nombre_cobertura="EXPLOSION, MOTIN, HUELGAS Y/O ALBOROTOS POPULARES, DAÑO MALICIOSO"; break;
                    case "286": nombre_cobertura="T Riesgos No Catastróficos"; break;
                    case "287": nombre_cobertura="U Riesgos Catastróficos"; break;
                    case "288": nombre_cobertura="A Incendio Casual y Rayo"; break;
                    case "289": nombre_cobertura="B Riesgos Varios"; break;
                    case "290": nombre_cobertura="C Inundación, Deslizamiento Vientos Inundación"; break;
                    case "291": nombre_cobertura="D Convulsiones de la Naturaleza"; break;
                    case "292": nombre_cobertura="E Daño Directo a la Mercancía"; break;
                    case "293": nombre_cobertura="F Pérdida de Beneficios"; break;
                    case "294": nombre_cobertura="G Lluvia y Derrame "; break;
                    case "295": nombre_cobertura="H Pérdida de Rentas por contrato de arrendamiento"; break;
                    case "296": nombre_cobertura="I Rotura de Cristales"; break;
                    case "297": nombre_cobertura="Q Gastos Extra"; break;
                    case "298": nombre_cobertura="A Incendio Casual y Rayo"; break;
                    case "299": nombre_cobertura="B Riesgos Varios"; break;
                    case "300": nombre_cobertura="C Inundación , Deslizamiento y Vientos"; break;
                    case "301": nombre_cobertura="D Convulsiones de la Naturaleza"; break;
                    case "302": nombre_cobertura="H Pérdida de Rentas por contrato de arrendamiento"; break;
                    case "303": nombre_cobertura="I Rotura de Cristales"; break;
                    case "304": nombre_cobertura="R Gastos por Alquiler"; break;
                    case "305": nombre_cobertura="V Daño directo de bienes inmuebles"; break;
                    case "306": nombre_cobertura="Y Daño directo de contenidos (opción 1,2 y 3)"; break;
                    case "307": nombre_cobertura="D Convulsiones de la naturaleza"; break;
                    case "308": nombre_cobertura="H Pérdida de rentas por contrato de arrendamiento"; break;
                    case "309": nombre_cobertura="K Responsabilidad Civil"; break;
                    case "310": nombre_cobertura="M Riesgos del Trabajo Servidores Domesticos"; break;
                    case "311": nombre_cobertura="P Accidentes Personales Daños Materiales"; break;
                    case "312": nombre_cobertura="Incendio"; break;
                    case "313": nombre_cobertura="Agua al sofocar un incendio "; break;
                    case "314": nombre_cobertura="Inundación , Deslizamiento y Vientos"; break;
                    case "315": nombre_cobertura="Impacto de rayo."; break;
                    case "316": nombre_cobertura="Humo u hollín proveniente de un incendio"; break;
                    case "317": nombre_cobertura="Implosión y/o Explosión de cualquier tipo"; break;
                    case "318": nombre_cobertura="Impacto de vehículos terrestres o aéreos u objetos caídos del cielo"; break;
                    case "319": nombre_cobertura="Caída objetos contra la propiedad asegurada."; break;
                    case "320": nombre_cobertura="Incendio y Daño Directo por Terremoto"; break;
                    case "321": nombre_cobertura="Daño Directo por Vendaval, huracán, tornado, tromba o granizo"; break;
                    case "322": nombre_cobertura="Daño Directo por Inundación"; break;
                    case "323": nombre_cobertura="Extensión de Cobertura Catastrófica"; break;
                    case "324": nombre_cobertura="Incendio y daño por desórdenes públicos."; break;
                    case "325": nombre_cobertura="Daño Directo por Maldad"; break;
                    case "326": nombre_cobertura="Saqueo"; break;
                    case "327": nombre_cobertura="Pérdida consiguiente por falta de refrigeración"; break;
                    case "328": nombre_cobertura="Pérdida de Renta"; break;
                    case "329": nombre_cobertura="Lucro Cesante (Pérdida de Beneficios)"; break;
                    case "330": nombre_cobertura="A Incendio Casual y Rayo"; break;
                    case "331": nombre_cobertura="B Riesgos de Naturaleza Catastrófica Inundación"; break;
                    case "332": nombre_cobertura="C Daños por Agua y Riesgos Atmosféricos"; break;
                    case "333": nombre_cobertura="D Rotura de Cristales, Domos, Mármoles y Granitos."; break;
                    case "334": nombre_cobertura="E Robo"; break;
                    case "335": nombre_cobertura="F Gastos por Alquiler"; break;
                    case "336": nombre_cobertura="G Pérdida de Rentas  "; break;
                    case "337": nombre_cobertura="I Bienes Refrigerados o Congelados"; break;
                    case "338": nombre_cobertura="J Mercancía en Tránsito"; break;
                    case "339": nombre_cobertura="L Rotura de Maquinaria"; break;
                    case "340": nombre_cobertura="M Equipo Electrónico"; break;
                    case "341": nombre_cobertura="P Interrupción de Negocios"; break;
                    case "342": nombre_cobertura="Q Multiasistencia"; break;
                    case "343": nombre_cobertura="H Responsabilidad Civil Extracontractual y Subjetiva"; break;
                    case "344": nombre_cobertura="Q Asistencia en la Vivienda"; break;
                    case "345": nombre_cobertura="Daños Materiales por Riesgos Catastróficos y No Catastróficos"; break;
                    }
                    break;
                    case "TDINCEN" :
                    //console.log('ingrese a TDINCEN');
                    switch (String(json.data.coberturasXPoliza[i].coberturasXPolizaPK.codigoCobertura)) {
                    case "10": nombre_cobertura="REMOCION DE ESCOMBROS"; break;
                    case "11": nombre_cobertura="GASTOS DE EXTINCIÓN DE INCENDIOS"; break;
                    case "12": nombre_cobertura="VALORES,  DOCUMENTOS"; break;
                    case "13": nombre_cobertura="HURACAN, TORMENTAS Y VIENTOS."; break;
                    case "108": nombre_cobertura="ARRIENDO DE LOCALES DE LOCALES PROVISORIOS"; break;
                    case "100": nombre_cobertura="I. TODO RIESGO CONDOMINIOS  PARA BIENES MUEBLES E INMUEBLES"; break;
                    case "110": nombre_cobertura="TRASLADOS TEMPORALES"; break;
                    case "111": nombre_cobertura="MONTAJE Y DESMONTAJE"; break;
                    case "112": nombre_cobertura="TRANSITO INCIDENTAL"; break;
                    case "113": nombre_cobertura="SERVICIO DE ASISTENCIA DOMICILARIA G&T"; break;
                    case "28": nombre_cobertura="DINERO Y/O VALORES FUERA DEL LOCAL"; break;
                    case "114": nombre_cobertura="EXPLOSION, MOTIN, HUELGAS Y/O ALBOROTOS POPULARES, DAÑO MALICIOSO"; break;
                    case "109": nombre_cobertura="DAÑOS POR AGUA"; break;
                    case "41": nombre_cobertura="RESPONSABILIDAD CIVIL LEGAL DE INCENDIO"; break;
                    case "44": nombre_cobertura="R.C. DE PRODUCTOS O TRABAJOS TERMINADOS"; break;
                    case "45": nombre_cobertura="INTERRUPCION DE NEGOCIOS PARA RIESGOS INDUSTRIALES"; break;
                    case "546": nombre_cobertura="INCENDIO Y/O RAYO"; break;
                    case "25": nombre_cobertura="ATRACO DE CONTENIDO"; break;
                    case "26": nombre_cobertura="CRISTALES Y/O ROTULOS"; break;
                    case "27": nombre_cobertura="DINERO Y/O VALORES DENTRO DEL LOCAL"; break;
                    case "29": nombre_cobertura="IMPACTO DE NAVES ACUATICAS"; break;
                    case "30": nombre_cobertura="OTRAS COBERTURAS"; break;
                    case "91": nombre_cobertura="INTERRUPCION RENTAS DE ALQUILER"; break;
                    case "48": nombre_cobertura="HONORARIOS PROFESIONALES"; break;
                    case "14": nombre_cobertura="DAÑO MALICIOSO ESPECIAL  (TERRORISMO)"; break;
                    case "15": nombre_cobertura="CORTO CIRCUITO"; break;
                    case "32": nombre_cobertura="FALLA ELECTRICA"; break;
                    case "34": nombre_cobertura="EXPLOSION DE CALDERAS"; break;
                    case "35": nombre_cobertura="TRANSPORTE TERRESTRE"; break;
                    case "36": nombre_cobertura="HURTO Y DESAPARICION MISTERIOSA"; break;
                    case "37": nombre_cobertura="MONTAJE Y DESMONTAJE"; break;
                    case "38": nombre_cobertura="ROTULOS LUMINOSOS"; break;
                    case "39": nombre_cobertura="RESPONSABILIDAD CIVIL FAMILIAR"; break;
                    case "17": nombre_cobertura="FRAUDE POR COMPUTADORAS DE TERCERAS PERSONAS Y EN TRANSFERENCIAS DE FONDOS"; break;
                    case "18": nombre_cobertura="FALSIFICACION DE TARJETAS DE CREDITO"; break;
                    case "19": nombre_cobertura="FALSIFICACION DE CHEQUES ENTRANTES"; break;
                    case "20": nombre_cobertura="FALSIFICACION DE DEPOSITANTES"; break;
                    case "21": nombre_cobertura="ACTOS DOLOSOS DE LOS TRABAJADORES"; break;
                    case "22": nombre_cobertura="PARAGUAS PARA ALMACENADORA"; break;
                    case "23": nombre_cobertura="DOLO O MALA FE PARA ALMACENADORA"; break;
                    case "24": nombre_cobertura="ROBO POR FORZAMIENTO DE LADRONES"; break;
                    case "7": nombre_cobertura="ROBO Y/O ATRACO"; break;
                    case "159": nombre_cobertura="RESPONSABILIDAD CIVIL"; break;
                    case "42": nombre_cobertura="AIG ASSIST"; break;
                    case "43": nombre_cobertura="RESPONSABILIDAD CIVIL DE GARAGE KEEPER"; break;
                    case "40": nombre_cobertura="RESPONSABILIDAD  CIVIL DE PREDIOS Y OPERACIONES"; break;
                    case "92": nombre_cobertura="ROTURA DE MAQUINARIA"; break;
                    case "127": nombre_cobertura="TERRORISMO (RJK)"; break;
                    case "128": nombre_cobertura="MAQUINARIA EN MOVIMIENTO"; break;
                    case "129": nombre_cobertura="TRANSPORTE INCIDENTAL DE BIENES"; break;
                    case "130": nombre_cobertura="HURTO Y/O PILLAJE POST SINIESTRO"; break;
                    case "131": nombre_cobertura="GASTOS DE LIMPIEZA Y DESCONTAMINACIÓN"; break;
                    case "132": nombre_cobertura="TODO RIESGO DE CONSTRUCCIÓN Y MONTAJE"; break;
                    case "148": nombre_cobertura="AMPARO AUTOMÁTICO DE NUEVAS PROPIEDADES"; break;
                    case "149": nombre_cobertura="DAÑO ELÉCTRICO"; break;
                    case "158": nombre_cobertura="RESPONSABILIDAD CIVIL DE CONDOMINIOS"; break;
                    case "176": nombre_cobertura="RESPONSABILIDAD CIVIL INCENDIO Y/O EXPLOSION"; break;
                    case "150": nombre_cobertura="Transporte"; break;
                    case "152": nombre_cobertura="AMPARO PROPIEDAD PERSONAL EMPLEADOS"; break;
                    case "153": nombre_cobertura="ALTERACIONES Y REPARACIONES"; break;
                    case "154": nombre_cobertura="ASISTENCIA DOMICILIAR"; break;
                    case "155": nombre_cobertura="EQUIPO ELECTRONICO"; break;
                    case "156": nombre_cobertura="MATERIA PRIMA Y PRODUCTO TERMINADO"; break;
                    case "117": nombre_cobertura="INUNDACION Y/O MAREMOTO"; break;
                    case "119": nombre_cobertura="GASTOS DE CERRAJERIA"; break;
                    case "123": nombre_cobertura="COBERTURA DE EQUIPO DE PROCESAMIENTO DE DATOS"; break;
                    case "120": nombre_cobertura="COBERTURA DE ACCIDENTES PERSONALES"; break;
                    case "121": nombre_cobertura="GASTOS EXTRAORDINARIOS"; break;
                    case "133": nombre_cobertura="INTERRUPCIÓN DE FLUIDO ELECTRICO FUERA DE LAS INSTALACIONES"; break;
                    case "101": nombre_cobertura="II. RESPONSABILIDAD CIVIL CONDOMINIOS"; break;
                    case "102": nombre_cobertura=" R. C. PARA AREAS DE ESTACIONAMIENTOS Y PARQUEOS"; break;
                    case "103": nombre_cobertura="IV.  CUOTAS DE ADMINISTRACION"; break;
                    case "104": nombre_cobertura="1.  ROTURA DE MAQUINARIA"; break;
                    case "157": nombre_cobertura="ROBO POR FORZAMIENTO DE LADRONES y/o ROBO AGRAVADO"; break;
                    case "106": nombre_cobertura=" AMPARO AUTOMATICO DE GASTOS ADICIONALES"; break;
                    case "107": nombre_cobertura="EXTENSIONES AUTOMATICAS"; break;
                    case "16": nombre_cobertura="DEMOLICION"; break;
                    case "115": nombre_cobertura="PILLAJE POST-SINIESTRO"; break;
                    case "122": nombre_cobertura="COBERTURA DE TARJETA DE CREDITO"; break;
                    case "118": nombre_cobertura="TODO RIESGO MULTICOBERTURAS"; break;
                    case "124": nombre_cobertura="MOTIN Y/O HUELGAS Y/O CONMOCION CIVIL Y/O DAÑO MALICIOSO"; break;
                    case "125": nombre_cobertura="MOTIN Y/O HUELGAS Y/O CONMOCION CIVIL Y/O DAÑO MALICIOSO Y/O EXPLOSION"; break;
                    case "126": nombre_cobertura="TERREMOTO, TEMBLOR Y/O ERUPCION VOLCANICA, CAIDA DE CENIZA Y/O ARENA ..."; break;
                    case "6": nombre_cobertura="DAÑO MALICIOSO ESPECIAL"; break;
                    case "5": nombre_cobertura="TERREMOTO, TEMBLOR Y/O ERUPCION VOLCANICA"; break;
                    case "2": nombre_cobertura="TODO RIESGO  (2)"; break;
                    case "3": nombre_cobertura="TODO RIESGO  (3)"; break;
                    case "1": nombre_cobertura="TODO RIESGO  DE INCENDIO"; break;
                    case "4": nombre_cobertura="MOTIN  y HUELGAS  Y ALBOROTOS POPULARES"; break;
                    case "9": nombre_cobertura="DINERO Y/O VALORES"; break;
                    case "178": nombre_cobertura="TRANSPORTE INCIDENTAL"; break;
                    case "179": nombre_cobertura="INT. DE NEGOCIOS COMERCIAL E INT. DE RENTAS DE ALQUILER"; break;
                    case "180": nombre_cobertura="CUOTAS DE ADMINISTRACION"; break;
                    case "181": nombre_cobertura="WALLET GUARD"; break;
                    case "182": nombre_cobertura="ACCIDENTES PERSONALES"; break;
                    case "177": nombre_cobertura="REPARACIONES PROVISORIAS"; break;
                    case "135": nombre_cobertura="DAÑO ELECTRICO"; break;
                    case "136": nombre_cobertura="CAR/EAR"; break;
                    case "137": nombre_cobertura="ROTURA DE MAQUINARIA Y/O EXPLOSION DE CALDERAS"; break;
                    case "138": nombre_cobertura="INT. DE NEGOCIOS RIESGOS INDUSTRIALES INTERDEPENDIENTE"; break;
                    case "141": nombre_cobertura="RESPONSABILIDAD CIVIL PATRONAL"; break;
                    case "142": nombre_cobertura="RESPONSABILIDAD CIVIL DE ELEVADORES Y/O GRADAS ELECTRICAS"; break;
                    case "143": nombre_cobertura="GASTOS MÉDICOS VOLUNTARIOS"; break;
                    case "144": nombre_cobertura="CUENTAS POR COBRAR"; break;
                    case "145": nombre_cobertura="CAJAS FUERTES"; break;
                    case "46": nombre_cobertura="INTERRUPCIÓN DE NEGOCIOS PARA RIESGOS COMERCIALES"; break;
                    case "147": nombre_cobertura="GASTOS EXTRAS"; break;
                    case "151": nombre_cobertura="INTERRUPCIÓN DE NEGOCIOS RIESGOS INDUSTRIALES Y COMERCIALES"; break;
                    case "160": nombre_cobertura="RESPONSABILIDAD CIVIL DE TRANSPORTISTAS"; break;
                    case "161": nombre_cobertura="RESPONSABILIDAD CIVIL DE POLUCION O CONTAMINACION"; break;
                    case "162": nombre_cobertura="RESPONSABILIDAD CIVIL GENERAL"; break;
                    case "134": nombre_cobertura="EXTENSION DE COBERTURA A PROVEEDORES Y CLIENTES"; break;
                    case "139": nombre_cobertura="PÉRDIDAS CONSECUENCIALES"; break;
                    case "140": nombre_cobertura="Gastos Adicionales y/o Extras"; break;
                    case "163": nombre_cobertura="GASTOS DE APRESURAMIENTO"; break;
                    case "164": nombre_cobertura="CONTAMINACION DE AMONIACO"; break;
                    case "165": nombre_cobertura="DAÑOS POR AGUA"; break;
                    case "166": nombre_cobertura="RECONSTRUCCION DE DOCUMENTOS"; break;
                    case "167": nombre_cobertura="ROTURA DE CALDERAS Y MAQUINARIA"; break;
                    case "168": nombre_cobertura="AMPARO AUTOMATICO DE GASTOS ADICIONALES DERIVADOS DE SINIESTRO"; break;
                    case "169": nombre_cobertura="ALTERACIONES, ADICIONES Y MEJORAS"; break;
                    case "170": nombre_cobertura="ALQUILER DE PLANTA DE GENERACION DE ENERGIA ELECTRICA"; break;
                    case "171": nombre_cobertura="RESPONSABILIDAD CIVIL CONTRACTURAL"; break;
                    case "172": nombre_cobertura="RESPONSABILIDAD CIVIL CRUZADA"; break;
                    case "173": nombre_cobertura="RESPONSABILIDAD CIVIL CONTRATISTAS Y SUBCONTRATISTAS INDEPENDIENTES"; break;
                    case "174": nombre_cobertura="RESPONSABILIDAD CIVIL PARA PARQUEOS Y ESTACIONAMIENTOS"; break;
                    case "175": nombre_cobertura="DINERO Y CHEQUES EN LA OFICINA ADMINISTRATIVA"; break;
                    case "547": nombre_cobertura="VEHICULOS CON MOTORES APAGADOS"; break;
                    case "607": nombre_cobertura="REMOCION DE ESCOMBROS"; break;
                    case "608": nombre_cobertura="GASTOS DE EXTINCIÓN DE INCENDIOS"; break;
                    case "609": nombre_cobertura="VALORES,  DOCUMENTOS"; break;
                    case "610": nombre_cobertura="HURACAN, TORMENTAS Y VIENTOS."; break;
                    case "611": nombre_cobertura="ARRIENDO DE LOCALES DE LOCALES PROVISORIOS"; break;
                    case "612": nombre_cobertura="I. TODO RIESGO CONDOMINIOS  PARA BIENES MUEBLES E INMUEBLES"; break;
                    case "613": nombre_cobertura="TRASLADOS TEMPORALES"; break;
                    case "614": nombre_cobertura="MONTAJE Y DESMONTAJE"; break;
                    case "615": nombre_cobertura="TRANSITO INCIDENTAL"; break;
                    case "616": nombre_cobertura="SERVICIO DE ASISTENCIA DOMICILARIA G&T"; break;
                    case "617": nombre_cobertura="DINERO Y/O VALORES FUERA DEL LOCAL"; break;
                    case "618": nombre_cobertura="EXPLOSION, MOTIN, HUELGAS Y/O ALBOROTOS POPULARES, DAÑO MALICIOSO"; break;
                    case "286": nombre_cobertura="T Riesgos No Catastróficos"; break;
                    case "287": nombre_cobertura="U Riesgos Catastróficos"; break;
                    case "288": nombre_cobertura="A Incendio Casual y Rayo"; break;
                    case "289": nombre_cobertura="B Riesgos Varios"; break;
                    case "290": nombre_cobertura="C Inundación, Deslizamiento Vientos Inundación"; break;
                    case "291": nombre_cobertura="D Convulsiones de la Naturaleza"; break;
                    case "292": nombre_cobertura="E Daño Directo a la Mercancía"; break;
                    case "293": nombre_cobertura="F Pérdida de Beneficios"; break;
                    case "294": nombre_cobertura="G Lluvia y Derrame "; break;
                    case "295": nombre_cobertura="H Pérdida de Rentas por contrato de arrendamiento"; break;
                    case "296": nombre_cobertura="I Rotura de Cristales"; break;
                    case "297": nombre_cobertura="Q Gastos Extra"; break;
                    case "298": nombre_cobertura="A Incendio Casual y Rayo"; break;
                    case "299": nombre_cobertura="B Riesgos Varios"; break;
                    case "300": nombre_cobertura="C Inundación , Deslizamiento y Vientos"; break;
                    case "301": nombre_cobertura="D Convulsiones de la Naturaleza"; break;
                    case "302": nombre_cobertura="H Pérdida de Rentas por contrato de arrendamiento"; break;
                    case "303": nombre_cobertura="I Rotura de Cristales"; break;
                    case "304": nombre_cobertura="R Gastos por Alquiler"; break;
                    case "305": nombre_cobertura="V Daño directo de bienes inmuebles"; break;
                    case "306": nombre_cobertura="Y Daño directo de contenidos (opción 1,2 y 3)"; break;
                    case "307": nombre_cobertura="D Convulsiones de la naturaleza"; break;
                    case "308": nombre_cobertura="H Pérdida de rentas por contrato de arrendamiento"; break;
                    case "309": nombre_cobertura="K Responsabilidad Civil"; break;
                    case "310": nombre_cobertura="M Riesgos del Trabajo Servidores Domesticos"; break;
                    case "311": nombre_cobertura="P Accidentes Personales Daños Materiales"; break;
                    case "312": nombre_cobertura="Incendio"; break;
                    case "313": nombre_cobertura="Agua al sofocar un incendio "; break;
                    case "314": nombre_cobertura="Inundación , Deslizamiento y Vientos"; break;
                    case "315": nombre_cobertura="Impacto de rayo."; break;
                    case "316": nombre_cobertura="Humo u hollín proveniente de un incendio"; break;
                    case "317": nombre_cobertura="Implosión y/o Explosión de cualquier tipo"; break;
                    case "318": nombre_cobertura="Impacto de vehículos terrestres o aéreos u objetos caídos del cielo"; break;
                    case "319": nombre_cobertura="Caída objetos contra la propiedad asegurada."; break;
                    case "320": nombre_cobertura="Incendio y Daño Directo por Terremoto"; break;
                    case "321": nombre_cobertura="Daño Directo por Vendaval, huracán, tornado, tromba o granizo"; break;
                    case "322": nombre_cobertura="Daño Directo por Inundación"; break;
                    case "323": nombre_cobertura="Extensión de Cobertura Catastrófica"; break;
                    case "324": nombre_cobertura="Incendio y daño por desórdenes públicos."; break;
                    case "325": nombre_cobertura="Daño Directo por Maldad"; break;
                    case "326": nombre_cobertura="Saqueo"; break;
                    case "327": nombre_cobertura="Pérdida consiguiente por falta de refrigeración"; break;
                    case "328": nombre_cobertura="Pérdida de Renta"; break;
                    case "329": nombre_cobertura="Lucro Cesante (Pérdida de Beneficios)"; break;
                    case "330": nombre_cobertura="A Incendio Casual y Rayo"; break;
                    case "331": nombre_cobertura="B Riesgos de Naturaleza Catastrófica Inundación"; break;
                    case "332": nombre_cobertura="C Daños por Agua y Riesgos Atmosféricos"; break;
                    case "333": nombre_cobertura="D Rotura de Cristales, Domos, Mármoles y Granitos."; break;
                    case "334": nombre_cobertura="E Robo"; break;
                    case "335": nombre_cobertura="F Gastos por Alquiler"; break;
                    case "336": nombre_cobertura="G Pérdida de Rentas  "; break;
                    case "337": nombre_cobertura="I Bienes Refrigerados o Congelados"; break;
                    case "338": nombre_cobertura="J Mercancía en Tránsito"; break;
                    case "339": nombre_cobertura="L Rotura de Maquinaria"; break;
                    case "340": nombre_cobertura="M Equipo Electrónico"; break;
                    case "341": nombre_cobertura="P Interrupción de Negocios"; break;
                    case "342": nombre_cobertura="Q Multiasistencia"; break;
                    case "343": nombre_cobertura="H Responsabilidad Civil Extracontractual y Subjetiva"; break;
                    case "344": nombre_cobertura="Q Asistencia en la Vivienda"; break;
                    case "345": nombre_cobertura="Daños Materiales por Riesgos Catastróficos y No Catastróficos"; break;
                    }  break;
                    case 'RC':
                    //console.log('ingrese a RC');
                    switch (String(json.data.coberturasXPoliza[i].coberturasXPolizaPK.codigoCobertura)) {
                      case "28": nombre_cobertura="RESPONSABILIDAD RESERVA DE DOMINIO"; break;
                      case "46": nombre_cobertura="R.C. PARA VUELOS CHARTER"; break;
                      case "29": nombre_cobertura="RESPONSABILIDAD LEGAL POR DAÑOS POR INCENDIO"; break;
                      case "47": nombre_cobertura="RESPONSABILIDAD LEGAL POR DAÑOS POR INCENDIO"; break;
                      case "48": nombre_cobertura="OTRAS COBERTURAS"; break;
                      case "49": nombre_cobertura="DEMOLICION"; break;
                      case "44": nombre_cobertura="RESPONSABILIDAD CIVIL GASOLINERAS"; break;
                      case "41": nombre_cobertura="RESPONSABILIDAD CIVIL BAJO LEYES DE BEBIDAS ALCOHOLICAS"; break;
                      case "42": nombre_cobertura="RESPONSABILIDAD CIVIL POR EMBARCACIONES QUE NO SON PROPIEDAD DEL ASEGURADO"; break;
                      case "29": nombre_cobertura="RESPONSABILIDAD CIVIL POR CONTAMINACION DEL MEDIO AMBIENTE"; break;
                      case "30": nombre_cobertura="R.C. MAQUINAS AGRICOLAS"; break;
                      case "31": nombre_cobertura="USO O TENENCIA DE COMBUSTIBLES"; break;
                      case "32": nombre_cobertura="TRANSPORTE DE COMBUSTIBLES"; break;
                      case "33": nombre_cobertura="RESPONSABILIDAD CIVIL CONTAMINACIÓN POR CONTACTO CON OTRAS CARGAS."; break;
                      case "36": nombre_cobertura="RESPONSABILIDAD CIVIL LESIONES PERSONALES Y PUBLICITARIAS"; break;
                      case "37": nombre_cobertura="REPATRIACION"; break;
                      case "38": nombre_cobertura="RESPONSABILIDAD CIVIL DE AUTO CONTINGENTE"; break;
                      case "39": nombre_cobertura="MALA PRACTICA HOSPITALARIA"; break;
                      case "40": nombre_cobertura="RESPONSABILIDAD CIVIL DE MONTACARGAS"; break;
                      case "35": nombre_cobertura="NEGLIGENCIA"; break;
                      case "23": nombre_cobertura="RESPONSABILIDAD CIVIL GUARDIAS"; break;
                      case "43": nombre_cobertura="R.C. MAL MANEJO"; break;
                      case "50": nombre_cobertura="RESPONSABILIDAD DE DIRECTORES Y OFICIALES"; break;
                      case "51": nombre_cobertura="RESPONSABILIDAD CIVIL DE MINERÍA"; break;
                      case "52": nombre_cobertura="RESPONSABILIDAD CIVIL SUBCONTRATISTAS"; break;
                      case "53": nombre_cobertura="RESPONSABILIDAD CIVIL DE ROTULOS"; break;
                      case "54": nombre_cobertura="RESPONSABILIDAD CIVIL DE GASTOS MÉDICOS VOLUNTARIOS"; break;
                      case "57": nombre_cobertura="Responsabilidad Civil de Restaurantes"; break;
                      case "59": nombre_cobertura="GASTOS MÉDICOS VOLUNTARIOS"; break;
                      case "62": nombre_cobertura="RESPONSABILIDAD CIVIL DE CONDOMINIO"; break;
                      case "56": nombre_cobertura="HOLE IN ONE"; break;
                      case "58": nombre_cobertura="EXCESOS DE RESPONSABILIDAD CIVIL"; break;
                      case "63": nombre_cobertura="RESP. CIVIL CLUBS Y ASOCIACIONES SOCIALES Y DEPORTIVAS"; break;
                      case "45": nombre_cobertura="CUOTAS DE ADMINISTRACION"; break;
                      case "60": nombre_cobertura="R. C. Otros"; break;
                      case "34": nombre_cobertura="RESPONSABILIDAD  CIVIL  DE ESTACIONAMIENTOS"; break;
                      case "6": nombre_cobertura="RESPONSABILIDAD  CIVIL  DE TRANSPORTISTAS"; break;
                      case "7": nombre_cobertura="RESPONSABILIDAD CIVIL FAMILIAR"; break;
                      case "8": nombre_cobertura="RESPONSABILIDAD CIVIL GENERAL"; break;
                      case "9": nombre_cobertura="RESPONSABILIDAD CIVIL PROFESIONAL"; break;
                      case "10": nombre_cobertura="RESPONSABILIDAD CIVIL PENAL O CRIMINAL"; break;
                      case "11": nombre_cobertura="RESPONSABILIDAD CIVIL DE ELEVADORES y/o GRADAS ELECTRICAS"; break;
                      case "12": nombre_cobertura="RESPONSABILIDAD CIVIL CRUZADA"; break;
                      case "13": nombre_cobertura="RESPONSABILIDAD CIVIL DE PARQUEOS Y/O ESTACIONAMIENTOS"; break;
                      case "14": nombre_cobertura="RESPONSABILIDAD  CIVIL  DE CONTRATISTAS"; break;
                      case "15": nombre_cobertura="RESPONSABILIDAD CIVIL DE VEHICULOS"; break;
                      case "16": nombre_cobertura="RESPONSABILIDAD CIVIL ASUMIDA"; break;
                      case "17": nombre_cobertura="R. C. POR DAÑOS A TERCEROS EN SUS PERSONAS"; break;
                      case "18": nombre_cobertura="RESPONSABILIDAD CIVIL DE CONSTRUCCION"; break;
                      case "19": nombre_cobertura="R. C. POR DAÑOS A TERCEROS EN SUS BIENES"; break;
                      case "22": nombre_cobertura="EXCESOS DE R. C. DE VEHICULOS"; break;
                      case "24": nombre_cobertura="RESPONSABILIDAD CIVIL POR EXPLOSION DE CALDERAS Y/O APARATOS QUE OPERAN A PRESIO"; break;
                      case "25": nombre_cobertura="*********SUSTITUR POR LA COBERTURA 23*******"; break;
                      case "26": nombre_cobertura="RESPONSABILIDAD CIVIL POR CAIDA PARCIAL O TOTAL DE ROTULOS, ARBOLES Y/O RAMAS"; break;
                      case "27": nombre_cobertura="RESPONSABILIDAD DE GARAGE KEEPERS"; break;
                      case "20": nombre_cobertura="RESPONSABILIDAD CIVIL DE DIRECTIVOS Y CONSEJEROS"; break;
                      case "21": nombre_cobertura="RESPONSABILIDAD CIVIL DE ESTIBADORES"; break;
                      case "1": nombre_cobertura="RESPONSABILIDAD  CIVIL  LEGAL DE INCENDIO Y/O EXPLOSION"; break;
                      case "2": nombre_cobertura="RESPONSABILIDAD  CIVIL  DE PREDIOS Y OPERACIONES"; break;
                      case "3": nombre_cobertura="RESPONSABILIDAD  CIVIL  DE PRODUCTOS O TRABAJOS TERMINADOS"; break;
                      case "4": nombre_cobertura="RESPONSABILIDAD  CIVIL CONTRACTUAL O ASUMIDA"; break;
                      case "5": nombre_cobertura="RESPONSABILIDAD  CIVIL PATRONAL"; break;
                      case "68": nombre_cobertura="RESPONSABILIDAD CIVIL"; break;
                      case "70": nombre_cobertura="RESPONSABILIDAD CIVIL DERIVADA DE LA VIOLACION DE PATENTES"; break;
                      case "65": nombre_cobertura="RESPONSABILIDAD CIVIL DE NAVES AEREAS"; break;
                      case "67": nombre_cobertura="REEMBOLSO A LA SOCIEDAD POR INDEMNIZACIÓN"; break;
                      case "69": nombre_cobertura="RESPONSABILIDAD CIVIL DE DIRECTIVOS Y FUNCIONARIOS"; break;
                      case "64": nombre_cobertura="RESPONSABILIDAD COLECTIVA DE PASAJEROS"; break;
                      case "66": nombre_cobertura="RESPONSABILIDAD CIVIL DIRECTORES Y ADMINISTRADORES"; break;
                      case "61": nombre_cobertura="RESPONSABILIDAD CIVIL MAQUINARIA Y EQUIPO MÓVIL"; break;
                      case "71": nombre_cobertura="RESPONSABILIDAD CIVIL HOTELERA"; break;
                      case "200": nombre_cobertura="A- RESPONSABILIDAD CIVIL POR LESION O MUERTE DE PERSONAS"; break;
                      case "201": nombre_cobertura="B-	ATENCIÓN MEDICA INMEDIATA"; break;
                      case "202": nombre_cobertura="C-RESPONSABILIDAD CIVIL POR DAÑOS A PROPIEDADES DE TERCEROS"; break;
                      case "203": nombre_cobertura="D-SUBLÍMITE DE LAVANDERÍAS Y GUARDARROPAS"; break;
                      case "204": nombre_cobertura="E-EQUIPAJE DE HUESPEDES"; break;
                      case "205": nombre_cobertura="F-RESGUARDOS DE CAJAS DE SEGURIDAD"; break;
                      case "206": nombre_cobertura="L-RESPONSABILIDAD CIVIL (COB A Y C)     "; break;
                      case "207": nombre_cobertura="M-RESPONSABILIDAD CIVIL PRODUCTOS"; break;
                      case "208": nombre_cobertura="N-RESPONSABILIDAD CIVIL PATRONAL"; break;
                      case "209": nombre_cobertura="O-RESPONSABILIDAD CIVIL COLISION O VUELCO DE VEHÍCULOS"; break;
                      case "210": nombre_cobertura="P-RESPONSABILIDAD CIVIL ROBO DE VEHÍCULOS"; break;
                      case "211": nombre_cobertura="Q-RESPONSABILIDAD CIVIL PRUEBA DE VEHÍCULOS PARA TALLERES"; break;
                      case "212": nombre_cobertura="R-RESPONSABILIDAD CIVIL ATENCIÓN MÉDICA INMEDIATA"; break;
                      case "213": nombre_cobertura="RESPONSABILIDAD CIVIL CONSTRUCCION"; break;
                      case "214": nombre_cobertura="RESPONSABILIDAD CIVIL SERVICIOS"; break;
                      case "215": nombre_cobertura="RESPONSABILIDAD CIVIL INDUSTRIAS"; break;
                      case "216": nombre_cobertura="RESPONSABILIDAD CIVIL LOCALES COMERCIALES"; break;
                      case "217": nombre_cobertura="RESPONSABILIDAD CIVIL HOTELES Y SIMILARES"; break;
                      case "218": nombre_cobertura="RESPONSABILIDAD CIVIL OPERADORES TURISTICOS"; break;
                      case "219": nombre_cobertura="RESPONSABILIDAD CIVIL PARQUEOS"; break;
                      case "220": nombre_cobertura="RESPONSABILIDAD CIVIL TALLERES"; break;
                      case "221": nombre_cobertura="RESPONSABILIDAD CIVIL AUTOLAVADOS"; break;
                      case "222": nombre_cobertura="RESPONSABILIDAD CIVIL ESTACIONES DE SERVICIO"; break;
                      case "223": nombre_cobertura="RESPONSABILIDAD CIVIL ESPECTACULOS PUBLICOS"; break;
                      case "224": nombre_cobertura="RESPONSABILIDAD CIVIL EVENTOS TAURINOS Y PIROTECNICOS"; break;
                      case "225": nombre_cobertura="RESPONSABILIDAD CIVIL JUEGOS ELECTROMECANICOS"; break;
                      case "226": nombre_cobertura="RESPONSABILIDAD CIVIL FAMILIAR Y PRIVADA"; break;
                      case "227": nombre_cobertura="RESPONSABILIDAD CIVIL PREDIOS Y OPERACIONES"; break;
                      case "228": nombre_cobertura="RESPONSABILIDAD CIVIL FABRICANTES Y CONTRATISTAS"; break;
                      case "229": nombre_cobertura="RESPONSABILIDAD CIVIL DUEÑOS. PROPIETARIOS Y ARRENDATARIOS"; break;
                      case "230": nombre_cobertura="RESPONSABILIDAD CIVIL ALMACENADORA"; break;
                      case "231": nombre_cobertura="RESPONSABILIDAD CIVIL PRODUCTOS"; break;
                      case "232": nombre_cobertura="COBERTURA GENERAL COMPRENSIVA"; break;
                      case "233": nombre_cobertura="RESPONSABILIDDAD CIVIL CRUZADA"; break;
                      case "234": nombre_cobertura="COBERTURA DE RESPONSABILIDAD CIVIL LEGAL DE DAÑOS POR INCENDIO"; break;
                      case "235": nombre_cobertura="RESPONSABILIAD CIVIL LEGAL"; break;
                      case "236": nombre_cobertura="RESPONSABILIAD CIVIL LEGAL CONTRACTUAL"; break;
                      case "237": nombre_cobertura="RESPONSABILIAD CIVIL LEGAL EXTRA CONTRACTUAL"; break;
                      case "238": nombre_cobertura="RESPONSABILIAD CIVIL CONTRACTUAL"; break;
                      case "239": nombre_cobertura="RESPONSABILIAD CIVIL EXTRA CONTRACTUAL"; break;
                      case "240": nombre_cobertura="U: Responsabilidad Civil Umbrella"; break;
                      case "72": nombre_cobertura="RESPONSABILIDAD CIVIL PREDIO Y OPERACIONES INDUSTRIALES"; break;
                      case "241": nombre_cobertura="RESPONSABILIDAD CIVIL CONTRATISTAS SUBCONTRATISTAS"; break;
                      case "242": nombre_cobertura="RESPONSABILIDAD CIVIL DE ELEVADORES Y/O ESCALERAS ELECTRICAS"; break;
                      case "243": nombre_cobertura="RESPONSABILIDAD CIVIL DE TRANSPORTISTAS"; break;
                      case "244": nombre_cobertura="RESPONSABILIDAD CIVIL EN EXCESO DE AUTOMOVIL"; break;
                      case "245": nombre_cobertura="RESPONSABILIDAD CIVIL POR CONTAMINACIÓN AL MEDIO AMBIENTE"; break;
                      case "246": nombre_cobertura="RESPONSABILIDAD CIVIL POR INCENDIO Y/O EXPLOSION"; break;
                      case "247": nombre_cobertura="RESPONSABILIDAD CIVIL POR DAÑOS PATRIMONIALES PUROS"; break;
                      case "248": nombre_cobertura="RESPONSABILIDAD CIVIL POR ONDAS Y CAMPOS ELECTROMAGNETICOS"; break;
                      case "249": nombre_cobertura="RESPONSABILIDAD CIVL POST-TRABAJOS"; break;
                      case "250": nombre_cobertura="RESPONSABILIDAD CIVIL POR VIOLACION DE PATENTES"; break;
                      case "251": nombre_cobertura="RESPONSABILIDAD CIVIL POR PRACTICAS LABORALES"; break;
                      case "252": nombre_cobertura="RESPONSABILIDAD CIVIL FIDUCIARIA DE PLANES DE PRESTACIONES PARA EMPLEADOS"; break;
                      case "253": nombre_cobertura="RESPONSABILIDAD CIVIL CUIDADO, CONTROL Y CUSTODIA"; break;
                    }
                    break;
                    case 'TR':
                //console.log('ingrese a TTR');
                    switch (String(json.data.coberturasXPoliza[i].coberturasXPolizaPK.codigoCobertura)) {
                      case "27": nombre_cobertura="DAÑO MALICIOSO ESPECIAL (Terrorismo)"; break;
                case "23": nombre_cobertura="FALTA DE ENTREGA"; break;
                case "28": nombre_cobertura="CARGA Y DESCARGA"; break;
                case "29": nombre_cobertura="MOTIN, HUELGA, CONMOCION CIVIL, DAÑOS MALICIOSOS Y ACTOS DE TERRORISMO"; break;
                case "30": nombre_cobertura="MOTIN, HUELGA, CONMOCION CIVIL Y DAÑOS MALICIOSOS"; break;
                case "31": nombre_cobertura="AVERIA GRUESA"; break;
                case "21": nombre_cobertura="ATRACO"; break;
                case "32": nombre_cobertura="OTRAS COBERTURAS"; break;
                case "25": nombre_cobertura="CARGA SOBRE CUBIERTA"; break;
                case "26": nombre_cobertura="FLOTE"; break;
                case "22": nombre_cobertura="ROBO Y/O ATRACO"; break;
                case "24": nombre_cobertura="TODO RIESGO EXTERNO"; break;
                case "16": nombre_cobertura="TERREMOTO,TEMBLOR,ERUPCION VOLCANICA, CAIDA DE CENIZA Y/O ARENA VOLCANICA E INC"; break;
                case "17": nombre_cobertura="MAREMOTO"; break;
                case "18": nombre_cobertura="TEMBLOR"; break;
                case "19": nombre_cobertura="HURACAN"; break;
                case "20": nombre_cobertura="VIENTOS TEMPESTUOSOS"; break;
                case "34": nombre_cobertura="BODEGA A BODEGA"; break;
                case "35": nombre_cobertura="HUELGAS, ALBOROTOS POPULARES Y CONMOCION CIVIL"; break;
                case "36": nombre_cobertura="TODO RIESGO (INCISO C)"; break;
                case "37": nombre_cobertura="RATERIA"; break;
                case "38": nombre_cobertura="HUELGAS, ALBOROTOS POPULARES Y CONMOCION CIVIL ."; break;
                case "1": nombre_cobertura="RIESGOS ORDINARIOS DE TRANSITO TRANSPORTE TERRESTRE Y/O AEREO"; break;
                case "8": nombre_cobertura="TRANSBORDO Y RECUPERACION"; break;
                case "9": nombre_cobertura="ROTURAS"; break;
                case "2": nombre_cobertura="RIESGOS ORDINARIOS DE TRANSITO   TRANSPORTE MARITIMO"; break;
                case "7": nombre_cobertura="CONTACTO CON OTRAS CARGAS"; break;
                case "11": nombre_cobertura="MOTIN, HUELGAS Y ALBOROTOS POPULARES"; break;
                case "12": nombre_cobertura="GUERRA"; break;
                case "10": nombre_cobertura="FALTA DE PESO O DERRAME"; break;
                case "3": nombre_cobertura="ROBO DE BULTO POR ENTERO"; break;
                case "4": nombre_cobertura="ROBO PARCIAL"; break;
                case "5": nombre_cobertura="MOJADURA"; break;
                case "6": nombre_cobertura="OXIDACION"; break;
                case "13": nombre_cobertura="TODO RIESGO  1.1.82  (A)"; break;
                case "14": nombre_cobertura="C. A. P.   1.1.82   (B)"; break;
                case "15": nombre_cobertura=" L.A.P.  1.1.82  (C)"; break;
                case "39": nombre_cobertura="TODO RIESGO  1.1.63  (A)"; break;
                case "40": nombre_cobertura="PAGO DE IMPUESTOS ANTE SAT"; break;
                case "54": nombre_cobertura="Cobertura de riesgos de cláusulas del instituto de londres, para cargamentos “a”"; break;
                case "55": nombre_cobertura="Cobertura de riesgos de cláusulas del instituto de londres, para cargamentos “b”"; break;
                case "56": nombre_cobertura="Huelga"; break;
                case "57": nombre_cobertura="Guerra"; break;
                case "41": nombre_cobertura="Incendio, rayo, auto-ignición y explosión"; break;
                case "42": nombre_cobertura="Terremoto, temblor"; break;
                case "43": nombre_cobertura="Colisión del vehículo con otro vehículo u objeto"; break;
                case "44": nombre_cobertura="Vuelco del vehículo transportador de los bienes descritos"; break;
                case "45": nombre_cobertura="Vendaval, ciclón o tornado"; break;
                case "46": nombre_cobertura="Derrumbe de puentes o alcantarillas"; break;
                case "47": nombre_cobertura="Derrumbe de tierra, de piedras o de rocas"; break;
                case "48": nombre_cobertura="Inundación por el desbordamiento de ríos o quebradas"; break;
                case "49": nombre_cobertura="Saqueo"; break;
                case "50": nombre_cobertura="Robo o Asalto"; break;
                case "51": nombre_cobertura="Maniobras de Carga y Descarga"; break;
                case "52": nombre_cobertura="Refrigeración"; break;
                case "53": nombre_cobertura="Extraterritorialidad"; break;
                case "69": nombre_cobertura="Movimientos Bruscos"; break;
                case "58": nombre_cobertura="Riesgos del Medio de Transporte"; break;
                case "59": nombre_cobertura="Caída, Colisión o Vuelco de Mercancías"; break;
                case "60": nombre_cobertura="Caída de Mercancía en Predios"; break;
                case "61": nombre_cobertura="Fallas Mecánicas en el Sistema de Refrigeración"; break;
                case "62": nombre_cobertura="Todo Riesgo 1.1.82 A"; break;
                case "63": nombre_cobertura="Motin y Huelga"; break;
                case "64": nombre_cobertura="Robo y/o Atraco"; break;
                case "65": nombre_cobertura="Carga y Descarga"; break;
                case "66": nombre_cobertura="Bodega a Bodega"; break;
                case "67": nombre_cobertura="Estadía"; break;
                case "68": nombre_cobertura="Riesgos Ordinarios de Transito 1.1.82 C"; break;
                case "71": nombre_cobertura="TRANSBORDO"; break;
                case "72": nombre_cobertura="RECUPERACION"; break;
                case "73": nombre_cobertura="DESTRUCCION"; break;
                case "70": nombre_cobertura="Responsabilidad Civil Carga Vía Terrestre"; break;
                    }
                break;
                }
                if (json.data.coberturasXPoliza[0].coberturasXPolizaPK.codigoDireccion!= undefined)
                {
                    if($('#monedaPol').val()=='Q') 
                    {
                      datosini.push({CODIGO:json.data.coberturasXPoliza[i].coberturasXPolizaPK.codigoCobertura,DESCRIPCION:nombre_cobertura,SUMA:aMoneda(json.data.coberturasXPoliza[i].sumaAsegurada, opcionesQuetzales)});
                    }else
                    {
                      datosini.push({CODIGO:json.data.coberturasXPoliza[i].coberturasXPolizaPK.codigoCobertura,DESCRIPCION:nombre_cobertura,SUMA:aMoneda(json.data.coberturasXPoliza[i].sumaAsegurada, opcionesDolares)});
                    }
                }
            }
          }
          mivaribleDep = datosini;
          setTimeout(
          function()
          {
          }, 9000);
          setMiVariableCoberturas(mivaribleDep);
      } else {
        //swal("Error", "No devolvio 200", "warning");
      }

    }).catch(function(error) {


    })

  }else //else de ($('#valPrin').val()==1 || $('#valPrin').val()=="1")
  {
      servicio =  `${process.env.REACT_APP_API_URL}/it-api-clientes2/rs/operaciones/coberturaPoliza/`+$('#tipoPol').val()+'/'+$('#numeroPol').val()+'/'+$('#asegu').val();
      axios.get(servicio,{
        headers: {
          'Authorization': `Bearer ${sessionStorage.getItem('jwtToken')}`,
        },
      }).then(json => {
          if (json.status === 200) 
          {
      
    if (json.data.vCoberturasXPoliza.length == undefined || json.data.vCoberturasXPoliza.length=='undefined')
    {

        switch (json.data.vCoberturasXPoliza.codigoTipoPoliza) 
        {
          case 'EE':
          //console.log('ingrese a equipo');
          switch (json.data.vCoberturasXPoliza.codigoCobertura) {
          case 9: nombre_cobertura="DAÑO MALICIOSO ESPECIAL  TERRORISMO "; break;
          case 23: nombre_cobertura="HURACAN, CICLON, TIFON"; break;
          case 17: nombre_cobertura="OTRAS COBERTURAS"; break;
          case 14: nombre_cobertura="FALLA APROVISIONAMIENTO DE LA CORRIENTE ELECTRICA"; break;
          case 18: nombre_cobertura="TRANSPORTE INCIDENTAL"; break;
          case 19: nombre_cobertura="MOTIN, HUELGAS Y/O ALBOROTOS POPULARES, DAÑOS MALICIOSOS E INCENDIO CONSECUTIVO"; break;
          case 20: nombre_cobertura="EXPLOSION, MOTIN, HUELGAS Y/O ALBOROTOS POPULARES, DAÑO MALICIOSO"; break;
          case 24: nombre_cobertura="MOTIN, HUELGA Y/O CONMOCION CIVIL"; break;
          case 16: nombre_cobertura="TODO RIESGO"; break;
          case 15: nombre_cobertura="INTERRUPCION DE NEGOCIOS PARA RIESGOS COMERCIALES"; break;
          case 22: nombre_cobertura="TERREMOTO"; break;
          case 21: nombre_cobertura="COBERTURA DE EQUIPOS MOVILES Y PORTATILES FUERA DE LOS PREDIOS ASEGURADOS"; break;
          case 7: nombre_cobertura="CORTO CIRCUITO, ALTERACIONES DE LA CORRIENTE ELECTRICA."; break;
          case 8: nombre_cobertura="GASTOS DE REGRABACION O REPROCESAMIENTO DE DATOS"; break;
          case 12: nombre_cobertura="INCREMENTO EN EL COSTO DE LA OPERACION"; break;
          case 13: nombre_cobertura="ROBO Y/O ATRACO"; break;
          case 1: nombre_cobertura="SECCION I : DAÑOS MATERIALES"; break;
          case 2: nombre_cobertura="SECCION II :  PORTADORES EXTERNOS DE DATOS"; break;
          case 3: nombre_cobertura="SECCION III :   GASTOS EXTRAS"; break;
          case 4: nombre_cobertura="TERREMOTO, TEMBLOR Y/O ERUPCION VOLCANICA E INCENDIO CONSECUTIVO"; break;
          case 5: nombre_cobertura="DAÑO MALICIOSO"; break;
          case 6: nombre_cobertura="ROBO POR FORZAMIENTO DE LADRONES"; break;
          case 25: nombre_cobertura="TRANSPORTE"; break;
          case 58: nombre_cobertura="A.- INCENDIO CASUAL RAYO Y EXPLOSIÓN"; break;
          case 59: nombre_cobertura="B.- ROBO"; break;
          case 60: nombre_cobertura="C.- MOTIN, HUELGA, PARA LEGAL"; break;
          case 61: nombre_cobertura="D.- HURACLÁN, CICLON, INUNDACIÓN, DESLIZAMIENTO"; break;
          case 62: nombre_cobertura="F.-TEMBLOR TERREMOTO"; break;
          case 63: nombre_cobertura="G. DANO MALICIOSO"; break;
          case 64: nombre_cobertura="H.- DAÑOS POR COLISIÓN Y VUELCO"; break;
          case 26: nombre_cobertura="A - Daño Directo Equipo Electronico"; break;
          case 27: nombre_cobertura="B - Robo"; break;
          case 28: nombre_cobertura="E - Equipo Móvil Y/O Portátil"; break;
          case 29: nombre_cobertura="C - Eventos De Las Naturaleza"; break;
          case 30: nombre_cobertura="D - Otros Riesgos"; break;
          case 31: nombre_cobertura="S - Portadores Externos De Datos"; break;
          case 32: nombre_cobertura="R - Daño Directo Equipo Electronico"; break;
          case 33: nombre_cobertura="E - Equipo Móvil Y/O Portátil"; break;
          case 34: nombre_cobertura="S - Portadores Externos De Datos"; break;
          case 35: nombre_cobertura="A - Responsabilidad Civil Por Lesion O Muerte A Terceros Excluyendo Pasajeros"; break;
          case 36: nombre_cobertura="C - Responsabilidad Civil Por Daños A Propiedades De Terceros"; break;
          case 37: nombre_cobertura="E - Basica"; break;
          case 38: nombre_cobertura="F - Robo Y Hurto"; break;
          case 39: nombre_cobertura="G - Riesgos Adicionales"; break;
          case 40: nombre_cobertura="H - Extraterritorialidad"; break;
          case 41: nombre_cobertura="Incendio, impacto de rayo, implosión y explosión"; break;
          case 42: nombre_cobertura="Humo, hollín, gases, líquidos y polvos corrosivos"; break;
          case 43: nombre_cobertura="Inundación, acción del agua y humedad"; break;
          case 44: nombre_cobertura="Cortocircuito"; break;
          case 45: nombre_cobertura="Errores de construcción"; break;
          case 46: nombre_cobertura="Errores de manejo"; break;
          case 47: nombre_cobertura="Robo con violencia"; break;
          case 48: nombre_cobertura="Granizo, helada, tempestad"; break;
          case 49: nombre_cobertura="Dolo de terceros, impericia"; break;
          case 50: nombre_cobertura="Deslizamiento de tierra, Hundimiento del terreno y caído de rocas"; break;
          case 51: nombre_cobertura="Riesgo de terremoto"; break;
          case 52: nombre_cobertura="Riesgo de tifón, ciclón y huracán"; break;
          case 53: nombre_cobertura="Riesgo por daños causados por huelga, motín y conmoción civil"; break;
          case 54: nombre_cobertura="Riesgo de hurto"; break;
          case 55: nombre_cobertura="Cobertura de equipos móviles portátiles fuera de su local asegurado"; break;
          case 56: nombre_cobertura="Incremento en el costo de operación"; break;
          case 57: nombre_cobertura="Portadores externos de datos"; break;
          case 65: nombre_cobertura="TODO RIESGO"; break;
          }
          break;
          case 'CERT':
          //console.log('ingrese a certificado');
          switch (json.data.vCoberturasXPoliza.codigoCobertura) {
          case "23": nombre_cobertura="DAÑO MALICIOSO ESPECIAL (Terrorismo)"; break;
          case "24": nombre_cobertura="BARREDURA"; break;
          case "25": nombre_cobertura="MOTIN, HUELGA, CONMOCION CIVIL, DAÑOS MALICIOSOS Y ACTOS DE TERRORISMO"; break;
          case "16": nombre_cobertura="ATRACO"; break;
          case "26": nombre_cobertura="OTRAS COBERTURAS"; break;
          case "27": nombre_cobertura="MOTIN, HUELGA, CONMOCION CIVIL Y DAÑOS MALICIOSOS"; break;
          case "22": nombre_cobertura="AVERIA GRUESA"; break;
          case "17": nombre_cobertura="ROBO Y/O ATRACO"; break;
          case "18": nombre_cobertura="FALTA DE ENTREGA"; break;
          case "19": nombre_cobertura="CARGA Y DESCARGA"; break;
          case "34": nombre_cobertura="TODO RIESGO 1.1.63  (A)"; break;
          case "20": nombre_cobertura="TODO RIESGO"; break;
          case "21": nombre_cobertura="INTERRUPCION DE NEGOCIOS POR DAÑOS A MAQUINARIA"; break;
          case "31": nombre_cobertura="TODO RIESGO (INCISO C)"; break;
          case "28": nombre_cobertura="BODEGA A BODEGA"; break;
          case "32": nombre_cobertura="ESTADIA"; break;
          case "29": nombre_cobertura="RATERIA"; break;
          case "30": nombre_cobertura="HUELGAS, ALBOROTOS POPULARES Y CONMOCION CIVIL PARA EMBARQUES TERRESTRES"; break;
          case "13": nombre_cobertura="TODO RIESGO 1.1.82  (A)"; break;
          case "11": nombre_cobertura="MOTIN, HUELGAS Y ALBOROTOS POPULARES"; break;
          case "12": nombre_cobertura="GUERRA"; break;
          case "10": nombre_cobertura="FALTA DE PESO O DERRAME"; break;
          case "3": nombre_cobertura="ROBO DE BULTO POR ENTERO"; break;
          case "4": nombre_cobertura="ROBO PARCIAL"; break;
          case "14": nombre_cobertura="C.A.P.  1.1.82 (B)"; break;
          case "15": nombre_cobertura="L.A.P.  1.1.82 (C)"; break;
          case "1": nombre_cobertura="RIESGOS ORDINARIOS DE TRANSITO TRANSPORTE TERRESTRE Y/O AEREO"; break;
          case "2": nombre_cobertura="RIESGOS ORDINARIOS DE TRANSITO TRANSPORTE MARITIMO"; break;
          case "6": nombre_cobertura="OXIDACION"; break;
          case "5": nombre_cobertura="MOJADURA"; break;
          case "7": nombre_cobertura="CONTACTO CON OTRAS CARGAS"; break;
          case "8": nombre_cobertura="MANCHAS"; break;
          case "9": nombre_cobertura="ROTURAS"; break;
          case "33": nombre_cobertura="ALMACENAMIENTO"; break;
          case "48": nombre_cobertura="Cobertura de riesgos de cláusulas del instituto de londres, para cargamentos “a”"; break;
          case "49": nombre_cobertura="Cobertura de riesgos de cláusulas del instituto de londres, para cargamentos “b”"; break;
          case "50": nombre_cobertura="Huelga"; break;
          case "51": nombre_cobertura="Guerra"; break;
          case "39": nombre_cobertura="Vendaval, ciclón o tornado"; break;
          case "40": nombre_cobertura="Derrumbe de puentes o alcantarillas"; break;
          case "41": nombre_cobertura="Derrumbe de tierra, de piedras o de rocas"; break;
          case "42": nombre_cobertura="Inundación por el desbordamiento de ríos o quebradas"; break;
          case "43": nombre_cobertura="Saqueo"; break;
          case "44": nombre_cobertura="Robo o Asalto"; break;
          case "45": nombre_cobertura="Maniobras de Carga y Descarga; y Movimientos Bruscos"; break;
          case "46": nombre_cobertura="Refrigeración"; break;
          case "47": nombre_cobertura="Extraterritorialidad"; break;
          case "35": nombre_cobertura="Incendio, rayo, auto-ignición y explosión"; break;
          case "36": nombre_cobertura="Terremoto, temblor"; break;
          case "37": nombre_cobertura="Colisión del vehículo con otro vehículo u objeto"; break;
          case "38": nombre_cobertura="Vuelco del vehículo transportador de los bienes descritos"; break;
          case "53": nombre_cobertura="TODO RIESGO"; break;
          case "52": nombre_cobertura="COBERTURA BÁSICA H: Riesgos del Medio de Transporte"; break;
          case "54": nombre_cobertura="1.1.09 A"; break;
          case "55": nombre_cobertura="TRANSBORDO"; break;
          case "56": nombre_cobertura="RECUPERACION"; break;
          case "57": nombre_cobertura="DESTRUCCION"; break;
          case "58": nombre_cobertura="DAÑOS INTERNOS A CONTENEDORES"; break;
          }
          break;
          case "INCEN" :
          //console.log('ingrese a INCEN');
          switch (json.data.vCoberturasXPoliza.codigoCobertura) {
          case "10": nombre_cobertura="REMOCION DE ESCOMBROS"; break;
          case "11": nombre_cobertura="GASTOS DE EXTINCIÓN DE INCENDIOS"; break;
          case "12": nombre_cobertura="VALORES,  DOCUMENTOS"; break;
          case "13": nombre_cobertura="HURACAN, TORMENTAS Y VIENTOS."; break;
          case "108": nombre_cobertura="ARRIENDO DE LOCALES DE LOCALES PROVISORIOS"; break;
          case "100": nombre_cobertura="I. TODO RIESGO CONDOMINIOS  PARA BIENES MUEBLES E INMUEBLES"; break;
          case "110": nombre_cobertura="TRASLADOS TEMPORALES"; break;
          case "111": nombre_cobertura="MONTAJE Y DESMONTAJE"; break;
          case "112": nombre_cobertura="TRANSITO INCIDENTAL"; break;
          case "113": nombre_cobertura="SERVICIO DE ASISTENCIA DOMICILARIA G&T"; break;
          case "28": nombre_cobertura="DINERO Y/O VALORES FUERA DEL LOCAL"; break;
          case "114": nombre_cobertura="EXPLOSION, MOTIN, HUELGAS Y/O ALBOROTOS POPULARES, DAÑO MALICIOSO"; break;
          case "109": nombre_cobertura="DAÑOS POR AGUA"; break;
          case "41": nombre_cobertura="RESPONSABILIDAD CIVIL LEGAL DE INCENDIO"; break;
          case "44": nombre_cobertura="R.C. DE PRODUCTOS O TRABAJOS TERMINADOS"; break;
          case "45": nombre_cobertura="INTERRUPCION DE NEGOCIOS PARA RIESGOS INDUSTRIALES"; break;
          case "546": nombre_cobertura="INCENDIO Y/O RAYO"; break;
          case "25": nombre_cobertura="ATRACO DE CONTENIDO"; break;
          case "26": nombre_cobertura="CRISTALES Y/O ROTULOS"; break;
          case "27": nombre_cobertura="DINERO Y/O VALORES DENTRO DEL LOCAL"; break;
          case "29": nombre_cobertura="IMPACTO DE NAVES ACUATICAS"; break;
          case "30": nombre_cobertura="OTRAS COBERTURAS"; break;
          case "91": nombre_cobertura="INTERRUPCION RENTAS DE ALQUILER"; break;
          case "48": nombre_cobertura="HONORARIOS PROFESIONALES"; break;
          case "14": nombre_cobertura="DAÑO MALICIOSO ESPECIAL  (TERRORISMO)"; break;
          case "15": nombre_cobertura="CORTO CIRCUITO"; break;
          case "32": nombre_cobertura="FALLA ELECTRICA"; break;
          case "34": nombre_cobertura="EXPLOSION DE CALDERAS"; break;
          case "35": nombre_cobertura="TRANSPORTE TERRESTRE"; break;
          case "36": nombre_cobertura="HURTO Y DESAPARICION MISTERIOSA"; break;
          case "37": nombre_cobertura="MONTAJE Y DESMONTAJE"; break;
          case "38": nombre_cobertura="ROTULOS LUMINOSOS"; break;
          case "39": nombre_cobertura="RESPONSABILIDAD CIVIL FAMILIAR"; break;
          case "17": nombre_cobertura="FRAUDE POR COMPUTADORAS DE TERCERAS PERSONAS Y EN TRANSFERENCIAS DE FONDOS"; break;
          case "18": nombre_cobertura="FALSIFICACION DE TARJETAS DE CREDITO"; break;
          case "19": nombre_cobertura="FALSIFICACION DE CHEQUES ENTRANTES"; break;
          case "20": nombre_cobertura="FALSIFICACION DE DEPOSITANTES"; break;
          case "21": nombre_cobertura="ACTOS DOLOSOS DE LOS TRABAJADORES"; break;
          case "22": nombre_cobertura="PARAGUAS PARA ALMACENADORA"; break;
          case "23": nombre_cobertura="DOLO O MALA FE PARA ALMACENADORA"; break;
          case "24": nombre_cobertura="ROBO POR FORZAMIENTO DE LADRONES"; break;
          case "7": nombre_cobertura="ROBO Y/O ATRACO"; break;
          case "159": nombre_cobertura="RESPONSABILIDAD CIVIL"; break;
          case "42": nombre_cobertura="AIG ASSIST"; break;
          case "43": nombre_cobertura="RESPONSABILIDAD CIVIL DE GARAGE KEEPER"; break;
          case "40": nombre_cobertura="RESPONSABILIDAD  CIVIL DE PREDIOS Y OPERACIONES"; break;
          case "92": nombre_cobertura="ROTURA DE MAQUINARIA"; break;
          case "127": nombre_cobertura="TERRORISMO (RJK)"; break;
          case "128": nombre_cobertura="MAQUINARIA EN MOVIMIENTO"; break;
          case "129": nombre_cobertura="TRANSPORTE INCIDENTAL DE BIENES"; break;
          case "130": nombre_cobertura="HURTO Y/O PILLAJE POST SINIESTRO"; break;
          case "131": nombre_cobertura="GASTOS DE LIMPIEZA Y DESCONTAMINACIÓN"; break;
          case "132": nombre_cobertura="TODO RIESGO DE CONSTRUCCIÓN Y MONTAJE"; break;
          case "148": nombre_cobertura="AMPARO AUTOMÁTICO DE NUEVAS PROPIEDADES"; break;
          case "149": nombre_cobertura="DAÑO ELÉCTRICO"; break;
          case "158": nombre_cobertura="RESPONSABILIDAD CIVIL DE CONDOMINIOS"; break;
          case "176": nombre_cobertura="RESPONSABILIDAD CIVIL INCENDIO Y/O EXPLOSION"; break;
          case "150": nombre_cobertura="Transporte"; break;
          case "152": nombre_cobertura="AMPARO PROPIEDAD PERSONAL EMPLEADOS"; break;
          case "153": nombre_cobertura="ALTERACIONES Y REPARACIONES"; break;
          case "154": nombre_cobertura="ASISTENCIA DOMICILIAR"; break;
          case "155": nombre_cobertura="EQUIPO ELECTRONICO"; break;
          case "156": nombre_cobertura="MATERIA PRIMA Y PRODUCTO TERMINADO"; break;
          case "117": nombre_cobertura="INUNDACION Y/O MAREMOTO"; break;
          case "119": nombre_cobertura="GASTOS DE CERRAJERIA"; break;
          case "123": nombre_cobertura="COBERTURA DE EQUIPO DE PROCESAMIENTO DE DATOS"; break;
          case "120": nombre_cobertura="COBERTURA DE ACCIDENTES PERSONALES"; break;
          case "121": nombre_cobertura="GASTOS EXTRAORDINARIOS"; break;
          case "133": nombre_cobertura="INTERRUPCIÓN DE FLUIDO ELECTRICO FUERA DE LAS INSTALACIONES"; break;
          case "101": nombre_cobertura="II. RESPONSABILIDAD CIVIL CONDOMINIOS"; break;
          case "102": nombre_cobertura=" R. C. PARA AREAS DE ESTACIONAMIENTOS Y PARQUEOS"; break;
          case "103": nombre_cobertura="IV.  CUOTAS DE ADMINISTRACION"; break;
          case "104": nombre_cobertura="1.  ROTURA DE MAQUINARIA"; break;
          case "157": nombre_cobertura="ROBO POR FORZAMIENTO DE LADRONES y/o ROBO AGRAVADO"; break;
          case "106": nombre_cobertura=" AMPARO AUTOMATICO DE GASTOS ADICIONALES"; break;
          case "107": nombre_cobertura="EXTENSIONES AUTOMATICAS"; break;
          case "16": nombre_cobertura="DEMOLICION"; break;
          case "115": nombre_cobertura="PILLAJE POST-SINIESTRO"; break;
          case "122": nombre_cobertura="COBERTURA DE TARJETA DE CREDITO"; break;
          case "118": nombre_cobertura="TODO RIESGO MULTICOBERTURAS"; break;
          case "124": nombre_cobertura="MOTIN Y/O HUELGAS Y/O CONMOCION CIVIL Y/O DAÑO MALICIOSO"; break;
          case "125": nombre_cobertura="MOTIN Y/O HUELGAS Y/O CONMOCION CIVIL Y/O DAÑO MALICIOSO Y/O EXPLOSION"; break;
          case "126": nombre_cobertura="TERREMOTO, TEMBLOR Y/O ERUPCION VOLCANICA, CAIDA DE CENIZA Y/O ARENA ..."; break;
          case "6": nombre_cobertura="DAÑO MALICIOSO ESPECIAL"; break;
          case "5": nombre_cobertura="TERREMOTO, TEMBLOR Y/O ERUPCION VOLCANICA"; break;
          case "2": nombre_cobertura="TODO RIESGO  (2)"; break;
          case "3": nombre_cobertura="TODO RIESGO  (3)"; break;
          case "1": nombre_cobertura="TODO RIESGO  DE INCENDIO"; break;
          case "4": nombre_cobertura="MOTIN  y HUELGAS  Y ALBOROTOS POPULARES"; break;
          case "9": nombre_cobertura="DINERO Y/O VALORES"; break;
          case "178": nombre_cobertura="TRANSPORTE INCIDENTAL"; break;
          case "179": nombre_cobertura="INT. DE NEGOCIOS COMERCIAL E INT. DE RENTAS DE ALQUILER"; break;
          case "180": nombre_cobertura="CUOTAS DE ADMINISTRACION"; break;
          case "181": nombre_cobertura="WALLET GUARD"; break;
          case "182": nombre_cobertura="ACCIDENTES PERSONALES"; break;
          case "177": nombre_cobertura="REPARACIONES PROVISORIAS"; break;
          case "135": nombre_cobertura="DAÑO ELECTRICO"; break;
          case "136": nombre_cobertura="CAR/EAR"; break;
          case "137": nombre_cobertura="ROTURA DE MAQUINARIA Y/O EXPLOSION DE CALDERAS"; break;
          case "138": nombre_cobertura="INT. DE NEGOCIOS RIESGOS INDUSTRIALES INTERDEPENDIENTE"; break;
          case "141": nombre_cobertura="RESPONSABILIDAD CIVIL PATRONAL"; break;
          case "142": nombre_cobertura="RESPONSABILIDAD CIVIL DE ELEVADORES Y/O GRADAS ELECTRICAS"; break;
          case "143": nombre_cobertura="GASTOS MÉDICOS VOLUNTARIOS"; break;
          case "144": nombre_cobertura="CUENTAS POR COBRAR"; break;
          case "145": nombre_cobertura="CAJAS FUERTES"; break;
          case "46": nombre_cobertura="INTERRUPCIÓN DE NEGOCIOS PARA RIESGOS COMERCIALES"; break;
          case "147": nombre_cobertura="GASTOS EXTRAS"; break;
          case "151": nombre_cobertura="INTERRUPCIÓN DE NEGOCIOS RIESGOS INDUSTRIALES Y COMERCIALES"; break;
          case "160": nombre_cobertura="RESPONSABILIDAD CIVIL DE TRANSPORTISTAS"; break;
          case "161": nombre_cobertura="RESPONSABILIDAD CIVIL DE POLUCION O CONTAMINACION"; break;
          case "162": nombre_cobertura="RESPONSABILIDAD CIVIL GENERAL"; break;
          case "134": nombre_cobertura="EXTENSION DE COBERTURA A PROVEEDORES Y CLIENTES"; break;
          case "139": nombre_cobertura="PÉRDIDAS CONSECUENCIALES"; break;
          case "140": nombre_cobertura="Gastos Adicionales y/o Extras"; break;
          case "163": nombre_cobertura="GASTOS DE APRESURAMIENTO"; break;
          case "164": nombre_cobertura="CONTAMINACION DE AMONIACO"; break;
          case "165": nombre_cobertura="DAÑOS POR AGUA"; break;
          case "166": nombre_cobertura="RECONSTRUCCION DE DOCUMENTOS"; break;
          case "167": nombre_cobertura="ROTURA DE CALDERAS Y MAQUINARIA"; break;
          case "168": nombre_cobertura="AMPARO AUTOMATICO DE GASTOS ADICIONALES DERIVADOS DE SINIESTRO"; break;
          case "169": nombre_cobertura="ALTERACIONES, ADICIONES Y MEJORAS"; break;
          case "170": nombre_cobertura="ALQUILER DE PLANTA DE GENERACION DE ENERGIA ELECTRICA"; break;
          case "171": nombre_cobertura="RESPONSABILIDAD CIVIL CONTRACTURAL"; break;
          case "172": nombre_cobertura="RESPONSABILIDAD CIVIL CRUZADA"; break;
          case "173": nombre_cobertura="RESPONSABILIDAD CIVIL CONTRATISTAS Y SUBCONTRATISTAS INDEPENDIENTES"; break;
          case "174": nombre_cobertura="RESPONSABILIDAD CIVIL PARA PARQUEOS Y ESTACIONAMIENTOS"; break;
          case "175": nombre_cobertura="DINERO Y CHEQUES EN LA OFICINA ADMINISTRATIVA"; break;
          case "547": nombre_cobertura="VEHICULOS CON MOTORES APAGADOS"; break;
          case "607": nombre_cobertura="REMOCION DE ESCOMBROS"; break;
          case "608": nombre_cobertura="GASTOS DE EXTINCIÓN DE INCENDIOS"; break;
          case "609": nombre_cobertura="VALORES,  DOCUMENTOS"; break;
          case "610": nombre_cobertura="HURACAN, TORMENTAS Y VIENTOS."; break;
          case "611": nombre_cobertura="ARRIENDO DE LOCALES DE LOCALES PROVISORIOS"; break;
          case "612": nombre_cobertura="I. TODO RIESGO CONDOMINIOS  PARA BIENES MUEBLES E INMUEBLES"; break;
          case "613": nombre_cobertura="TRASLADOS TEMPORALES"; break;
          case "614": nombre_cobertura="MONTAJE Y DESMONTAJE"; break;
          case "615": nombre_cobertura="TRANSITO INCIDENTAL"; break;
          case "616": nombre_cobertura="SERVICIO DE ASISTENCIA DOMICILARIA G&T"; break;
          case "617": nombre_cobertura="DINERO Y/O VALORES FUERA DEL LOCAL"; break;
          case "618": nombre_cobertura="EXPLOSION, MOTIN, HUELGAS Y/O ALBOROTOS POPULARES, DAÑO MALICIOSO"; break;
          case "286": nombre_cobertura="T Riesgos No Catastróficos"; break;
          case "287": nombre_cobertura="U Riesgos Catastróficos"; break;
          case "288": nombre_cobertura="A Incendio Casual y Rayo"; break;
          case "289": nombre_cobertura="B Riesgos Varios"; break;
          case "290": nombre_cobertura="C Inundación, Deslizamiento Vientos Inundación"; break;
          case "291": nombre_cobertura="D Convulsiones de la Naturaleza"; break;
          case "292": nombre_cobertura="E Daño Directo a la Mercancía"; break;
          case "293": nombre_cobertura="F Pérdida de Beneficios"; break;
          case "294": nombre_cobertura="G Lluvia y Derrame "; break;
          case "295": nombre_cobertura="H Pérdida de Rentas por contrato de arrendamiento"; break;
          case "296": nombre_cobertura="I Rotura de Cristales"; break;
          case "297": nombre_cobertura="Q Gastos Extra"; break;
          case "298": nombre_cobertura="A Incendio Casual y Rayo"; break;
          case "299": nombre_cobertura="B Riesgos Varios"; break;
          case "300": nombre_cobertura="C Inundación , Deslizamiento y Vientos"; break;
          case "301": nombre_cobertura="D Convulsiones de la Naturaleza"; break;
          case "302": nombre_cobertura="H Pérdida de Rentas por contrato de arrendamiento"; break;
          case "303": nombre_cobertura="I Rotura de Cristales"; break;
          case "304": nombre_cobertura="R Gastos por Alquiler"; break;
          case "305": nombre_cobertura="V Daño directo de bienes inmuebles"; break;
          case "306": nombre_cobertura="Y Daño directo de contenidos (opción 1,2 y 3)"; break;
          case "307": nombre_cobertura="D Convulsiones de la naturaleza"; break;
          case "308": nombre_cobertura="H Pérdida de rentas por contrato de arrendamiento"; break;
          case "309": nombre_cobertura="K Responsabilidad Civil"; break;
          case "310": nombre_cobertura="M Riesgos del Trabajo Servidores Domesticos"; break;
          case "311": nombre_cobertura="P Accidentes Personales Daños Materiales"; break;
          case "312": nombre_cobertura="Incendio"; break;
          case "313": nombre_cobertura="Agua al sofocar un incendio "; break;
          case "314": nombre_cobertura="Inundación , Deslizamiento y Vientos"; break;
          case "315": nombre_cobertura="Impacto de rayo."; break;
          case "316": nombre_cobertura="Humo u hollín proveniente de un incendio"; break;
          case "317": nombre_cobertura="Implosión y/o Explosión de cualquier tipo"; break;
          case "318": nombre_cobertura="Impacto de vehículos terrestres o aéreos u objetos caídos del cielo"; break;
          case "319": nombre_cobertura="Caída objetos contra la propiedad asegurada."; break;
          case "320": nombre_cobertura="Incendio y Daño Directo por Terremoto"; break;
          case "321": nombre_cobertura="Daño Directo por Vendaval, huracán, tornado, tromba o granizo"; break;
          case "322": nombre_cobertura="Daño Directo por Inundación"; break;
          case "323": nombre_cobertura="Extensión de Cobertura Catastrófica"; break;
          case "324": nombre_cobertura="Incendio y daño por desórdenes públicos."; break;
          case "325": nombre_cobertura="Daño Directo por Maldad"; break;
          case "326": nombre_cobertura="Saqueo"; break;
          case "327": nombre_cobertura="Pérdida consiguiente por falta de refrigeración"; break;
          case "328": nombre_cobertura="Pérdida de Renta"; break;
          case "329": nombre_cobertura="Lucro Cesante (Pérdida de Beneficios)"; break;
          case "330": nombre_cobertura="A Incendio Casual y Rayo"; break;
          case "331": nombre_cobertura="B Riesgos de Naturaleza Catastrófica Inundación"; break;
          case "332": nombre_cobertura="C Daños por Agua y Riesgos Atmosféricos"; break;
          case "333": nombre_cobertura="D Rotura de Cristales, Domos, Mármoles y Granitos."; break;
          case "334": nombre_cobertura="E Robo"; break;
          case "335": nombre_cobertura="F Gastos por Alquiler"; break;
          case "336": nombre_cobertura="G Pérdida de Rentas  "; break;
          case "337": nombre_cobertura="I Bienes Refrigerados o Congelados"; break;
          case "338": nombre_cobertura="J Mercancía en Tránsito"; break;
          case "339": nombre_cobertura="L Rotura de Maquinaria"; break;
          case "340": nombre_cobertura="M Equipo Electrónico"; break;
          case "341": nombre_cobertura="P Interrupción de Negocios"; break;
          case "342": nombre_cobertura="Q Multiasistencia"; break;
          case "343": nombre_cobertura="H Responsabilidad Civil Extracontractual y Subjetiva"; break;
          case "344": nombre_cobertura="Q Asistencia en la Vivienda"; break;
          case "345": nombre_cobertura="Daños Materiales por Riesgos Catastróficos y No Catastróficos"; break;
          }
          break;
          case "TDINCEN" :
          //console.log('ingrese a TDINCEN');
          switch (json.data.vCoberturasXPoliza.codigoCobertura) {
          case "10": nombre_cobertura="REMOCION DE ESCOMBROS"; break;
          case "11": nombre_cobertura="GASTOS DE EXTINCIÓN DE INCENDIOS"; break;
          case "12": nombre_cobertura="VALORES,  DOCUMENTOS"; break;
          case "13": nombre_cobertura="HURACAN, TORMENTAS Y VIENTOS."; break;
          case "108": nombre_cobertura="ARRIENDO DE LOCALES DE LOCALES PROVISORIOS"; break;
          case "100": nombre_cobertura="I. TODO RIESGO CONDOMINIOS  PARA BIENES MUEBLES E INMUEBLES"; break;
          case "110": nombre_cobertura="TRASLADOS TEMPORALES"; break;
          case "111": nombre_cobertura="MONTAJE Y DESMONTAJE"; break;
          case "112": nombre_cobertura="TRANSITO INCIDENTAL"; break;
          case "113": nombre_cobertura="SERVICIO DE ASISTENCIA DOMICILARIA G&T"; break;
          case "28": nombre_cobertura="DINERO Y/O VALORES FUERA DEL LOCAL"; break;
          case "114": nombre_cobertura="EXPLOSION, MOTIN, HUELGAS Y/O ALBOROTOS POPULARES, DAÑO MALICIOSO"; break;
          case "109": nombre_cobertura="DAÑOS POR AGUA"; break;
          case "41": nombre_cobertura="RESPONSABILIDAD CIVIL LEGAL DE INCENDIO"; break;
          case "44": nombre_cobertura="R.C. DE PRODUCTOS O TRABAJOS TERMINADOS"; break;
          case "45": nombre_cobertura="INTERRUPCION DE NEGOCIOS PARA RIESGOS INDUSTRIALES"; break;
          case "546": nombre_cobertura="INCENDIO Y/O RAYO"; break;
          case "25": nombre_cobertura="ATRACO DE CONTENIDO"; break;
          case "26": nombre_cobertura="CRISTALES Y/O ROTULOS"; break;
          case "27": nombre_cobertura="DINERO Y/O VALORES DENTRO DEL LOCAL"; break;
          case "29": nombre_cobertura="IMPACTO DE NAVES ACUATICAS"; break;
          case "30": nombre_cobertura="OTRAS COBERTURAS"; break;
          case "91": nombre_cobertura="INTERRUPCION RENTAS DE ALQUILER"; break;
          case "48": nombre_cobertura="HONORARIOS PROFESIONALES"; break;
          case "14": nombre_cobertura="DAÑO MALICIOSO ESPECIAL  (TERRORISMO)"; break;
          case "15": nombre_cobertura="CORTO CIRCUITO"; break;
          case "32": nombre_cobertura="FALLA ELECTRICA"; break;
          case "34": nombre_cobertura="EXPLOSION DE CALDERAS"; break;
          case "35": nombre_cobertura="TRANSPORTE TERRESTRE"; break;
          case "36": nombre_cobertura="HURTO Y DESAPARICION MISTERIOSA"; break;
          case "37": nombre_cobertura="MONTAJE Y DESMONTAJE"; break;
          case "38": nombre_cobertura="ROTULOS LUMINOSOS"; break;
          case "39": nombre_cobertura="RESPONSABILIDAD CIVIL FAMILIAR"; break;
          case "17": nombre_cobertura="FRAUDE POR COMPUTADORAS DE TERCERAS PERSONAS Y EN TRANSFERENCIAS DE FONDOS"; break;
          case "18": nombre_cobertura="FALSIFICACION DE TARJETAS DE CREDITO"; break;
          case "19": nombre_cobertura="FALSIFICACION DE CHEQUES ENTRANTES"; break;
          case "20": nombre_cobertura="FALSIFICACION DE DEPOSITANTES"; break;
          case "21": nombre_cobertura="ACTOS DOLOSOS DE LOS TRABAJADORES"; break;
          case "22": nombre_cobertura="PARAGUAS PARA ALMACENADORA"; break;
          case "23": nombre_cobertura="DOLO O MALA FE PARA ALMACENADORA"; break;
          case "24": nombre_cobertura="ROBO POR FORZAMIENTO DE LADRONES"; break;
          case "7": nombre_cobertura="ROBO Y/O ATRACO"; break;
          case "159": nombre_cobertura="RESPONSABILIDAD CIVIL"; break;
          case "42": nombre_cobertura="AIG ASSIST"; break;
          case "43": nombre_cobertura="RESPONSABILIDAD CIVIL DE GARAGE KEEPER"; break;
          case "40": nombre_cobertura="RESPONSABILIDAD  CIVIL DE PREDIOS Y OPERACIONES"; break;
          case "92": nombre_cobertura="ROTURA DE MAQUINARIA"; break;
          case "127": nombre_cobertura="TERRORISMO (RJK)"; break;
          case "128": nombre_cobertura="MAQUINARIA EN MOVIMIENTO"; break;
          case "129": nombre_cobertura="TRANSPORTE INCIDENTAL DE BIENES"; break;
          case "130": nombre_cobertura="HURTO Y/O PILLAJE POST SINIESTRO"; break;
          case "131": nombre_cobertura="GASTOS DE LIMPIEZA Y DESCONTAMINACIÓN"; break;
          case "132": nombre_cobertura="TODO RIESGO DE CONSTRUCCIÓN Y MONTAJE"; break;
          case "148": nombre_cobertura="AMPARO AUTOMÁTICO DE NUEVAS PROPIEDADES"; break;
          case "149": nombre_cobertura="DAÑO ELÉCTRICO"; break;
          case "158": nombre_cobertura="RESPONSABILIDAD CIVIL DE CONDOMINIOS"; break;
          case "176": nombre_cobertura="RESPONSABILIDAD CIVIL INCENDIO Y/O EXPLOSION"; break;
          case "150": nombre_cobertura="Transporte"; break;
          case "152": nombre_cobertura="AMPARO PROPIEDAD PERSONAL EMPLEADOS"; break;
          case "153": nombre_cobertura="ALTERACIONES Y REPARACIONES"; break;
          case "154": nombre_cobertura="ASISTENCIA DOMICILIAR"; break;
          case "155": nombre_cobertura="EQUIPO ELECTRONICO"; break;
          case "156": nombre_cobertura="MATERIA PRIMA Y PRODUCTO TERMINADO"; break;
          case "117": nombre_cobertura="INUNDACION Y/O MAREMOTO"; break;
          case "119": nombre_cobertura="GASTOS DE CERRAJERIA"; break;
          case "123": nombre_cobertura="COBERTURA DE EQUIPO DE PROCESAMIENTO DE DATOS"; break;
          case "120": nombre_cobertura="COBERTURA DE ACCIDENTES PERSONALES"; break;
          case "121": nombre_cobertura="GASTOS EXTRAORDINARIOS"; break;
          case "133": nombre_cobertura="INTERRUPCIÓN DE FLUIDO ELECTRICO FUERA DE LAS INSTALACIONES"; break;
          case "101": nombre_cobertura="II. RESPONSABILIDAD CIVIL CONDOMINIOS"; break;
          case "102": nombre_cobertura=" R. C. PARA AREAS DE ESTACIONAMIENTOS Y PARQUEOS"; break;
          case "103": nombre_cobertura="IV.  CUOTAS DE ADMINISTRACION"; break;
          case "104": nombre_cobertura="1.  ROTURA DE MAQUINARIA"; break;
          case "157": nombre_cobertura="ROBO POR FORZAMIENTO DE LADRONES y/o ROBO AGRAVADO"; break;
          case "106": nombre_cobertura=" AMPARO AUTOMATICO DE GASTOS ADICIONALES"; break;
          case "107": nombre_cobertura="EXTENSIONES AUTOMATICAS"; break;
          case "16": nombre_cobertura="DEMOLICION"; break;
          case "115": nombre_cobertura="PILLAJE POST-SINIESTRO"; break;
          case "122": nombre_cobertura="COBERTURA DE TARJETA DE CREDITO"; break;
          case "118": nombre_cobertura="TODO RIESGO MULTICOBERTURAS"; break;
          case "124": nombre_cobertura="MOTIN Y/O HUELGAS Y/O CONMOCION CIVIL Y/O DAÑO MALICIOSO"; break;
          case "125": nombre_cobertura="MOTIN Y/O HUELGAS Y/O CONMOCION CIVIL Y/O DAÑO MALICIOSO Y/O EXPLOSION"; break;
          case "126": nombre_cobertura="TERREMOTO, TEMBLOR Y/O ERUPCION VOLCANICA, CAIDA DE CENIZA Y/O ARENA ..."; break;
          case "6": nombre_cobertura="DAÑO MALICIOSO ESPECIAL"; break;
          case "5": nombre_cobertura="TERREMOTO, TEMBLOR Y/O ERUPCION VOLCANICA"; break;
          case "2": nombre_cobertura="TODO RIESGO  (2)"; break;
          case "3": nombre_cobertura="TODO RIESGO  (3)"; break;
          case "1": nombre_cobertura="TODO RIESGO  DE INCENDIO"; break;
          case "4": nombre_cobertura="MOTIN  y HUELGAS  Y ALBOROTOS POPULARES"; break;
          case "9": nombre_cobertura="DINERO Y/O VALORES"; break;
          case "178": nombre_cobertura="TRANSPORTE INCIDENTAL"; break;
          case "179": nombre_cobertura="INT. DE NEGOCIOS COMERCIAL E INT. DE RENTAS DE ALQUILER"; break;
          case "180": nombre_cobertura="CUOTAS DE ADMINISTRACION"; break;
          case "181": nombre_cobertura="WALLET GUARD"; break;
          case "182": nombre_cobertura="ACCIDENTES PERSONALES"; break;
          case "177": nombre_cobertura="REPARACIONES PROVISORIAS"; break;
          case "135": nombre_cobertura="DAÑO ELECTRICO"; break;
          case "136": nombre_cobertura="CAR/EAR"; break;
          case "137": nombre_cobertura="ROTURA DE MAQUINARIA Y/O EXPLOSION DE CALDERAS"; break;
          case "138": nombre_cobertura="INT. DE NEGOCIOS RIESGOS INDUSTRIALES INTERDEPENDIENTE"; break;
          case "141": nombre_cobertura="RESPONSABILIDAD CIVIL PATRONAL"; break;
          case "142": nombre_cobertura="RESPONSABILIDAD CIVIL DE ELEVADORES Y/O GRADAS ELECTRICAS"; break;
          case "143": nombre_cobertura="GASTOS MÉDICOS VOLUNTARIOS"; break;
          case "144": nombre_cobertura="CUENTAS POR COBRAR"; break;
          case "145": nombre_cobertura="CAJAS FUERTES"; break;
          case "46": nombre_cobertura="INTERRUPCIÓN DE NEGOCIOS PARA RIESGOS COMERCIALES"; break;
          case "147": nombre_cobertura="GASTOS EXTRAS"; break;
          case "151": nombre_cobertura="INTERRUPCIÓN DE NEGOCIOS RIESGOS INDUSTRIALES Y COMERCIALES"; break;
          case "160": nombre_cobertura="RESPONSABILIDAD CIVIL DE TRANSPORTISTAS"; break;
          case "161": nombre_cobertura="RESPONSABILIDAD CIVIL DE POLUCION O CONTAMINACION"; break;
          case "162": nombre_cobertura="RESPONSABILIDAD CIVIL GENERAL"; break;
          case "134": nombre_cobertura="EXTENSION DE COBERTURA A PROVEEDORES Y CLIENTES"; break;
          case "139": nombre_cobertura="PÉRDIDAS CONSECUENCIALES"; break;
          case "140": nombre_cobertura="Gastos Adicionales y/o Extras"; break;
          case "163": nombre_cobertura="GASTOS DE APRESURAMIENTO"; break;
          case "164": nombre_cobertura="CONTAMINACION DE AMONIACO"; break;
          case "165": nombre_cobertura="DAÑOS POR AGUA"; break;
          case "166": nombre_cobertura="RECONSTRUCCION DE DOCUMENTOS"; break;
          case "167": nombre_cobertura="ROTURA DE CALDERAS Y MAQUINARIA"; break;
          case "168": nombre_cobertura="AMPARO AUTOMATICO DE GASTOS ADICIONALES DERIVADOS DE SINIESTRO"; break;
          case "169": nombre_cobertura="ALTERACIONES, ADICIONES Y MEJORAS"; break;
          case "170": nombre_cobertura="ALQUILER DE PLANTA DE GENERACION DE ENERGIA ELECTRICA"; break;
          case "171": nombre_cobertura="RESPONSABILIDAD CIVIL CONTRACTURAL"; break;
          case "172": nombre_cobertura="RESPONSABILIDAD CIVIL CRUZADA"; break;
          case "173": nombre_cobertura="RESPONSABILIDAD CIVIL CONTRATISTAS Y SUBCONTRATISTAS INDEPENDIENTES"; break;
          case "174": nombre_cobertura="RESPONSABILIDAD CIVIL PARA PARQUEOS Y ESTACIONAMIENTOS"; break;
          case "175": nombre_cobertura="DINERO Y CHEQUES EN LA OFICINA ADMINISTRATIVA"; break;
          case "547": nombre_cobertura="VEHICULOS CON MOTORES APAGADOS"; break;
          case "607": nombre_cobertura="REMOCION DE ESCOMBROS"; break;
          case "608": nombre_cobertura="GASTOS DE EXTINCIÓN DE INCENDIOS"; break;
          case "609": nombre_cobertura="VALORES,  DOCUMENTOS"; break;
          case "610": nombre_cobertura="HURACAN, TORMENTAS Y VIENTOS."; break;
          case "611": nombre_cobertura="ARRIENDO DE LOCALES DE LOCALES PROVISORIOS"; break;
          case "612": nombre_cobertura="I. TODO RIESGO CONDOMINIOS  PARA BIENES MUEBLES E INMUEBLES"; break;
          case "613": nombre_cobertura="TRASLADOS TEMPORALES"; break;
          case "614": nombre_cobertura="MONTAJE Y DESMONTAJE"; break;
          case "615": nombre_cobertura="TRANSITO INCIDENTAL"; break;
          case "616": nombre_cobertura="SERVICIO DE ASISTENCIA DOMICILARIA G&T"; break;
          case "617": nombre_cobertura="DINERO Y/O VALORES FUERA DEL LOCAL"; break;
          case "618": nombre_cobertura="EXPLOSION, MOTIN, HUELGAS Y/O ALBOROTOS POPULARES, DAÑO MALICIOSO"; break;
          case "286": nombre_cobertura="T Riesgos No Catastróficos"; break;
          case "287": nombre_cobertura="U Riesgos Catastróficos"; break;
          case "288": nombre_cobertura="A Incendio Casual y Rayo"; break;
          case "289": nombre_cobertura="B Riesgos Varios"; break;
          case "290": nombre_cobertura="C Inundación, Deslizamiento Vientos Inundación"; break;
          case "291": nombre_cobertura="D Convulsiones de la Naturaleza"; break;
          case "292": nombre_cobertura="E Daño Directo a la Mercancía"; break;
          case "293": nombre_cobertura="F Pérdida de Beneficios"; break;
          case "294": nombre_cobertura="G Lluvia y Derrame "; break;
          case "295": nombre_cobertura="H Pérdida de Rentas por contrato de arrendamiento"; break;
          case "296": nombre_cobertura="I Rotura de Cristales"; break;
          case "297": nombre_cobertura="Q Gastos Extra"; break;
          case "298": nombre_cobertura="A Incendio Casual y Rayo"; break;
          case "299": nombre_cobertura="B Riesgos Varios"; break;
          case "300": nombre_cobertura="C Inundación , Deslizamiento y Vientos"; break;
          case "301": nombre_cobertura="D Convulsiones de la Naturaleza"; break;
          case "302": nombre_cobertura="H Pérdida de Rentas por contrato de arrendamiento"; break;
          case "303": nombre_cobertura="I Rotura de Cristales"; break;
          case "304": nombre_cobertura="R Gastos por Alquiler"; break;
          case "305": nombre_cobertura="V Daño directo de bienes inmuebles"; break;
          case "306": nombre_cobertura="Y Daño directo de contenidos (opción 1,2 y 3)"; break;
          case "307": nombre_cobertura="D Convulsiones de la naturaleza"; break;
          case "308": nombre_cobertura="H Pérdida de rentas por contrato de arrendamiento"; break;
          case "309": nombre_cobertura="K Responsabilidad Civil"; break;
          case "310": nombre_cobertura="M Riesgos del Trabajo Servidores Domesticos"; break;
          case "311": nombre_cobertura="P Accidentes Personales Daños Materiales"; break;
          case "312": nombre_cobertura="Incendio"; break;
          case "313": nombre_cobertura="Agua al sofocar un incendio "; break;
          case "314": nombre_cobertura="Inundación , Deslizamiento y Vientos"; break;
          case "315": nombre_cobertura="Impacto de rayo."; break;
          case "316": nombre_cobertura="Humo u hollín proveniente de un incendio"; break;
          case "317": nombre_cobertura="Implosión y/o Explosión de cualquier tipo"; break;
          case "318": nombre_cobertura="Impacto de vehículos terrestres o aéreos u objetos caídos del cielo"; break;
          case "319": nombre_cobertura="Caída objetos contra la propiedad asegurada."; break;
          case "320": nombre_cobertura="Incendio y Daño Directo por Terremoto"; break;
          case "321": nombre_cobertura="Daño Directo por Vendaval, huracán, tornado, tromba o granizo"; break;
          case "322": nombre_cobertura="Daño Directo por Inundación"; break;
          case "323": nombre_cobertura="Extensión de Cobertura Catastrófica"; break;
          case "324": nombre_cobertura="Incendio y daño por desórdenes públicos."; break;
          case "325": nombre_cobertura="Daño Directo por Maldad"; break;
          case "326": nombre_cobertura="Saqueo"; break;
          case "327": nombre_cobertura="Pérdida consiguiente por falta de refrigeración"; break;
          case "328": nombre_cobertura="Pérdida de Renta"; break;
          case "329": nombre_cobertura="Lucro Cesante (Pérdida de Beneficios)"; break;
          case "330": nombre_cobertura="A Incendio Casual y Rayo"; break;
          case "331": nombre_cobertura="B Riesgos de Naturaleza Catastrófica Inundación"; break;
          case "332": nombre_cobertura="C Daños por Agua y Riesgos Atmosféricos"; break;
          case "333": nombre_cobertura="D Rotura de Cristales, Domos, Mármoles y Granitos."; break;
          case "334": nombre_cobertura="E Robo"; break;
          case "335": nombre_cobertura="F Gastos por Alquiler"; break;
          case "336": nombre_cobertura="G Pérdida de Rentas  "; break;
          case "337": nombre_cobertura="I Bienes Refrigerados o Congelados"; break;
          case "338": nombre_cobertura="J Mercancía en Tránsito"; break;
          case "339": nombre_cobertura="L Rotura de Maquinaria"; break;
          case "340": nombre_cobertura="M Equipo Electrónico"; break;
          case "341": nombre_cobertura="P Interrupción de Negocios"; break;
          case "342": nombre_cobertura="Q Multiasistencia"; break;
          case "343": nombre_cobertura="H Responsabilidad Civil Extracontractual y Subjetiva"; break;
          case "344": nombre_cobertura="Q Asistencia en la Vivienda"; break;
          case "345": nombre_cobertura="Daños Materiales por Riesgos Catastróficos y No Catastróficos"; break;
          }  break;
          case 'RC':
          //console.log('ingrese a RC');
          switch (json.data.vCoberturasXPoliza.codigoCobertura) {
          case "28": nombre_cobertura="RESPONSABILIDAD RESERVA DE DOMINIO"; break;
          case "46": nombre_cobertura="R.C. PARA VUELOS CHARTER"; break;
          case "29": nombre_cobertura="RESPONSABILIDAD LEGAL POR DAÑOS POR INCENDIO"; break;
          case "47": nombre_cobertura="RESPONSABILIDAD LEGAL POR DAÑOS POR INCENDIO"; break;
          case "48": nombre_cobertura="OTRAS COBERTURAS"; break;
          case "49": nombre_cobertura="DEMOLICION"; break;
          case "44": nombre_cobertura="RESPONSABILIDAD CIVIL GASOLINERAS"; break;
          case "41": nombre_cobertura="RESPONSABILIDAD CIVIL BAJO LEYES DE BEBIDAS ALCOHOLICAS"; break;
          case "42": nombre_cobertura="RESPONSABILIDAD CIVIL POR EMBARCACIONES QUE NO SON PROPIEDAD DEL ASEGURADO"; break;
          case "29": nombre_cobertura="RESPONSABILIDAD CIVIL POR CONTAMINACION DEL MEDIO AMBIENTE"; break;
          case "30": nombre_cobertura="R.C. MAQUINAS AGRICOLAS"; break;
          case "31": nombre_cobertura="USO O TENENCIA DE COMBUSTIBLES"; break;
          case "32": nombre_cobertura="TRANSPORTE DE COMBUSTIBLES"; break;
          case "33": nombre_cobertura="RESPONSABILIDAD CIVIL CONTAMINACIÓN POR CONTACTO CON OTRAS CARGAS."; break;
          case "36": nombre_cobertura="RESPONSABILIDAD CIVIL LESIONES PERSONALES Y PUBLICITARIAS"; break;
          case "37": nombre_cobertura="REPATRIACION"; break;
          case "38": nombre_cobertura="RESPONSABILIDAD CIVIL DE AUTO CONTINGENTE"; break;
          case "39": nombre_cobertura="MALA PRACTICA HOSPITALARIA"; break;
          case "40": nombre_cobertura="RESPONSABILIDAD CIVIL DE MONTACARGAS"; break;
          case "35": nombre_cobertura="NEGLIGENCIA"; break;
          case "23": nombre_cobertura="RESPONSABILIDAD CIVIL GUARDIAS"; break;
          case "43": nombre_cobertura="R.C. MAL MANEJO"; break;
          case "50": nombre_cobertura="RESPONSABILIDAD DE DIRECTORES Y OFICIALES"; break;
          case "51": nombre_cobertura="RESPONSABILIDAD CIVIL DE MINERÍA"; break;
          case "52": nombre_cobertura="RESPONSABILIDAD CIVIL SUBCONTRATISTAS"; break;
          case "53": nombre_cobertura="RESPONSABILIDAD CIVIL DE ROTULOS"; break;
          case "54": nombre_cobertura="RESPONSABILIDAD CIVIL DE GASTOS MÉDICOS VOLUNTARIOS"; break;
          case "57": nombre_cobertura="Responsabilidad Civil de Restaurantes"; break;
          case "59": nombre_cobertura="GASTOS MÉDICOS VOLUNTARIOS"; break;
          case "62": nombre_cobertura="RESPONSABILIDAD CIVIL DE CONDOMINIO"; break;
          case "56": nombre_cobertura="HOLE IN ONE"; break;
          case "58": nombre_cobertura="EXCESOS DE RESPONSABILIDAD CIVIL"; break;
          case "63": nombre_cobertura="RESP. CIVIL CLUBS Y ASOCIACIONES SOCIALES Y DEPORTIVAS"; break;
          case "45": nombre_cobertura="CUOTAS DE ADMINISTRACION"; break;
          case "60": nombre_cobertura="R. C. Otros"; break;
          case "34": nombre_cobertura="RESPONSABILIDAD  CIVIL  DE ESTACIONAMIENTOS"; break;
          case "6": nombre_cobertura="RESPONSABILIDAD  CIVIL  DE TRANSPORTISTAS"; break;
          case "7": nombre_cobertura="RESPONSABILIDAD CIVIL FAMILIAR"; break;
          case "8": nombre_cobertura="RESPONSABILIDAD CIVIL GENERAL"; break;
          case "9": nombre_cobertura="RESPONSABILIDAD CIVIL PROFESIONAL"; break;
          case "10": nombre_cobertura="RESPONSABILIDAD CIVIL PENAL O CRIMINAL"; break;
          case "11": nombre_cobertura="RESPONSABILIDAD CIVIL DE ELEVADORES y/o GRADAS ELECTRICAS"; break;
          case "12": nombre_cobertura="RESPONSABILIDAD CIVIL CRUZADA"; break;
          case "13": nombre_cobertura="RESPONSABILIDAD CIVIL DE PARQUEOS Y/O ESTACIONAMIENTOS"; break;
          case "14": nombre_cobertura="RESPONSABILIDAD  CIVIL  DE CONTRATISTAS"; break;
          case "15": nombre_cobertura="RESPONSABILIDAD CIVIL DE VEHICULOS"; break;
          case "16": nombre_cobertura="RESPONSABILIDAD CIVIL ASUMIDA"; break;
          case "17": nombre_cobertura="R. C. POR DAÑOS A TERCEROS EN SUS PERSONAS"; break;
          case "18": nombre_cobertura="RESPONSABILIDAD CIVIL DE CONSTRUCCION"; break;
          case "19": nombre_cobertura="R. C. POR DAÑOS A TERCEROS EN SUS BIENES"; break;
          case "22": nombre_cobertura="EXCESOS DE R. C. DE VEHICULOS"; break;
          case "24": nombre_cobertura="RESPONSABILIDAD CIVIL POR EXPLOSION DE CALDERAS Y/O APARATOS QUE OPERAN A PRESIO"; break;
          case "25": nombre_cobertura="*********SUSTITUR POR LA COBERTURA 23*******"; break;
          case "26": nombre_cobertura="RESPONSABILIDAD CIVIL POR CAIDA PARCIAL O TOTAL DE ROTULOS, ARBOLES Y/O RAMAS"; break;
          case "27": nombre_cobertura="RESPONSABILIDAD DE GARAGE KEEPERS"; break;
          case "20": nombre_cobertura="RESPONSABILIDAD CIVIL DE DIRECTIVOS Y CONSEJEROS"; break;
          case "21": nombre_cobertura="RESPONSABILIDAD CIVIL DE ESTIBADORES"; break;
          case "1": nombre_cobertura="RESPONSABILIDAD  CIVIL  LEGAL DE INCENDIO Y/O EXPLOSION"; break;
          case "2": nombre_cobertura="RESPONSABILIDAD  CIVIL  DE PREDIOS Y OPERACIONES"; break;
          case "3": nombre_cobertura="RESPONSABILIDAD  CIVIL  DE PRODUCTOS O TRABAJOS TERMINADOS"; break;
          case "4": nombre_cobertura="RESPONSABILIDAD  CIVIL CONTRACTUAL O ASUMIDA"; break;
          case "5": nombre_cobertura="RESPONSABILIDAD  CIVIL PATRONAL"; break;
          case "68": nombre_cobertura="RESPONSABILIDAD CIVIL"; break;
          case "70": nombre_cobertura="RESPONSABILIDAD CIVIL DERIVADA DE LA VIOLACION DE PATENTES"; break;
          case "65": nombre_cobertura="RESPONSABILIDAD CIVIL DE NAVES AEREAS"; break;
          case "67": nombre_cobertura="REEMBOLSO A LA SOCIEDAD POR INDEMNIZACIÓN"; break;
          case "69": nombre_cobertura="RESPONSABILIDAD CIVIL DE DIRECTIVOS Y FUNCIONARIOS"; break;
          case "64": nombre_cobertura="RESPONSABILIDAD COLECTIVA DE PASAJEROS"; break;
          case "66": nombre_cobertura="RESPONSABILIDAD CIVIL DIRECTORES Y ADMINISTRADORES"; break;
          case "61": nombre_cobertura="RESPONSABILIDAD CIVIL MAQUINARIA Y EQUIPO MÓVIL"; break;
          case "71": nombre_cobertura="RESPONSABILIDAD CIVIL HOTELERA"; break;
          case "200": nombre_cobertura="A- RESPONSABILIDAD CIVIL POR LESION O MUERTE DE PERSONAS"; break;
          case "201": nombre_cobertura="B-	ATENCIÓN MEDICA INMEDIATA"; break;
          case "202": nombre_cobertura="C-RESPONSABILIDAD CIVIL POR DAÑOS A PROPIEDADES DE TERCEROS"; break;
          case "203": nombre_cobertura="D-SUBLÍMITE DE LAVANDERÍAS Y GUARDARROPAS"; break;
          case "204": nombre_cobertura="E-EQUIPAJE DE HUESPEDES"; break;
          case "205": nombre_cobertura="F-RESGUARDOS DE CAJAS DE SEGURIDAD"; break;
          case "206": nombre_cobertura="L-RESPONSABILIDAD CIVIL (COB A Y C)     "; break;
          case "207": nombre_cobertura="M-RESPONSABILIDAD CIVIL PRODUCTOS"; break;
          case "208": nombre_cobertura="N-RESPONSABILIDAD CIVIL PATRONAL"; break;
          case "209": nombre_cobertura="O-RESPONSABILIDAD CIVIL COLISION O VUELCO DE VEHÍCULOS"; break;
          case "210": nombre_cobertura="P-RESPONSABILIDAD CIVIL ROBO DE VEHÍCULOS"; break;
          case "211": nombre_cobertura="Q-RESPONSABILIDAD CIVIL PRUEBA DE VEHÍCULOS PARA TALLERES"; break;
          case "212": nombre_cobertura="R-RESPONSABILIDAD CIVIL ATENCIÓN MÉDICA INMEDIATA"; break;
          case "213": nombre_cobertura="RESPONSABILIDAD CIVIL CONSTRUCCION"; break;
          case "214": nombre_cobertura="RESPONSABILIDAD CIVIL SERVICIOS"; break;
          case "215": nombre_cobertura="RESPONSABILIDAD CIVIL INDUSTRIAS"; break;
          case "216": nombre_cobertura="RESPONSABILIDAD CIVIL LOCALES COMERCIALES"; break;
          case "217": nombre_cobertura="RESPONSABILIDAD CIVIL HOTELES Y SIMILARES"; break;
          case "218": nombre_cobertura="RESPONSABILIDAD CIVIL OPERADORES TURISTICOS"; break;
          case "219": nombre_cobertura="RESPONSABILIDAD CIVIL PARQUEOS"; break;
          case "220": nombre_cobertura="RESPONSABILIDAD CIVIL TALLERES"; break;
          case "221": nombre_cobertura="RESPONSABILIDAD CIVIL AUTOLAVADOS"; break;
          case "222": nombre_cobertura="RESPONSABILIDAD CIVIL ESTACIONES DE SERVICIO"; break;
          case "223": nombre_cobertura="RESPONSABILIDAD CIVIL ESPECTACULOS PUBLICOS"; break;
          case "224": nombre_cobertura="RESPONSABILIDAD CIVIL EVENTOS TAURINOS Y PIROTECNICOS"; break;
          case "225": nombre_cobertura="RESPONSABILIDAD CIVIL JUEGOS ELECTROMECANICOS"; break;
          case "226": nombre_cobertura="RESPONSABILIDAD CIVIL FAMILIAR Y PRIVADA"; break;
          case "227": nombre_cobertura="RESPONSABILIDAD CIVIL PREDIOS Y OPERACIONES"; break;
          case "228": nombre_cobertura="RESPONSABILIDAD CIVIL FABRICANTES Y CONTRATISTAS"; break;
          case "229": nombre_cobertura="RESPONSABILIDAD CIVIL DUEÑOS. PROPIETARIOS Y ARRENDATARIOS"; break;
          case "230": nombre_cobertura="RESPONSABILIDAD CIVIL ALMACENADORA"; break;
          case "231": nombre_cobertura="RESPONSABILIDAD CIVIL PRODUCTOS"; break;
          case "232": nombre_cobertura="COBERTURA GENERAL COMPRENSIVA"; break;
          case "233": nombre_cobertura="RESPONSABILIDDAD CIVIL CRUZADA"; break;
          case "234": nombre_cobertura="COBERTURA DE RESPONSABILIDAD CIVIL LEGAL DE DAÑOS POR INCENDIO"; break;
          case "235": nombre_cobertura="RESPONSABILIAD CIVIL LEGAL"; break;
          case "236": nombre_cobertura="RESPONSABILIAD CIVIL LEGAL CONTRACTUAL"; break;
          case "237": nombre_cobertura="RESPONSABILIAD CIVIL LEGAL EXTRA CONTRACTUAL"; break;
          case "238": nombre_cobertura="RESPONSABILIAD CIVIL CONTRACTUAL"; break;
          case "239": nombre_cobertura="RESPONSABILIAD CIVIL EXTRA CONTRACTUAL"; break;
          case "240": nombre_cobertura="U: Responsabilidad Civil Umbrella"; break;
          case "72": nombre_cobertura="RESPONSABILIDAD CIVIL PREDIO Y OPERACIONES INDUSTRIALES"; break;
          case "241": nombre_cobertura="RESPONSABILIDAD CIVIL CONTRATISTAS SUBCONTRATISTAS"; break;
          case "242": nombre_cobertura="RESPONSABILIDAD CIVIL DE ELEVADORES Y/O ESCALERAS ELECTRICAS"; break;
          case "243": nombre_cobertura="RESPONSABILIDAD CIVIL DE TRANSPORTISTAS"; break;
          case "244": nombre_cobertura="RESPONSABILIDAD CIVIL EN EXCESO DE AUTOMOVIL"; break;
          case "245": nombre_cobertura="RESPONSABILIDAD CIVIL POR CONTAMINACIÓN AL MEDIO AMBIENTE"; break;
          case "246": nombre_cobertura="RESPONSABILIDAD CIVIL POR INCENDIO Y/O EXPLOSION"; break;
          case "247": nombre_cobertura="RESPONSABILIDAD CIVIL POR DAÑOS PATRIMONIALES PUROS"; break;
          case "248": nombre_cobertura="RESPONSABILIDAD CIVIL POR ONDAS Y CAMPOS ELECTROMAGNETICOS"; break;
          case "249": nombre_cobertura="RESPONSABILIDAD CIVL POST-TRABAJOS"; break;
          case "250": nombre_cobertura="RESPONSABILIDAD CIVIL POR VIOLACION DE PATENTES"; break;
          case "251": nombre_cobertura="RESPONSABILIDAD CIVIL POR PRACTICAS LABORALES"; break;
          case "252": nombre_cobertura="RESPONSABILIDAD CIVIL FIDUCIARIA DE PLANES DE PRESTACIONES PARA EMPLEADOS"; break;
          case "253": nombre_cobertura="RESPONSABILIDAD CIVIL CUIDADO, CONTROL Y CUSTODIA"; break;
          }
          break;
          case 'TR':
        //console.log('ingrese a TTR');
        switch (json.data.vCoberturasXPoliza.codigoCobertura) {
        case "27": nombre_cobertura="DAÑO MALICIOSO ESPECIAL (Terrorismo)"; break;
        case "23": nombre_cobertura="FALTA DE ENTREGA"; break;
        case "28": nombre_cobertura="CARGA Y DESCARGA"; break;
        case "29": nombre_cobertura="MOTIN, HUELGA, CONMOCION CIVIL, DAÑOS MALICIOSOS Y ACTOS DE TERRORISMO"; break;
        case "30": nombre_cobertura="MOTIN, HUELGA, CONMOCION CIVIL Y DAÑOS MALICIOSOS"; break;
        case "31": nombre_cobertura="AVERIA GRUESA"; break;
        case "21": nombre_cobertura="ATRACO"; break;
        case "32": nombre_cobertura="OTRAS COBERTURAS"; break;
        case "25": nombre_cobertura="CARGA SOBRE CUBIERTA"; break;
        case "26": nombre_cobertura="FLOTE"; break;
        case "22": nombre_cobertura="ROBO Y/O ATRACO"; break;
        case "24": nombre_cobertura="TODO RIESGO EXTERNO"; break;
        case "16": nombre_cobertura="TERREMOTO,TEMBLOR,ERUPCION VOLCANICA, CAIDA DE CENIZA Y/O ARENA VOLCANICA E INC"; break;
        case "17": nombre_cobertura="MAREMOTO"; break;
        case "18": nombre_cobertura="TEMBLOR"; break;
        case "19": nombre_cobertura="HURACAN"; break;
        case "20": nombre_cobertura="VIENTOS TEMPESTUOSOS"; break;
        case "34": nombre_cobertura="BODEGA A BODEGA"; break;
        case "35": nombre_cobertura="HUELGAS, ALBOROTOS POPULARES Y CONMOCION CIVIL"; break;
        case "36": nombre_cobertura="TODO RIESGO (INCISO C)"; break;
        case "37": nombre_cobertura="RATERIA"; break;
        case "38": nombre_cobertura="HUELGAS, ALBOROTOS POPULARES Y CONMOCION CIVIL ."; break;
        case "1": nombre_cobertura="RIESGOS ORDINARIOS DE TRANSITO TRANSPORTE TERRESTRE Y/O AEREO"; break;
        case "8": nombre_cobertura="TRANSBORDO Y RECUPERACION"; break;
        case "9": nombre_cobertura="ROTURAS"; break;
        case "2": nombre_cobertura="RIESGOS ORDINARIOS DE TRANSITO   TRANSPORTE MARITIMO"; break;
        case "7": nombre_cobertura="CONTACTO CON OTRAS CARGAS"; break;
        case "11": nombre_cobertura="MOTIN, HUELGAS Y ALBOROTOS POPULARES"; break;
        case "12": nombre_cobertura="GUERRA"; break;
        case "10": nombre_cobertura="FALTA DE PESO O DERRAME"; break;
        case "3": nombre_cobertura="ROBO DE BULTO POR ENTERO"; break;
        case "4": nombre_cobertura="ROBO PARCIAL"; break;
        case "5": nombre_cobertura="MOJADURA"; break;
        case "6": nombre_cobertura="OXIDACION"; break;
        case "13": nombre_cobertura="TODO RIESGO  1.1.82  (A)"; break;
        case "14": nombre_cobertura="C. A. P.   1.1.82   (B)"; break;
        case "15": nombre_cobertura=" L.A.P.  1.1.82  (C)"; break;
        case "39": nombre_cobertura="TODO RIESGO  1.1.63  (A)"; break;
        case "40": nombre_cobertura="PAGO DE IMPUESTOS ANTE SAT"; break;
        case "54": nombre_cobertura="Cobertura de riesgos de cláusulas del instituto de londres, para cargamentos “a”"; break;
        case "55": nombre_cobertura="Cobertura de riesgos de cláusulas del instituto de londres, para cargamentos “b”"; break;
        case "56": nombre_cobertura="Huelga"; break;
        case "57": nombre_cobertura="Guerra"; break;
        case "41": nombre_cobertura="Incendio, rayo, auto-ignición y explosión"; break;
        case "42": nombre_cobertura="Terremoto, temblor"; break;
        case "43": nombre_cobertura="Colisión del vehículo con otro vehículo u objeto"; break;
        case "44": nombre_cobertura="Vuelco del vehículo transportador de los bienes descritos"; break;
        case "45": nombre_cobertura="Vendaval, ciclón o tornado"; break;
        case "46": nombre_cobertura="Derrumbe de puentes o alcantarillas"; break;
        case "47": nombre_cobertura="Derrumbe de tierra, de piedras o de rocas"; break;
        case "48": nombre_cobertura="Inundación por el desbordamiento de ríos o quebradas"; break;
        case "49": nombre_cobertura="Saqueo"; break;
        case "50": nombre_cobertura="Robo o Asalto"; break;
        case "51": nombre_cobertura="Maniobras de Carga y Descarga"; break;
        case "52": nombre_cobertura="Refrigeración"; break;
        case "53": nombre_cobertura="Extraterritorialidad"; break;
        case "69": nombre_cobertura="Movimientos Bruscos"; break;
        case "58": nombre_cobertura="Riesgos del Medio de Transporte"; break;
        case "59": nombre_cobertura="Caída, Colisión o Vuelco de Mercancías"; break;
        case "60": nombre_cobertura="Caída de Mercancía en Predios"; break;
        case "61": nombre_cobertura="Fallas Mecánicas en el Sistema de Refrigeración"; break;
        case "62": nombre_cobertura="Todo Riesgo 1.1.82 A"; break;
        case "63": nombre_cobertura="Motin y Huelga"; break;
        case "64": nombre_cobertura="Robo y/o Atraco"; break;
        case "65": nombre_cobertura="Carga y Descarga"; break;
        case "66": nombre_cobertura="Bodega a Bodega"; break;
        case "67": nombre_cobertura="Estadía"; break;
        case "68": nombre_cobertura="Riesgos Ordinarios de Transito 1.1.82 C"; break;
        case "71": nombre_cobertura="TRANSBORDO"; break;
        case "72": nombre_cobertura="RECUPERACION"; break;
        case "73": nombre_cobertura="DESTRUCCION"; break;
        case "70": nombre_cobertura="Responsabilidad Civil Carga Vía Terrestre"; break;
        }
        break;
        }
        datosini.push({CODIGO:json.data.vCoberturasXPoliza.codigoCobertura,DESCRIPCION:nombre_cobertura,SUMA:aMoneda(json.data.vCoberturasXPoliza.sumaAsegurada, opcionesQuetzales)});
    }else
    {
      for (var i = 0; i < json.data.vCoberturasXPoliza.length; i++) 
      {
        switch (json.data.vCoberturasXPoliza[i].codigoTipoPoliza) 
        {
            case 'EE':
              //console.log('ingrese a equipo');
                switch (json.data.vCoberturasXPoliza[i].codigoCobertura) {
                  case 9: nombre_cobertura="DAÑO MALICIOSO ESPECIAL  TERRORISMO "; break;
            case 23: nombre_cobertura="HURACAN, CICLON, TIFON"; break;
            case 17: nombre_cobertura="OTRAS COBERTURAS"; break;
            case 14: nombre_cobertura="FALLA APROVISIONAMIENTO DE LA CORRIENTE ELECTRICA"; break;
            case 18: nombre_cobertura="TRANSPORTE INCIDENTAL"; break;
            case 19: nombre_cobertura="MOTIN, HUELGAS Y/O ALBOROTOS POPULARES, DAÑOS MALICIOSOS E INCENDIO CONSECUTIVO"; break;
            case 20: nombre_cobertura="EXPLOSION, MOTIN, HUELGAS Y/O ALBOROTOS POPULARES, DAÑO MALICIOSO"; break;
            case 24: nombre_cobertura="MOTIN, HUELGA Y/O CONMOCION CIVIL"; break;
            case 16: nombre_cobertura="TODO RIESGO"; break;
            case 15: nombre_cobertura="INTERRUPCION DE NEGOCIOS PARA RIESGOS COMERCIALES"; break;
            case 22: nombre_cobertura="TERREMOTO"; break;
            case 21: nombre_cobertura="COBERTURA DE EQUIPOS MOVILES Y PORTATILES FUERA DE LOS PREDIOS ASEGURADOS"; break;
            case 7: nombre_cobertura="CORTO CIRCUITO, ALTERACIONES DE LA CORRIENTE ELECTRICA."; break;
            case 8: nombre_cobertura="GASTOS DE REGRABACION O REPROCESAMIENTO DE DATOS"; break;
            case 12: nombre_cobertura="INCREMENTO EN EL COSTO DE LA OPERACION"; break;
            case 13: nombre_cobertura="ROBO Y/O ATRACO"; break;
            case 1: nombre_cobertura="SECCION I : DAÑOS MATERIALES"; break;
            case 2: nombre_cobertura="SECCION II :  PORTADORES EXTERNOS DE DATOS"; break;
            case 3: nombre_cobertura="SECCION III :   GASTOS EXTRAS"; break;
            case 4: nombre_cobertura="TERREMOTO, TEMBLOR Y/O ERUPCION VOLCANICA E INCENDIO CONSECUTIVO"; break;
            case 5: nombre_cobertura="DAÑO MALICIOSO"; break;
            case 6: nombre_cobertura="ROBO POR FORZAMIENTO DE LADRONES"; break;
            case 25: nombre_cobertura="TRANSPORTE"; break;
            case 58: nombre_cobertura="A.- INCENDIO CASUAL RAYO Y EXPLOSIÓN"; break;
            case 59: nombre_cobertura="B.- ROBO"; break;
            case 60: nombre_cobertura="C.- MOTIN, HUELGA, PARA LEGAL"; break;
            case 61: nombre_cobertura="D.- HURACLÁN, CICLON, INUNDACIÓN, DESLIZAMIENTO"; break;
            case 62: nombre_cobertura="F.-TEMBLOR TERREMOTO"; break;
            case 63: nombre_cobertura="G. DANO MALICIOSO"; break;
            case 64: nombre_cobertura="H.- DAÑOS POR COLISIÓN Y VUELCO"; break;
            case 26: nombre_cobertura="A - Daño Directo Equipo Electronico"; break;
            case 27: nombre_cobertura="B - Robo"; break;
            case 28: nombre_cobertura="E - Equipo Móvil Y/O Portátil"; break;
            case 29: nombre_cobertura="C - Eventos De Las Naturaleza"; break;
            case 30: nombre_cobertura="D - Otros Riesgos"; break;
            case 31: nombre_cobertura="S - Portadores Externos De Datos"; break;
            case 32: nombre_cobertura="R - Daño Directo Equipo Electronico"; break;
            case 33: nombre_cobertura="E - Equipo Móvil Y/O Portátil"; break;
            case 34: nombre_cobertura="S - Portadores Externos De Datos"; break;
            case 35: nombre_cobertura="A - Responsabilidad Civil Por Lesion O Muerte A Terceros Excluyendo Pasajeros"; break;
            case 36: nombre_cobertura="C - Responsabilidad Civil Por Daños A Propiedades De Terceros"; break;
            case 37: nombre_cobertura="E - Basica"; break;
            case 38: nombre_cobertura="F - Robo Y Hurto"; break;
            case 39: nombre_cobertura="G - Riesgos Adicionales"; break;
            case 40: nombre_cobertura="H - Extraterritorialidad"; break;
            case 41: nombre_cobertura="Incendio, impacto de rayo, implosión y explosión"; break;
            case 42: nombre_cobertura="Humo, hollín, gases, líquidos y polvos corrosivos"; break;
            case 43: nombre_cobertura="Inundación, acción del agua y humedad"; break;
            case 44: nombre_cobertura="Cortocircuito"; break;
            case 45: nombre_cobertura="Errores de construcción"; break;
            case 46: nombre_cobertura="Errores de manejo"; break;
            case 47: nombre_cobertura="Robo con violencia"; break;
            case 48: nombre_cobertura="Granizo, helada, tempestad"; break;
            case 49: nombre_cobertura="Dolo de terceros, impericia"; break;
            case 50: nombre_cobertura="Deslizamiento de tierra, Hundimiento del terreno y caído de rocas"; break;
            case 51: nombre_cobertura="Riesgo de terremoto"; break;
            case 52: nombre_cobertura="Riesgo de tifón, ciclón y huracán"; break;
            case 53: nombre_cobertura="Riesgo por daños causados por huelga, motín y conmoción civil"; break;
            case 54: nombre_cobertura="Riesgo de hurto"; break;
            case 55: nombre_cobertura="Cobertura de equipos móviles portátiles fuera de su local asegurado"; break;
            case 56: nombre_cobertura="Incremento en el costo de operación"; break;
            case 57: nombre_cobertura="Portadores externos de datos"; break;
            case 65: nombre_cobertura="TODO RIESGO"; break;
                }
                break;
            case 'CERT':
              //console.log('ingrese a certificado');
              switch (String(json.data.vCoberturasXPoliza[i].codigoCobertura)) {
                case "23": nombre_cobertura="DAÑO MALICIOSO ESPECIAL (Terrorismo)"; break;
              case "24": nombre_cobertura="BARREDURA"; break;
              case "25": nombre_cobertura="MOTIN, HUELGA, CONMOCION CIVIL, DAÑOS MALICIOSOS Y ACTOS DE TERRORISMO"; break;
              case "16": nombre_cobertura="ATRACO"; break;
              case "26": nombre_cobertura="OTRAS COBERTURAS"; break;
              case "27": nombre_cobertura="MOTIN, HUELGA, CONMOCION CIVIL Y DAÑOS MALICIOSOS"; break;
              case "22": nombre_cobertura="AVERIA GRUESA"; break;
              case "17": nombre_cobertura="ROBO Y/O ATRACO"; break;
              case "18": nombre_cobertura="FALTA DE ENTREGA"; break;
              case "19": nombre_cobertura="CARGA Y DESCARGA"; break;
              case "34": nombre_cobertura="TODO RIESGO 1.1.63  (A)"; break;
              case "20": nombre_cobertura="TODO RIESGO"; break;
              case "21": nombre_cobertura="INTERRUPCION DE NEGOCIOS POR DAÑOS A MAQUINARIA"; break;
              case "31": nombre_cobertura="TODO RIESGO (INCISO C)"; break;
              case "28": nombre_cobertura="BODEGA A BODEGA"; break;
              case "32": nombre_cobertura="ESTADIA"; break;
              case "29": nombre_cobertura="RATERIA"; break;
              case "30": nombre_cobertura="HUELGAS, ALBOROTOS POPULARES Y CONMOCION CIVIL PARA EMBARQUES TERRESTRES"; break;
              case "13": nombre_cobertura="TODO RIESGO 1.1.82  (A)"; break;
              case "11": nombre_cobertura="MOTIN, HUELGAS Y ALBOROTOS POPULARES"; break;
              case "12": nombre_cobertura="GUERRA"; break;
              case "10": nombre_cobertura="FALTA DE PESO O DERRAME"; break;
              case "3": nombre_cobertura="ROBO DE BULTO POR ENTERO"; break;
              case "4": nombre_cobertura="ROBO PARCIAL"; break;
              case "14": nombre_cobertura="C.A.P.  1.1.82 (B)"; break;
              case "15": nombre_cobertura="L.A.P.  1.1.82 (C)"; break;
              case "1": nombre_cobertura="RIESGOS ORDINARIOS DE TRANSITO TRANSPORTE TERRESTRE Y/O AEREO"; break;
              case "2": nombre_cobertura="RIESGOS ORDINARIOS DE TRANSITO TRANSPORTE MARITIMO"; break;
              case "6": nombre_cobertura="OXIDACION"; break;
              case "5": nombre_cobertura="MOJADURA"; break;
              case "7": nombre_cobertura="CONTACTO CON OTRAS CARGAS"; break;
              case "8": nombre_cobertura="MANCHAS"; break;
              case "9": nombre_cobertura="ROTURAS"; break;
              case "33": nombre_cobertura="ALMACENAMIENTO"; break;
              case "48": nombre_cobertura="Cobertura de riesgos de cláusulas del instituto de londres, para cargamentos “a”"; break;
              case "49": nombre_cobertura="Cobertura de riesgos de cláusulas del instituto de londres, para cargamentos “b”"; break;
              case "50": nombre_cobertura="Huelga"; break;
              case "51": nombre_cobertura="Guerra"; break;
              case "39": nombre_cobertura="Vendaval, ciclón o tornado"; break;
              case "40": nombre_cobertura="Derrumbe de puentes o alcantarillas"; break;
              case "41": nombre_cobertura="Derrumbe de tierra, de piedras o de rocas"; break;
              case "42": nombre_cobertura="Inundación por el desbordamiento de ríos o quebradas"; break;
              case "43": nombre_cobertura="Saqueo"; break;
              case "44": nombre_cobertura="Robo o Asalto"; break;
              case "45": nombre_cobertura="Maniobras de Carga y Descarga; y Movimientos Bruscos"; break;
              case "46": nombre_cobertura="Refrigeración"; break;
              case "47": nombre_cobertura="Extraterritorialidad"; break;
              case "35": nombre_cobertura="Incendio, rayo, auto-ignición y explosión"; break;
              case "36": nombre_cobertura="Terremoto, temblor"; break;
              case "37": nombre_cobertura="Colisión del vehículo con otro vehículo u objeto"; break;
              case "38": nombre_cobertura="Vuelco del vehículo transportador de los bienes descritos"; break;
              case "53": nombre_cobertura="TODO RIESGO"; break;
              case "52": nombre_cobertura="COBERTURA BÁSICA H: Riesgos del Medio de Transporte"; break;
              case "54": nombre_cobertura="1.1.09 A"; break;
              case "55": nombre_cobertura="TRANSBORDO"; break;
              case "56": nombre_cobertura="RECUPERACION"; break;
              case "57": nombre_cobertura="DESTRUCCION"; break;
              case "58": nombre_cobertura="DAÑOS INTERNOS A CONTENEDORES"; break;
            }
              break;
            case "INCEN" :
              //console.log('ingrese a INCEN');
            switch (String(json.data.vCoberturasXPoliza[i].codigoCobertura)) {
            case "10": nombre_cobertura="REMOCION DE ESCOMBROS"; break;
            case "11": nombre_cobertura="GASTOS DE EXTINCIÓN DE INCENDIOS"; break;
            case "12": nombre_cobertura="VALORES,  DOCUMENTOS"; break;
            case "13": nombre_cobertura="HURACAN, TORMENTAS Y VIENTOS."; break;
            case "108": nombre_cobertura="ARRIENDO DE LOCALES DE LOCALES PROVISORIOS"; break;
            case "100": nombre_cobertura="I. TODO RIESGO CONDOMINIOS  PARA BIENES MUEBLES E INMUEBLES"; break;
            case "110": nombre_cobertura="TRASLADOS TEMPORALES"; break;
            case "111": nombre_cobertura="MONTAJE Y DESMONTAJE"; break;
            case "112": nombre_cobertura="TRANSITO INCIDENTAL"; break;
            case "113": nombre_cobertura="SERVICIO DE ASISTENCIA DOMICILARIA G&T"; break;
            case "28": nombre_cobertura="DINERO Y/O VALORES FUERA DEL LOCAL"; break;
            case "114": nombre_cobertura="EXPLOSION, MOTIN, HUELGAS Y/O ALBOROTOS POPULARES, DAÑO MALICIOSO"; break;
            case "109": nombre_cobertura="DAÑOS POR AGUA"; break;
            case "41": nombre_cobertura="RESPONSABILIDAD CIVIL LEGAL DE INCENDIO"; break;
            case "44": nombre_cobertura="R.C. DE PRODUCTOS O TRABAJOS TERMINADOS"; break;
            case "45": nombre_cobertura="INTERRUPCION DE NEGOCIOS PARA RIESGOS INDUSTRIALES"; break;
            case "546": nombre_cobertura="INCENDIO Y/O RAYO"; break;
            case "25": nombre_cobertura="ATRACO DE CONTENIDO"; break;
            case "26": nombre_cobertura="CRISTALES Y/O ROTULOS"; break;
            case "27": nombre_cobertura="DINERO Y/O VALORES DENTRO DEL LOCAL"; break;
            case "29": nombre_cobertura="IMPACTO DE NAVES ACUATICAS"; break;
            case "30": nombre_cobertura="OTRAS COBERTURAS"; break;
            case "91": nombre_cobertura="INTERRUPCION RENTAS DE ALQUILER"; break;
            case "48": nombre_cobertura="HONORARIOS PROFESIONALES"; break;
            case "14": nombre_cobertura="DAÑO MALICIOSO ESPECIAL  (TERRORISMO)"; break;
            case "15": nombre_cobertura="CORTO CIRCUITO"; break;
            case "32": nombre_cobertura="FALLA ELECTRICA"; break;
            case "34": nombre_cobertura="EXPLOSION DE CALDERAS"; break;
            case "35": nombre_cobertura="TRANSPORTE TERRESTRE"; break;
            case "36": nombre_cobertura="HURTO Y DESAPARICION MISTERIOSA"; break;
            case "37": nombre_cobertura="MONTAJE Y DESMONTAJE"; break;
            case "38": nombre_cobertura="ROTULOS LUMINOSOS"; break;
            case "39": nombre_cobertura="RESPONSABILIDAD CIVIL FAMILIAR"; break;
            case "17": nombre_cobertura="FRAUDE POR COMPUTADORAS DE TERCERAS PERSONAS Y EN TRANSFERENCIAS DE FONDOS"; break;
            case "18": nombre_cobertura="FALSIFICACION DE TARJETAS DE CREDITO"; break;
            case "19": nombre_cobertura="FALSIFICACION DE CHEQUES ENTRANTES"; break;
            case "20": nombre_cobertura="FALSIFICACION DE DEPOSITANTES"; break;
            case "21": nombre_cobertura="ACTOS DOLOSOS DE LOS TRABAJADORES"; break;
            case "22": nombre_cobertura="PARAGUAS PARA ALMACENADORA"; break;
            case "23": nombre_cobertura="DOLO O MALA FE PARA ALMACENADORA"; break;
            case "24": nombre_cobertura="ROBO POR FORZAMIENTO DE LADRONES"; break;
            case "7": nombre_cobertura="ROBO Y/O ATRACO"; break;
            case "159": nombre_cobertura="RESPONSABILIDAD CIVIL"; break;
            case "42": nombre_cobertura="AIG ASSIST"; break;
            case "43": nombre_cobertura="RESPONSABILIDAD CIVIL DE GARAGE KEEPER"; break;
            case "40": nombre_cobertura="RESPONSABILIDAD  CIVIL DE PREDIOS Y OPERACIONES"; break;
            case "92": nombre_cobertura="ROTURA DE MAQUINARIA"; break;
            case "127": nombre_cobertura="TERRORISMO (RJK)"; break;
            case "128": nombre_cobertura="MAQUINARIA EN MOVIMIENTO"; break;
            case "129": nombre_cobertura="TRANSPORTE INCIDENTAL DE BIENES"; break;
            case "130": nombre_cobertura="HURTO Y/O PILLAJE POST SINIESTRO"; break;
            case "131": nombre_cobertura="GASTOS DE LIMPIEZA Y DESCONTAMINACIÓN"; break;
            case "132": nombre_cobertura="TODO RIESGO DE CONSTRUCCIÓN Y MONTAJE"; break;
            case "148": nombre_cobertura="AMPARO AUTOMÁTICO DE NUEVAS PROPIEDADES"; break;
            case "149": nombre_cobertura="DAÑO ELÉCTRICO"; break;
            case "158": nombre_cobertura="RESPONSABILIDAD CIVIL DE CONDOMINIOS"; break;
            case "176": nombre_cobertura="RESPONSABILIDAD CIVIL INCENDIO Y/O EXPLOSION"; break;
            case "150": nombre_cobertura="Transporte"; break;
            case "152": nombre_cobertura="AMPARO PROPIEDAD PERSONAL EMPLEADOS"; break;
            case "153": nombre_cobertura="ALTERACIONES Y REPARACIONES"; break;
            case "154": nombre_cobertura="ASISTENCIA DOMICILIAR"; break;
            case "155": nombre_cobertura="EQUIPO ELECTRONICO"; break;
            case "156": nombre_cobertura="MATERIA PRIMA Y PRODUCTO TERMINADO"; break;
            case "117": nombre_cobertura="INUNDACION Y/O MAREMOTO"; break;
            case "119": nombre_cobertura="GASTOS DE CERRAJERIA"; break;
            case "123": nombre_cobertura="COBERTURA DE EQUIPO DE PROCESAMIENTO DE DATOS"; break;
            case "120": nombre_cobertura="COBERTURA DE ACCIDENTES PERSONALES"; break;
            case "121": nombre_cobertura="GASTOS EXTRAORDINARIOS"; break;
            case "133": nombre_cobertura="INTERRUPCIÓN DE FLUIDO ELECTRICO FUERA DE LAS INSTALACIONES"; break;
            case "101": nombre_cobertura="II. RESPONSABILIDAD CIVIL CONDOMINIOS"; break;
            case "102": nombre_cobertura=" R. C. PARA AREAS DE ESTACIONAMIENTOS Y PARQUEOS"; break;
            case "103": nombre_cobertura="IV.  CUOTAS DE ADMINISTRACION"; break;
            case "104": nombre_cobertura="1.  ROTURA DE MAQUINARIA"; break;
            case "157": nombre_cobertura="ROBO POR FORZAMIENTO DE LADRONES y/o ROBO AGRAVADO"; break;
            case "106": nombre_cobertura=" AMPARO AUTOMATICO DE GASTOS ADICIONALES"; break;
            case "107": nombre_cobertura="EXTENSIONES AUTOMATICAS"; break;
            case "16": nombre_cobertura="DEMOLICION"; break;
            case "115": nombre_cobertura="PILLAJE POST-SINIESTRO"; break;
            case "122": nombre_cobertura="COBERTURA DE TARJETA DE CREDITO"; break;
            case "118": nombre_cobertura="TODO RIESGO MULTICOBERTURAS"; break;
            case "124": nombre_cobertura="MOTIN Y/O HUELGAS Y/O CONMOCION CIVIL Y/O DAÑO MALICIOSO"; break;
            case "125": nombre_cobertura="MOTIN Y/O HUELGAS Y/O CONMOCION CIVIL Y/O DAÑO MALICIOSO Y/O EXPLOSION"; break;
            case "126": nombre_cobertura="TERREMOTO, TEMBLOR Y/O ERUPCION VOLCANICA, CAIDA DE CENIZA Y/O ARENA ..."; break;
            case "6": nombre_cobertura="DAÑO MALICIOSO ESPECIAL"; break;
            case "5": nombre_cobertura="TERREMOTO, TEMBLOR Y/O ERUPCION VOLCANICA"; break;
            case "2": nombre_cobertura="TODO RIESGO  (2)"; break;
            case "3": nombre_cobertura="TODO RIESGO  (3)"; break;
            case "1": nombre_cobertura="TODO RIESGO  DE INCENDIO"; break;
            case "4": nombre_cobertura="MOTIN  y HUELGAS  Y ALBOROTOS POPULARES"; break;
            case "9": nombre_cobertura="DINERO Y/O VALORES"; break;
            case "178": nombre_cobertura="TRANSPORTE INCIDENTAL"; break;
            case "179": nombre_cobertura="INT. DE NEGOCIOS COMERCIAL E INT. DE RENTAS DE ALQUILER"; break;
            case "180": nombre_cobertura="CUOTAS DE ADMINISTRACION"; break;
            case "181": nombre_cobertura="WALLET GUARD"; break;
            case "182": nombre_cobertura="ACCIDENTES PERSONALES"; break;
            case "177": nombre_cobertura="REPARACIONES PROVISORIAS"; break;
            case "135": nombre_cobertura="DAÑO ELECTRICO"; break;
            case "136": nombre_cobertura="CAR/EAR"; break;
            case "137": nombre_cobertura="ROTURA DE MAQUINARIA Y/O EXPLOSION DE CALDERAS"; break;
            case "138": nombre_cobertura="INT. DE NEGOCIOS RIESGOS INDUSTRIALES INTERDEPENDIENTE"; break;
            case "141": nombre_cobertura="RESPONSABILIDAD CIVIL PATRONAL"; break;
            case "142": nombre_cobertura="RESPONSABILIDAD CIVIL DE ELEVADORES Y/O GRADAS ELECTRICAS"; break;
            case "143": nombre_cobertura="GASTOS MÉDICOS VOLUNTARIOS"; break;
            case "144": nombre_cobertura="CUENTAS POR COBRAR"; break;
            case "145": nombre_cobertura="CAJAS FUERTES"; break;
            case "46": nombre_cobertura="INTERRUPCIÓN DE NEGOCIOS PARA RIESGOS COMERCIALES"; break;
            case "147": nombre_cobertura="GASTOS EXTRAS"; break;
            case "151": nombre_cobertura="INTERRUPCIÓN DE NEGOCIOS RIESGOS INDUSTRIALES Y COMERCIALES"; break;
            case "160": nombre_cobertura="RESPONSABILIDAD CIVIL DE TRANSPORTISTAS"; break;
            case "161": nombre_cobertura="RESPONSABILIDAD CIVIL DE POLUCION O CONTAMINACION"; break;
            case "162": nombre_cobertura="RESPONSABILIDAD CIVIL GENERAL"; break;
            case "134": nombre_cobertura="EXTENSION DE COBERTURA A PROVEEDORES Y CLIENTES"; break;
            case "139": nombre_cobertura="PÉRDIDAS CONSECUENCIALES"; break;
            case "140": nombre_cobertura="Gastos Adicionales y/o Extras"; break;
            case "163": nombre_cobertura="GASTOS DE APRESURAMIENTO"; break;
            case "164": nombre_cobertura="CONTAMINACION DE AMONIACO"; break;
            case "165": nombre_cobertura="DAÑOS POR AGUA"; break;
            case "166": nombre_cobertura="RECONSTRUCCION DE DOCUMENTOS"; break;
            case "167": nombre_cobertura="ROTURA DE CALDERAS Y MAQUINARIA"; break;
            case "168": nombre_cobertura="AMPARO AUTOMATICO DE GASTOS ADICIONALES DERIVADOS DE SINIESTRO"; break;
            case "169": nombre_cobertura="ALTERACIONES, ADICIONES Y MEJORAS"; break;
            case "170": nombre_cobertura="ALQUILER DE PLANTA DE GENERACION DE ENERGIA ELECTRICA"; break;
            case "171": nombre_cobertura="RESPONSABILIDAD CIVIL CONTRACTURAL"; break;
            case "172": nombre_cobertura="RESPONSABILIDAD CIVIL CRUZADA"; break;
            case "173": nombre_cobertura="RESPONSABILIDAD CIVIL CONTRATISTAS Y SUBCONTRATISTAS INDEPENDIENTES"; break;
            case "174": nombre_cobertura="RESPONSABILIDAD CIVIL PARA PARQUEOS Y ESTACIONAMIENTOS"; break;
            case "175": nombre_cobertura="DINERO Y CHEQUES EN LA OFICINA ADMINISTRATIVA"; break;
            case "547": nombre_cobertura="VEHICULOS CON MOTORES APAGADOS"; break;
            case "607": nombre_cobertura="REMOCION DE ESCOMBROS"; break;
            case "608": nombre_cobertura="GASTOS DE EXTINCIÓN DE INCENDIOS"; break;
            case "609": nombre_cobertura="VALORES,  DOCUMENTOS"; break;
            case "610": nombre_cobertura="HURACAN, TORMENTAS Y VIENTOS."; break;
            case "611": nombre_cobertura="ARRIENDO DE LOCALES DE LOCALES PROVISORIOS"; break;
            case "612": nombre_cobertura="I. TODO RIESGO CONDOMINIOS  PARA BIENES MUEBLES E INMUEBLES"; break;
            case "613": nombre_cobertura="TRASLADOS TEMPORALES"; break;
            case "614": nombre_cobertura="MONTAJE Y DESMONTAJE"; break;
            case "615": nombre_cobertura="TRANSITO INCIDENTAL"; break;
            case "616": nombre_cobertura="SERVICIO DE ASISTENCIA DOMICILARIA G&T"; break;
            case "617": nombre_cobertura="DINERO Y/O VALORES FUERA DEL LOCAL"; break;
            case "618": nombre_cobertura="EXPLOSION, MOTIN, HUELGAS Y/O ALBOROTOS POPULARES, DAÑO MALICIOSO"; break;
            case "286": nombre_cobertura="T Riesgos No Catastróficos"; break;
            case "287": nombre_cobertura="U Riesgos Catastróficos"; break;
            case "288": nombre_cobertura="A Incendio Casual y Rayo"; break;
            case "289": nombre_cobertura="B Riesgos Varios"; break;
            case "290": nombre_cobertura="C Inundación, Deslizamiento Vientos Inundación"; break;
            case "291": nombre_cobertura="D Convulsiones de la Naturaleza"; break;
            case "292": nombre_cobertura="E Daño Directo a la Mercancía"; break;
            case "293": nombre_cobertura="F Pérdida de Beneficios"; break;
            case "294": nombre_cobertura="G Lluvia y Derrame "; break;
            case "295": nombre_cobertura="H Pérdida de Rentas por contrato de arrendamiento"; break;
            case "296": nombre_cobertura="I Rotura de Cristales"; break;
            case "297": nombre_cobertura="Q Gastos Extra"; break;
            case "298": nombre_cobertura="A Incendio Casual y Rayo"; break;
            case "299": nombre_cobertura="B Riesgos Varios"; break;
            case "300": nombre_cobertura="C Inundación , Deslizamiento y Vientos"; break;
            case "301": nombre_cobertura="D Convulsiones de la Naturaleza"; break;
            case "302": nombre_cobertura="H Pérdida de Rentas por contrato de arrendamiento"; break;
            case "303": nombre_cobertura="I Rotura de Cristales"; break;
            case "304": nombre_cobertura="R Gastos por Alquiler"; break;
            case "305": nombre_cobertura="V Daño directo de bienes inmuebles"; break;
            case "306": nombre_cobertura="Y Daño directo de contenidos (opción 1,2 y 3)"; break;
            case "307": nombre_cobertura="D Convulsiones de la naturaleza"; break;
            case "308": nombre_cobertura="H Pérdida de rentas por contrato de arrendamiento"; break;
            case "309": nombre_cobertura="K Responsabilidad Civil"; break;
            case "310": nombre_cobertura="M Riesgos del Trabajo Servidores Domesticos"; break;
            case "311": nombre_cobertura="P Accidentes Personales Daños Materiales"; break;
            case "312": nombre_cobertura="Incendio"; break;
            case "313": nombre_cobertura="Agua al sofocar un incendio "; break;
            case "314": nombre_cobertura="Inundación , Deslizamiento y Vientos"; break;
            case "315": nombre_cobertura="Impacto de rayo."; break;
            case "316": nombre_cobertura="Humo u hollín proveniente de un incendio"; break;
            case "317": nombre_cobertura="Implosión y/o Explosión de cualquier tipo"; break;
            case "318": nombre_cobertura="Impacto de vehículos terrestres o aéreos u objetos caídos del cielo"; break;
            case "319": nombre_cobertura="Caída objetos contra la propiedad asegurada."; break;
            case "320": nombre_cobertura="Incendio y Daño Directo por Terremoto"; break;
            case "321": nombre_cobertura="Daño Directo por Vendaval, huracán, tornado, tromba o granizo"; break;
            case "322": nombre_cobertura="Daño Directo por Inundación"; break;
            case "323": nombre_cobertura="Extensión de Cobertura Catastrófica"; break;
            case "324": nombre_cobertura="Incendio y daño por desórdenes públicos."; break;
            case "325": nombre_cobertura="Daño Directo por Maldad"; break;
            case "326": nombre_cobertura="Saqueo"; break;
            case "327": nombre_cobertura="Pérdida consiguiente por falta de refrigeración"; break;
            case "328": nombre_cobertura="Pérdida de Renta"; break;
            case "329": nombre_cobertura="Lucro Cesante (Pérdida de Beneficios)"; break;
            case "330": nombre_cobertura="A Incendio Casual y Rayo"; break;
            case "331": nombre_cobertura="B Riesgos de Naturaleza Catastrófica Inundación"; break;
            case "332": nombre_cobertura="C Daños por Agua y Riesgos Atmosféricos"; break;
            case "333": nombre_cobertura="D Rotura de Cristales, Domos, Mármoles y Granitos."; break;
            case "334": nombre_cobertura="E Robo"; break;
            case "335": nombre_cobertura="F Gastos por Alquiler"; break;
            case "336": nombre_cobertura="G Pérdida de Rentas  "; break;
            case "337": nombre_cobertura="I Bienes Refrigerados o Congelados"; break;
            case "338": nombre_cobertura="J Mercancía en Tránsito"; break;
            case "339": nombre_cobertura="L Rotura de Maquinaria"; break;
            case "340": nombre_cobertura="M Equipo Electrónico"; break;
            case "341": nombre_cobertura="P Interrupción de Negocios"; break;
            case "342": nombre_cobertura="Q Multiasistencia"; break;
            case "343": nombre_cobertura="H Responsabilidad Civil Extracontractual y Subjetiva"; break;
            case "344": nombre_cobertura="Q Asistencia en la Vivienda"; break;
            case "345": nombre_cobertura="Daños Materiales por Riesgos Catastróficos y No Catastróficos"; break;
            }
              break;
            case "TDINCEN" :
            //console.log('ingrese a TDINCEN');
            switch (String(json.data.vCoberturasXPoliza[i].codigoCobertura)) {
            case "10": nombre_cobertura="REMOCION DE ESCOMBROS"; break;
            case "11": nombre_cobertura="GASTOS DE EXTINCIÓN DE INCENDIOS"; break;
            case "12": nombre_cobertura="VALORES,  DOCUMENTOS"; break;
            case "13": nombre_cobertura="HURACAN, TORMENTAS Y VIENTOS."; break;
            case "108": nombre_cobertura="ARRIENDO DE LOCALES DE LOCALES PROVISORIOS"; break;
            case "100": nombre_cobertura="I. TODO RIESGO CONDOMINIOS  PARA BIENES MUEBLES E INMUEBLES"; break;
            case "110": nombre_cobertura="TRASLADOS TEMPORALES"; break;
            case "111": nombre_cobertura="MONTAJE Y DESMONTAJE"; break;
            case "112": nombre_cobertura="TRANSITO INCIDENTAL"; break;
            case "113": nombre_cobertura="SERVICIO DE ASISTENCIA DOMICILARIA G&T"; break;
            case "28": nombre_cobertura="DINERO Y/O VALORES FUERA DEL LOCAL"; break;
            case "114": nombre_cobertura="EXPLOSION, MOTIN, HUELGAS Y/O ALBOROTOS POPULARES, DAÑO MALICIOSO"; break;
            case "109": nombre_cobertura="DAÑOS POR AGUA"; break;
            case "41": nombre_cobertura="RESPONSABILIDAD CIVIL LEGAL DE INCENDIO"; break;
            case "44": nombre_cobertura="R.C. DE PRODUCTOS O TRABAJOS TERMINADOS"; break;
            case "45": nombre_cobertura="INTERRUPCION DE NEGOCIOS PARA RIESGOS INDUSTRIALES"; break;
            case "546": nombre_cobertura="INCENDIO Y/O RAYO"; break;
            case "25": nombre_cobertura="ATRACO DE CONTENIDO"; break;
            case "26": nombre_cobertura="CRISTALES Y/O ROTULOS"; break;
            case "27": nombre_cobertura="DINERO Y/O VALORES DENTRO DEL LOCAL"; break;
            case "29": nombre_cobertura="IMPACTO DE NAVES ACUATICAS"; break;
            case "30": nombre_cobertura="OTRAS COBERTURAS"; break;
            case "91": nombre_cobertura="INTERRUPCION RENTAS DE ALQUILER"; break;
            case "48": nombre_cobertura="HONORARIOS PROFESIONALES"; break;
            case "14": nombre_cobertura="DAÑO MALICIOSO ESPECIAL  (TERRORISMO)"; break;
            case "15": nombre_cobertura="CORTO CIRCUITO"; break;
            case "32": nombre_cobertura="FALLA ELECTRICA"; break;
            case "34": nombre_cobertura="EXPLOSION DE CALDERAS"; break;
            case "35": nombre_cobertura="TRANSPORTE TERRESTRE"; break;
            case "36": nombre_cobertura="HURTO Y DESAPARICION MISTERIOSA"; break;
            case "37": nombre_cobertura="MONTAJE Y DESMONTAJE"; break;
            case "38": nombre_cobertura="ROTULOS LUMINOSOS"; break;
            case "39": nombre_cobertura="RESPONSABILIDAD CIVIL FAMILIAR"; break;
            case "17": nombre_cobertura="FRAUDE POR COMPUTADORAS DE TERCERAS PERSONAS Y EN TRANSFERENCIAS DE FONDOS"; break;
            case "18": nombre_cobertura="FALSIFICACION DE TARJETAS DE CREDITO"; break;
            case "19": nombre_cobertura="FALSIFICACION DE CHEQUES ENTRANTES"; break;
            case "20": nombre_cobertura="FALSIFICACION DE DEPOSITANTES"; break;
            case "21": nombre_cobertura="ACTOS DOLOSOS DE LOS TRABAJADORES"; break;
            case "22": nombre_cobertura="PARAGUAS PARA ALMACENADORA"; break;
            case "23": nombre_cobertura="DOLO O MALA FE PARA ALMACENADORA"; break;
            case "24": nombre_cobertura="ROBO POR FORZAMIENTO DE LADRONES"; break;
            case "7": nombre_cobertura="ROBO Y/O ATRACO"; break;
            case "159": nombre_cobertura="RESPONSABILIDAD CIVIL"; break;
            case "42": nombre_cobertura="AIG ASSIST"; break;
            case "43": nombre_cobertura="RESPONSABILIDAD CIVIL DE GARAGE KEEPER"; break;
            case "40": nombre_cobertura="RESPONSABILIDAD  CIVIL DE PREDIOS Y OPERACIONES"; break;
            case "92": nombre_cobertura="ROTURA DE MAQUINARIA"; break;
            case "127": nombre_cobertura="TERRORISMO (RJK)"; break;
            case "128": nombre_cobertura="MAQUINARIA EN MOVIMIENTO"; break;
            case "129": nombre_cobertura="TRANSPORTE INCIDENTAL DE BIENES"; break;
            case "130": nombre_cobertura="HURTO Y/O PILLAJE POST SINIESTRO"; break;
            case "131": nombre_cobertura="GASTOS DE LIMPIEZA Y DESCONTAMINACIÓN"; break;
            case "132": nombre_cobertura="TODO RIESGO DE CONSTRUCCIÓN Y MONTAJE"; break;
            case "148": nombre_cobertura="AMPARO AUTOMÁTICO DE NUEVAS PROPIEDADES"; break;
            case "149": nombre_cobertura="DAÑO ELÉCTRICO"; break;
            case "158": nombre_cobertura="RESPONSABILIDAD CIVIL DE CONDOMINIOS"; break;
            case "176": nombre_cobertura="RESPONSABILIDAD CIVIL INCENDIO Y/O EXPLOSION"; break;
            case "150": nombre_cobertura="Transporte"; break;
            case "152": nombre_cobertura="AMPARO PROPIEDAD PERSONAL EMPLEADOS"; break;
            case "153": nombre_cobertura="ALTERACIONES Y REPARACIONES"; break;
            case "154": nombre_cobertura="ASISTENCIA DOMICILIAR"; break;
            case "155": nombre_cobertura="EQUIPO ELECTRONICO"; break;
            case "156": nombre_cobertura="MATERIA PRIMA Y PRODUCTO TERMINADO"; break;
            case "117": nombre_cobertura="INUNDACION Y/O MAREMOTO"; break;
            case "119": nombre_cobertura="GASTOS DE CERRAJERIA"; break;
            case "123": nombre_cobertura="COBERTURA DE EQUIPO DE PROCESAMIENTO DE DATOS"; break;
            case "120": nombre_cobertura="COBERTURA DE ACCIDENTES PERSONALES"; break;
            case "121": nombre_cobertura="GASTOS EXTRAORDINARIOS"; break;
            case "133": nombre_cobertura="INTERRUPCIÓN DE FLUIDO ELECTRICO FUERA DE LAS INSTALACIONES"; break;
            case "101": nombre_cobertura="II. RESPONSABILIDAD CIVIL CONDOMINIOS"; break;
            case "102": nombre_cobertura=" R. C. PARA AREAS DE ESTACIONAMIENTOS Y PARQUEOS"; break;
            case "103": nombre_cobertura="IV.  CUOTAS DE ADMINISTRACION"; break;
            case "104": nombre_cobertura="1.  ROTURA DE MAQUINARIA"; break;
            case "157": nombre_cobertura="ROBO POR FORZAMIENTO DE LADRONES y/o ROBO AGRAVADO"; break;
            case "106": nombre_cobertura=" AMPARO AUTOMATICO DE GASTOS ADICIONALES"; break;
            case "107": nombre_cobertura="EXTENSIONES AUTOMATICAS"; break;
            case "16": nombre_cobertura="DEMOLICION"; break;
            case "115": nombre_cobertura="PILLAJE POST-SINIESTRO"; break;
            case "122": nombre_cobertura="COBERTURA DE TARJETA DE CREDITO"; break;
            case "118": nombre_cobertura="TODO RIESGO MULTICOBERTURAS"; break;
            case "124": nombre_cobertura="MOTIN Y/O HUELGAS Y/O CONMOCION CIVIL Y/O DAÑO MALICIOSO"; break;
            case "125": nombre_cobertura="MOTIN Y/O HUELGAS Y/O CONMOCION CIVIL Y/O DAÑO MALICIOSO Y/O EXPLOSION"; break;
            case "126": nombre_cobertura="TERREMOTO, TEMBLOR Y/O ERUPCION VOLCANICA, CAIDA DE CENIZA Y/O ARENA ..."; break;
            case "6": nombre_cobertura="DAÑO MALICIOSO ESPECIAL"; break;
            case "5": nombre_cobertura="TERREMOTO, TEMBLOR Y/O ERUPCION VOLCANICA"; break;
            case "2": nombre_cobertura="TODO RIESGO  (2)"; break;
            case "3": nombre_cobertura="TODO RIESGO  (3)"; break;
            case "1": nombre_cobertura="TODO RIESGO  DE INCENDIO"; break;
            case "4": nombre_cobertura="MOTIN  y HUELGAS  Y ALBOROTOS POPULARES"; break;
            case "9": nombre_cobertura="DINERO Y/O VALORES"; break;
            case "178": nombre_cobertura="TRANSPORTE INCIDENTAL"; break;
            case "179": nombre_cobertura="INT. DE NEGOCIOS COMERCIAL E INT. DE RENTAS DE ALQUILER"; break;
            case "180": nombre_cobertura="CUOTAS DE ADMINISTRACION"; break;
            case "181": nombre_cobertura="WALLET GUARD"; break;
            case "182": nombre_cobertura="ACCIDENTES PERSONALES"; break;
            case "177": nombre_cobertura="REPARACIONES PROVISORIAS"; break;
            case "135": nombre_cobertura="DAÑO ELECTRICO"; break;
            case "136": nombre_cobertura="CAR/EAR"; break;
            case "137": nombre_cobertura="ROTURA DE MAQUINARIA Y/O EXPLOSION DE CALDERAS"; break;
            case "138": nombre_cobertura="INT. DE NEGOCIOS RIESGOS INDUSTRIALES INTERDEPENDIENTE"; break;
            case "141": nombre_cobertura="RESPONSABILIDAD CIVIL PATRONAL"; break;
            case "142": nombre_cobertura="RESPONSABILIDAD CIVIL DE ELEVADORES Y/O GRADAS ELECTRICAS"; break;
            case "143": nombre_cobertura="GASTOS MÉDICOS VOLUNTARIOS"; break;
            case "144": nombre_cobertura="CUENTAS POR COBRAR"; break;
            case "145": nombre_cobertura="CAJAS FUERTES"; break;
            case "46": nombre_cobertura="INTERRUPCIÓN DE NEGOCIOS PARA RIESGOS COMERCIALES"; break;
            case "147": nombre_cobertura="GASTOS EXTRAS"; break;
            case "151": nombre_cobertura="INTERRUPCIÓN DE NEGOCIOS RIESGOS INDUSTRIALES Y COMERCIALES"; break;
            case "160": nombre_cobertura="RESPONSABILIDAD CIVIL DE TRANSPORTISTAS"; break;
            case "161": nombre_cobertura="RESPONSABILIDAD CIVIL DE POLUCION O CONTAMINACION"; break;
            case "162": nombre_cobertura="RESPONSABILIDAD CIVIL GENERAL"; break;
            case "134": nombre_cobertura="EXTENSION DE COBERTURA A PROVEEDORES Y CLIENTES"; break;
            case "139": nombre_cobertura="PÉRDIDAS CONSECUENCIALES"; break;
            case "140": nombre_cobertura="Gastos Adicionales y/o Extras"; break;
            case "163": nombre_cobertura="GASTOS DE APRESURAMIENTO"; break;
            case "164": nombre_cobertura="CONTAMINACION DE AMONIACO"; break;
            case "165": nombre_cobertura="DAÑOS POR AGUA"; break;
            case "166": nombre_cobertura="RECONSTRUCCION DE DOCUMENTOS"; break;
            case "167": nombre_cobertura="ROTURA DE CALDERAS Y MAQUINARIA"; break;
            case "168": nombre_cobertura="AMPARO AUTOMATICO DE GASTOS ADICIONALES DERIVADOS DE SINIESTRO"; break;
            case "169": nombre_cobertura="ALTERACIONES, ADICIONES Y MEJORAS"; break;
            case "170": nombre_cobertura="ALQUILER DE PLANTA DE GENERACION DE ENERGIA ELECTRICA"; break;
            case "171": nombre_cobertura="RESPONSABILIDAD CIVIL CONTRACTURAL"; break;
            case "172": nombre_cobertura="RESPONSABILIDAD CIVIL CRUZADA"; break;
            case "173": nombre_cobertura="RESPONSABILIDAD CIVIL CONTRATISTAS Y SUBCONTRATISTAS INDEPENDIENTES"; break;
            case "174": nombre_cobertura="RESPONSABILIDAD CIVIL PARA PARQUEOS Y ESTACIONAMIENTOS"; break;
            case "175": nombre_cobertura="DINERO Y CHEQUES EN LA OFICINA ADMINISTRATIVA"; break;
            case "547": nombre_cobertura="VEHICULOS CON MOTORES APAGADOS"; break;
            case "607": nombre_cobertura="REMOCION DE ESCOMBROS"; break;
            case "608": nombre_cobertura="GASTOS DE EXTINCIÓN DE INCENDIOS"; break;
            case "609": nombre_cobertura="VALORES,  DOCUMENTOS"; break;
            case "610": nombre_cobertura="HURACAN, TORMENTAS Y VIENTOS."; break;
            case "611": nombre_cobertura="ARRIENDO DE LOCALES DE LOCALES PROVISORIOS"; break;
            case "612": nombre_cobertura="I. TODO RIESGO CONDOMINIOS  PARA BIENES MUEBLES E INMUEBLES"; break;
            case "613": nombre_cobertura="TRASLADOS TEMPORALES"; break;
            case "614": nombre_cobertura="MONTAJE Y DESMONTAJE"; break;
            case "615": nombre_cobertura="TRANSITO INCIDENTAL"; break;
            case "616": nombre_cobertura="SERVICIO DE ASISTENCIA DOMICILARIA G&T"; break;
            case "617": nombre_cobertura="DINERO Y/O VALORES FUERA DEL LOCAL"; break;
            case "618": nombre_cobertura="EXPLOSION, MOTIN, HUELGAS Y/O ALBOROTOS POPULARES, DAÑO MALICIOSO"; break;
            case "286": nombre_cobertura="T Riesgos No Catastróficos"; break;
            case "287": nombre_cobertura="U Riesgos Catastróficos"; break;
            case "288": nombre_cobertura="A Incendio Casual y Rayo"; break;
            case "289": nombre_cobertura="B Riesgos Varios"; break;
            case "290": nombre_cobertura="C Inundación, Deslizamiento Vientos Inundación"; break;
            case "291": nombre_cobertura="D Convulsiones de la Naturaleza"; break;
            case "292": nombre_cobertura="E Daño Directo a la Mercancía"; break;
            case "293": nombre_cobertura="F Pérdida de Beneficios"; break;
            case "294": nombre_cobertura="G Lluvia y Derrame "; break;
            case "295": nombre_cobertura="H Pérdida de Rentas por contrato de arrendamiento"; break;
            case "296": nombre_cobertura="I Rotura de Cristales"; break;
            case "297": nombre_cobertura="Q Gastos Extra"; break;
            case "298": nombre_cobertura="A Incendio Casual y Rayo"; break;
            case "299": nombre_cobertura="B Riesgos Varios"; break;
            case "300": nombre_cobertura="C Inundación , Deslizamiento y Vientos"; break;
            case "301": nombre_cobertura="D Convulsiones de la Naturaleza"; break;
            case "302": nombre_cobertura="H Pérdida de Rentas por contrato de arrendamiento"; break;
            case "303": nombre_cobertura="I Rotura de Cristales"; break;
            case "304": nombre_cobertura="R Gastos por Alquiler"; break;
            case "305": nombre_cobertura="V Daño directo de bienes inmuebles"; break;
            case "306": nombre_cobertura="Y Daño directo de contenidos (opción 1,2 y 3)"; break;
            case "307": nombre_cobertura="D Convulsiones de la naturaleza"; break;
            case "308": nombre_cobertura="H Pérdida de rentas por contrato de arrendamiento"; break;
            case "309": nombre_cobertura="K Responsabilidad Civil"; break;
            case "310": nombre_cobertura="M Riesgos del Trabajo Servidores Domesticos"; break;
            case "311": nombre_cobertura="P Accidentes Personales Daños Materiales"; break;
            case "312": nombre_cobertura="Incendio"; break;
            case "313": nombre_cobertura="Agua al sofocar un incendio "; break;
            case "314": nombre_cobertura="Inundación , Deslizamiento y Vientos"; break;
            case "315": nombre_cobertura="Impacto de rayo."; break;
            case "316": nombre_cobertura="Humo u hollín proveniente de un incendio"; break;
            case "317": nombre_cobertura="Implosión y/o Explosión de cualquier tipo"; break;
            case "318": nombre_cobertura="Impacto de vehículos terrestres o aéreos u objetos caídos del cielo"; break;
            case "319": nombre_cobertura="Caída objetos contra la propiedad asegurada."; break;
            case "320": nombre_cobertura="Incendio y Daño Directo por Terremoto"; break;
            case "321": nombre_cobertura="Daño Directo por Vendaval, huracán, tornado, tromba o granizo"; break;
            case "322": nombre_cobertura="Daño Directo por Inundación"; break;
            case "323": nombre_cobertura="Extensión de Cobertura Catastrófica"; break;
            case "324": nombre_cobertura="Incendio y daño por desórdenes públicos."; break;
            case "325": nombre_cobertura="Daño Directo por Maldad"; break;
            case "326": nombre_cobertura="Saqueo"; break;
            case "327": nombre_cobertura="Pérdida consiguiente por falta de refrigeración"; break;
            case "328": nombre_cobertura="Pérdida de Renta"; break;
            case "329": nombre_cobertura="Lucro Cesante (Pérdida de Beneficios)"; break;
            case "330": nombre_cobertura="A Incendio Casual y Rayo"; break;
            case "331": nombre_cobertura="B Riesgos de Naturaleza Catastrófica Inundación"; break;
            case "332": nombre_cobertura="C Daños por Agua y Riesgos Atmosféricos"; break;
            case "333": nombre_cobertura="D Rotura de Cristales, Domos, Mármoles y Granitos."; break;
            case "334": nombre_cobertura="E Robo"; break;
            case "335": nombre_cobertura="F Gastos por Alquiler"; break;
            case "336": nombre_cobertura="G Pérdida de Rentas  "; break;
            case "337": nombre_cobertura="I Bienes Refrigerados o Congelados"; break;
            case "338": nombre_cobertura="J Mercancía en Tránsito"; break;
            case "339": nombre_cobertura="L Rotura de Maquinaria"; break;
            case "340": nombre_cobertura="M Equipo Electrónico"; break;
            case "341": nombre_cobertura="P Interrupción de Negocios"; break;
            case "342": nombre_cobertura="Q Multiasistencia"; break;
            case "343": nombre_cobertura="H Responsabilidad Civil Extracontractual y Subjetiva"; break;
            case "344": nombre_cobertura="Q Asistencia en la Vivienda"; break;
            case "345": nombre_cobertura="Daños Materiales por Riesgos Catastróficos y No Catastróficos"; break;
            }  break;
            case 'RC':
            //console.log('ingrese a RC');
              switch (String(json.data.vCoberturasXPoliza[i].codigoCobertura)) {
                case "28": nombre_cobertura="RESPONSABILIDAD RESERVA DE DOMINIO"; break;
                case "46": nombre_cobertura="R.C. PARA VUELOS CHARTER"; break;
                case "29": nombre_cobertura="RESPONSABILIDAD LEGAL POR DAÑOS POR INCENDIO"; break;
                case "47": nombre_cobertura="RESPONSABILIDAD LEGAL POR DAÑOS POR INCENDIO"; break;
                case "48": nombre_cobertura="OTRAS COBERTURAS"; break;
                case "49": nombre_cobertura="DEMOLICION"; break;
                case "44": nombre_cobertura="RESPONSABILIDAD CIVIL GASOLINERAS"; break;
                case "41": nombre_cobertura="RESPONSABILIDAD CIVIL BAJO LEYES DE BEBIDAS ALCOHOLICAS"; break;
                case "42": nombre_cobertura="RESPONSABILIDAD CIVIL POR EMBARCACIONES QUE NO SON PROPIEDAD DEL ASEGURADO"; break;
                case "29": nombre_cobertura="RESPONSABILIDAD CIVIL POR CONTAMINACION DEL MEDIO AMBIENTE"; break;
                case "30": nombre_cobertura="R.C. MAQUINAS AGRICOLAS"; break;
                case "31": nombre_cobertura="USO O TENENCIA DE COMBUSTIBLES"; break;
                case "32": nombre_cobertura="TRANSPORTE DE COMBUSTIBLES"; break;
                case "33": nombre_cobertura="RESPONSABILIDAD CIVIL CONTAMINACIÓN POR CONTACTO CON OTRAS CARGAS."; break;
                case "36": nombre_cobertura="RESPONSABILIDAD CIVIL LESIONES PERSONALES Y PUBLICITARIAS"; break;
                case "37": nombre_cobertura="REPATRIACION"; break;
                case "38": nombre_cobertura="RESPONSABILIDAD CIVIL DE AUTO CONTINGENTE"; break;
                case "39": nombre_cobertura="MALA PRACTICA HOSPITALARIA"; break;
                case "40": nombre_cobertura="RESPONSABILIDAD CIVIL DE MONTACARGAS"; break;
                case "35": nombre_cobertura="NEGLIGENCIA"; break;
                case "23": nombre_cobertura="RESPONSABILIDAD CIVIL GUARDIAS"; break;
                case "43": nombre_cobertura="R.C. MAL MANEJO"; break;
                case "50": nombre_cobertura="RESPONSABILIDAD DE DIRECTORES Y OFICIALES"; break;
                case "51": nombre_cobertura="RESPONSABILIDAD CIVIL DE MINERÍA"; break;
                case "52": nombre_cobertura="RESPONSABILIDAD CIVIL SUBCONTRATISTAS"; break;
                case "53": nombre_cobertura="RESPONSABILIDAD CIVIL DE ROTULOS"; break;
                case "54": nombre_cobertura="RESPONSABILIDAD CIVIL DE GASTOS MÉDICOS VOLUNTARIOS"; break;
                case "57": nombre_cobertura="Responsabilidad Civil de Restaurantes"; break;
                case "59": nombre_cobertura="GASTOS MÉDICOS VOLUNTARIOS"; break;
                case "62": nombre_cobertura="RESPONSABILIDAD CIVIL DE CONDOMINIO"; break;
                case "56": nombre_cobertura="HOLE IN ONE"; break;
                case "58": nombre_cobertura="EXCESOS DE RESPONSABILIDAD CIVIL"; break;
                case "63": nombre_cobertura="RESP. CIVIL CLUBS Y ASOCIACIONES SOCIALES Y DEPORTIVAS"; break;
                case "45": nombre_cobertura="CUOTAS DE ADMINISTRACION"; break;
                case "60": nombre_cobertura="R. C. Otros"; break;
                case "34": nombre_cobertura="RESPONSABILIDAD  CIVIL  DE ESTACIONAMIENTOS"; break;
                case "6": nombre_cobertura="RESPONSABILIDAD  CIVIL  DE TRANSPORTISTAS"; break;
                case "7": nombre_cobertura="RESPONSABILIDAD CIVIL FAMILIAR"; break;
                case "8": nombre_cobertura="RESPONSABILIDAD CIVIL GENERAL"; break;
                case "9": nombre_cobertura="RESPONSABILIDAD CIVIL PROFESIONAL"; break;
                case "10": nombre_cobertura="RESPONSABILIDAD CIVIL PENAL O CRIMINAL"; break;
                case "11": nombre_cobertura="RESPONSABILIDAD CIVIL DE ELEVADORES y/o GRADAS ELECTRICAS"; break;
                case "12": nombre_cobertura="RESPONSABILIDAD CIVIL CRUZADA"; break;
                case "13": nombre_cobertura="RESPONSABILIDAD CIVIL DE PARQUEOS Y/O ESTACIONAMIENTOS"; break;
                case "14": nombre_cobertura="RESPONSABILIDAD  CIVIL  DE CONTRATISTAS"; break;
                case "15": nombre_cobertura="RESPONSABILIDAD CIVIL DE VEHICULOS"; break;
                case "16": nombre_cobertura="RESPONSABILIDAD CIVIL ASUMIDA"; break;
                case "17": nombre_cobertura="R. C. POR DAÑOS A TERCEROS EN SUS PERSONAS"; break;
                case "18": nombre_cobertura="RESPONSABILIDAD CIVIL DE CONSTRUCCION"; break;
                case "19": nombre_cobertura="R. C. POR DAÑOS A TERCEROS EN SUS BIENES"; break;
                case "22": nombre_cobertura="EXCESOS DE R. C. DE VEHICULOS"; break;
                case "24": nombre_cobertura="RESPONSABILIDAD CIVIL POR EXPLOSION DE CALDERAS Y/O APARATOS QUE OPERAN A PRESIO"; break;
                case "25": nombre_cobertura="*********SUSTITUR POR LA COBERTURA 23*******"; break;
                case "26": nombre_cobertura="RESPONSABILIDAD CIVIL POR CAIDA PARCIAL O TOTAL DE ROTULOS, ARBOLES Y/O RAMAS"; break;
                case "27": nombre_cobertura="RESPONSABILIDAD DE GARAGE KEEPERS"; break;
                case "20": nombre_cobertura="RESPONSABILIDAD CIVIL DE DIRECTIVOS Y CONSEJEROS"; break;
                case "21": nombre_cobertura="RESPONSABILIDAD CIVIL DE ESTIBADORES"; break;
                case "1": nombre_cobertura="RESPONSABILIDAD  CIVIL  LEGAL DE INCENDIO Y/O EXPLOSION"; break;
                case "2": nombre_cobertura="RESPONSABILIDAD  CIVIL  DE PREDIOS Y OPERACIONES"; break;
                case "3": nombre_cobertura="RESPONSABILIDAD  CIVIL  DE PRODUCTOS O TRABAJOS TERMINADOS"; break;
                case "4": nombre_cobertura="RESPONSABILIDAD  CIVIL CONTRACTUAL O ASUMIDA"; break;
                case "5": nombre_cobertura="RESPONSABILIDAD  CIVIL PATRONAL"; break;
                case "68": nombre_cobertura="RESPONSABILIDAD CIVIL"; break;
                case "70": nombre_cobertura="RESPONSABILIDAD CIVIL DERIVADA DE LA VIOLACION DE PATENTES"; break;
                case "65": nombre_cobertura="RESPONSABILIDAD CIVIL DE NAVES AEREAS"; break;
                case "67": nombre_cobertura="REEMBOLSO A LA SOCIEDAD POR INDEMNIZACIÓN"; break;
                case "69": nombre_cobertura="RESPONSABILIDAD CIVIL DE DIRECTIVOS Y FUNCIONARIOS"; break;
                case "64": nombre_cobertura="RESPONSABILIDAD COLECTIVA DE PASAJEROS"; break;
                case "66": nombre_cobertura="RESPONSABILIDAD CIVIL DIRECTORES Y ADMINISTRADORES"; break;
                case "61": nombre_cobertura="RESPONSABILIDAD CIVIL MAQUINARIA Y EQUIPO MÓVIL"; break;
                case "71": nombre_cobertura="RESPONSABILIDAD CIVIL HOTELERA"; break;
                case "200": nombre_cobertura="A- RESPONSABILIDAD CIVIL POR LESION O MUERTE DE PERSONAS"; break;
                case "201": nombre_cobertura="B-	ATENCIÓN MEDICA INMEDIATA"; break;
                case "202": nombre_cobertura="C-RESPONSABILIDAD CIVIL POR DAÑOS A PROPIEDADES DE TERCEROS"; break;
                case "203": nombre_cobertura="D-SUBLÍMITE DE LAVANDERÍAS Y GUARDARROPAS"; break;
                case "204": nombre_cobertura="E-EQUIPAJE DE HUESPEDES"; break;
                case "205": nombre_cobertura="F-RESGUARDOS DE CAJAS DE SEGURIDAD"; break;
                case "206": nombre_cobertura="L-RESPONSABILIDAD CIVIL (COB A Y C)     "; break;
                case "207": nombre_cobertura="M-RESPONSABILIDAD CIVIL PRODUCTOS"; break;
                case "208": nombre_cobertura="N-RESPONSABILIDAD CIVIL PATRONAL"; break;
                case "209": nombre_cobertura="O-RESPONSABILIDAD CIVIL COLISION O VUELCO DE VEHÍCULOS"; break;
                case "210": nombre_cobertura="P-RESPONSABILIDAD CIVIL ROBO DE VEHÍCULOS"; break;
                case "211": nombre_cobertura="Q-RESPONSABILIDAD CIVIL PRUEBA DE VEHÍCULOS PARA TALLERES"; break;
                case "212": nombre_cobertura="R-RESPONSABILIDAD CIVIL ATENCIÓN MÉDICA INMEDIATA"; break;
                case "213": nombre_cobertura="RESPONSABILIDAD CIVIL CONSTRUCCION"; break;
                case "214": nombre_cobertura="RESPONSABILIDAD CIVIL SERVICIOS"; break;
                case "215": nombre_cobertura="RESPONSABILIDAD CIVIL INDUSTRIAS"; break;
                case "216": nombre_cobertura="RESPONSABILIDAD CIVIL LOCALES COMERCIALES"; break;
                case "217": nombre_cobertura="RESPONSABILIDAD CIVIL HOTELES Y SIMILARES"; break;
                case "218": nombre_cobertura="RESPONSABILIDAD CIVIL OPERADORES TURISTICOS"; break;
                case "219": nombre_cobertura="RESPONSABILIDAD CIVIL PARQUEOS"; break;
                case "220": nombre_cobertura="RESPONSABILIDAD CIVIL TALLERES"; break;
                case "221": nombre_cobertura="RESPONSABILIDAD CIVIL AUTOLAVADOS"; break;
                case "222": nombre_cobertura="RESPONSABILIDAD CIVIL ESTACIONES DE SERVICIO"; break;
                case "223": nombre_cobertura="RESPONSABILIDAD CIVIL ESPECTACULOS PUBLICOS"; break;
                case "224": nombre_cobertura="RESPONSABILIDAD CIVIL EVENTOS TAURINOS Y PIROTECNICOS"; break;
                case "225": nombre_cobertura="RESPONSABILIDAD CIVIL JUEGOS ELECTROMECANICOS"; break;
                case "226": nombre_cobertura="RESPONSABILIDAD CIVIL FAMILIAR Y PRIVADA"; break;
                case "227": nombre_cobertura="RESPONSABILIDAD CIVIL PREDIOS Y OPERACIONES"; break;
                case "228": nombre_cobertura="RESPONSABILIDAD CIVIL FABRICANTES Y CONTRATISTAS"; break;
                case "229": nombre_cobertura="RESPONSABILIDAD CIVIL DUEÑOS. PROPIETARIOS Y ARRENDATARIOS"; break;
                case "230": nombre_cobertura="RESPONSABILIDAD CIVIL ALMACENADORA"; break;
                case "231": nombre_cobertura="RESPONSABILIDAD CIVIL PRODUCTOS"; break;
                case "232": nombre_cobertura="COBERTURA GENERAL COMPRENSIVA"; break;
                case "233": nombre_cobertura="RESPONSABILIDDAD CIVIL CRUZADA"; break;
                case "234": nombre_cobertura="COBERTURA DE RESPONSABILIDAD CIVIL LEGAL DE DAÑOS POR INCENDIO"; break;
                case "235": nombre_cobertura="RESPONSABILIAD CIVIL LEGAL"; break;
                case "236": nombre_cobertura="RESPONSABILIAD CIVIL LEGAL CONTRACTUAL"; break;
                case "237": nombre_cobertura="RESPONSABILIAD CIVIL LEGAL EXTRA CONTRACTUAL"; break;
                case "238": nombre_cobertura="RESPONSABILIAD CIVIL CONTRACTUAL"; break;
                case "239": nombre_cobertura="RESPONSABILIAD CIVIL EXTRA CONTRACTUAL"; break;
                case "240": nombre_cobertura="U: Responsabilidad Civil Umbrella"; break;
                case "72": nombre_cobertura="RESPONSABILIDAD CIVIL PREDIO Y OPERACIONES INDUSTRIALES"; break;
                case "241": nombre_cobertura="RESPONSABILIDAD CIVIL CONTRATISTAS SUBCONTRATISTAS"; break;
                case "242": nombre_cobertura="RESPONSABILIDAD CIVIL DE ELEVADORES Y/O ESCALERAS ELECTRICAS"; break;
                case "243": nombre_cobertura="RESPONSABILIDAD CIVIL DE TRANSPORTISTAS"; break;
                case "244": nombre_cobertura="RESPONSABILIDAD CIVIL EN EXCESO DE AUTOMOVIL"; break;
                case "245": nombre_cobertura="RESPONSABILIDAD CIVIL POR CONTAMINACIÓN AL MEDIO AMBIENTE"; break;
                case "246": nombre_cobertura="RESPONSABILIDAD CIVIL POR INCENDIO Y/O EXPLOSION"; break;
                case "247": nombre_cobertura="RESPONSABILIDAD CIVIL POR DAÑOS PATRIMONIALES PUROS"; break;
                case "248": nombre_cobertura="RESPONSABILIDAD CIVIL POR ONDAS Y CAMPOS ELECTROMAGNETICOS"; break;
                case "249": nombre_cobertura="RESPONSABILIDAD CIVL POST-TRABAJOS"; break;
                case "250": nombre_cobertura="RESPONSABILIDAD CIVIL POR VIOLACION DE PATENTES"; break;
                case "251": nombre_cobertura="RESPONSABILIDAD CIVIL POR PRACTICAS LABORALES"; break;
                case "252": nombre_cobertura="RESPONSABILIDAD CIVIL FIDUCIARIA DE PLANES DE PRESTACIONES PARA EMPLEADOS"; break;
                case "253": nombre_cobertura="RESPONSABILIDAD CIVIL CUIDADO, CONTROL Y CUSTODIA"; break;
              }
              break;
            case 'TR':
              //console.log('ingrese a TTR');
                  switch (String(json.data.vCoberturasXPoliza[i].codigoCobertura)) {
                    case "27": nombre_cobertura="DAÑO MALICIOSO ESPECIAL (Terrorismo)"; break;
            case "23": nombre_cobertura="FALTA DE ENTREGA"; break;
            case "28": nombre_cobertura="CARGA Y DESCARGA"; break;
            case "29": nombre_cobertura="MOTIN, HUELGA, CONMOCION CIVIL, DAÑOS MALICIOSOS Y ACTOS DE TERRORISMO"; break;
            case "30": nombre_cobertura="MOTIN, HUELGA, CONMOCION CIVIL Y DAÑOS MALICIOSOS"; break;
            case "31": nombre_cobertura="AVERIA GRUESA"; break;
            case "21": nombre_cobertura="ATRACO"; break;
            case "32": nombre_cobertura="OTRAS COBERTURAS"; break;
            case "25": nombre_cobertura="CARGA SOBRE CUBIERTA"; break;
            case "26": nombre_cobertura="FLOTE"; break;
            case "22": nombre_cobertura="ROBO Y/O ATRACO"; break;
            case "24": nombre_cobertura="TODO RIESGO EXTERNO"; break;
            case "16": nombre_cobertura="TERREMOTO,TEMBLOR,ERUPCION VOLCANICA, CAIDA DE CENIZA Y/O ARENA VOLCANICA E INC"; break;
            case "17": nombre_cobertura="MAREMOTO"; break;
            case "18": nombre_cobertura="TEMBLOR"; break;
            case "19": nombre_cobertura="HURACAN"; break;
            case "20": nombre_cobertura="VIENTOS TEMPESTUOSOS"; break;
            case "34": nombre_cobertura="BODEGA A BODEGA"; break;
            case "35": nombre_cobertura="HUELGAS, ALBOROTOS POPULARES Y CONMOCION CIVIL"; break;
            case "36": nombre_cobertura="TODO RIESGO (INCISO C)"; break;
            case "37": nombre_cobertura="RATERIA"; break;
            case "38": nombre_cobertura="HUELGAS, ALBOROTOS POPULARES Y CONMOCION CIVIL ."; break;
            case "1": nombre_cobertura="RIESGOS ORDINARIOS DE TRANSITO TRANSPORTE TERRESTRE Y/O AEREO"; break;
            case "8": nombre_cobertura="TRANSBORDO Y RECUPERACION"; break;
            case "9": nombre_cobertura="ROTURAS"; break;
            case "2": nombre_cobertura="RIESGOS ORDINARIOS DE TRANSITO   TRANSPORTE MARITIMO"; break;
            case "7": nombre_cobertura="CONTACTO CON OTRAS CARGAS"; break;
            case "11": nombre_cobertura="MOTIN, HUELGAS Y ALBOROTOS POPULARES"; break;
            case "12": nombre_cobertura="GUERRA"; break;
            case "10": nombre_cobertura="FALTA DE PESO O DERRAME"; break;
            case "3": nombre_cobertura="ROBO DE BULTO POR ENTERO"; break;
            case "4": nombre_cobertura="ROBO PARCIAL"; break;
            case "5": nombre_cobertura="MOJADURA"; break;
            case "6": nombre_cobertura="OXIDACION"; break;
            case "13": nombre_cobertura="TODO RIESGO  1.1.82  (A)"; break;
            case "14": nombre_cobertura="C. A. P.   1.1.82   (B)"; break;
            case "15": nombre_cobertura=" L.A.P.  1.1.82  (C)"; break;
            case "39": nombre_cobertura="TODO RIESGO  1.1.63  (A)"; break;
            case "40": nombre_cobertura="PAGO DE IMPUESTOS ANTE SAT"; break;
            case "54": nombre_cobertura="Cobertura de riesgos de cláusulas del instituto de londres, para cargamentos “a”"; break;
            case "55": nombre_cobertura="Cobertura de riesgos de cláusulas del instituto de londres, para cargamentos “b”"; break;
            case "56": nombre_cobertura="Huelga"; break;
            case "57": nombre_cobertura="Guerra"; break;
            case "41": nombre_cobertura="Incendio, rayo, auto-ignición y explosión"; break;
            case "42": nombre_cobertura="Terremoto, temblor"; break;
            case "43": nombre_cobertura="Colisión del vehículo con otro vehículo u objeto"; break;
            case "44": nombre_cobertura="Vuelco del vehículo transportador de los bienes descritos"; break;
            case "45": nombre_cobertura="Vendaval, ciclón o tornado"; break;
            case "46": nombre_cobertura="Derrumbe de puentes o alcantarillas"; break;
            case "47": nombre_cobertura="Derrumbe de tierra, de piedras o de rocas"; break;
            case "48": nombre_cobertura="Inundación por el desbordamiento de ríos o quebradas"; break;
            case "49": nombre_cobertura="Saqueo"; break;
            case "50": nombre_cobertura="Robo o Asalto"; break;
            case "51": nombre_cobertura="Maniobras de Carga y Descarga"; break;
            case "52": nombre_cobertura="Refrigeración"; break;
            case "53": nombre_cobertura="Extraterritorialidad"; break;
            case "69": nombre_cobertura="Movimientos Bruscos"; break;
            case "58": nombre_cobertura="Riesgos del Medio de Transporte"; break;
            case "59": nombre_cobertura="Caída, Colisión o Vuelco de Mercancías"; break;
            case "60": nombre_cobertura="Caída de Mercancía en Predios"; break;
            case "61": nombre_cobertura="Fallas Mecánicas en el Sistema de Refrigeración"; break;
            case "62": nombre_cobertura="Todo Riesgo 1.1.82 A"; break;
            case "63": nombre_cobertura="Motin y Huelga"; break;
            case "64": nombre_cobertura="Robo y/o Atraco"; break;
            case "65": nombre_cobertura="Carga y Descarga"; break;
            case "66": nombre_cobertura="Bodega a Bodega"; break;
            case "67": nombre_cobertura="Estadía"; break;
            case "68": nombre_cobertura="Riesgos Ordinarios de Transito 1.1.82 C"; break;
            case "71": nombre_cobertura="TRANSBORDO"; break;
            case "72": nombre_cobertura="RECUPERACION"; break;
            case "73": nombre_cobertura="DESTRUCCION"; break;
            case "70": nombre_cobertura="Responsabilidad Civil Carga Vía Terrestre"; break;
                  }
            break;
            case 'CRI':
    //console.log('ingrese a CRI');
      switch (String(json.data.vCoberturasXPoliza[i].codigoCobertura)) {
        case "9": nombre_cobertura="DINERO Y/O VALORES EN TRANSITO"; break;
        case "10": nombre_cobertura="DINERO Y/O VALORES EN EL LOCAL"; break;
        case "11": nombre_cobertura="CERTIFICADO DE REGALOS"; break;
        case "1": nombre_cobertura="SECCION I :   INFIDELIDAD DE EMPLEADOS"; break;
        case "3": nombre_cobertura="SECCION III :    PERDIDA FUERA DE LOS PREDIOS"; break;
        case "4": nombre_cobertura="SECCION IV :   GIROS BANCARIOS Y BILLETES FALSOS"; break;
        case "5": nombre_cobertura="SECCION V :  FALSIFICACION DE DOCUMENTOS POR PARTE DEL DEPOSITANTE"; break;
        case "6": nombre_cobertura="VI : FRAUDE REALIZADO POR TERCEROS POR COMPUTADORA Y POR TRANSFERENCIA DE FONDOS"; break;
        case "7": nombre_cobertura="SECCION  VII :  FRAUDE MEDIANTE TARJETAS DE CREDTIO"; break;
        case "8": nombre_cobertura="SECCION VIII  :   FRAUDE MEDIANTE RECEPCION DE CHEQUES"; break;
        case "2": nombre_cobertura="SECCION II :   PÉRDIDA DENTRO DE LOS PREDIOS"; break;
        case "12": nombre_cobertura="CRIMEN COMERCIAL"; break;
        case "13": nombre_cobertura="FRAUDE O ACTO DESHONESTO COMETIDO POR ALGUN EMPLEADO"; break;
        case "14": nombre_cobertura="ACTO FRAUDULENTO COMETIDO POR ALGUNA OTRA PERSONA"; break;
        case "15": nombre_cobertura="CUIDADO, CONTROL Y CUSTODIA"; break;
        case "16": nombre_cobertura="HONORARIOS, COSTOS Y GASTOS"; break;

      }
    break;
        }
        if ($('#monedaPol').val()=='Q')
        {
          datosini.push({CODIGO:json.data.vCoberturasXPoliza[i].codigoCobertura,DESCRIPCION:nombre_cobertura,SUMA:aMoneda(json.data.vCoberturasXPoliza[i].sumaAsegurada?json.data.vCoberturasXPoliza[i].sumaAsegurada:0, opcionesQuetzales)});
        }else
        {
          datosini.push({CODIGO:json.data.vCoberturasXPoliza[i].codigoCobertura,DESCRIPCION:nombre_cobertura,SUMA:aMoneda(json.data.vCoberturasXPoliza[i].sumaAsegurada?json.data.vCoberturasXPoliza[i].sumaAsegurada:0, opcionesDolares)});
        }
      }
    }
    mivaribleDep = datosini;
    setTimeout(function(){}, 9000);
    setMiVariableCoberturas(mivaribleDep);         
  } else 
  {

  }
}).catch(function(error) {


       })
}



}, []);

const columns =[
      {dataField: "CODIGO", text:"CODIGO"},
      {dataField: "DESCRIPCION", text:"DESCRIPCION"},
      {dataField: "SUMA", text:"SUMA ASEGURADA"},

];

  return (
    <BootStrapTable style={{maxWidth: '1600px', width: '95%'}}
      keyField="CODIGO"
      data={miVariableCoberturas}
      columns = {columns}
      pagination={paginationFactory()}
      striped
      bordered = {true}
          wrapperClasses="table-responsive"
      />

  );
};
export default Pagination;
