import React, {Component} from 'react';
import {

  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Collapse,
  FormGroup,
  Input,
  Label
} from 'reactstrap';

import Tab from './prueba.js';
import Select from 'react-select';
import $ from 'jquery';
import swal from 'sweetalert';
import Moment from 'moment';

class parte1 extends Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.toggleFade = this.toggleFade.bind(this);
    this.toggleLarge = this.toggleLarge.bind(this);
    this.toggleSmall = this.toggleSmall.bind(this);
    this.togglePrimary = this.togglePrimary.bind(this);
    this.toggleSuccess = this.toggleSuccess.bind(this);
    this.toggleWarning = this.toggleWarning.bind(this);
    this.toggleDanger = this.toggleDanger.bind(this);
    this.toggleInfo = this.toggleInfo.bind(this);
    this.toggleInfo2 = this.toggleInfo2.bind(this);
    this.state = {
      name: "React",
      aseguradora: '',
      tipopol: '',
      numeropol: '',
      poliza: '',
      miVariable: '',
      aseguradora: '',
      tipopol: '',
      numeropol: '',
      certificado: '',
      showHideDemo1: false,
      showHideDemo5: false,
      showHideDemo3: false,
      modal: false,
      large: false,
      small: false,
      primary: false,
      success: false,
      warning: false,
      danger: false,
      info: false,
      collapse: true,
      idPoliza :'',
      selectedOption: null,
       selectedOptionDep: null,
       selectedOptionPol: null,
       selectedOptionCert: null,
      idCertificado:'',
      datospoliza:[],
      datoscertificado:[],
      cliente:'',
      fadeIn: true,
      timeout: 300
    };
    this.hideComponentn = this.hideComponentn.bind(this);
    this.componentDidMount = this.componentDidMount.bind(this);
      this.handleChangePol = this.handleChangePol.bind(this);
    this.handleChangeCert = this.handleChangeCert.bind(this);
    let miVariable = window.miVariable;
  }


  hideComponentn(name) {
    //console.log(name);
    switch (name) {
      case "showHideDemo1":
        this.setState({
          showHideDemo1: !this.state.showHideDemo1
        });
        break;
      case "showHideDemo2":
        this.setState({
          showHideDemo2: !this.state.showHideDemo2
        });
        break;
    }
  }
  toggle() {
    this.setState({
      collapse: !this.state.collapse
    });
  }

  toggleFade() {
    this.setState((prevState) => {
      return {
        fadeIn: !prevState
      }
    });
  }

  toggleLarge() {
    this.setState({
      large: !this.state.large
    });
  }

  toggleSmall() {
    this.setState({
      small: !this.state.small
    });
  }

  togglePrimary() {
    this.setState({
      primary: !this.state.primary
    });
  }

  toggleSuccess() {
    this.setState({
      success: !this.state.success
    });
  }

  toggleWarning() {
    this.setState({
      warning: !this.state.warning
    });
  }

  toggleDanger() {
    this.setState({
      danger: !this.state.danger
    });
  }

  toggleInfo() {
    this.setState({
      info: !this.state.info
    });
  }
  toggleInfo2() {
    this.setState({
      info: !this.state.info
    });
  }

  handleChangeCert = selectedOptionCert => {
    $('#ce').val('');
    this.setState({selectedOptionCert});
    //console.log(`Option selected:`, selectedOptionCert);

  };

  handleChangePol = selectedOptionPol => {
    $('#ce').val('');
    this.setState({selectedOptionPol});
    var index = this.state.datoscertificado.indexOf(1)
    this.state.datoscertificado.splice(index, 1);
    //console.log(`Option selected:`, selectedOptionPol.value);
    let miVariableP = window.miVariable;
    //console.log(miVariableP);
    //console.log(miVariableP.tPolCertificado.length);
    if (miVariableP.tPolCertificado.length != undefined) {
      //console.log(miVariableP.tPolCertificado);
      //console.log(miVariableP.tPolCertificado.length);
      for (var i = 0; i < miVariableP.tPolCertificado.length; i++) {
        if (miVariableP.tPolCertificado[i].idPoliza.idPoliza === selectedOptionPol.value) {
          //console.log(miVariableP.tPolCertificado[i].idCertificado + '   ' + i + '   ' + selectedOptionPol.value);
          this.state.datoscertificado.push({value: miVariableP.tPolCertificado[i].idCertificado, label: miVariableP.tPolCertificado[i].idCertificado});
          this.setState({showHideDemo1:true})
          window.variableP= miVariableP.tPolCertificado[i];
          //console.log(window.variableP);
          swal({
            title: "Exitoso",
            text: "La información consultada es la gestionada por SOMIT, correspondiente a la vigencia:  " + Moment(window.variableP.idPoliza.vigenciaInicio).format('L') + ' - ' + Moment(window.variableP.idPoliza.vigenciaFin).format('L'),
            icon: "success",
            button: "OK!"
          });
          this.setState({idPoliza: miVariableP.tPolCertificado[i].idPoliza.idPoliza})
          this.setState({idCertificado: miVariableP.tPolCertificado[i].idCertificado})
          this.setState({idaseguradora: miVariableP.tPolCertificado[i].idPoliza.idAseguradora.idAseguradora})
          this.setState({tipopol: miVariableP.tPolCertificado[i].idPoliza.idTipoPoliza.codigoTipoPoliza})
          this.setState({numeropol: miVariableP.tPolCertificado[i].idPoliza.numeroPoliza})
      }
    }
  }
  else{
    //console.log(miVariableP.tPolCertificado);

      if (miVariableP.tPolCertificado.idPoliza.idPoliza === selectedOptionPol.value) {
              this.state.datoscertificado.push({value: miVariableP.tPolCertificado.idCertificado, label: miVariableP.tPolCertificado.idCertificado});
        this.setState({showHideDemo1:true})
        window.variableP= miVariableP.tPolCertificado;
        //console.log(window.variableP);
        swal({
          title: "Exitoso",
          text: "La información consultada es la gestionada por SOMIT, correspondiente a la vigencia:  " + Moment(window.variableP.idPoliza.vigenciaInicio).format('L') + ' - ' + Moment(window.variableP.idPoliza.vigenciaFin).format('L'),
          icon: "success",
          button: "OK!"
        });
        this.setState({idPoliza: miVariableP.tPolCertificado.idPoliza.idPoliza})
        this.setState({idCertificado: miVariableP.tPolCertificado.idCertificado})
        this.setState({idaseguradora: miVariableP.tPolCertificado.idPoliza.idAseguradora.idAseguradora})
        this.setState({tipopol: miVariableP.tPolCertificado.idPoliza.idTipoPoliza.codigoTipoPoliza})
        this.setState({numeropol: miVariableP.tPolCertificado.idPoliza.numeroPoliza})
    }


  }
};

componentDidMount() {
  //console.log('llegue aqui');
  let miVariableP = window.miVariable;
  if (miVariableP.tPolCertificado.length != undefined) {
    //console.log(miVariableP.tPolCertificado);
    //console.log(miVariableP.tPolCertificado.length);
    for (var i = 0; i < miVariableP.tPolCertificado.length; i++) {
      //console.log('ingrse al push');
      this.state.datospoliza.push({
        value: miVariableP.tPolCertificado[i].idPoliza.idPoliza,
        label: miVariableP.tPolCertificado[i].idPoliza.idAseguradora.abreviatura + '-' + miVariableP.tPolCertificado[i].idPoliza.idTipoPoliza.codigoTipoPoliza + '-' + miVariableP.tPolCertificado[i].idPoliza.numeroPoliza
      });
      //console.log(miVariableP.tPolCertificado);
      fetch(`${process.env.REACT_APP_API_URL}/it-api-clientes2/rs/operaciones/cliente/`+miVariableP.tPolCertificado[i].idCertificado,{
        headers: {
          'Authorization': `Bearer ${sessionStorage.getItem('jwtToken')}`,
        },
      })
      .then(res => res.json())
      .then((data) => {
        //console.log(data);
        this.setState({
        cliente: data.primerNombre+' '+data.segundoApellido +' '+data.primerApellido +' '+data.segundoApellido
      })
      })
    }

  } else {
    //console.log('ingrese al ese de parte2');
    this.state.datospoliza.push({
      value: miVariableP.tPolCertificado.idPoliza.idPoliza,
      label: miVariableP.tPolCertificado.idPoliza.idAseguradora.abreviatura + '-' + miVariableP.tPolCertificado.idPoliza.idTipoPoliza.codigoTipoPoliza + '-' + miVariableP.tPolCertificado.idPoliza.numeroPoliza
    });

    this.state.poliza = miVariableP.tPolCertificado.idPoliza.idAseguradora.abreviatura + '-' + miVariableP.tPolCertificado.idPoliza.idTipoPoliza.codigoTipoPoliza + '-' + miVariableP.tPolCertificado.idPoliza.numeroPoliza;
    this.state.idPoliza = miVariableP.tPolCertificado.idPoliza.idPoliza;
    //console.log('::::::::::::::::::::::::::' + miVariableP.tPolCertificado.idPoliza.idPoliza);
  }
  fetch(`${process.env.REACT_APP_API_URL}/it-api-clientes2/rs/operaciones/cliente/`+miVariableP.tPolCertificado.idCertificado,{
    headers: {
      'Authorization': `Bearer ${sessionStorage.getItem('jwtToken')}`,
    },
  })
  .then(res => res.json())
  .then((data) => {
    //console.log(data);
    this.setState({
    cliente: data.primerNombre+' '+data.segundoApellido +' '+data.primerApellido +' '+data.segundoApellido
  })
window.clienteTitular =this.state.cliente;
  })
}

render() {
  let miVariable = window.miVariable.tPolCertificado;
  let certificado = 1;
  let poliza = this.state.datospoliza;
  let cliente;


  //console.log('ingrse a este cliente');
    cliente=this.state.cliente;
  const {selectedOptionPol} = this.state;

  const pol = this.state.datospoliza;
  //console.log(pol);

const cert = this.state.datoscertificado;


  const {selectedOptionCert} = this.state;

  return (<Card>
    <CardHeader style={{
        background: '#872434',
        color: 'white'
      }}>
      <strong>
        Informacion General Asegurado
      </strong>

      <div className="card-header-actions">

        <Button color="link" className="card-header-action btn-minimize" data-target="#collapseExample" onClick={this.toggle}>
          <i className="icon-arrow-up"></i>
        </Button>
        <Button color="link" className="card-header-action btn-close" onClick={this.toggleFade}>
          <i className="icon-close"></i>
        </Button>
      </div>
    </CardHeader>
    <Collapse isOpen={this.state.collapse} id="collapseExample">
      <CardBody>
        <row>

          <FormGroup row="row">

            <Col md="1">
              <strong><Label>Poliza:</Label></strong>
            </Col>
            <Col md="3">
            <Select id='pol' name='pol' options={pol} value={selectedOptionPol} onChange={this.handleChangePol} />

          </Col>
            <Col md="1">
              <strong><Label>Certificado:</Label></strong>
            </Col>
            <Col md="2">
              <Select id="ce" name="ce" options={cert} value={selectedOptionCert} onChange={this.handleChangeCert} efaultValue={{value:this.state.idCertificado, label:this.state.certificado}}/>
            </Col>
            <Col md="1">
              <strong>
                <Label>Titular:</Label>
              </strong>
            </Col>
            <Col md="4">
              <Input type="text" id="disabled-input" name="disabled-input" value={cliente.replace(undefined, '')} placeholder="Titular" disabled="disabled"/>
            </Col>
          </FormGroup>
        </row>

        <FormGroup className="form-actions">
          <br></br>
          {this.state.showHideDemo1}
          {this.state.showHideDemo1 && <Tab/>}
        </FormGroup>
      </CardBody>
    </Collapse>
  </Card>);
}
}
export default parte1;
