import React, { Component } from "react";
import loader from './pageLoader.gif';
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Form,
  Row,
  FormGroup,
  Input,
  Label,

} from 'reactstrap';
import $ from 'jquery';
import swal from 'sweetalert';
import Select from 'react-select';
import axios from './axios-config';
import Moment from 'moment';
import Dropzone from 'react-dropzone';
import Drop from './dropzone.js';


class parte2 extends Component {
constructor(){
  super();

  this.state = {
    datosEnfe:[],
     aseguradora : '',
     tipopol:'',
     numeropol:'',
     poliza : '',
     idaseguradora:'',
     fechaOcurrencia :'',
     enfermedad:'',
     comentarios:'',
     contacto:'',
     mail:'',
     telefono:'',
     idPoliza :'',
     certificado:'',
     selectedOption: null,
     selectedOptionDep: null,
     selectedOptionPol: null,
     selectedOptionCert: null,
     idCertificado:'',
     idDependiente:'',
     datosdependiente:[],
     datospoliza:[],
     datoscertificado:[],
     cliente:'',
     myFiles:[],
     setMyFiles:[],
     files:[],
     ckdep:'',
     cktit:'',
     ckgm:true,
     ckcps:true,
     ckcpn:'',
     ckvd:'',
     dep:'',
     complement:'',
     otros:'',
     reclamo:'',
     id_pais:1,
     operacion:9,
     valida:false,
     tiporeclamogm:'',
      selectedFile: null,
     tiporeclamovd:''
  };

  this.handleChange = this.handleChange.bind(this);
  this.agregar_spinner_regional = this.agregar_spinner_regional.bind(this);
  this.handleChangeDep = this.handleChangeDep.bind(this);
  this.handleChangePol = this.handleChangePol.bind(this);
  this.handleChangeCert = this.handleChangeCert.bind(this);
  this.handleInputFecha = this.handleInputFecha.bind(this);
  this.handleInputOtros = this.handleInputOtros.bind(this);
  this.handleInputChangeConsu = this.handleInputChangeConsu.bind(this);
  this.handleInputChangeTipo = this.handleInputChangeTipo.bind(this);
  this.handleInputComentarios = this.handleInputComentarios.bind(this);
  this.handleInputContacto = this.handleInputContacto.bind(this);
  this.handleInputMail = this.handleInputMail.bind(this);
  this.handleInputTelefono = this.handleInputTelefono.bind(this);
  this.handleInputChangeComplement = this.handleInputChangeComplement.bind(this);
}

handleInputFecha(event) {
  //console.log(event.target.value);
   this.setState({fechaOcurrencia: event.target.value});
 }
 handleInputComentarios(event) {
   //console.log(event.target.value);
    this.setState({comentarios: event.target.value});
  }
  handleInputContacto(event) {
     this.setState({contacto: event.target.value});
   }
   handleInputMail(event) {
      this.setState({mail: event.target.value});
    }
    handleInputTelefono(event) {
       this.setState({telefono: event.target.value});
     }
     handleInputOtros(event) {
        this.setState({otros: event.target.value});
      }


handleChange = selectedOption => {
    this.setState({ selectedOption });
    //console.log(`Option selected:`, selectedOption);
    if (selectedOption.value==460){
    $('#otros').show();
  }
  };

  handleChangeDep = selectedOptionDep => {
      this.setState({ selectedOptionDep });
      //console.log(`Option selected:`, selectedOptionDep);
    };

  handleChangeCert = selectedOptionCert => {
    //console.log(selectedOptionCert)

    this.setState({ selectedOptionCert });
  };

    handleChangePol = selectedOptionPol => {
        $('#ce').val('');
        this.setState({ selectedOptionPol });
        var index = this.state.datoscertificado.indexOf(1)
        this.state.datoscertificado.splice(index, 1);
        let miVariableP = window.miVariable;
        if (miVariableP.tPolCertificado.length!=undefined){
          for (var i = 0; i < miVariableP.tPolCertificado.length; i++) {
                if (miVariableP.tPolCertificado[i].idPoliza.idPoliza===selectedOptionPol.value){
                    this.state.datoscertificado.push({value: miVariableP.tPolCertificado[i].idCertificado, label:miVariableP.tPolCertificado[i].idCertificado});


                    //console.log($('#ce').val());

                    this.setState({idCertificado:miVariableP.tPolCertificado[i].idCertificado});
                    this.setState({certificado:miVariableP.tPolCertificado[i].idCertificado});
                    this.setState({selectedOptionCert:miVariableP.tPolCertificado[i].idCertificado});
                    this.handleChangeCert(miVariableP.tPolCertificado[i].idCertificado);
                    $('#ce').val(miVariableP.tPolCertificado[i].idCertificado);
                    this.setState({idPoliza: miVariableP.tPolCertificado[i].idPoliza.idPoliza})
                    this.setState({idCertificado: miVariableP.tPolCertificado[i].idCertificado})
                    this.setState({idaseguradora: miVariableP.tPolCertificado[i].idPoliza.idAseguradora.idAseguradora})
                    this.setState({tipopol: miVariableP.tPolCertificado[i].idPoliza.idTipoPoliza.codigoTipoPoliza})
                    this.setState({numeropol: miVariableP.tPolCertificado[i].idPoliza.numeroPoliza})

                      let dependientes='';
                      fetch(`${process.env.REACT_APP_API_URL}/it-api-clientes2/rs/operaciones/dependientes/`+miVariableP.tPolCertificado[i].idCertificado,{
                        headers: {
                          'Authorization': `Bearer ${sessionStorage.getItem('jwtToken')}`,
                        },
                      })
                      .then(res => res.json())
                      .then((data) => {
                        this.setState({
                        dep: data
                      })
                      dependientes = this.state.dep;
                          if (dependientes == null){
                            //console.log('no tiene dependientes')
                          }else{

                          for (var i = 0; i < dependientes.tPolDependiente.length; i++) {
                          this.state.datosdependiente.push({value: dependientes.tPolDependiente[i].idDependiente,  label:dependientes.tPolDependiente[i].primerNombre +' '+dependientes.tPolDependiente[i].primerApellido });
                          }

                          }
                      })
                }
          }
        }else{
              if (miVariableP.tPolCertificado.idPoliza.idPoliza === selectedOptionPol.value) {
                this.state.datoscertificado.push({value: miVariableP.tPolCertificado.idCertificado, label: miVariableP.tPolCertificado.idCertificado});
                this.setState({showHideDemo1:true})
                window.variableP= miVariableP.tPolCertificado;
                this.setState({idPoliza: miVariableP.tPolCertificado.idPoliza.idPoliza})
                this.setState({idCertificado: miVariableP.tPolCertificado.idCertificado})
                this.setState({idaseguradora: miVariableP.tPolCertificado.idPoliza.idAseguradora.idAseguradora})
                this.setState({tipopol: miVariableP.tPolCertificado.idPoliza.idTipoPoliza.codigoTipoPoliza})
                this.setState({numeropol: miVariableP.tPolCertificado.idPoliza.numeroPoliza})
            }
              let dependientes='';
              fetch(`${process.env.REACT_APP_API_URL}/it-api-clientes2/rs/operaciones/dependientes/`+miVariableP.tPolCertificado.idCertificado,{
                headers: {
                  'Authorization': `Bearer ${sessionStorage.getItem('jwtToken')}`,
                },
              })
              .then(res => res.json())
              .then((data) => {
                  //console.log(data);
                  this.setState({
                  dep: data
                })
              dependientes = this.state.dep;
                  if (dependientes == null){
                    //console.log('no tiene dependientes')
                  }else{
                  for (var i = 0; i < dependientes.tPolDependiente.length; i++) {
                  this.state.datosdependiente.push({value: dependientes.tPolDependiente[i].idDependiente,  label:dependientes.tPolDependiente[i].primerNombre +' '+dependientes.tPolDependiente[i].primerApellido });
                  }
                  }
              })
        }
      };

componentDidMount() {
  //console.log('entro aca');
  var dat_poliza=0;
  let miVariableP = window.miVariable;
  if (miVariableP.tPolCertificado.length!=undefined){
    for (var i = 0; i < miVariableP.tPolCertificado.length; i++) {
       dat_poliza=miVariableP.tPolCertificado[i].idPoliza.idPoliza;
      fetch(`${process.env.REACT_APP_API_URL}/it-api-clientes2/rs/operaciones/polizas_migracion/`+miVariableP.tPolCertificado[i].idPoliza.idPoliza,{
        headers: {
          'Authorization': `Bearer ${sessionStorage.getItem('jwtToken')}`,
        },
      })
      .then(res => res.json())
      .then((data) => {
            for (var a = 0; a < miVariableP.tPolCertificado.length; a++) {
                        if (data.estado == 'A' && data.idepol == miVariableP.tPolCertificado[a].idPoliza.idPoliza){
                          this.state.datospoliza.push({value: miVariableP.tPolCertificado[a].idPoliza.idPoliza,
                              label:miVariableP.tPolCertificado[a].idPoliza.idAseguradora.abreviatura +
                              '-'+miVariableP.tPolCertificado[a].idPoliza.idTipoPoliza.codigoTipoPoliza+'-'+
                              miVariableP.tPolCertificado[a].idPoliza.numeroPoliza });
                        }
                        else{

                        }
            }
      })
      //console.log(miVariableP.tPolCertificado);
      fetch(`${process.env.REACT_APP_API_URL}/it-api-clientes2/rs/operaciones/cliente/`+miVariableP.tPolCertificado[i].idCertificado,{
        headers: {
          'Authorization': `Bearer ${sessionStorage.getItem('jwtToken')}`,
        },
      })
      .then(res => res.json())
      .then((data) => {
        //console.log(data);
        this.setState({
        cliente: data.primerNombre+' '+data.segundoNombre +' '+data.primerApellido +' '+data.segundoApellido
      })
      })

    }

  }
  else{
    //console.log('ingrese al ese de parte2');
    this.state.datospoliza.push({value: miVariableP.tPolCertificado.idPoliza.idPoliza,
        label:miVariableP.tPolCertificado.idPoliza.idAseguradora.abreviatura +'-'+miVariableP.tPolCertificado.idPoliza.idTipoPoliza.codigoTipoPoliza+'-'+miVariableP.tPolCertificado.idPoliza.numeroPoliza  });

    this.state.poliza = miVariableP.tPolCertificado.idPoliza.idAseguradora.abreviatura +'-'+miVariableP.tPolCertificado.idPoliza.idTipoPoliza.codigoTipoPoliza+'-'+miVariableP.tPolCertificado.idPoliza.numeroPoliza ;
    this.state.idPoliza =miVariableP.tPolCertificado.idPoliza.idPoliza;
    //console.log('::::::::::::::::::::::::::'+miVariableP.tPolCertificado.idPoliza.idPoliza);

    this.state.idCertificado=miVariableP.tPolCertificado.idCertificado;
    this.state.numeroCertificado=miVariableP.tPolCertificado.numeroCertificado;
    //console.log(miVariableP.tPolCertificado);
    fetch(`${process.env.REACT_APP_API_URL}/it-api-clientes2/rs/operaciones/cliente/`+miVariableP.tPolCertificado.idCertificado,{
      headers: {
        'Authorization': `Bearer ${sessionStorage.getItem('jwtToken')}`,
      },
    })
    .then(res => res.json())
    .then((data) => {
      //console.log(data);
      this.setState({
      cliente: data.primerNombre+' '+data.segundoNombre +' '+data.primerApellido +' '+data.segundoApellido
    })
  window.clienteTitular =this.state.cliente;
    })
  }


  $('#div_dep').hide();
  let enfermedades='';
  fetch(`${process.env.REACT_APP_API_URL}/it-api-clientes2/rs/operaciones/enfermedades/`,{
    headers: {
      'Authorization': `Bearer ${sessionStorage.getItem('jwtToken')}`,
    },
  })
  .then(res => res.json())
  .then((data) => {
    ////console.log(data);
    this.setState({
    informacion: data
  })
  enfermedades = this.state.informacion.tGmEnfermedad;

  for (var i = 0; i < enfermedades.length; i++) {

  this.state.datosEnfe.push({value: enfermedades[i].idEnfermedad,  label:enfermedades[i].nombre});
  }

  })


}

handleInputChangeTipo(event) {
  //console.log(event);
//  alert(event.target.name);
  const targett = event.target;
  const valuet = targett.type === 'checkbox' ? targett.checked : targett.value;
  const namet = targett.name;
  if (namet==='ckgm'){
    //console.log(valuet);
      $('#ckgm').prop('checked', true);
      $('#ckvd').prop('checked', true);
this.state.ckvd = false;
      this.setState({
       [namet]: valuet
     });
}else {
    $('#ckgm').prop('checked', false);
    $('#ckvid').prop('checked', true);
  this.state.ckgm = false;
  this.setState({
       [namet]: valuet
       });
}
}

handleInputChangeComplement(event) {
  //console.log(event);
//  alert(event.target.name);
  const targett = event.target;
  const valuet = targett.type === 'checkbox' ? targett.checked : targett.value;
  const namet = targett.name;
  if (namet==='ckcps'){
    //console.log(valuet);
      $('#ckcps').prop('checked', true);
      $('#ckcpn').prop('checked', false);
this.state.ckcpn = false;
      this.setState({
       [namet]: valuet
     });
}else {
    $('#ckcps').prop('checked', false);
    $('#ckcpn').prop('checked', true);
  this.state.ckcps = false;
  this.setState({
       [namet]: valuet
       });
}

}

agregar_spinner_regional = (id_objeto) =>
{

//console.log("llegue"+id_objeto);

$("#" + id_objeto).addClass("overlay");
$("#" + id_objeto).show();
$("#" + id_objeto).css('display', 'block');
}

limpiar_spinner = (id_objeto) =>
{
  //console.log("ingrese a limpieza");
    $('#' + id_objeto).removeClass("overlay");
    $('#' + id_objeto).html("");
    $("#" + id_objeto).hide();
}


enviar_digitalizacion= () =>
{
  //console.log(window.datoArchivo);


  //this.state.files = $('#dato').val();
  this.setState({
    files: window.datoArchivo
  });
//console.log(this.state.files);
//console.log(this.state.files.length);
//console.log(this.state.files[0].length);
//console.log(this.state.files[0]);
  //custom-file-input
  const formData = new FormData();

  // Update the formData object
    formData.append('codigo_aseguradora',this.state.idaseguradora);
  formData.append('codigo_tipo_poliza',this.state.tipopol);
    formData.append('numero_poliza',this.state.numeropol);
      formData.append('id_reclamo',this.state.reclamo);
        formData.append('rec_id_pais',this.state.id_pais);
        formData.append('operacion',this.state.operacion);
        for (var i = 0; i < this.state.files[0].length; i++) {
          //console.log(i);
      //console.log(this.state.files[i]);
          //console.log(this.state.files[0][i].name);
          formData.append(
            "archivos[]",
            this.state.files[0][i],
            this.state.files[0][i].name
          );

        }

    var data = formData;
        //console.log(data);
        var url = '/Gestion_Reclamo';
        $.ajax({
            type: 'POST',
            url: url,
            enctype: 'multipart/form-data',
            processData: false,
            contentType: false,
            cache: false,
            data: data,
            success: function (digita)
            {

                var res = eval(digita);
                if (res[0] === 'false')
                {

                    swal({
                        title: "Somit Regional",
                        text: "No fue posible digitalizar, su sesión ha finalizado, vuelva a ingresar por favor.",
                        type: "error",
                        showCancelButton: false,
                        confirmButtonColor: '#3085d6',
                        cancelButtonColor: '#7c211e',
                        confirmButtonClass: "btn-danger",
                        confirmButtonText: 'aceptar',
                        closeOnConfirm: true,
                        closeOnCancel: false,
                    },
                            function (isConfirm)
                            {

                            });
                } else
                {
                    var result = "";
                    for (var i = 0; i < res.length; i++)
                    {
                        result = result + res[i] + "\n";
                    }
  //                  swal('Somit Regional', result, 'warning');
                }

            },
            complete: function ()
            {

            },
            error: function (digita)
            {

            }
        });



    return false;
}


onClear = () => {
  this.setState({
    telefono:'',
    contacto:'',
    mail:'',
    fechaOcurrencia:'',
    comentarios:'',
    selectedOption:'',
    selectedOptionDep:'',
    ckgm:'',
    ckcp:'',
    ckvd:'',
    cktit:'',
    ckcps:'',
    ckcpn:'',
    ckdep:'',
    files:[],
    archivos:[],
    datosdependiente:[],
    datospoliza:[],
    datoscertificado:[],
  });
}


onValidate = () => {
  var archivo = window.datoArchivo;
  //console.log(this.state.cktit);
  //console.log(this.state.ckdep);
  //console.log(this.state.ckvd);
  //console.log(this.state.ckgm);
if (this.state.telefono==='' ){
  this.state.valida=false
}else if (this.state.contacto==='' ){
  this.state.valida=false
}else if (this.state.mail==='' ){
  this.state.valida=false
}else if (this.state.selectedOption==='' ){
  this.state.valida=false
}else if (this.state.fechaOcurrencia==='' ){
  this.state.valida=false
}else if (this.state.comentarios==='' ){
  this.state.valida=false
}
else if (this.state.cktit==='' && this.state.ckdep===''){
  this.state.valida=false
}
else if (this.state.ckcps===false && this.state.ckcpn===false){
  this.state.valida=false
}
else if (this.state.ckvd===false && this.state.ckgm===false){
  this.state.valida=false
}

else if (archivo[0].length===0 ){
  this.state.valida=false
  swal('Atencion', 'Para dar tramite a tu reclamo es necesario que adjuntes la documentación del reclamo','warning');
}else{
  this.state.valida=true
}

return this.state.valida;
}

handleInputChangeConsu(event) {
  //console.log(event);
//  alert(event.target.name);
  const target = event.target;
  const value = target.type === 'checkbox' ? target.checked : target.value;
  const name = target.name;
  if (name==='ckdep'){
    //console.log(value);
      $('#ckdep').prop('checked', true);
     $('#div_dep').show();//('disabled');
      $('#cktit').prop('checked', false);
      $('#dep').prop('disabled', false);
      this.state.cktit = false;
        this.setState({
         [name]: value
       });
}else {
  $('#div_dep').hide();
    $('#ckdep').prop('checked', false);
    $('#cktit').prop('checked', true);
  this.state.ckdep = false;
    this.setState({
         [name]: value
       });
}
}


render (){


////console.log(files);
////console.log(files.key);
  let tiporec='';
  let comp='';
  let reclamode='';
  let dependiente='';
  let obs_adicional='';

  const alerta=()=>{



if(this.state.cktit ===true && this.state.ckdep===false ){
 reclamode=2;
 dependiente = 0;
}else if (this.state.cktit ===false && this.state.ckdep===true){
  reclamode=2;
  dependiente = this.state.selectedOptionDep.value;
}else{
  reclamode=2;
  dependiente = false;
}

if(this.state.ckgm ===true){
 tiporec=1;
}else{
  tiporec=2;
}

if(this.state.ckcps ===true ){
 comp=1;
}else{
  comp=2;
}

if(this.state.otros.length !=0){
obs_adicional = 'Diagnostico: '+this.state.otros;
}else{
obs_adicional = '';
}



var archivos = window.datoArchivo;
let tamano= 0;
for (var i = 0; i < archivos[0].length; i++) {

  //console.log(archivos[0][i].size);
  tamano = tamano + archivos[0][i].size;
}
//console.log(tamano);
if(tamano >= 5242880){
    swal('Atencion', 'No puedes exceder del limite de carga de 5 MB en tus archivos','warning');
}
else{
////console.log(datos);
////console.log(this.validate());
let vali=this.onValidate();
//console.log(vali);
    if (vali===true){
      let datos=[{
      'poliza':this.state.idPoliza,
      'certificado':this.state.idCertificado,
      'reclamode':reclamode,
      'dependiente':dependiente,
      'tiporec':tiporec,
      'fecha':Moment(this.state.fechaOcurrencia).format('L'),
      'enfermedad':this.state.selectedOption.value,
      'comentarios':obs_adicional +',' +this.state.comentarios,
      'contacto':this.state.contacto,
      'mail':this.state.mail,
      'telefono':this.state.telefono,
      'sucursal':1,
      'usuario':1,
      'archivos':window.datoArchivo,
      'comp':comp
    }]
        this.agregar_spinner_regional("dv_gmrec_blockgeneral");
      axios.post(`${process.env.REACT_APP_API_URL}/it-api-clientes2/rs/operaciones/new/`,datos,{
        headers: {
          'Authorization': `Bearer ${sessionStorage.getItem('jwtToken')}`,
        },
      })
    .then((response) => {
       setTimeout(this.limpiar_spinner('dv_gmrec_blockgeneral'), 8000);
      swal("Exitoso", "Tu solicitud ha sido creada existosamente. Seguimiento #: "+response.data, "success");

      this.state.reclamo = response.data;
      this.enviar_digitalizacion();
        this.onClear();
        //console.log(response);
      }, (error) => {
        //console.log(error);
      });
    }else{
    swal("Importante", "Debes completar los datos para que se genere el reclamo", "warning");
  }
}
  }
  let miVariable = window.miVariable;
  //console.log(miVariable);

  const options = this.state.datosEnfe;
  const dependientes= this.state.datosdependiente;

const pol = this.state.datospoliza;
//console.log(pol);

  const cert = this.state.datoscertificado;
  const { selectedOption } = this.state;
  const { selectedOptionDep } = this.state;
  const {selectedOptionPol} = this.state;
  const {selectedOptionCert} = this.state;
let cliente;
//console.log(selectedOption);
//console.log(selectedOptionPol);
//console.log('ingrse a este cliente');
  cliente=this.state.cliente;



  return (<div id="formulario" name="formulario">
    <Card>
      <CardHeader style={{background:'#872434', color:'white'}}>
      <strong> Formulario de ingreso </strong>
          </CardHeader>
        <CardBody>
          <Form className="was-validated">

        <row>

          <FormGroup row>

            <Col md="1">
              <strong><Label>Poliza:</Label></strong>
            </Col>
            <Col md="3">
            <Select id='pol' name='pol' options={pol} value={selectedOptionPol} onChange={this.handleChangePol}  />

          </Col>
            <Col md="1">
              <strong><Label>Certificado:</Label></strong>
            </Col>
            <Col md="2">
              <Select id="ce" name="ce" selectedValue={cert} options={cert} value={cert} defaultValue={cert}/>
            </Col>
            <Col md="1">
              <strong><Label>Titular:</Label></strong>
            </Col>
            <Col md="4">
               <Input type="text" id="disabled-input" name="disabled-input" value={cliente.replace(undefined, '')} placeholder="Titular" disabled  />
            </Col>
          </FormGroup>
        </row>

       <FormGroup row>

         <Col md="2">
           <strong><Label>Reclamo de:</Label></strong>
         </Col>
         <Col md="2">
           <FormGroup check className="radio">
             <input
               name="cktit"
               id="cktit"
               type="checkbox"
               checked={this.state.titu}
               onChange={this.handleInputChangeConsu} />&nbsp;&nbsp;
             <Label check className="form-check-label" htmlFor="radio1">Titular</Label>
         </FormGroup>
            </Col>
           <Col md="2">
             <FormGroup check className="radio">
               <input
                 name="ckdep"
                 id="ckdep"
                 type="checkbox"
                 checked={this.state.depe}
                 onChange={this.handleInputChangeConsu} />&nbsp;&nbsp;
             <Label check className="form-check-label" htmlFor="radio2">Dependiente</Label>

           </FormGroup>

         </Col>

         <Col md="2">
           <strong><Label>Tipo de Reclamo:</Label></strong>
         </Col>
         <Col md="2">
           <FormGroup check className="radio">
             <input
               name="ckgm"
               id="ckgm"
               type="checkbox"
               checked={this.state.ckgm}
               onChange={this.handleInputChangeTipo} />&nbsp;&nbsp;
             <Label check className="form-check-label" htmlFor="radio1">Gastos Medicos</Label>
         </FormGroup>
            </Col>
           <Col md="2">
             <FormGroup check className="radio">
               <input
                 name="ckvd"
                 id="ckvd"
                 type="checkbox"
                 checked={this.state.ckvd}
                 onChange={this.handleInputChangeTipo} />&nbsp;&nbsp;
             <Label check className="form-check-label" htmlFor="radio2">Vida</Label>
           </FormGroup>

         </Col>
       </FormGroup>
       <FormGroup row id="div_dep" name="div_dep">
          <Col md="3">
            <strong><Label>Dependiente</Label></strong>
          </Col>
          <Col xs="12" md="9" id="prueba" name="prueba">
            <Select  id='depend' name='depend' options={dependientes} value={selectedOptionDep} onChange={this.handleChangeDep}/>
          </Col>
        </FormGroup>
                     <FormGroup row>
                       <Col md="2">
                        <strong> <Label htmlFor="date-input">Fecha de Ocurrencia</Label></strong>
                       </Col>
                       <Col xs="12" md="10">
                         <Input className="form-control" required="required" type="date" value={this.state.fechaOcurrencia}  onChange={this.handleInputFecha} id="date-input" name="date-input" placeholder="date" />
                       </Col>
                     </FormGroup>
                     <FormGroup row>

                       <Col md="5">
                         <strong><Label>¿Presenta reclamo de este diagnóstico por primera vez?</Label></strong>
                       </Col>
                       <Col md="2">
                         <FormGroup check className="radio">
                           <input
                             name="ckcps"
                             id="ckcps"
                             type="checkbox"
                             checked={this.state.ckcps}
                             onChange={this.handleInputChangeComplement} />&nbsp;&nbsp;
                           <Label check className="form-check-label" htmlFor="radio1">Si</Label>
                       </FormGroup>
                          </Col>
                         <Col md="2">
                           <FormGroup check className="radio">
                             <input
                               name="ckcpn"
                               id="ckcpn"
                               type="checkbox"
                               checked={this.state.ckcpn}
                               onChange={this.handleInputChangeComplement} />&nbsp;&nbsp;
                           <Label check className="form-check-label" htmlFor="radio2">No</Label>
                         </FormGroup>

                       </Col>

                     </FormGroup>
                     <FormGroup row>
                       <Col md="12">
                         <strong><Label htmlFor="textarea-input">Enfermedad</Label></strong>
                       </Col>
                       <Col xl="12" md="12">
                          <Select options={options} value={selectedOption}
                              onChange={this.handleChange}/>
                       </Col>
                     </FormGroup>
                     <FormGroup row id="otros" name="otros" style={{display:'none'}}>
                       <Col md="12">
                         <strong><Label htmlFor="textarea-input">Otra Enfermedad</Label></strong>
                       </Col>
                       <Col xl="12" md="12">
                         <Input className="form-control" required="required" type="text" value={this.state.otros}  onChange={this.handleInputOtros} id="date-input" name="date-input" placeholder="Ingrese su enfermedad" />

                       </Col>
                     </FormGroup>
                     <FormGroup row>
                       <Col md="12">
                         <strong><Label htmlFor="textarea-input">Comentarios</Label></strong>
                      </Col>
                       <Col xl="12" md="12">
                         <Input className="form-control" required="required" value={this.state.comentarios} onChange={this.handleInputComentarios} type="textarea" name="textarea-input" id="textarea-input" rows="2"
                                placeholder="Ingrese comentarios del siniestro" />
                       </Col>
                     </FormGroup>
                     <div>
<strong>Detalles de Contacto</strong>
                     </div>
                     <hr style={{background:'#872434', size:5, color:'#872434'}} />
                       <FormGroup row>
                         <Col md="2">
                           <strong><Label>Contacto:</Label></strong>
                         </Col>
                         <Col md="2">
                             <Input className="form-control" required="required" value={this.state.contacto} onChange={this.handleInputContacto} type="text" id="disabled-input" name="disabled-input" placeholder="Contacto"  />
                         </Col>

                         <Col md="2">
                           <strong><Label>Email:</Label></strong>
                         </Col>
                         <Col md="2">
                            <Input className="form-control" required="required" value={this.state.mail} onChange={this.handleInputMail}type="email" id="disabled-input" name="disabled-input" placeholder="Email"  />

                         </Col>
                         <Col md="2">
                           <strong><Label>Telefono:</Label></strong>
                         </Col>
                         <Col md="2">
                            <Input className="form-control" required="required" value={this.state.telefono} onChange={this.handleInputTelefono} type="number" id="disabled-input" name="disabled-input" placeholder="Telefono" />

                         </Col>
                       </FormGroup>
                       <strong>Documentos</strong>
                    <hr style={{background:'#872434', size:5, color:'#872434'}} />
                      <div id="dv_gmrec_blockgeneral" style={{
                          display: 'none'
                        }}>
                        <div className="centrado-porcentual">
                          <div>
                            <div className="lockscreen-logo"></div>
                            <div className="lockscreen-name"></div>
                            <center><img src={loader}/></center>
                            <div className="help-block text-center"></div>
                            <div className="text-center"></div>
                            <div className="lockscreen-footer text-center">
                              Copyright &copy; 2021
                              <a href="http://somit.com" className="text-black">Somit S.A</a>&nbsp; All rights reserved
                            </div>
                          </div>
                        </div>
                      </div>
<center><h4>El limite de tamaño de archivos no puede exceder los 5 MB.</h4></center>



<div><Drop /></div>

       <br></br>
       <FormGroup className="form-actions">

                     <Button   style={{ float: 'right' }} size="sm"  onClick={() => alerta()}color="success">Generar</Button>
       </FormGroup>

     </Form>

      </CardBody>

    </Card>
  </div>

);
}
}
  export default parte2;
