import React, {Component} from 'react';

class gestiones extends Component
 {
  render() {
    return <iframe src="https://miseguro.somit.com" width="1400" height="800" frameborder="0"/>;
  }
}

export default gestiones;
