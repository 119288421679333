import React, { useState, useEffect} from 'react';
import BootStrapTable from "react-bootstrap-table-next";
import success from './check.png';
import fail from './checkx.jpg';
import warning from './checki.jpg';
import AsesorCobro from './asesorCobro.js';
import paginationFactory from "react-bootstrap-table2-paginator";
import {
  Col,
  Label,
  Input,
  Row
} from 'reactstrap';
import axios from './axios-config';
import swal from 'sweetalert';
import Moment from 'moment';
import $ from 'jquery';
import {Modal, Button} from 'react-bootstrap';
import Select from 'react-select';


const Pagination = () => {
  const [miVariableF, setMiVariableF] = useState([]);
  const [miVariableC, setMiVariableC] = useState([]);
  const [State, setState] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [show, setShow]= useState(false);
  const handleClose1 = () => setShow(false);
  const handleShow1 = () => setShow(true);
  const nuevoArray    = [];
  const [selectedOptionRam,setselectedOptionRam] =useState([]);


  const handleChange = selectedOptionRam => {
      $('#labelCliente').val(selectedOptionRam.label);
    let datosfac=[];
  let miVariableF='';
  let estado ="";
  let indicador ="";
  const aMoneda = (numero, opciones) => {
         // Valores por defecto
         opciones = opciones || {};
         opciones.simbolo = opciones.simbolo || "$";
         opciones.separadorDecimal = opciones.separadorDecimal || ".";
         opciones.separadorMiles = opciones.separadorMiles || ",";
         opciones.numeroDeDecimales = opciones.numeroDeDecimales >= 0 ? opciones.numeroDeDecimales : 2;
         opciones.posicionSimbolo = opciones.posicionSimbolo || "i";
         const CIFRAS_MILES = 3;

         // Redondear y convertir a cadena
         var num =parseFloat(numero);
         ////console.log(num);
         let numeroComoCadena = num.toFixed(opciones.numeroDeDecimales);

         // Comenzar desde la izquierda del separador o desde el final de la cadena si no se proporciona
         let posicionDelSeparador = numeroComoCadena.indexOf(opciones.separadorDecimal);
         if (posicionDelSeparador === -1) posicionDelSeparador = numeroComoCadena.length;
         let formateadoSinDecimales = "", indice = posicionDelSeparador;
         // Ir cortando desde la derecha de 3 en 3, y concatenar en una nueva cadena
         while (indice >= 0) {
             let limiteInferior = indice - CIFRAS_MILES;
             // Agregar separador si cortamos más de 3
             formateadoSinDecimales = (limiteInferior > 0 ? opciones.separadorMiles : "") + numeroComoCadena.substring(limiteInferior, indice) + formateadoSinDecimales;
             indice -= CIFRAS_MILES;
         }
         let formateadoSinSimbolo = `${formateadoSinDecimales}${numeroComoCadena.substr(posicionDelSeparador, opciones.numeroDeDecimales + 1)}`;
         return opciones.posicionSimbolo === "i" ? opciones.simbolo + formateadoSinSimbolo : formateadoSinSimbolo + opciones.simbolo;
     };
     // Probar
     const opcionesQuetzales = {
         numeroDeDecimales: 2,
         separadorDecimal: ".",
         separadorMiles: ",",
         simbolo: "Q ", // Con un espacio, ya que la función no agrega espacios
         posicionSimbolo: "i", // i = izquierda, d = derecha
     },
         opcionesDolares = {
             numeroDeDecimales: 2,
             separadorDecimal: ".",
             separadorMiles: ",",
             simbolo: " USD", // Con un espacio, ya que la función no agrega espacios
             posicionSimbolo: "d", // i = izquierda, d = derecha
         };


     const formatterQuetzales = new Intl.NumberFormat('es-GT', {
           style: 'currency',
           currency: 'GTQ',
           minimumFractionDigits: 2
         })


    var nuevoArray    = []
     var nuevoArrayMonto    = []
      var arrayTemporalMonto = []
        var arrayTemporal = []

     //console.log("selected1",selectedOptionRam);
     axios.get(`${process.env.REACT_APP_API_URL}/it-api-clientes2/rs/operaciones/estado/`+$('#idepol').val()+'/'+selectedOptionRam.value,{
      headers: {
        'Authorization': `Bearer ${sessionStorage.getItem('jwtToken')}`,
      },
    }).then(json => {
       //console.log('peticion');
       $('#datGen').hide();

        $('#datGen').hide();
          if (json.status === 200) {
            //console.log('servicio'+json.data.facturasClientesWeb);
               if( json.data.facturasClientesWeb.length>0){

//console.log('ingrese longitud')
     for (var i = 0; i < json.data.facturasClientesWeb.length; i++) {


       if (json.data.facturasClientesWeb[i].estado==='P'){
         estado = 'Pagado';
         indicador=success;
       }else if (json.data.facturasClientesWeb[i].estado==='A'){
         estado = 'Anulado';
         indicador=fail;
       }else{
         estado = 'Pendiente';
         indicador=warning;
       }
  if ($('#monedaPol').val()=='Q'){
     datosfac.push({afiliada:json.data.facturasClientesWeb[i].nombre,requerimiento:json.data.facturasClientesWeb[i].numeroFactura,

       monto:aMoneda(json.data.facturasClientesWeb[i].prima, opcionesQuetzales),
       vencimiento:Moment(json.data.facturasClientesWeb[i].fechaDeVencimiento).format('L'),
       fecha:json.data.facturasClientesWeb[i].fechaDePago ? Moment(json.data.facturasClientesWeb[i].fechaDePago).format('L') : '',
       estado:estado,
       indicador:<center><img height="35px" src={indicador} width="auto" alt="logo"/></center>
     });
}else{
  datosfac.push({afiliada:json.data.facturasClientesWeb[i].nombre,requerimiento:json.data.facturasClientesWeb[i].numeroFactura,

    monto:aMoneda(json.data.facturasClientesWeb[i].prima, opcionesDolares),
    vencimiento:Moment(json.data.facturasClientesWeb[i].fechaDeVencimiento).format('L'),
    fecha:json.data.facturasClientesWeb[i].fechaDePago ? Moment(json.data.facturasClientesWeb[i].fechaDePago).format('L') : '',
    estado:estado,
    indicador:<center><img height="35px" src={indicador} width="auto" alt="logo"/></center>
  });

}
   }
 }else{
   //console.log('ingreses a else');

   if (json.data.facturasClientesWeb.estado==='P'){
     estado = 'Pagado';
     indicador=success;
   }else if (json.data.facturasClientesWeb.estado==='A'){
     estado = 'Anulado';
     indicador=fail;
   }else{
     estado = 'Pendiente';
     indicador=warning;
   }
  if ($('#monedaPol').val()=='Q'){
 datosfac.push({afiliada:json.data.facturasClientesWeb.nombre,requerimiento:json.data.facturasClientesWeb.numeroFactura,

   monto:aMoneda(json.data.facturasClientesWeb.prima, opcionesQuetzales),
   vencimiento:Moment(json.data.facturasClientesWeb.fechaDeVencimiento).format('L'),
   fecha:json.data.facturasClientesWeb.fechaDePago ? Moment(json.data.facturasClientesWeb.fechaDePago).format('L') : '',
   estado:estado,
   indicador:<center><img height="35px" src={indicador} width="auto" alt="logo"/></center>
 });
}else{
  datosfac.push({afiliada:json.data.facturasClientesWeb.nombre,requerimiento:json.data.facturasClientesWeb.numeroFactura,

    monto:aMoneda(json.data.facturasClientesWeb.prima, opcionesDolares),
    vencimiento:Moment(json.data.facturasClientesWeb.fechaDeVencimiento).format('L'),
    fecha:json.data.facturasClientesWeb.fechaDePago ? Moment(json.data.facturasClientesWeb.fechaDePago).format('L') : '',
    estado:estado,
    indicador:<center><img height="35px" src={indicador} width="auto" alt="logo"/></center>
  });

}
 }

        //console.log(nuevoArray);
     miVariableF = datosfac;

     setTimeout(
      function()
      {
      }, 9000);
      setMiVariableF(miVariableF);
          } else {
            swal("Error", "No se encontro información de  Poliza", "warning");
          }
        }).catch(function(error) {

          swal("Atención", "La póliza no cuenta con facturas pendientes ni tampoco pagadas nuevo", "warning");
        })

   };

  const toogleTrueFalse1 = () => {
    //console.log('ingrese');
  setShowModal(handleShow1);
  };

  useEffect(async() => {
    let datosfac=[];
  let miVariableF='';
  let estado ="";
  let indicador ="";
  const aMoneda = (numero, opciones) => {
         // Valores por defecto
         opciones = opciones || {};
         opciones.simbolo = opciones.simbolo || "$";
         opciones.separadorDecimal = opciones.separadorDecimal || ".";
         opciones.separadorMiles = opciones.separadorMiles || ",";
         opciones.numeroDeDecimales = opciones.numeroDeDecimales >= 0 ? opciones.numeroDeDecimales : 2;
         opciones.posicionSimbolo = opciones.posicionSimbolo || "i";
         const CIFRAS_MILES = 3;

         // Redondear y convertir a cadena
         var num =parseFloat(numero);
         ////console.log(num);
         let numeroComoCadena = num.toFixed(opciones.numeroDeDecimales);

         // Comenzar desde la izquierda del separador o desde el final de la cadena si no se proporciona
         let posicionDelSeparador = numeroComoCadena.indexOf(opciones.separadorDecimal);
         if (posicionDelSeparador === -1) posicionDelSeparador = numeroComoCadena.length;
         let formateadoSinDecimales = "", indice = posicionDelSeparador;
         // Ir cortando desde la derecha de 3 en 3, y concatenar en una nueva cadena
         while (indice >= 0) {
             let limiteInferior = indice - CIFRAS_MILES;
             // Agregar separador si cortamos más de 3
             formateadoSinDecimales = (limiteInferior > 0 ? opciones.separadorMiles : "") + numeroComoCadena.substring(limiteInferior, indice) + formateadoSinDecimales;
             indice -= CIFRAS_MILES;
         }
         let formateadoSinSimbolo = `${formateadoSinDecimales}${numeroComoCadena.substr(posicionDelSeparador, opciones.numeroDeDecimales + 1)}`;
         return opciones.posicionSimbolo === "i" ? opciones.simbolo + formateadoSinSimbolo : formateadoSinSimbolo + opciones.simbolo;
     };
     // Probar
     const opcionesQuetzales = {
         numeroDeDecimales: 2,
         separadorDecimal: ".",
         separadorMiles: ",",
         simbolo: "Q ", // Con un espacio, ya que la función no agrega espacios
         posicionSimbolo: "i", // i = izquierda, d = derecha
     },
         opcionesDolares = {
             numeroDeDecimales: 2,
             separadorDecimal: ".",
             separadorMiles: ",",
             simbolo: " USD", // Con un espacio, ya que la función no agrega espacios
             posicionSimbolo: "d", // i = izquierda, d = derecha
         };


     const formatterQuetzales = new Intl.NumberFormat('es-GT', {
           style: 'currency',
           currency: 'GTQ',
           minimumFractionDigits: 2
         })



if ($('#idclienteF').val()=='2391'){
  //console.log('ingrese a especial');
  $('#especial').show();
  axios.get(`${process.env.REACT_APP_API_URL}/it-api-clientes2/rs/operaciones/estado/`+$('#idepol').val(),{
    headers: {
      'Authorization': `Bearer ${sessionStorage.getItem('jwtToken')}`,
    },
  }).then(json => {
    $('#datGen').hide();

     $('#datGen').hide();
    //console.log('servi'+json.status );
       if (json.status === 200) {
         var nuevoArray    = []
          var nuevoArrayMonto    = []
          var arrayTemporalMonto = []
            var arrayTemporal = [];
            //console.log('Ingrese al servicio'+json.data.facturasClientesWeb.length);
            if( json.data.facturasClientesWeb.length>0){


  for (var i = 0; i < json.data.facturasClientesWeb.length; i++) {


    if (json.data.facturasClientesWeb[i].estado==='P'){
      estado = 'Pagado';
      indicador=success;
    }else if (json.data.facturasClientesWeb[i].estado==='A'){
      estado = 'Anulado';
      indicador=fail;
    }else{
      estado = 'Pendiente';
      indicador=warning;
    }
  if ($('#monedaPol').val()=='Q'){
  datosfac.push({afiliada:json.data.facturasClientesWeb[i].nombre,requerimiento:json.data.facturasClientesWeb[i].numeroFactura,

    monto:aMoneda(json.data.facturasClientesWeb[i].prima, opcionesQuetzales),
    vencimiento:Moment(json.data.facturasClientesWeb[i].fechaDeVencimiento).format('L'),
    fecha:json.data.facturasClientesWeb[i].fechaDePago ? Moment(json.data.facturasClientesWeb[i].fechaDePago).format('L') : '',
    estado:estado,
    indicador:<center><img height="35px" src={indicador} width="auto" alt="logo"/></center>
  });
}else{
  datosfac.push({afiliada:json.data.facturasClientesWeb[i].nombre,requerimiento:json.data.facturasClientesWeb[i].numeroFactura,

    monto:aMoneda(json.data.facturasClientesWeb[i].prima, opcionesDolares),
    vencimiento:Moment(json.data.facturasClientesWeb[i].fechaDeVencimiento).format('L'),
    fecha:json.data.facturasClientesWeb[i].fechaDePago ? Moment(json.data.facturasClientesWeb[i].fechaDePago).format('L') : '',
    estado:estado,
    indicador:<center><img height="35px" src={indicador} width="auto" alt="logo"/></center>
  });

}
}
}else{
//console.log('ingrese a solo una factura');
if (json.data.facturasClientesWeb.estado==='P'){
  estado = 'Pagado';
  indicador=success;
}else if (json.data.facturasClientesWeb.estado==='A'){
  estado = 'Anulado';
  indicador=fail;
}else{
  estado = 'Pendiente';
  indicador=warning;
}
  if ($('#monedaPol').val()=='Q'){
datosfac.push({afiliada:json.data.facturasClientesWeb.nombre ,requerimiento:json.data.facturasClientesWeb.numeroFactura,

monto:aMoneda(json.data.facturasClientesWeb.prima, opcionesQuetzales),
vencimiento:Moment(json.data.facturasClientesWeb.fechaDeVencimiento).format('L'),
fecha:json.data.facturasClientesWeb.fechaDePago ? Moment(json.data.facturasClientesWeb.fechaDePago).format('L') : '',
estado:estado,
indicador:<center><img height="35px" src={indicador} width="auto" alt="logo"/></center>
});
}else{
  datosfac.push({afiliada:json.data.facturasClientesWeb.nombre ,requerimiento:json.data.facturasClientesWeb.numeroFactura,

  monto:aMoneda(json.data.facturasClientesWeb.prima, opcionesDolares),
  vencimiento:Moment(json.data.facturasClientesWeb.fechaDeVencimiento).format('L'),
  fecha:json.data.facturasClientesWeb.fechaDePago ? Moment(json.data.facturasClientesWeb.fechaDePago).format('L') : '',
  estado:estado,
  indicador:<center><img height="35px" src={indicador} width="auto" alt="logo"/></center>
  });

}
}

     //console.log(nuevoArray);
  miVariableF = datosfac;

  setTimeout(
   function()
   {
   }, 9000);
   setMiVariableF(miVariableF);
       } else {
         swal("Error", "No se encontro información de  Poliza", "warning");
       }
     }).catch(function(error) {

       swal("Atención", "La póliza no cuenta con facturas pendientes ni tampoco pagadas", "warning");
     })
  axios.get(`${process.env.REACT_APP_API_URL}/it-api-clientes2/rs/operaciones/idepol/`+$('#idepol').val(),{
    headers: {
      'Authorization': `Bearer ${sessionStorage.getItem('jwtToken')}`,
    },
  }).then(json => {
       if (json.status === 200) {
         //console.log(json.data.facturasClientesWebInfo);
if (json.data.facturasClientesWebInfo.length > 0){
           for (var i = 0; i < json.data.facturasClientesWebInfo.length; i++) {
             if (json.data.facturasClientesWebInfo[i].codigoCliente==json.data.facturasClientesWebInfo[i].codigoCliente){
        nuevoArray.push({
          value:json.data.facturasClientesWebInfo[i].codigoCliente,
          label:json.data.facturasClientesWebInfo[i].nombre
       });
     }
     }

}else{
  nuevoArray.push({
    value:json.data.facturasClientesWebInfo.codigoCliente,
    label:json.data.facturasClientesWebInfo.nombre
 });
}

//console.log(nuevoArray);
  setTimeout(
   function()
   {
   }, 9000);
 setMiVariableC(nuevoArray);
       } else {
         swal("Error", "No se encontro información de Pagadores", "warning");
       }
     }).catch(function(error) {

       swal("Atención", "La póliza no cuenta con pagadores", "warning");
     })
}else{
  //console.log('ingrese a no especial');
$('#especial').hide();
 
    var esAsazgua = false;
    var clienteGet = $('#idclienteF').val();
    if ($('#hdEsAzasgua').val()=="S"){
      esAsazgua = true;
      clienteGet = $('#hdClienteActual').val()
    }
    axios.get(`${process.env.REACT_APP_API_URL}/it-api-clientes2/rs/operaciones/estado/`+$('#idepol').val()+'/'+clienteGet,{
      headers: {
        'Authorization': `Bearer ${sessionStorage.getItem('jwtToken')}`,
      },
    }).then(json => {
      //console.log('servi'+json.status );
         if (json.status === 200) {
          var nuevoArray    = []
          var nuevoArrayMonto    = []
          var arrayTemporalMonto = []
          var arrayTemporal = [];
          
          //console.log('Ingrese al servicio'+json.data.facturasClientesWeb.length);
          if( json.data.facturasClientesWeb.length>0){
            for (var i = 0; i < json.data.facturasClientesWeb.length; i++) {
              if ((!esAsazgua)||((esAsazgua) && 
                                 (json.data.facturasClientesWeb[i].numeroPoliza == $('#hdNumPolAzasgua').val()) &&
                                 (json.data.facturasClientesWeb[i].codigoCliente ==$('#hdClienteActual').val()))){
              if (json.data.facturasClientesWeb[i].estado==='P'){
                estado = 'Pagado';
                indicador=success;
              }else if (json.data.facturasClientesWeb[i].estado==='A'){
                estado = 'Anulado';
                indicador=fail;
              }else{
                estado = 'Pendiente';
                indicador=warning;
              }
              if ($('#monedaPol').val()=='Q'){
                datosfac.push({requerimiento:json.data.facturasClientesWeb[i].numeroFactura,
                  monto:aMoneda(json.data.facturasClientesWeb[i].prima, opcionesQuetzales),
                  vencimiento:Moment(json.data.facturasClientesWeb[i].fechaDeVencimiento).format('L'),
                  fecha:json.data.facturasClientesWeb[i].fechaDePago ? Moment(json.data.facturasClientesWeb[i].fechaDePago).format('L') : '',
                  estado:estado,
                  indicador:<center><img height="35px" src={indicador} width="auto" alt="logo"/></center>
                });
              }else{
                datosfac.push({requerimiento:json.data.facturasClientesWeb[i].numeroFactura,
                  monto:aMoneda(json.data.facturasClientesWeb[i].prima, opcionesDolares),
                  vencimiento:Moment(json.data.facturasClientesWeb[i].fechaDeVencimiento).format('L'),
                  fecha:json.data.facturasClientesWeb[i].fechaDePago ? Moment(json.data.facturasClientesWeb[i].fechaDePago).format('L') : '',
                  estado:estado,
                  indicador:<center><img height="35px" src={indicador} width="auto" alt="logo"/></center>
                });
              }
            }
            }
          }else{
            //console.log('ingrese a solo una factura');
            if ((!esAsazgua)||((esAsazgua) && 
                                 (json.data.facturasClientesWeb.numeroPoliza == "537") &&
                                 (json.data.facturasClientesWeb.codigoCliente == $('#hdClienteAzasgua').val()))){
            if (json.data.facturasClientesWeb.estado==='P'){
              estado = 'Pagado';
              indicador=success;
            }else if (json.data.facturasClientesWeb.estado==='A'){
              estado = 'Anulado';
              indicador=fail;
            }else{
              estado = 'Pendiente';
              indicador=warning;
            }
            if ($('#monedaPol').val()=='Q'){
              datosfac.push({requerimiento:json.data.facturasClientesWeb.numeroFactura,
                monto:aMoneda(json.data.facturasClientesWeb.prima, opcionesQuetzales),
                vencimiento:Moment(json.data.facturasClientesWeb.fechaDeVencimiento).format('L'),
                fecha:json.data.facturasClientesWeb.fechaDePago ? Moment(json.data.facturasClientesWeb.fechaDePago).format('L') : '',
                estado:estado,
                indicador:<center><img height="35px" src={indicador} width="auto" alt="logo"/></center>
              });
            }else{
              datosfac.push({requerimiento:json.data.facturasClientesWeb.numeroFactura,
                monto:aMoneda(json.data.facturasClientesWeb.prima, opcionesDolares),
                vencimiento:Moment(json.data.facturasClientesWeb.fechaDeVencimiento).format('L'),
                fecha:json.data.facturasClientesWeb.fechaDePago ? Moment(json.data.facturasClientesWeb.fechaDePago).format('L') : '',
                estado:estado,
                indicador:<center><img height="35px" src={indicador} width="auto" alt="logo"/></center>
              });
            }
            }
          }
          //console.log(nuevoArray);
          miVariableF = datosfac;
          setTimeout(
            function()
            {     }, 9000);
            setMiVariableF(miVariableF);
          } else {
            swal("Error", "No se encontro información de  Poliza", "warning");
          }
        }).catch(function(error) {
          swal("Atención", "La póliza no cuenta con facturas pendientes ni tampoco pagadas", "warning");
        })
      }
    }, []);

    const columns=[];
    //console.log("id_cliente_f:",$('#idclienteF').val());
    if ($('#idclienteF').val()=='2391'){
      //console.log('ingrese a mcdonalds');
      columns.push(
        {dataField: "afiliada", text:"Afiliada"},
        {dataField: "requerimiento", text:"No. Requerimiento"},
        {dataField: "monto", text:"Prima Total"},
        {dataField: "vencimiento", text:"Vencimiento"},

        {dataField: "fecha", text:"Fecha de Pago"},
        {dataField: "estado", text:"Estado"}
      );
    }else{
      //console.log('ingrese a normal');
      columns.push(
        {dataField: "requerimiento", text:"No. Requerimiento"},
        {dataField: "monto", text:"Prima Total"},
        {dataField: "vencimiento", text:"Vencimiento"},
        {dataField: "fecha", text:"Fecha de Pago"},
        {dataField: "estado", text:"Estado de Requerimiento"},
        {dataField: "indicador", text:"Estado"}
      );
    }

  //render() {
  //const rx = miVariableC;
  return (

    <div>
      <Modal size="xl" show={show} onHide={handleClose1}>
            <Modal.Header  style={{background:'#003479', color:'white'}} closeButton>
            <Modal.Title >
             Información De Asesores De Cobro
            </Modal.Title>
          </Modal.Header>
            <Modal.Body>
    <AsesorCobro />
              </Modal.Body>
    </Modal>



          <br></br>
<div id="especial" name="especial" style={{display: 'none'}}>
  <h6>Debes de seleccionar un pagador para ver sus requerimientos de pago</h6>
  <Row>
            <Col xs="12" md="4">
             <Select id="dx" onChange={handleChange} name="dx" options={miVariableC} value={selectedOptionRam} />
                                  </Col>
                                  <Col xs="12" md="4">
                                   <Input type='text' id='labelCliente' name='labelCliente' disabled />
                                                        </Col>
                                                        </Row>
</div>
<br></br>
      <BootStrapTable style={{maxWidth: '1600px', width: '95%'}}
        keyField="name"
        data={miVariableF}
        columns = {columns}
        pagination={paginationFactory()}
            />

        <br></br>
    <Col xs="12" md="12" lg="4">
      <Label htmlFor="fecha" >
        <strong style={{
            color: '#872434'
          }}><a onClick={() => toogleTrueFalse1()}>Ver Información de tu Asesor de Cobro</a></strong>
      </Label>
    </Col>
  </div>

  );
};
export default Pagination;
