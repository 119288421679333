import React, { useState, useEffect} from 'react';
import styled from 'styled-components';
import logo2 from './logo2.jpg';
import pol from './logopoliza.png';
import rec from './reclamosgm.png';
import pag from './pagos.jpg';
import ase from './ase.png';
import all from './all.png';
import asistente_1102 from './colaboradores/1102.jpg';
import asistente_1200 from './colaboradores/1200.JPG';
import asistente_1279 from './colaboradores/1279.jpg';
import asistente_1175 from './colaboradores/1175.JPG';
import asistente_1216 from './colaboradores/1216.jpg';
import gerente_cobro from './colaboradores/1073.jpg';

import ejemplo from './ejemplo.jpg';
import ReactStars from "react-rating-stars-component";
import "./styles.css";
import asi from './LOGOGYT.png';
import {MultiStepForm, Step} from 'react-multi-form'
import {Button} from 'reactstrap';
import Forms from './Forms.js';
import Parte1 from './parte1.js';
import Parte2 from './parte2.js';
import TablaPoliza from './TablaPoliza.js';
import TablaDep from './TablaDep.js';
import TablaBen from './TabBen.js';
import axios from './axios-config';
import swal from 'sweetalert';
import $ from 'jquery';
import LoadingOverlay from 'react-loading-overlay';
import BounceLoader from 'react-spinners/RingLoader';
import Moment from 'moment';
import 'moment/locale/es';
import loader from './pageLoader.gif';
import 'bootstrap/dist/css/bootstrap.min.css';
import { FaUserAlt } from 'react-icons/fa';
import { FaMailBulk } from 'react-icons/fa';
import { FaPhoneAlt } from 'react-icons/fa';
import { FaAddressCard } from 'react-icons/fa';
import { FaMapMarker } from 'react-icons/fa';
import { FaCalculator } from 'react-icons/fa';
import Califica from "./calificacion.js";
import Select from 'react-select';
import {
  Card,
  CardBody,
  CardImg,
  CardTitle,
  CardText,
  CardHeader,
  CardFooter,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Row
} from 'reactstrap';

const Container = styled.div `
  max-width: 1800px;
  margin: 40px auto;
  @media(max-width: 690px) {
    width: 300px;
  }
`


const Poliza = () => {
  let validacion = 0;
  const [Cobrador, setCobrador] = useState([]);
    const [GerenteCobro, setGerenteCobro] = useState([]);
let asistente="";
let gerente="";
  useEffect(async() => {
    let datos=[];
    let sex="";
    let mivariblease="";
    let parentesco="";


    axios.get(`${process.env.REACT_APP_API_URL}/it-api-clientes2/rs/operaciones/asesorGerente/1073`,{
      headers: {
        'Authorization': `Bearer ${sessionStorage.getItem('jwtToken')}`,
      },
    }).then(json => {
         if (json.status === 200) {

           $('#nom_gers').text(json.data.asistentesTable[0].nombre);
           $("#email_ger").attr('href', 'mailto:'+json.data.asistentesTable[0].email).attr('target','_blank');
           $('#mail_ger').text(json.data.asistentesTable[0].email);
           $('#puesto_ger').text(json.data.asistentesTable[0].codigoPuesto);
           $('#telefono_ger').text('(502) '+ json.data.asistentesTable[0].telefonoDirecto+' Ext. '+json.data.asistentesTable[0].extension);
           $('#whatsapp_ger').text('(502) '+22856501);
           setGerenteCobro(gerente_cobro);

  setTimeout(
     function()
     {

       //do something special
     }, 9000);
//     setMiVariable(mivaribleDep);
         } else {
           swal("Error", "No se encontro información de  Asesor", "warning");
         }
       }).catch(function(error) {

         swal("Error", "No eee encontro  información de Asesor", "warning");
       })

       axios.get(`${process.env.REACT_APP_API_URL}/it-api-clientes2/rs/operaciones/asesorCobrador/`+$('#idclienteF').val()+'/'+sessionStorage.getItem('ejecutivoEncargado').toString(),{
        headers: {
          'Authorization': `Bearer ${sessionStorage.getItem('jwtToken')}`,
        },
      }).then(json => {
            if (json.status === 200) {

              $('#nom_cob').text(json.data.asistentesTable[0].nombre);
              $("#email_cob").attr('href', 'mailto:'+json.data.asistentesTable[0].email).attr('target','_blank');
              $('#mail_cob').text(json.data.asistentesTable[0].email);
              $('#puesto_asis').text(json.data.asistentesTable[0].codigoPuesto);
              $('#telefono_cob').text('(502) '+ json.data.asistentesTable[0].telefonoDirecto+' Ext. '+json.data.asistentesTable[0].extension);
              $('#whatsapp_asis').text('(502) '+22856501);
            //console.log(json.data.asistentesTable[0].codigoAsistente);
              if (json.data.asistentesTable[0].codigoAsistente===1200) {setCobrador(asistente_1200);}
              else if (json.data.asistentesTable[0].codigoAsistente===1175){setCobrador(asistente_1175);}
              else if(json.data.asistentesTable[0].codigoAsistente===1216){setCobrador(asistente_1216);}
              else if(json.data.asistentesTable[0].codigoAsistente===1102){setCobrador(asistente_1102);}
              else{setCobrador(asistente_1279);}


     setTimeout(
        function()
        {

          //do something special
        }, 9000);
   //     setMiVariable(mivaribleDep);
            } else {
              swal("Error", "No se encontro información de  Asesor", "warning");
            }
          }).catch(function(error) {

            swal("Error", "No eee encontro  información de Asesor", "warning");
          })

  }, []);



  const secondExample = {
    size: 50,
    count: 10,
    color: "black",
    activeColor: "yellow",
    value: 7.5,
    a11y: true,
    isHalf: true,
    emptyIcon: <i className="far fa-star" />,
    halfIcon: <i className="fa fa-star-half-alt" />,
    filledIcon: <i className="fa fa-star" />,
    onChange: newValue => {
      //console.log(`Example 2: new value is ${newValue}`);
    }
  };


  const agregar_spinner_regional = (id_objeto) => {
    $("#" + id_objeto).addClass("overlay");
    $("#" + id_objeto).show();
  }

  const ratingChanged = (newRating) => {
    //console.log(newRating);
  };

  const limpiar_spinner = (id_objeto) => {
    //console.log("ingrese a limpieza");
    $('#' + id_objeto).removeClass("overlay");
    $('#' + id_objeto).html("");
    $("#" + id_objeto).hide();
  }

  const [active, setActive] = React.useState(1)
  const [loading, setLoading] = React.useState(false)
  return (<Container>
        <div className="row">
          <div  className="col-xs-12 col-md-6" align="left">
            <div className="col-xs-12 col-md-12" align="center">
              <strong style={{
                  color: '#003479'
                }}>Información Cobrador</strong>
                <hr style={{
                    background: '#872434',
                    size: 5,
                    color: '#872434'
                  }}/>
            </div>
  <p>Con el puedes gestionar el cobro de la poliza así como también obtener las facturas correspondientes a dichos pagos
  </p>

            <div className="col-xs-12 col-md-12" align="left">
            <center><img height="250px" src={Cobrador} width="225px" alt="logo"/></center>



            </div>
            <div className="col-xs-12 col-md-12" align="left">
              <br></br>
              <Col xs="12" md="12" lg="12">
              <Label htmlFor="Nombre">
                <strong>Nombre:</strong>
              </Label>&nbsp;<label id="nom_cob" name="nom_cob" value="" />
            </Col>
            <br></br>

            <Col xs="12" md="12" lg="12">
              <Label htmlFor="Puesto">
                <strong>Puesto:</strong>
              </Label>&nbsp;<label >Asistente de Cobro</label>
            </Col>
            <br></br>

            <Col xs="12" md="12" lg="12">
              <Label htmlFor="Email">
                <strong>Email:</strong>&nbsp;<a id="email_cob" name="email_cob"><label id="mail_cob" name="mail_cob" value="" /></a>
              </Label>
            </Col>
            <br></br>

            <Col xs="12" md="12" lg="12">
              <Label htmlFor="Telefono">
                <strong>Telefono:</strong>&nbsp;<a href="tel:+50223868310" target="_blank" ><label id="telefono_cob" name="telefono_cob" value="" /></a>
              </Label>
            </Col>
            <br></br>

            <Col xs="12" md="12" lg="12">
              <Label htmlFor="emisWhatsappion">
                <strong>Whatsapp:</strong>&nbsp;<a href='https://wa.me/50222856501' target="_blank"><label id="whatsapp_asis" name="whatsapp_asis" value="" /></a>
              </Label>
            </Col>



                </div>
  </div>
          <div  className="col-xs-12 col-md-6" align="left">
            <div className="col-xs-12 col-md-12" align="center">
              <strong style={{
                  color: '#003479'
                }}>Información Gerente</strong>
                <hr style={{
                    background: '#872434',
                    size: 5,
                    color: '#872434'
                  }}/>
              </div>
              <br></br>
                <br></br>
            <div className="col-xs-12 col-md-12" align="left">
            <center><img height="250px" src={GerenteCobro} width="225px" alt="logo"/></center>
            </div>
            <div className="col-xs-12 col-md-12" align="left">
              <br></br>
              <Col xs="12" md="12" lg="12">
              <Label htmlFor="Nombre">
                <strong>Nombre:</strong>
              </Label>&nbsp;<label id="nom_gers" name="nom_gers" value=""></label>
            </Col>
            <br></br>

            <Col xs="12" md="12" lg="12">
              <Label htmlFor="Puesto">
                <strong>Puesto:</strong>
              </Label>&nbsp;<label id="pu_ger" name="pu_ger" value="Gerente de Cuenta" >Gerente de Cobros</label>
            </Col>
            <br></br>

            <Col xs="12" md="12" lg="12">
              <Label htmlFor="Email">
                <strong>Email:</strong>&nbsp;<a id='email_ger' name='email_ger'><label id="mail_ger" name="mail_ger" value="" ></label></a>
              </Label>
            </Col>
            <br></br>

            <Col xs="12" md="12" lg="12">
              <Label htmlFor="Telefono">
                <strong>Telefono:</strong>&nbsp;<a href="tel:+50223868310" target="_blank">
                  <label id="telefono_ger" name="telefono_ger" value="" ></label></a>
              </Label>
            </Col>
            <br></br>

            <Col xs="12" md="12" lg="12">
              <Label htmlFor="emisWhatsappion">
                <strong>Whatsapp:</strong>&nbsp;<a href='https://wa.me/50222856501' target="_blank">
                  <label title="Whatsapp" id="whatsapp_ger" name="whatsapp_ger" value=""></label></a>
              </Label>
            </Col>


            </div>
          </div>



</div>



  </Container>)
}

export default Poliza
